import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LetDirective } from '@ngrx/component';
import { Observable, of } from 'rxjs';

import {
  DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN,
  DashboardExpandCandidateStateFacade,
} from '../../providers/expand/dashboard-expand-candidate-state-facade';

/**
 * A "widget" in the context of the Dashboard, is a card-like container that can be expanded to show more content.
 *
 * Conceptually, there are three variants of the widget:
 * 1. Grid - rendered as a card inside the Dashboard
 * 2. Singleton - the only component rendered. The "expanded" view of the widget that includes drill-in content
 * 3. Mobile - bellow a certain breakpoint, widgets are rendered one after another with their intrinsic height.
 *
 * The card is always visible in the grid. In the "singleton" or expanded view of the dashboard, the "drill-in" content of the
 * widget is rendered and the card has set dimensions, defined by the component's inputs.
 */
@Component({
  selector: 'ninety-dashboard-widget',
  standalone: true,
  imports: [CommonModule, LetDirective],
  styleUrls: ['./dashboard-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngrxLet="isExpanded$ as showDrillIn">
      <section
        [ngStyle]="{
            maxWidth: showDrillIn ? singletonWidth : '100%',
            height: showDrillIn ? singletonHeight : '100%',
        }">
        <header class="dashboard-widget-header">
          <ng-content select="[header]"></ng-content>
        </header>
        <div class="dashboard-widget-content">
          <ng-content></ng-content>
        </div>
        <footer class="dashboard-widget-footer">
          <ng-content select="[footer]"></ng-content>
        </footer>
      </section>

      <div class="dashboard-widget-drill-in" *ngIf="showDrillIn">
        <ng-content select="[drill-in]"></ng-content>
      </div>
    </ng-container>
  `,
})
export class DashboardWidgetComponent implements OnInit {
  /** The height of the widget in the singleton view */
  @Input() singletonHeight = '450px';
  /** The width of the widget in the singleton view */
  @Input() singletonWidth = '780px';

  protected isExpanded$: Observable<boolean>;
  constructor(
    @Optional()
    @Inject(DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN)
    private readonly facade: DashboardExpandCandidateStateFacade,
    private readonly host: ElementRef,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    if (!this.facade) {
      /*console.warn(
        'No provider injecting DashboardExpandCandidateStateFacade. Are you missing the candidate directive? ' +
          'Forgot to mock out the provider?'
      );*/
      this.isExpanded$ = of(false);
      return;
    }

    this.isExpanded$ = this.facade.isExpanded$;
    this.facade.isExpanded$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isExpanded => {
      if (isExpanded) {
        this.renderer.addClass(this.host.nativeElement, 'singleton');
        this.renderer.removeClass(this.host.nativeElement, 'grid');
      } else {
        this.renderer.removeClass(this.host.nativeElement, 'singleton');
        this.renderer.addClass(this.host.nativeElement, 'grid');
      }

      // Ensure view reacts to sizing changes while moving between grid and singleton
      this.cdr.markForCheck();
    });
  }
}
