import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { selectLanguage } from '@ninety/ui/legacy/state/index';
import { FilterBarActions } from '@ninety/web/pages/shared/layouts';

@Component({
  standalone: true,
  imports: [CommonModule, TerraIconModule, NinetyCMModule, LetDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ninety-my-ninety-tiered-page',
  template: `<ninety-cm-tiered-page *ngrxLet="language$ as language">
    <div headline>Manage Your Priorities in One Place</div>
    <div description>
      Upgrade your plan to access My 90 and seamlessly manage your {{ language.todo.items }}, key performance indicators
      {{ language.my90.measurableChart }}, and {{ language.rock.items }}. Stay organized, track progress, and gain full
      visibility across your team to drive results
    </div>
    <button ninety-cm-button>
      <terra-icon icon="lightning" variant="fill" />
      Explore Accelerate Plan
    </button>
    <a href="{{ myNinetyUrl }}" rel="external" target="_blank">Learn more about My 90</a>
  </ninety-cm-tiered-page>`,
})
export class MyNinetyTieredPageComponent implements OnInit {
  private readonly store = inject(Store);

  language$ = this.store.select(selectLanguage);
  myNinetyUrl =
    'https://help.ninety.io/accessing-your-account/my-90?from_search=173120559&swifty_search_highlight=My90';

  ngOnInit(): void {
    this.store.dispatch(FilterBarActions.hide());
  }
}
