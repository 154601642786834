<ng-container *ngrxLet="vm$ as vm">
  <h1 mat-dialog-title>Schedule a {{ vm.language.meeting.item }}</h1>
  <h2 mat-dialog-subtitle>Select an agenda, date and presenter for your {{ vm.language.meeting.item }}</h2>

  <mat-dialog-content>
    <form [formGroup]="form" class="parent" data-cy="schedule-meeting-modal">
      <div class="agenda">
        <terra-form-field>
          <terra-form-label>Agenda</terra-form-label>
          <terra-select
            formControlName="agenda"
            placeholder="Select Agenda"
            maxHeight="450"
            data-cy="schedule-meeting-dialog_agenda">
            <terra-option
              *ngFor="let agenda of vm.agendas"
              [value]="agenda"
              attr.data-cy="schedule-meeting-dialog_agenda-option-{{ agenda.displayText }}">
              {{ agenda.displayText }}
            </terra-option>
          </terra-select>
        </terra-form-field>
      </div>
      <div class="date">
        <div class="_terra-migration-approved-override--terra-mat-form-field">
          <terra-form-label>Date</terra-form-label>
          <mat-form-field appearance="outline" class="date__field _terra-migration-approved-override--mat-form-field">
            <input
              matInput
              [min]="minDate"
              [matDatepicker]="dueDatePicker"
              aria-label="date picker"
              formControlName="date"
              data-cy="schedule-meeting-dialog_date"
              (dateChange)="dateChanged($event.value)" />
            <mat-datepicker-toggle matSuffix [for]="dueDatePicker">
              <terra-icon matDatepickerToggleIcon icon="calendar" />
            </mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="time-wrp">
        <div class="_terra-migration-approved-override--terra-mat-form-field">
          <terra-form-label>Time</terra-form-label>

          <div class="time-fields-wrp">
            <terra-number-input
              class="time-hh"
              min="1"
              max="12"
              aria-label="hours"
              formControlName="hours"
              placeholder="hh"
              data-cy="schedule-meeting-dialog_hour" />
            <terra-number-input
              class="time-mm"
              min="0"
              max="59"
              aria-label="minutes"
              formControlName="minutes"
              placeholder="mm"
              data-cy="schedule-meeting-dialog_minutes" />
            <div class="period" data-cy="schedule-meeting-dialog_period">
              <terra-select [required]="true" formControlName="period">
                <terra-option [value]="TimePeriod.AM">{{ TimePeriod.AM }}</terra-option>
                <terra-option [value]="TimePeriod.PM">{{ TimePeriod.PM }}</terra-option>
              </terra-select>
            </div>
          </div>
        </div>
      </div>
      <div class="cadence">
        <terra-form-field>
          <terra-form-label>Repeating {{ vm.language.meeting.item }} Cadence</terra-form-label>
          <terra-select formControlName="cadence" data-cy="schedule-meeting-dialog_cadence">
            <terra-option
              *ngFor="let option of cadence$ | async"
              attr.data-cy="schedule-meeting-dialog_cadence-option-{{ option.value }}"
              class="cadence-option"
              [value]="option.value">
              {{ option.name }}
            </terra-option>
          </terra-select>
        </terra-form-field>
      </div>
      <div class="facilitator">
        <terra-form-field>
          <terra-form-label>Facilitator</terra-form-label>
          <terra-select
            formControlName="presenterId"
            maxHeight="300"
            placeholder="Select facilitator"
            data-cy="schedule-meeting-dialog_presenter">
            <terra-option *ngFor="let user of vm.users" [value]="user.value">{{ user.name }}</terra-option>
          </terra-select>
        </terra-form-field>
      </div>
      <div class="scribe">
        <terra-form-field>
          <terra-form-label>Scribe</terra-form-label>
          <terra-select
            formControlName="scribeId"
            maxHeight="300"
            placeholder="Select scribe"
            data-cy="schedule-meeting-dialog_scribe">
            <terra-option [value]="undefined">None</terra-option>
            <terra-option *ngFor="let user of vm.users" [value]="user.value">{{ user.name }}</terra-option>
          </terra-select>
        </terra-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <ninety-button secondary stroked mat-dialog-close class="right-space-10">Cancel</ninety-button>
    <ninety-button filled primary (click)="save()" [disabled]="!form.valid" data-cy="schedule-meeting-dialog_save">
      <terra-icon icon="save" />
      Save
    </ninety-button>
  </mat-dialog-actions>
</ng-container>
