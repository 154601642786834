<ninety-card class="activate-user-dialog">
  <div class="card-header">
    <div class="terra-title-small">License Count Update</div>
    <ninety-button flat compact (click)="close()"><terra-icon icon="x" size="24" /></ninety-button>
  </div>

  <div class="dialog-body">
    You have
    <span class="terra-body-bold"
      >{{ data.counts.assignableSeats }} unassigned
      {{ data.counts.assignableSeats === 1 ? 'license' : 'licenses' }}</span
    >. You are about to add
    <span class="terra-body-bold">
      {{ data.paidSeatsToAdd }} paid {{ data.paidSeatsToAdd === 1 ? 'role' : 'roles' }}</span
    >. The prorated difference will be charged to your payment method immediately for the
    <span class="terra-body-bold"> additional {{ licensesToAdd }} {{ licensesToAdd ? 'license' : 'licenses' }}</span
    >.
  </div>

  <ninety-card-footer>
    <ninety-button-row>
      <ninety-button data-cy="cancel-add-licenses-button" stroked (click)="close()">Go back</ninety-button>
      <ninety-button data-cy="confirm-add-licenses-button" filled primary (click)="addLicenses()">
        Add {{ licensesToAdd }} license{{ licensesToAdd === 1 ? '' : 's' }}
      </ninety-button>
    </ninety-button-row>
  </ninety-card-footer>
</ninety-card>
