import { Pipe, PipeTransform } from '@angular/core';

import { Unit } from '../models/scorecard/unit.enum';

/**
 * Takes a Unit or list of Units and returns a joined string of the units.
 */
@Pipe({
  name: 'unitName',
})
export class UnitNamePipe implements PipeTransform {
  transform(value: Unit | Unit[]): string {
    return UnitNamePipe.getNameString(value);
  }

  static getNameString(value: Unit | Unit[]): string {
    if (!value) return '';

    if (Array.isArray(value)) return value.map(value => UnitNamePipe.getNameString(value)).join(', ');

    if (value === Unit.yesno) return 'yes/no';
    return String(value);
  }
}
