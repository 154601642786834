import { isGsgTargetRole } from '../../../functions/is-gsg-target-role';
import { RoleCode } from '../../../models/_shared/role-code';

import { AddTeammateFormData } from './add-teammate-form';

export class NewUserDto {
  active: boolean;
  roleCode: RoleCode;

  /** required for active roles */
  email?: string;
  isImplementer?: boolean;
  shouldInvite?: boolean;
  guideEnabled?: boolean;

  /** required for active roles other than coach/implementer */
  teamIds?: string[];

  /** required for inactive role */
  firstName?: string;
  lastName?: string;

  public static fromFormData({ role, ...form }: AddTeammateFormData, isGSGTargetCompany: boolean): NewUserDto {
    if (!role.active) return toInactiveRole(form);
    if (role.isImplementer) return toCoachRole(form);
    return {
      active: true,
      isImplementer: false,
      roleCode: role.roleCode,
      email: form.email,
      teamIds: form.teamIds,
      shouldInvite: form.shouldInvite,
      guideEnabled: isGSGTargetCompany && isGsgTargetRole(role),
    };
  }
}

function toInactiveRole({ firstName, lastName, email }: Omit<AddTeammateFormData, 'role'>): NewUserDto {
  return {
    firstName,
    lastName,
    email,
    active: false,
    roleCode: RoleCode.observer,
  };
}

function toCoachRole({ email, shouldInvite }: Omit<AddTeammateFormData, 'role'>): NewUserDto {
  return {
    email,
    shouldInvite,
    teamIds: null,
    active: true,
    roleCode: RoleCode.owner,
    isImplementer: true,
    guideEnabled: false,
  };
}
