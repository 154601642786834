<div ninetyElementResizeAware class="pagination">
  <ng-container *ngIf="paginationStore.vm$ | async as vm">
    <ng-container *ngIf="vm.smallestPageSize < vm.length">
      <ninety-button
        compact
        class="navigation-button previous-button"
        (click)="paginationStore.previousPage()"
        [disabled]="!vm.canDecrement">
        <terra-icon icon="caret-left" size="16" class="page-icon" valign="sub" />
        <span>{{ vm.previousText }}</span>
      </ninety-button>

      <ninety-button
        compact
        class="navigation-button next-button"
        (click)="paginationStore.nextPage()"
        [disabled]="!vm.canIncrement">
        <span>{{ vm.nextText }}</span>
        <terra-icon icon="caret-right" size="16" class="right-icon page-icon" valign="sub" />
      </ninety-button>

      <div *ngIf="vm.showPageSizeSelectWrapper" class="page-size-box">
        <span *ngIf="vm.showDescriptiveText" class="showing">Show&nbsp;</span>
        <div class="page-size-select">
          <terra-select
            [ngModel]="vm.currentSelectOption"
            (ngModelChange)="paginationStore.reactToSelectChange($event)"
            [disabled]="vm.disablePageSizeSelect">
            <terra-option
              *ngFor="let option of vm.allSelectOptions"
              [disabled]="option.isItemDisabled"
              attr.data-cy="select_option-{{ option.text }}"
              [value]="option"
              >{{ option.text }}</terra-option
            >
          </terra-select>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
