<div
  class="phase-list-container"
  *ngrxLet="{
    title: title$,
    subtitle: subtitle$,
    isTrialing: isTrialing$,
    daysLeftInTrial: daysLeftInTrial$,
    isManagerOrAbove: isManagerOrAbove$,
    phases: phases$,
    isFreeTier: isFreeTier$,
  } as vm">
  <div class="header-and-days-remaining">
    <div class="header">
      <div class="title">{{ vm.title }}</div>
      <ninety-button flat icon class="close-btn" (click)="close()" data-cy="phase-list-close-button">
        <terra-icon icon="x" size="20" />
      </ninety-button>
    </div>
    <div class="days-remaining" *ngIf="vm.isTrialing" [class.week-left]="vm.daysLeftInTrial <= 7">
      <terra-icon *ngIf="vm.daysLeftInTrial <= 14" icon="warning-triangle" variant="fill" size="16" />
      <div [class.regular-text]="vm.daysLeftInTrial <= 14">{{ vm.daysLeftInTrial }} days remaining in your trial</div>
      <a *ngIf="vm.isManagerOrAbove" (click)="openPricingTierDialog()" class="subscribe-now">Subscribe now</a>
    </div>
  </div>

  <div class="phase-list">
    <ng-container *ngIf="vm.isFreeTier">
      <div class="blurred-overlay"></div>
      <ninety-card class="subscribe-now-card">
        <div class="terra-body">Subscribe now to continue unlocking the power of Ninety.</div>
        <button ninety-cm-button (click)="openPricingTierDialog()">
          <terra-icon icon="lightning" variant="fill"></terra-icon>
          Subscribe now
        </button>
      </ninety-card>
    </ng-container>

    <div class="subtitle">{{ vm.subtitle }}</div>

    <ninety-phase
      *ngFor="let phase of vm.phases; let i = index"
      [phase]="phase"
      [previousPhaseTitle]="vm.phases[i - 1]?.title"
      (click)="selectPhase(phase)" />
  </div>
</div>
