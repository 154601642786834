import { createReducer, on } from '@ngrx/store';

import type { Entitlements } from '../../../_shared/models/entitlements/entitlements-model';

import { EntitlementsStateActions } from './entitlements-state.actions';
import { EntitlementsState, InitialEntitlementsState } from './entitlements-state.model';

export const EntitlementsStateReducer = createReducer<EntitlementsState>(
  InitialEntitlementsState,
  on(
    EntitlementsStateActions.parseEntitlementsTokenSuccess,
    (state, { entitlements }): EntitlementsState => ({
      ...state,
      entitlements,
    })
  ),
  on(
    EntitlementsStateActions.clearEntitlements,
    (state): EntitlementsState => ({
      ...state,
      entitlements: {
        scopes: [],
      } as Entitlements,
    })
  )
);
