import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';

// eslint-disable-next-line terra/enforce-terra-module-import
import { TerraDividerModule, TerraFormFieldModule, TerraTextInputComponent, TerraTextInputModule } from '@ninety/terra';

import { CountryISO } from './models/country-iso.enum';
import { Country } from './models/country.model';
import { CountrySearchPipe } from './pipes/country-search.pipe';
import { PhoneCountryService } from './services/phone-country.service';

@Component({
  selector: 'ninety-country-phone-code-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    CountrySearchPipe,
    TerraDividerModule,
    TerraFormFieldModule,
    TerraTextInputModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./country-phone-code.component.scss'],
  template: `
    <button
      type="button"
      mat-button
      [matMenuTriggerFor]="menu"
      class="country-selector"
      [disabled]="disabled"
      (menuOpened)="onMenuOpened()">
      <span class="country-selector-flag flag" [ngClass]="selectedCountry?.flagClass"></span>
      <span class="country-selector-code" *ngIf="displayDialCode && selectedCountry?.dialCode">
        +{{ selectedCountry?.dialCode }}
      </span>
      <img class="arrow-drop-down-img" src="/assets/images/phone-number/arrow_drop_down_grey600_18dp.png" />
    </button>
    <mat-menu #menu="matMenu" panelClass="mat-menu-country-list">
      <div class="country-search-wrapper">
        <terra-form-field>
          <terra-text-input
            #countrySearchInput
            [formControl]="searchCriteria"
            [placeholder]="placeholder"
            (click)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()" />
        </terra-form-field>
      </div>
      <terra-divider />
      <button
        type="button"
        mat-menu-item
        class="country-list-button"
        *ngFor="let country of preferredCountriesInDropDown"
        (click)="onCountrySelect(country)">
        <div class="icon-wrapper">
          <div class="flag" [ngClass]="country.flagClass"></div>
        </div>
        <div class="label-wrapper">
          {{ country.name }}
          <span *ngIf="country?.dialCode">+{{ country.dialCode }}</span>
        </div>
      </button>
      <terra-divider *ngIf="preferredCountriesInDropDown?.length" />
      <div style="max-height: 400px; overflow-y: auto;">
        <button
          *ngFor="let country of countrySvc.allCountries | countrySearch : searchCriteria.value"
          type="button"
          mat-menu-item
          class="country-list-button"
          [attr.data-cy]="'country-phone-code_' + country.name"
          (click)="onCountrySelect(country)">
          <div class="icon-wrapper">
            <div class="flag" [ngClass]="country.flagClass"></div>
          </div>
          <div class="label-wrapper">{{ country.name }} +{{ country.dialCode }}</div>
        </button>
      </div>
    </mat-menu>
  `,
})
export class CountryPhoneCodeComponent implements OnInit {
  @Input() disabled = false;
  @Input() preferredCountries: Array<CountryISO> = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
    CountryISO.Australia,
  ];
  @Input() placeholder = 'Search...';
  @Input() displayDialCode = true;
  @Input() selectedCountry;

  @Output() countryChanged = new EventEmitter<Country>();

  @ViewChild('countrySearchInput', { static: false }) countrySearchInput: TerraTextInputComponent;

  preferredCountriesInDropDown: Array<Country> = [];
  searchCriteria = new FormControl('');

  constructor(public countrySvc: PhoneCountryService) {}

  public ngOnInit(): void {
    if (!this.placeholder) {
      this.placeholder = 'Search ...';
    }
    if (this.preferredCountries.length) {
      this.preferredCountries.forEach(iso2 => {
        const preferredCountry = this.countrySvc.allCountries.filter(c => c.iso2 === iso2).shift();
        if (preferredCountry) {
          this.preferredCountriesInDropDown.push(preferredCountry);
        }
      });
    }
  }

  public onCountrySelect(country: Country): void {
    this.countryChanged.emit(country);
  }

  public onMenuOpened(): void {
    setTimeout(() => {
      this.countrySearchInput.focus();
    }, 0);
  }
}
