import { CommonModule, NgForOf, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { LetDirective, PushPipe } from '@ngrx/component';

import {
  TerraDividerModule,
  TerraFormFieldModule,
  TerraIconModule,
  TerraSearchInputModule,
  TerraTextInputModule,
} from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';
import { HelpfulComponent } from '@ninety/web/pages/layouts/components/helpful/helpful.component';

import { AddHelpfulComponent } from './components/add-helpful/add-helpful.component';
import { EmailSuppressionListComponent } from './components/email-suppression-list/email-suppression-list.component';
import { HardDeleteCompanyComponent } from './components/hard-delete-company/hard-delete-company.component';

@NgModule({
  declarations: [HelpfulComponent, AddHelpfulComponent, EmailSuppressionListComponent, HardDeleteCompanyComponent],
  exports: [HelpfulComponent],
  imports: [
    CommonModule,
    MatCardModule,
    CardModule,
    LetDirective,
    NinetyTooltipDirective,
    TerraIconModule,
    MatInputModule,
    TerraSearchInputModule,
    ReactiveFormsModule,
    TerraDividerModule,
    TerraTextInputModule,
    SharedModule,
    ButtonComponent,
    NgIf,
    MatListModule,
    MatProgressSpinnerModule,
    NgForOf,
    PushPipe,
    MatTabsModule,
    TerraFormFieldModule,
  ],
})
export class HelpfulModule {}
