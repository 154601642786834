<h1 mat-dialog-title>Print Agenda</h1>
<div class="header">Please select what agenda you would like to be printed</div>
<ninety-button flat icon class="close-dialog-btn" (click)="dialogRef.close()">
  <terra-icon icon="x" />
</ninety-button>
<terra-divider height="short" />
<mat-dialog-content>
  <div class="content" *ngrxLet="language$ as language">
    <ul>
      <li>
        <a class="menu-item" (click)="printAgenda(MeetingAgendaType.weekly)">
          <terra-icon icon="calendar" />
          <span id="meeting-level-ten-label">{{ language.meeting.levelTen }}</span>
        </a>
      </li>
      <li>
        <a class="menu-item" (click)="printAgenda(MeetingAgendaType.quarterly)">
          <terra-icon icon="calendar" />
          <span id="meeting-level-ten-label">{{ language.meeting.quarterlySession }}</span>
        </a>
      </li>
      <li>
        <a class="menu-item" (click)="printAgenda(MeetingAgendaType.annualDayOne)">
          <terra-icon icon="calendar" />
          <span id="meeting-annual-session-label">{{ language.meeting.annualSession }}</span>
        </a>
      </li>
      <li>
        <a class="menu-item" (click)="printAgenda(MeetingAgendaType.weeklyOneOnOne)">
          <terra-icon icon="calendar" />
          <span id="meeting-annual-session-label">Weekly One on One</span>
        </a>
      </li>

      <li *ngFor="let customMeetingAgenda of customMeetingSettings$ | async; let i = index">
        <a class="menu-item" (click)="printAgenda(MeetingAgendaType.custom, customMeetingAgenda.id)">
          <terra-icon icon="lightning" />
          <span [id]="'custom-meeting-agenda-label' + i">{{ customMeetingAgenda.displayText }}</span>
        </a>
      </li>
    </ul>
  </div>
</mat-dialog-content>
<terra-divider height="short" />
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <ninety-button stroked mat-dialog-close>Cancel</ninety-button>
</mat-dialog-actions>
