import { CommonModule } from '@angular/common';
import { Component, DestroyRef, ElementRef, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, of } from 'rxjs';

import {
  TerraCheckboxModule,
  TerraDividerModule,
  TerraFormFieldModule,
  TerraIconModule,
  TerraOptionModule,
  TerraSearchInputModule,
  TerraSelectModule,
  TerraTextInputModule,
} from '@ninety/terra';
import { NinetyChipComponent } from '@ninety/ui/legacy/components/ninety-chip/ninety-chip.component';

import { ButtonComponent } from '../../../_components/buttons/button/button.component';
import { CardModule } from '../../../_components/cards/card.module';
import { EMAIL_REGEX } from '../../../_core';
import {
  AddTeammatesActions,
  CurrentUserSelectors,
  FeatureFlagKeys,
  selectCompanyBillingCounts,
  selectCurrentUserIsAdminOrOwner,
  selectFeatureFlag,
  selectIsEmailAvailable,
  selectIsPaidPlan,
  selectRoleSelectOptions,
  SubscriptionActions,
} from '../../../_state';
import { RoleSelectOption } from '../../models/_shared/role-select-option';
import { Team } from '../../models/_shared/team';
import { NameSearchPipe } from '../../pipes/name-search.pipe';
import { SharedModule } from '../../shared.module';
import { NinetyValidators } from '../../validators/ninety-validators';

import { AddTeammatesDialogShouldShowTeamOptionPipe } from './add-teammates-dialog-option.pipe';
import { AddTeammateForm, AddTeammateFormData } from './models/add-teammate-form';
import { AddTeammatesDialogData } from './models/add-teammates-dialog-data';

@Component({
  selector: 'ninety-add-teammates-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    ButtonComponent,
    TerraIconModule,
    PushPipe,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TerraTextInputModule,
    TerraFormFieldModule,
    LetDirective,
    TerraOptionModule,
    TerraDividerModule,
    TerraSearchInputModule,
    TerraSelectModule,
    TerraCheckboxModule,
    NinetyChipComponent,
    AddTeammatesDialogShouldShowTeamOptionPipe,
  ],
  templateUrl: './add-teammates-dialog.component.html',
  styleUrls: ['./add-teammates-dialog.component.scss'],
  providers: [NameSearchPipe],
})
export class AddTeammatesDialogComponent implements OnInit {
  protected readonly roles$ = this.store.select(selectRoleSelectOptions);
  protected readonly inactiveAsRole$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.directoryAddUsersModal));
  protected readonly counts$ = this.store.select(selectCompanyBillingCounts);
  protected readonly isPaidPlan$ = this.store.select(selectIsPaidPlan);

  protected readonly teams$ = this.store.select(CurrentUserSelectors.selectTeams);
  protected readonly currentUserIsAdminOrOwner$ = this.store.select(selectCurrentUserIsAdminOrOwner);

  addTeammatesFormArray: FormArray<FormGroup<AddTeammateForm>>;
  _teamSearchInput = '';
  protected _teamSearchResults: Team[] = [];
  _initialFormValue: AddTeammateFormData[];

  @ViewChild('addTeammatesDialogBody') addTeammatesDialogBody: ElementRef;

  constructor(
    private readonly store: Store,
    @Inject(MAT_DIALOG_DATA) private readonly data: AddTeammatesDialogData,
    private readonly dialogRef: MatDialogRef<AddTeammatesDialogComponent>,
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly nameSearchPipe: NameSearchPipe
  ) {}

  ngOnInit(): void {
    this._initializeForm();
  }

  addAnotherTeammate(): void {
    this.addTeammatesFormArray.push(this._createAddTeammateFormGroup());
    setTimeout(() => {
      this.addTeammatesDialogBody.nativeElement.scrollTop = this.addTeammatesDialogBody.nativeElement.scrollHeight;
    }, 0);
  }

  removeForm(i: number): void {
    if (this.addTeammatesFormArray.length === 1) {
      this.addTeammatesFormArray.at(i).reset({ shouldInvite: true });
      return;
    }
    this.addTeammatesFormArray.removeAt(i);
  }

  save(): void {
    this.store.dispatch(AddTeammatesActions.addTeammates({ userForms: this.addTeammatesFormArray.getRawValue() }));
  }

  close(): void {
    this._hasChanges()
      ? this.store.dispatch(AddTeammatesActions.openUnsavedChangesDialog())
      : this.store.dispatch(AddTeammatesActions.closeAddTeammatesDialog());
  }

  goToDataImport(): void {
    this.router.navigateByUrl('settings/company/data-import');
    this.store.dispatch(AddTeammatesActions.closeAddTeammatesDialog());
  }

  goToBilling() {
    this.store.dispatch(SubscriptionActions.goToBillingManageSeats());
    this.dialogRef.close();
  }

  private _createAddTeammateFormGroup(data?: AddTeammateFormData): FormGroup<AddTeammateForm> {
    const group = this.fb.group({
      role: new FormControl<RoleSelectOption>(data?.role || null, [Validators.required]),
      email: new FormControl<string>(data?.email || null, {
        asyncValidators: (control: AbstractControl) =>
          of(control.value).pipe(
            distinctUntilChanged(),
            debounceTime(300),
            concatLatestFrom(email => this.store.select(selectIsEmailAvailable(email))),
            map(([email, isEmailAvailable]) => (!email || isEmailAvailable ? null : { emailTaken: true }))
          ),
      }),
      firstName: new FormControl<string>(data?.firstName || null),
      lastName: new FormControl<string>(data?.lastName || null),
      teamIds: new FormControl<string[]>(data?.teamIds || null),
      shouldInvite: new FormControl<boolean>(data?.shouldInvite || true),
    });

    /** when role changes, set other fields validators and show/hide accordingly */
    group.controls.role.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((role: RoleSelectOption) => this._setRoleValidators(role, group));

    if (data?.role) this._setRoleValidators(data.role, group);

    return group;
  }

  private _setRoleValidators(role: RoleSelectOption, group: FormGroup<AddTeammateForm>): void {
    if (role.active) this._setActiveRoleValidators(group, role.isImplementer);
    else this._setInactiveRoleValidators(group);
  }

  private _setInactiveRoleValidators(group: FormGroup<AddTeammateForm>): void {
    group.controls.firstName.setValidators(Validators.required);
    group.controls.lastName.setValidators(Validators.required);
    group.controls.email.setValidators([
      Validators.email,
      Validators.pattern(EMAIL_REGEX),
      NinetyValidators.noDuplicateEmailsInFormArray(this.addTeammatesFormArray),
    ]);
    group.controls.teamIds.clearValidators();
    this._updateValueAndValidity(group);
  }

  /** roles other than inactive and coach */
  private _setActiveRoleValidators(group: FormGroup<AddTeammateForm>, isCoach: boolean): void {
    group.controls.firstName.clearValidators();
    group.controls.lastName.clearValidators();
    group.controls.email.setValidators([
      Validators.required,
      Validators.email,
      Validators.pattern(EMAIL_REGEX),
      NinetyValidators.noDuplicateEmailsInFormArray(this.addTeammatesFormArray),
    ]);
    if (isCoach) group.controls.teamIds.clearValidators();
    else group.controls.teamIds.setValidators(Validators.required);
    this._updateValueAndValidity(group);
  }

  /** kinda dumb that group.updateValueAndValidity() doesn't update everything */
  private _updateValueAndValidity(group: FormGroup<AddTeammateForm>): void {
    group.controls.firstName.updateValueAndValidity();
    group.controls.lastName.updateValueAndValidity();
    group.controls.email.updateValueAndValidity();
    group.controls.teamIds.updateValueAndValidity();
  }

  private _initializeForm(): void {
    this.addTeammatesFormArray = new FormArray<FormGroup<AddTeammateForm>>(
      this.data.savedFormData.map(form => this._createAddTeammateFormGroup(form))
    );

    this._initialFormValue = this.addTeammatesFormArray.getRawValue();

    this.addTeammatesFormArray.valueChanges
      .pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this._hasChanges()) this.dialogRef.disableClose = true;
      });
  }

  private _hasChanges(): boolean {
    return JSON.stringify(this.addTeammatesFormArray.getRawValue()) !== JSON.stringify(this._initialFormValue);
  }

  protected _compareRole(a: RoleSelectOption, b: RoleSelectOption) {
    return a?.userRole === b?.userRole;
  }

  protected _search(teams: Team[], searchTerm: string) {
    this._teamSearchResults = this.nameSearchPipe.transform(teams, searchTerm);
  }
}
