import ObjectId from 'bson-objectid';
import { merge } from 'lodash';

import { User } from '../models/_shared/user';
import { Accountability } from '../models/accountability-chart/accountability';
import { SeatHolder } from '../models/accountability-chart/seat-holder';
import { SeatModel } from '../models/accountability-chart/seat.model';
import { createUser } from '../testing/user-data-factories';

export function createSeatHolderFromUser(user: User, partial?: Partial<SeatHolder>): SeatHolder {
  const _default: SeatHolder = {
    _id: new ObjectId().toHexString(),
    chartId: new ObjectId().toHexString(),
    companyId: '',
    deleted: false,
    managerSeatId: '',
    ordinal: 0,
    seatId: '',
    userId: user._id,
    user,
  };
  return merge(_default, partial);
}

/** Create a seat designed for the Seat component */
export function createSeat(opts?: {
  partial?: Partial<SeatModel>;
  user?: User;
  nAccountabilities?: number;
  nSeatHolders?: number;
}): SeatModel {
  const chartId = opts?.partial?.chartId ?? new ObjectId().toHexString();
  const user = opts?.user ?? createUser();
  const seatHolders: SeatHolder[] =
    opts?.partial?.seatHolders ??
    Array.from({ length: opts?.nSeatHolders ?? 1 }, () =>
      createSeatHolderFromUser(user, {
        ordinal: 0,
        chartId,
      })
    );

  const accountabilities: Accountability[] =
    opts?.partial?.accountabilities ??
    Array.from({ length: opts?.nAccountabilities ?? 3 }, (_, i) =>
      Accountability.from({ name: `Accountability ${i + 1}`, chartId, ordinal: i })
    );

  const seat: SeatModel = {
    _id: new ObjectId().toHexString(),
    companyId: new ObjectId().toHexString(),
    name: 'Seat Name',
    visionary: false,
    integrator: false,
    ordinal: 0,
    seatHolders,
    accountabilities,
    chartId,
    attachments: [],
  };

  return merge({}, seat, opts?.partial);
}
