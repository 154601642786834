import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';

export enum DirectoryUserRole {
  all = 'all',
  owner = 'owner',
  admin = 'admin',
  manager = 'manager',
  teamMember = 'team-member',
  coach = 'coach',
  observer = 'observer',
  inactive = 'inactive',
}

export const DirectoryUserRoleFromRoleCode = {
  [RoleCode.owner]: DirectoryUserRole.owner,
  [RoleCode.admin]: DirectoryUserRole.admin,
  [RoleCode.manager]: DirectoryUserRole.manager,
  [RoleCode.managee]: DirectoryUserRole.teamMember,
  [RoleCode.observer]: DirectoryUserRole.observer,
  // coach has owner roleCode and isImplementer = true
  // inactive has observer roleCode and active = false
};
