import { ErrorWithId } from '@ninety/ui/legacy/shared/models/errors/error-with-id';

import { DiscoColor } from './disco-color.model';

export class DiscoGradient {
  private constructor(
    public readonly colors: ReadonlyArray<DiscoColor>,
    public readonly positions: ReadonlyArray<number>
  ) {
    if (positions.length !== colors.length) throw GradiantParseError.wrongLength(positions.length, colors.length);
    if (positions.length < 2) throw GradiantParseError.notEnoughColors();
    if (positions[0] !== 0) throw GradiantParseError.firstPositionNotZero();
    if (positions.at(-1) !== 1) throw GradiantParseError.lastPositionNotOne();

    for (let i = 0; i < positions.length; i++) {
      const position = positions[i];
      if (!position && position !== 0) throw GradiantParseError.invalidPosition(i, position);
      if (position < 0 || position > 1) throw GradiantParseError.invalidPosition(i, position);
    }
  }

  static fromGradientArray(gradient: [number, DiscoColor][]): DiscoGradient {
    const colors = gradient.map(([_, color]) => color);
    const positions = gradient.map(([position, _]) => position);
    return new DiscoGradient(colors, positions);
  }

  static fromPositionAndColors(positions: ReadonlyArray<number>, colors: ReadonlyArray<DiscoColor>): DiscoGradient;
  static fromPositionAndColors(positions: number[], colors: DiscoColor[]): DiscoGradient {
    return new DiscoGradient(colors, positions);
  }

  /** Get the gradiant color at a specific point, where point is value between 0 and 1 representing where on the gradiant the point is */
  getColorAt(point: number): DiscoColor {
    if (point < 0 || point > 1) throw GradiantParseError.invalidPoint(point);
    if (point === 0) return this.colors[0];
    if (point === 1) return this.colors.at(-1);

    // We find the two colors that the point falls between.
    let colorRange: [number, number] = [0, 1];
    for (let i = 1; i < this.positions.length; i++) {
      if (point === this.positions[i]) return this.colors[i];

      if (point < this.positions[i]) {
        colorRange = [i - 1, i];
        break;
      }
    }

    // We get the two colors and their positions.
    const firstColor = this.colors[colorRange[0]];
    const secondColor = this.colors[colorRange[1]];
    const firstColorPosition = this.positions[colorRange[0]];
    const secondColorPosition = this.positions[colorRange[1]];

    // We calculate the distance of the point from the first color position, relative to the distance between the two
    // colors. This gives us a ratio that we can use to interpolate between the two colors.
    const rangeDistance = secondColorPosition - firstColorPosition;
    const pointDistance = point - firstColorPosition;
    const ratio = pointDistance / rangeDistance;

    // We interpolate between the two colors using the ratio and return the resulting color.
    const w2 = ratio;
    const w1 = 1 - w2;
    return DiscoColor.fromRGBA(
      Math.round(firstColor.r * w1 + secondColor.r * w2),
      Math.round(firstColor.g * w1 + secondColor.g * w2),
      Math.round(firstColor.b * w1 + secondColor.b * w2)
    );
  }
}

export class GradiantParseError extends ErrorWithId {
  private constructor(message: string) {
    super(message, 'GradiantParseError');
  }

  static wrongLength(positionsLength: number, colorsLength: number): GradiantParseError {
    return new GradiantParseError(
      `Positions and colors must be the same length. Positions: ${positionsLength}, Colors: ${colorsLength}`
    );
  }

  static notEnoughColors(): GradiantParseError {
    return new GradiantParseError('Must have at least two colors');
  }

  static firstPositionNotZero(): GradiantParseError {
    return new GradiantParseError('First position must be 0');
  }

  static lastPositionNotOne(): GradiantParseError {
    return new GradiantParseError('Last position must be 1');
  }

  static invalidPosition(index: number, position: number): GradiantParseError {
    return new GradiantParseError(`Position at index ${index} must be between 0 and 1. Got ${position}`);
  }

  static invalidPoint(point: number): GradiantParseError {
    return new GradiantParseError(`Point must be between 0 and 1. Got ${point}`);
  }
}
