export enum LegacyPeriodInterval {
  weekly = 'weekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  annual = 'annual',
  trailingFourWeeks = 'trailingFourWeeks',
  trailingThirteenWeeks = 'trailingThirteenWeeks',
}

export enum ScorecardPeriodToHideCurrentPeriod {
  weekly = 'week',
  monthly = 'month',
  quarterly = 'quarter',
  annual = 'year',
  trailingFourWeeks = 'week',
  trailingThirteenWeeks = 'week',
}
