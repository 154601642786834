import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { KpiManagerPagedResultQueryParams } from '../../_shared/models/scorecard/kpi-manager-paged-result-query-params';
import { KpiManagerPagedResult } from '../../_shared/models/scorecard/kpi-manager-paged-result.type';

@Injectable({
  providedIn: 'root',
})
export class KpiManagerApiService {
  constructor(private http: HttpClient) {}

  private getApiUrl(companyId: string) {
    return `/api/v4/scorecard-api/${companyId}/kpi-manager`;
  }

  bulkArchiveKpisById({ companyId, kpiIds }: { companyId: string; kpiIds: string[] }): Observable<void> {
    return this.http.post<void>(`${this.getApiUrl(companyId)}/bulk-archive`, { kpiIds });
  }

  bulkUnarchiveKpisById({ companyId, kpiIds }: { companyId: string; kpiIds: string[] }): Observable<void> {
    return this.http.post<void>(`${this.getApiUrl(companyId)}/bulk-unarchive`, { kpiIds });
  }

  bulkDeleteKpisById({ companyId, kpiIds }: { companyId: string; kpiIds: string[] }): Observable<void> {
    return this.http.post<void>(`${this.getApiUrl(companyId)}/bulk-delete`, { kpiIds });
  }

  bulkDuplicateKpisById({ companyId, kpiIds }: { companyId: string; kpiIds: string[] }): Observable<void> {
    return this.http.post<void>(`${this.getApiUrl(companyId)}/bulk-duplicate`, { kpiIds });
  }

  bulkReassignKpisById({
    companyId,
    kpiIds,
    assignToUserId,
  }: {
    companyId: string;
    kpiIds: string[];
    assignToUserId: string;
  }): Observable<void> {
    return this.http.post<void>(`${this.getApiUrl(companyId)}/bulk-reassign`, { kpiIds, assignToUserId });
  }

  getPagedResult({ companyId, queryParams }: { companyId: string; queryParams: KpiManagerPagedResultQueryParams }) {
    const httpParams = this.filterQueryParams(queryParams);

    return this.http.get<KpiManagerPagedResult>(this.getApiUrl(companyId), {
      params: httpParams,
    });
  }

  getCsvExport({
    companyId,
    queryParams,
  }: {
    companyId: string;
    queryParams: KpiManagerPagedResultQueryParams;
  }): Observable<string> {
    const httpParams = this.filterQueryParams(queryParams);

    return this.http.get(`${this.getApiUrl(companyId)}/export-csv`, {
      headers: {
        Accept: 'text/csv',
      },
      responseType: 'text',
      params: httpParams,
    });
  }

  /**
   * Creates an instance of @type {HttpParams} from a record of primitive values, omitting keys for null/undefined
   * values.
   */
  private filterQueryParams(queryParams: Record<string, string | number | boolean | null | undefined>) {
    const httpParams = Object.entries(queryParams).reduce((acc, [key, value]) => {
      // Don't send undefined queryParams, simply omit them instead
      if (value == null) return acc;

      return acc.append(key, value);
    }, new HttpParams());

    return httpParams;
  }
}
