import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { TerraFormFieldModule, TerraIconModule, TerraMultilineInputModule, TerraTextInputModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';

import {
  AccountabilityListItem,
  AccountabilityListItemTextChange,
  Identifiable,
} from '../../../models/accountabilities';

@Component({
  selector: 'ninety-accountability-list-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TerraIconModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    TerraFormFieldModule,
    TerraTextInputModule,
    TerraMultilineInputModule,
  ],
  templateUrl: './accountability-list-item.component.html',
  styleUrls: ['./accountability-list-item.component.scss'],
})
export class AccountabilityListItemComponent implements AfterViewInit {
  @ViewChild('nameInput', { static: false, read: ElementRef }) nameInputElRef: ElementRef;

  @Input() canEdit: boolean;
  @Input()
  item: AccountabilityListItem;

  @Output() clickedRemove = new EventEmitter<AccountabilityListItem>();
  @Output() save = new EventEmitter<AccountabilityListItemTextChange>();
  @Output() enterEdit = new EventEmitter<Identifiable>();
  @Output() exitEdit = new EventEmitter<Identifiable>();

  nameCopy = '';
  descriptionCopy = '';

  get canSave() {
    const nameChanged = this.nameCopy !== this.item.accountability.name;
    const descriptionChanged = this.descriptionCopy !== this.item.accountability.description;
    const nameIsNotEmpty = !!this.nameCopy.length;

    return nameIsNotEmpty && (nameChanged || descriptionChanged);
  }

  /**
   * When an accountability is added, it should be set to open the edit form. If the edit form is open,
   * we want to focus the rolename input for the user.
   */
  ngAfterViewInit() {
    this.nameInputElRef?.nativeElement?.focus();
  }

  resetForm() {
    this.nameCopy = '';
    this.descriptionCopy = '';
  }

  enterEditMode() {
    this.enterEdit.emit({ id: this.item.id });

    this.nameCopy = this.item.accountability.name;
    this.descriptionCopy = this.item.accountability.description;
  }

  exitEditMode() {
    this.exitEdit.emit({ id: this.item.id });

    this.resetForm();
  }

  onClickSave() {
    if (!this.canSave) {
      return;
    }

    const event: AccountabilityListItemTextChange = {
      id: this.item.id,
      name: this.nameCopy,
      description: this.descriptionCopy,
    };

    this.save.emit(event);

    this.resetForm();
  }
}
