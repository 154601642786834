import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MainLayoutService {
  private _mainPageContent: HTMLElement;
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get mainPageContent(): HTMLElement {
    /**
     * We should not attempt to cache the mainPageContent HTMLElement.
     * Ex: if (this._mainPageContent) return this._mainPageContent;
     * When the element is removed from the DOM and then created again, caching returns the old reference
     * This can happen when creating a company, taking an assessment, or any other time the MainLayoutComponent is destroyed.
     */

    // Attempt to define, but return undefined if the content cannot be reached.
    this._mainPageContent = this.document.getElementById('main-page-content');
    if (!this._mainPageContent) {
      //console.error('Failed to access main-page-content');
    }
    return this._mainPageContent;
  }

  /**
   * @deprecated
   * Bottom padding solution no longer works on the main content.  If extra padding is needed please add it within the actual routed content
   */
  setMainLayoutBottomPadding(em: number) {
    // this.removeClass('with-bottom-padding')
    // this.setStyle('padding-bottom', `${em}em`);
  }

  /**
   * @deprecated
   * Bottom padding solution no longer works on the main content.  If extra padding is needed please add it within the actual routed content
   */
  unsetMainLayoutBottomPadding() {
    // this.removeStyle('padding-bottom');
    // this.setClass('with-bottom-padding');
  }

  setStyle(style: string, value: any, flags?: RendererStyleFlags2) {
    setTimeout(() => {
      if (!this.mainPageContent) {
        // console.error('Cannot set style on main-page-content');
        return;
      }
      this.renderer.setStyle(this.mainPageContent, style, value, flags);
    });
  }

  removeStyle(style: string, flags?: RendererStyleFlags2) {
    setTimeout(() => {
      if (!this.mainPageContent) {
        // console.error('Cannot remove style on main-page-content');
        return;
      }

      this.renderer.removeStyle(this.mainPageContent, style, flags);
    });
  }

  setClass(cssClass: string) {
    setTimeout(() => {
      if (!this.mainPageContent) {
        // console.error('Cannot set class on main-page-content');
        return;
      }

      this._addClass(cssClass);
    });
  }

  removeClass(cssClass: string) {
    setTimeout(() => {
      if (!this.mainPageContent) {
        // console.error('Cannot remove class on main-page-content');
        return;
      }

      this._removeClass(cssClass);
    });
  }

  private _addClass(cssClass: string) {
    this.renderer.addClass(this.mainPageContent, cssClass);
  }

  private _removeClass(cssClass: string) {
    this.renderer.removeClass(this.mainPageContent, cssClass);
  }
}
