import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, take } from 'rxjs';

import { BillingTypes } from '@ninety/ui/legacy/shared/models/company/billing-types.enum';
import { selectCompany } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';

import { StateService } from '../services/state.service';

import { STRIPE_BILLING_PATH } from './active-subscription.guard';

@Injectable({
  providedIn: 'root',
})
export class BillingGuard {
  constructor(
    private readonly store: Store,
    private readonly stateService: StateService,
    private readonly router: Router
  ) {}

  /** only use for Billing V2 */
  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(selectCompany).pipe(
      take(1),
      map(company => {
        switch (company.billingType) {
          case BillingTypes.BillingV2:
            return true;
          case BillingTypes.BillingV3:
            return this.router.createUrlTree(['/settings/billing-v3']);
          default:
            return this.getBillingUrlForCompany();
        }
      })
    );
  }

  private getBillingUrlForCompany(): UrlTree {
    if (!this.stateService?.isManagerOrAbove) {
      return this.router.parseUrl('/settings/user/profile');
    }

    return this.router.parseUrl(`settings/${STRIPE_BILLING_PATH}`);
  }
}
