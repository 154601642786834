<mat-spinner color="accent" *ngIf="spinner"></mat-spinner>
<h1 mat-dialog-title>Add a {{ stateService.language.milestone.item }}</h1>
<mat-dialog-content>
  <div class="form-wrp gap-16">
    <div class="form-field top-row">
      <ninety-user-select [userId]="data.rock.userId" (selectUser)="onSelectUser($event)"> </ninety-user-select>
      <terra-form-field class="title-form-field">
        <terra-text-input
          data-cy="milestone-create-dialog_title-input"
          matInput
          placeholder="Title"
          [(ngModel)]="milestone.title"
          required />
      </terra-form-field>
      <mat-form-field
        data-cy="milestone-create-dialog_due-date"
        class="date-picker _terra-migration-approved-override--mat-form-field"
        appearance="outline">
        <input
          matInput
          [matDatepicker]="dueDatePicker"
          placeholder="Due Date"
          [(ngModel)]="milestone.dueDate"
          required />
        <mat-datepicker-toggle matSuffix [for]="dueDatePicker">
          <terra-icon matDatepickerToggleIcon icon="calendar" size="16" />
        </mat-datepicker-toggle>
        <mat-datepicker #dueDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <ninety-text-editor
    data-cy="milestone-create-dialog_description"
    [(text)]="milestone.description"></ninety-text-editor>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <ninety-button
    size="large"
    secondary
    stroked
    class="cancel-button button"
    data-cy="milestone-create-dialog_cancel-button"
    (click)="closeMilestoneDialog()">
    <span>Cancel</span>
  </ninety-button>
  <ninety-button
    primary
    filled
    size="large"
    class="add-button button"
    data-cy="milestone-create-dialog_add-button"
    color="accent"
    (click)="addMilestoneToRock()"
    [disabled]="spinner || !milestoneIsValid()">
    <span>Add</span>
  </ninety-button>
</mat-dialog-actions>
