<mat-spinner color="accent" *ngIf="spinner"></mat-spinner>
<div class="container tools-hub">
  <mat-card class="ninety-card" *ngrxLet="settings$ as settings">
    <h4 class="orange bottom-space-10 terra-body-bold">ToolsHub Add-ons</h4>
    <div class="section-options">
      <div class="option" *ngIf="!hideDash$ | ngrxPush">
        <terra-switch
          class="company-departmental-option"
          aria-label="Dashboard"
          [(ngModel)]="settings.dashboard"
          (ngModelChange)="updateSettings('dashboard', $event)">
          Dashboard
        </terra-switch>
      </div>

      <div class="option">
        <terra-switch
          class="company-departmental-option"
          aria-label="Survey"
          [disabled]="true"
          [(ngModel)]="settings.survey"
          (ngModelChange)="updateSettings('survey', $event)">
          Survey
        </terra-switch>
      </div>
      <div class="option">
        <terra-switch
          class="company-departmental-option"
          aria-label="CRM"
          [disabled]="true"
          [(ngModel)]="settings.crm"
          (ngModelChange)="updateSettings('crm', $event)">
          CRM
        </terra-switch>
      </div>
      <div class="option">
        <terra-switch
          class="company-departmental-option"
          aria-label="Hiring"
          [disabled]="true"
          [(ngModel)]="settings.hiring"
          (ngModelChange)="updateSettings('hiring', $event)">
          Hiring
        </terra-switch>
      </div>
      <div class="option">
        <terra-switch
          class="company-departmental-option"
          aria-label="Pipeline"
          [disabled]="true"
          [(ngModel)]="settings.pipeline"
          (ngModelChange)="updateSettings('pipeline', $event)">
          Pipeline
        </terra-switch>
      </div>
    </div>
  </mat-card>
</div>
