<div class="recommended-label" *ngIf="plan.isRecommended; else planHighlight">Recommended</div>
<ng-template #planHighlight>
  <div class="plan-highlight"></div>
</ng-template>

<ninety-card
  class="price-card hide-overflow"
  [ngClass]="{
    'current-plan': plan.isCurrentPlan,
    'recommended-card': plan.isRecommended,
    highlighted: plan.isCurrentPlan || plan.isCurrentTrial,
    'free-and-trialing': plan.isFreeAndTrialing
  }">
  <!-- Card Header -->
  <div class="card-header">
    <div class="plan-name">
      <span>{{ plan.displayName }}</span>
      <ninety-chip *ngIf="plan.isCurrentTrial">Current trial</ninety-chip>
    </div>
    <div class="plan-description">{{ plan.details.description }}</div>
  </div>

  <!-- Card Price -->
  <div class="card-price">
    <span class="plan-price">${{ plan.price }}</span>
    <span class="per-month-or-year">per user per {{ plan.frequency }}</span>
  </div>

  <!-- Select Plan Button -->
  <ninety-button
    class="full-width choose-btn"
    filled
    primary
    (click)="selectPlan()"
    [disabled]="plan.isCurrentPlan || plan.isFreeAndTrialing">
    <span class="button-text">
      {{ plan.isFreeAndTrialing ? 'Available after trial' : plan.isCurrentPlan ? 'Current plan' : 'Select Plan' }}
    </span>
  </ninety-button>

  <!-- Card Features -->
  <div class="card-features">
    <div class="everything-bold-text">{{ plan.details.everythingText }}</div>
    <ul>
      <li class="feature-list-item" *ngFor="let feature of plan.details.features">
        <terra-icon class="checkmark" icon="check" variant="bold" size="16"></terra-icon>
        <span class="feature-name">
          {{ feature.title }}
        </span>
      </li>
      <li
        class="feature-list-item see-all-text extended-item"
        *ngIf="plan?.details.featuresExtended && !extendedListOpen"
        (click)="extendedListOpen = true">
        <terra-icon icon="caret-right" variant="bold" size="16"></terra-icon>See all features
      </li>
      <ng-container *ngIf="extendedListOpen">
        <li class="feature-list-item extended-item" *ngFor="let feature of plan?.details.featuresExtended">
          <terra-icon class="checkmark" icon="check" variant="bold" size="16"></terra-icon>
          <span class="feature-name dotted-underline">
            {{ feature.title }}
          </span>
        </li>
      </ng-container>
    </ul>
  </div>
</ninety-card>
