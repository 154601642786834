import { EventEmitter, InjectionToken } from '@angular/core';

/*
 * Injection token and interface describing token
 * This is needed by any component that supports lazy loading options
 *
 * A provider needs to be added to the component that supports lazy loading options
 * terraQuickFilter and terraSelect have examples
 */
export interface TerraLazyOptionsSelect {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compareWith: (o1: any, o2: any) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  openedChange: EventEmitter<boolean>;
}

export const TERRA_LAZY_OPTIONS_SELECT = new InjectionToken<TerraLazyOptionsSelect>('TERRA_LAZY_OPTIONS_SELECT');
