import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, switchMap } from 'rxjs';

import { environment } from '@ninety/ui/web/environments';

import { EntitlementsService } from '../services/entitlements.service';

@Injectable()
export class EntitlementsInterceptor implements HttpInterceptor {
  private readonly apiBasePaths = [environment.apiOrigin, environment.mazApiOrigin];

  constructor(private readonly entitlementsService: EntitlementsService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // avoid infinite loop
    if (req.url.includes(environment.entitlementsApiOrigin) || req.headers?.['entitlements']) {
      return next.handle(req);
    }

    const shouldAddHeader = this.apiBasePaths.some(basePath => req.url.startsWith(basePath));
    if (!shouldAddHeader) {
      return next.handle(req);
    }

    return this.entitlementsService.getOrFetchEntitlementsToken().pipe(
      switchMap(entitlementsToken => {
        if (!entitlementsToken) {
          return next.handle(req);
        }
        const clonedRequest = req.clone({
          setHeaders: {
            entitlements: entitlementsToken,
          },
        });
        return next.handle(clonedRequest);
      }),
      catchError(() => {
        // Allow request to proceed without token on error
        return next.handle(req);
      })
    );
  }
}
