import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@ninety/ui/web/environments';

import { CatchErrorAndNotify } from '../../_core/decorators/catch-error-and-notify';
import { ErrorService } from '../../_core/services/error.service';
import { CheckoutParams } from '../../_shared/models/billing-v3/checkout-params';
import { SubscriptionV3 } from '../../_shared/models/billing-v3/subscription';
import { SubscriptionConfigurationV3 } from '../../_shared/models/billing-v3/subscription-configuration-v3';
import { BillingCounts } from '../../_shared/models/billingv2/billing-counts-response';

type GetBillingUrlResponse = { redirectUrl: string };

@Injectable({
  providedIn: 'root',
})
export class BillingV3Service {
  private readonly apiBasePath = environment.billingApiOrigin;
  private readonly billingV3Api = `${this.apiBasePath}/v3`;
  private readonly billingV2Api = 'api/v4/BillingV2';

  constructor(private readonly http: HttpClient, private readonly _errorService: ErrorService) {}

  /** Subscription Configurations */
  @CatchErrorAndNotify
  getCommercialModelSubscriptions(): Observable<SubscriptionConfigurationV3[]> {
    return this.http.get<SubscriptionConfigurationV3[]>(`${this.billingV3Api}/get-active-subscription-configurations`);
  }

  /** Subscriptions */

  getCheckoutUrl(isFreeOrTrialing: boolean, checkoutParams: CheckoutParams): Observable<GetBillingUrlResponse> {
    return isFreeOrTrialing ? this._getStartPlanUrl(checkoutParams) : this._getChangePlanUrl(checkoutParams);
  }

  getCancelUrl(): Observable<GetBillingUrlResponse> {
    return this.http.post<GetBillingUrlResponse>(`${this.billingV3Api}/get-cancel-url`, {});
  }

  /** When there is no active subscription, (trialing and freemium) */
  private _getStartPlanUrl(checkoutParams: CheckoutParams): Observable<GetBillingUrlResponse> {
    return this.http.post<GetBillingUrlResponse>(`${this.billingV3Api}/get-checkout-url`, checkoutParams);
  }

  /** When changing from one active subscription to another */
  private _getChangePlanUrl(checkoutParams: CheckoutParams): Observable<GetBillingUrlResponse> {
    return this.http.post<GetBillingUrlResponse>(`${this.billingV3Api}/get-change-plan-url`, checkoutParams);
  }

  /** Used after most stripe actions to get up to date subscription information */
  getUpdatedSubscription(): Observable<SubscriptionV3> {
    return this.http.get<SubscriptionV3>(`${this.billingV3Api}/get-updated-subscription`);
  }

  /** routes to stripe to change the license count and confirm payment */
  getChangeQuantityUrl(quantity: number, redirectUrl: string): Observable<GetBillingUrlResponse> {
    return this.http.post<GetBillingUrlResponse>(`${this.billingV3Api}/get-change-quantity-url`, {
      quantity,
      redirectUrl,
    });
  }

  getBillingPortalUrl(): Observable<GetBillingUrlResponse> {
    return this.http.post<GetBillingUrlResponse>(`${this.billingV3Api}/get-billing-portal-url`, {});
  }

  /** Ninety API */

  public getBillingCounts(): Observable<BillingCounts> {
    return this.http.get<BillingCounts>(`${this.billingV2Api}/BillingCounts`);
  }
}
