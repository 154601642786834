<ng-container *ngrxLet="data$ as data">
  <!-- This toolbar is for all other tools except feedback and conversations -->
  <ninety-top-toolbar
    [meetingStarted]="inMeeting"
    data-cy="main-header_top-toolbar"
    *ngIf="
      data.activeTool !== '1-on-1' &&
      data.activeTool !== '' &&
      !(data.rocksv3FeatureFlag && (data.activeTool?.includes('rocks-v3') || data.activeTool?.includes('milestones')))
    "></ninety-top-toolbar>
  <!-- This toolbar specifically addresses conversation mobile views -->
  <ninety-top-toolbar
    [meetingStarted]="inMeeting"
    data-cy="main-header_top-toolbar"
    *ngIf="isMobile && isConversation && inFeedback"></ninety-top-toolbar>
  <ninety-tabs-bar
    *ngIf="data.toolTabGroup && !(data.toolTabGroup.tool === '1-on-1' && data.weeklyConversationsFeatureFlag)"
    class="terrafied-tabs">
    <nav mat-tab-nav-bar class="ninety-tabs">
      <span *ngFor="let tab of data.toolTabGroup.tabs">
        <ng-container *ngrxLet="tab.show$ || true as show">
          <a
            mat-tab-link
            *ngIf="show"
            [active]="data.activeRoute === tab.route"
            (click)="navigateTo(data.activeTool, tab.route)"
            [attr.data-cy]="tab.dataCy ? tab.dataCy : null">
            {{ tab.label }}
          </a>
        </ng-container>
      </span>
    </nav>
  </ninety-tabs-bar>
</ng-container>
<ninety-filters-toolbar [isMeeting]="inMeeting" [isFeedback]="inFeedback"></ninety-filters-toolbar>
