import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

/**
 * Styles used to apply line-count based word-wrap and ellipsis
 */
interface WrapTillNThenEllipsis {
  // Set height based on font size
  'line-height': string;
  'max-height': string;
  // Use WebKit's word clamp feature
  display: string;
  '-webkit-line-clamp': string;
  '-webkit-box-orient': string;
  // Use ellipsis
  'text-overflow': string;
  overflow: string;
  'word-wrap': string;
  // Prevent a space from being the first character on a line
  'white-space': string;
}

export interface EllipsifyFontSizeOptions {
  /**
   * If present, used for all line counts where a font-size does not exist in fontSizeAtLineCount. Value is in pixels.
   */
  defaultFontSize?: number;

  /**
   * Allows overriding a font-size based on the number of lines currently being displayed.
   * {# of lines: font-size in pixels}
   */
  fontSizeAtLineCount?: {
    [lineCount: number]: number;
  };
}

/**
 * This directive is designed to be added to a text element which should wrap up until a set number of lines, then
 * show an ellipsis and hide overflow.
 *
 * It also exposes the observable isOverflowing$ which is helpful when trying to enable/disable an element (like a
 * tooltip) when the max lines is reached and overflow is hidden.
 *
 * NOTE - this is only in use on the sidebar company-logo. It is not tested for performance.
 *
 * ANY USE CASE WHICH INVOLVES MORE THAN A HANDFUL OF INSTANCES OF THIS DIRECTIVE SHOULD BE EVALUATED FOR PERFORMANCE.
 * In specific, performance impacts on resize and initial load.
 */
@Directive({
  selector: '[ninetyEllipsify]',
})
export class EllipsifyDirective implements OnChanges, AfterViewInit {
  private static readonly DEFAULT_LINE_COUNT = 2;
  private static readonly LINE_FONT_SIZE_MULTIPLIER = 1.3;

  private readonly nativeHost: HTMLElement;
  private readonly _lineCount$: BehaviorSubject<number>;

  /**
   * After this many lines, hide overflow behind ellipsis
   */
  @Input() maxLineCount? = EllipsifyDirective.DEFAULT_LINE_COUNT;

  /**
   * Configure font-size based on line-count with a default.
   */
  @Input() fontSizeOptions?: EllipsifyFontSizeOptions;

  /**
   * While the value of the text is ignored, the content must be provided *and* bound to for updates after content
   * changes. See onChanges
   */
  @Input() content: any;

  /**
   * After resize, the amount of lines and thus the font change may need to be changed.
   */
  @HostListener('window:resize', ['$event.target'])
  afterResize() {
    setTimeout(() => this.changeFontSize());
  }

  constructor(private renderer: Renderer2, elementRef: ElementRef) {
    this.nativeHost = elementRef.nativeElement as HTMLElement;
    this._lineCount$ = new BehaviorSubject<number>(0);
  }

  get lineCount$(): Observable<number> {
    return this._lineCount$.pipe(distinctUntilChanged());
  }

  get isOverflowing(): Observable<boolean> {
    return this.lineCount$.pipe(
      map(count => count > 1 && this.maxLineCount < count),
      distinctUntilChanged()
    );
  }

  ngOnChanges({ maxLineCount, fontSizeOptions, content }: SimpleChanges): void {
    if (maxLineCount && !maxLineCount.firstChange) {
      this.setWordClampStyles();
    }

    if ((fontSizeOptions && !fontSizeOptions.firstChange) || (content && !content.firstChange)) {
      this.changeFontSize();
    }
  }

  ngAfterViewInit() {
    // Set the styles first, which configures the overflow
    this.setWordClampStyles();

    // Then, in the next event loop, set the font size conditionally
    setTimeout(() => this.changeFontSize());
  }

  private changeFontSize(recurse = true) {
    const lineHeight = this.getLineHeightIfInitialized();
    if (!lineHeight) return;

    let lineCount = this.getTotalLineCount(lineHeight);
    if (lineCount > this.maxLineCount) lineCount = this.maxLineCount; // Words are hidden with ellipsis

    const fontSize =
      (this.fontSizeOptions?.fontSizeAtLineCount && this.fontSizeOptions?.fontSizeAtLineCount[lineCount]) ??
      this.fontSizeOptions?.defaultFontSize;
    if (!fontSize) return;

    const fontSizeStyle = `${fontSize}px`;
    const currentFontSize = this.getCssValue('font-size');
    if (fontSizeStyle === currentFontSize) return;

    this.renderer.setStyle(this.nativeHost, 'font-size', fontSizeStyle);
    this.emitLineCountIfChanged();

    // The number of lines may change after the font-size is changed. In these cases, an infinite recursion loop
    // happens - prevent this by passing false. Causes edge cases where the font size for 2 lines is applied to 1
    // line
    if (recurse) this.changeFontSize(false);
  }

  private getTotalLineCount(lineHeight: number = undefined): number {
    lineHeight ??= this.getLineHeightIfInitialized();
    if (!lineHeight) return;

    return Math.round(this.nativeHost.scrollHeight / lineHeight);
  }

  private getLineHeightIfInitialized(): number | undefined {
    const lineHeight = this.getCssValue('line-height');
    if (!lineHeight) return;

    return EllipsifyDirective.getCssSizeAsNumber(lineHeight);
  }

  private emitLineCountIfChanged() {
    this._lineCount$.next(this.getTotalLineCount()); // The observable is piped through distinctUntilChanged()
  }

  private getCssValue(key: string): string {
    return window.getComputedStyle(this.nativeHost, null).getPropertyValue(key);
  }

  /**
   * This function wraps parseFloat to make sure you get the expected sizing & don’t pass an empty value. The former
   * happens if you’ve misconfigured certain parts of the directive, like when you set the font. The latter will cause
   * the math of this directive to fail and must be protected against.
   * @param sizeInCss
   * @param allowedSizing
   */
  static getCssSizeAsNumber(sizeInCss: string, allowedSizing = 'px'): number {
    if (!sizeInCss) {
      throw new Error('Cannot convert an empty size');
    }

    if (!sizeInCss.match(`[\\d]+${allowedSizing}`)) {
      throw new Error(`Can only convert ${allowedSizing} values, not ${sizeInCss}`);
    }

    try {
      return parseFloat(sizeInCss);
    } catch (e) {
      // console.error('Failed to get font size in EllipsifyDirective.');
      throw e;
    }
  }

  private setWordClampStyles() {
    const styles = EllipsifyDirective.buildWordClampStyles(this.maxLineCount);
    Object.entries(styles).forEach(([k, v]) => this.renderer.setStyle(this.nativeHost, k, v));
  }

  static buildWordClampStyles(lines: number, fontSizeEm = 1): WrapTillNThenEllipsis {
    const lineHeight = fontSizeEm * this.LINE_FONT_SIZE_MULTIPLIER;

    return {
      'line-height': `${lineHeight}em`,
      'max-height': `${lineHeight * lines}em`,
      display: '-webkit-box',
      '-webkit-line-clamp': `${lines}`,
      '-webkit-box-orient': 'vertical',
      'text-overflow': 'ellipsis',
      overflow: 'hidden',
      'word-wrap': 'break-word',
      'white-space': 'pre-line',
    };
  }
}
