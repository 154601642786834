<terra-select
  [disabled]="disabled"
  [ngModel]="selectedRole"
  (selectionChange)="selectRole($event)"
  placeholder="Select role">
  <ng-container *ngFor="let role of roles$ | ngrxPush">
    <ng-container *ngrxLet="inactiveAsRole$ as inactiveAsRole">
      <terra-divider *ngIf="(inactiveAsRole && !role.active) || (!inactiveAsRole && role.isImplementer)" />
    </ng-container>
    <terra-option [disabled]="role.isItemDisabled || role.cannotAssign" [value]="role">
      {{ role.title }}
      <span *terraOptionSuffix>{{ role.subtitle }}</span>
    </terra-option>
  </ng-container>
</terra-select>
