import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraInputAutoFocusDirective } from '../terra-input-auto-focus/terra-input-autofocus.directive';

import { TerraPasswordInputComponent } from './terra-password-input.component';

@NgModule({
  imports: [CommonModule, TerraPasswordInputComponent, TerraInputAutoFocusDirective],
  exports: [TerraPasswordInputComponent, TerraInputAutoFocusDirective],
})
export class TerraPasswordInputModule {}
