<ng-container *ngrxLet="vm$ as vm">
  <div class="container">
    <mat-card class="ninety-card">
      <mat-card-header>
        <mat-card-title class="PageHeader">&nbsp;</mat-card-title>
        <ng-container *ngIf="vm.enableTeamPageEnhancements">
          <terra-select
            data-cy="teams_status-filter-select"
            [ngModel]="vm.currentStatus"
            (selectionChange)="selectStatus($event)"
            class="status-select">
            <terra-option data-cy="teams_status-filter-select-all" value="all">All</terra-option>
            <terra-option data-cy="teams_status-filter-select-active" value="active">Active</terra-option>
            <terra-option data-cy="teams_status-filter-select-archived" value="archived">Archived</terra-option>
            <terra-option data-cy="teams_status-filter-select-my-teams" value="my-teams">My teams</terra-option>
          </terra-select>
          <div class="fill-remaining-space"></div>
          <ninety-team-search
            class="team-search"
            data-cy="teams_search-field"
            (searchChanged)="onSearchChanged($event)" />
        </ng-container>
        <ninety-button
          filled
          primary
          class="right-space-15"
          (click)="openNewTeamDialog()"
          [disabled]="vm.spinner"
          data-cy="teams_create-team-button">
          <terra-icon size="16" icon="add" />
          Create team
        </ninety-button>
      </mat-card-header>
    </mat-card>
  </div>
  <div class="container teams-page">
    <mat-card class="ninety-card">
      <mat-card-header>
        <mat-card-title class="PageHeader">Teams</mat-card-title>
      </mat-card-header>
      <mat-toolbar class="header-btns">
        <h4 class="flex1 name-header">Name</h4>
        <h4
          class="slt-header"
          matTooltipPosition="above"
          matTooltipClass="ninety-tooltip"
          matTooltip="The Leadership Team (&quot;LT&quot;) owns the
            company's {{ vm.language.vto.item }} and {{ vm.language.rock.items }} and
            controls how they're shared with other teams.">
          LT
        </h4>
        <h4 class="project-header">
          Project
          <sup>
            <terra-icon icon="info-circle" size="16" [tp]="projectTooltip" />
          </sup>

          <ng-template #projectTooltip let-hide>
            <div class="tooltip-template">
              <h3>Project teams</h3>
              <p>Used for one-time objectives. These teams do not use the Vision tool.</p>
            </div>
          </ng-template>
        </h4>
        <h4 class="private-header">
          Private
          <sup>
            <terra-icon icon="info-circle" size="16" [tp]="privateTooltip" tpMaxWidth="600" />
          </sup>
          <ng-template #privateTooltip let-hide>
            <div class="tooltip-template">
              <h3>Private teams</h3>
              <p>Used for 1:1 meetings between leaders and direct reports.</p>
            </div>
          </ng-template>
        </h4>
        <div class="delete-header"></div>
      </mat-toolbar>
      <mat-list class="ninety-list">
        <mat-list-item
          *ngFor="let team of vm.filteredTeams; trackBy: trackByTeamId"
          matRipple
          class="list-item"
          [class.opened]="vm.editedTeamId === team._id"
          [disabled]="vm.spinner"
          (click)="editTeam(team._id)">
          <div class="list-title dflex flex1" attr.data-cy="teams_team-name-{{ team.name }}">
            <div [ngClass]="{ 'text-disabled': team.archived }">
              {{ team.name }} <span *ngIf="team.archived">(archived)</span>
            </div>
            <terra-icon
              icon="user-check"
              variant="regular"
              *ngIf="team.loggedInUserBelongsToTeam"
              class="on-team-icon"
              ninetyTooltip="You are in this team" />
          </div>
          <terra-radio-group [ngModel]="vm.sltTeamId">
            <terra-radio-button
              aria-label="Set as Leadership Team"
              class="slt-radio"
              [value]="team._id"
              [disabled]="team.private || !team.canEdit || team.archived || team.project"
              [matTooltip]="team.private ? 'A leadership team cannot be private' : ''"
              matTooltipPosition="above"
              (mousedown)="setSltTeam($event, team)"
              ninetyStopPropagation
              attr.data-cy="teams_set-leadership-team_team-name-{{ team.name }}" />
          </terra-radio-group>
          <terra-checkbox
            attr.data-cy="teams_set-project_team-name-{{ team.name }}"
            aria-label="Set as Project Team"
            [ngModel]="team.project"
            class="project-checkbox"
            [disabled]="team.isSlt || !team.canEdit || team.archived"
            (change)="updateTeam(team._id, { project: $event })"
            ninetyStopPropagation>
          </terra-checkbox>
          <terra-checkbox
            attr.data-cy="teams_set-private_team-name-{{ team.name }}"
            aria-label="Set as Private Team"
            [(ngModel)]="team.private"
            class="private-checkbox"
            [ngModel]="team.private"
            [disabled]="team.isSlt || !team.canEdit || team.archived"
            (change)="updateTeam(team._id, { private: $event })"
            ninetyStopPropagation>
          </terra-checkbox>
          <button
            *ngIf="!vm.enableTeamPageEnhancements"
            attr.data-cy="teams_delete_team-name-{{ team.name }}"
            mat-icon-button
            color="warn"
            class="delete-btn"
            (click)="deleteTeam(team)"
            ninetyStopPropagation
            aria-label="Delete Team"
            [disabled]="team.isSlt || !team.canEdit || team.archived"
            matTooltip="Delete Team"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="delete" />
          </button>

          <ng-container *ngIf="vm.enableTeamPageEnhancements">
            <ninety-button
              class="more-actions no-hover"
              ninetyStopPropagation
              [matMenuTriggerFor]="moreActions"
              compact
              icon
              ninetyStopPropagation
              text
              attr.data-cy="teams_more_team-name-{{ team.name }}"
              [disabled]="team.isSlt || !team.canEdit">
              <terra-icon icon="more" />
            </ninety-button>

            <mat-menu #moreActions="matMenu" class="template-options-menu-teams-page" xPosition="before">
              <ninety-button
                *ngIf="!team.archived"
                mat-menu-item
                (click)="archiveTeam(team)"
                attr.data-cy="teams_archive_team-name-{{ team.name }}">
                <terra-icon icon="archive" size="20" class="more-actions-icon" />
                Archive team
              </ninety-button>
              <ninety-button
                *ngIf="team.archived"
                mat-menu-item
                (click)="reactivateTeam(team)"
                attr.data-cy="teams_reactivate_team-name-{{ team.name }}">
                <terra-icon icon="user-circle-add" size="20" class="more-actions-icon" />
                Reactivate team
              </ninety-button>
              <ninety-button
                mat-menu-item
                (click)="deleteTeam(team)"
                attr.data-cy="teams_delete_team-name-{{ team.name }}"
                class="more-actions-delete-team">
                <terra-icon icon="delete" size="20" />
                Delete team
              </ninety-button>
            </mat-menu>
          </ng-container>
        </mat-list-item>
      </mat-list>
    </mat-card>
  </div>
</ng-container>
