import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';

import { TerraRadioButtonModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/index';
import { MeetingLanguage } from '@ninety/ui/legacy/shared/index';

export enum DeleteOption {
  one = 'one',
  all = 'all',
}

export type DeleteDialogOptions = {
  deleteAll: boolean;
  isRepeating: boolean;
};

export type DeleteMeetingScheduleDialogData = {
  meetingLanguage: MeetingLanguage;
  isRepeating: boolean;
  cadence: string;
};

@Component({
  selector: 'ninety-delete-meeting-schedule-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, TerraRadioButtonModule, FormsModule, ButtonComponent],
  templateUrl: './delete-meeting-schedule-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteMeetingScheduleDialogComponent {
  DeleteOption = DeleteOption;
  deleteOption = DeleteOption.one;

  readonly dialogRef = inject(MatDialogRef<DeleteMeetingScheduleDialogComponent, DeleteDialogOptions>);
  readonly data = inject<DeleteMeetingScheduleDialogData>(MAT_DIALOG_DATA);

  onDelete(): void {
    this.dialogRef.close({ deleteAll: this.deleteOption === DeleteOption.all, isRepeating: this.data.isRepeating });
  }
}
