import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Store } from '@ngrx/store';
import {
  difference as _difference,
  pullAll as _pullAll,
  union as _union,
  uniqBy as _uniqBy,
  xor as _xor,
} from 'lodash';

import { StateService } from '../../../_core/services/state.service';
import { CurrentUserSelectors, extractValueFromStore } from '../../../_state';
import { TeamSelectors } from '../../../_state/app-entities/team-list/team-list-state.selectors';
import { RoleCode } from '../../models/_shared/role-code';
import { Team } from '../../models/_shared/team';

type TeamCheckbox = {
  id: string;
  name: string;
  checked: boolean;
};

@Component({
  selector: 'ninety-teams-select',
  templateUrl: './teams-select.component.html',
  styleUrls: ['./teams-select.component.scss'],
})

/** Please please PLEASE refactor/rewrite this component.  And add some tests. */
// TODO: This looks the same as role-select. Look into refactoring so it extends ninety-select and is consistent with role-select component.
export class TeamsSelectComponent implements OnInit {
  private _userTeams: Team[];

  @Input() set userTeams(teams: Team[]) {
    this._userTeams = _uniqBy(teams, 'teamId');
    this.setSelectedTeams(this.userTeams);
  }

  get userTeams(): Team[] {
    return this._userTeams;
  }

  @Input() hideLabel: boolean;
  @Input() disabled = false;
  @Input() disabledText = 'N/A';
  @Input() required: boolean;
  @Input() showRequiredError = false;
  @Output() showRequiredErrorChange: EventEmitter<boolean> = new EventEmitter();
  @Input() requiredText = 'Required';
  @Input() outlineMode: boolean;
  @Input() placeholder: string;
  @Output() selectionChange = new EventEmitter();
  @Output() updateTeams = new EventEmitter();

  @ViewChild(MatMenuTrigger) teamsMenu: MatMenuTrigger;

  selectedTeamsNames = '';

  menuOpen = false; //* menu is currently open/visible
  checkboxes: TeamCheckbox[];
  menuOpened = false; //* has menu been opened previously
  selectedTeams: string[] = [];
  filteredSelectedTeams: Team[] = [];
  noSelectedTeams = this.selectedTeams.length === 0;
  showTeamsNames = false;
  showTeamOptions: string[];
  userIsManager = false;

  constructor(private stateService: StateService, private store: Store) {}

  ngOnInit() {
    this.userIsManager = this.stateService.currentUser.roleCode === RoleCode.manager;

    // initialize checkboxes if no user teams are passed in
    if (!this.userTeams) this.setSelectedTeams([]);

    // show all teams for owners and admins
    if (this.stateService.isAdminOrOwner) {
      this.showTeamOptions = this.checkboxes.map(team => team.id);
    } else {
      this.showTeamOptions = extractValueFromStore(this.store, CurrentUserSelectors.selectTeamIds);
    }

    /*
     * CSS works down the DOM (although selectors are processed backwards),
     * so you can't navigate up an element tree and then back down to reach
     * an element's cousin. You can only either operate on the same level of
     * elements (only going forward), or go down.
     *
     * if the above weren't true we wouldn't need the following focus listeners
     * and we could do something like what's explained here:
     * http://www.jonahnisenson.com/how-to-style-adjacent-sibling-elements-on-hover-or-focus-events/
     */
  }

  closeMenu() {
    this.menuOpen = false;
    this.teamsMenu.closeMenu();
  }

  onClose() {
    //not including private teams current user is not a part of
    const visibleTeamsInDropdownIds = extractValueFromStore(this.store, TeamSelectors.selectAllIds);

    //including private teams
    const allUserTeamIds = this.userTeams ? this.userTeams.map(({ teamId }) => teamId) : [];

    const userPrivateTeamIdsNotInDropdown = _difference(allUserTeamIds, visibleTeamsInDropdownIds);
    const mergedTeamIds = [...this.selectedTeams, ...userPrivateTeamIdsNotInDropdown];

    if (_xor(mergedTeamIds, allUserTeamIds).length > 0) {
      //only update if team arrays are different
      this.updateTeams.emit(mergedTeamIds);
    }
  }

  setSelectedTeams(teams: Team[]): void {
    this.selectedTeams = teams.map(({ teamId }) => teamId);
    this.checkboxes = extractValueFromStore(this.store, TeamSelectors.selectAll).map(el => ({
      id: el._id,
      name: el.name,
      checked: this.selectedTeams.includes(el._id),
    }));

    this.filteredSelectedTeams = extractValueFromStore(this.store, TeamSelectors.selectAll).filter(t =>
      this.selectedTeams.includes(t?._id?.toString())
    );
    this.updateSelectedTeamNames();
  }

  setMenuOpened(): void {
    this.menuOpened = true;
    this.menuOpen = true;
    this.emitShowRequiredError();
  }

  emitShowRequiredError(): void {
    const show = this.menuOpened && this.noSelectedTeams && this.required;
    this.showRequiredError = show;
    this.showRequiredErrorChange.emit(show);
  }

  isSelected(e: boolean, teamId: string): void {
    this.updateSelectedTeams(e, teamId);
  }

  selectTeam(teamId: string): void {
    const isChecked = !this.checkboxes.find(el => el.id === teamId).checked;
    this.updateSelectedTeams(isChecked, teamId);
  }

  updateSelectedTeams(isChecked: boolean, teamId: string, emitSelectionChange = true): void {
    if (!this.checkboxes) return;

    this.checkboxes.find(el => (el.id === teamId ? (el.checked = isChecked) : null));

    if (isChecked) this.selectedTeams = [...this.selectedTeams, teamId];
    else this.selectedTeams = [...this.selectedTeams.filter(t => t !== teamId)];

    this.filteredSelectedTeams = extractValueFromStore(this.store, TeamSelectors.selectAll).filter(t =>
      this.selectedTeams.includes(t?._id?.toString())
    );

    this.noSelectedTeams = this.selectedTeams.length === 0;
    this.emitShowRequiredError();
    if (emitSelectionChange) {
      this.selectionChange.emit(this.selectedTeams);
    }

    this.updateSelectedTeamNames();
  }

  updateSelectedTeamNames(): void {
    this.showTeamsNames = !this.menuOpen && this.selectedTeams.length > 0;
    if (this.selectedTeams.length > 0) {
      this.selectedTeamsNames = this.filteredSelectedTeams.map(team => team.name).join(', ');
    } else {
      this.selectedTeamsNames = '';
      this.showTeamsNames = false;
    }
  }

  resetTeams() {
    this.selectedTeams = [];
    this.selectedTeamsNames = '';
    this.noSelectedTeams = true;
    this.emitShowRequiredError();
    this.selectionChange.emit(this.selectedTeams);
  }

  bulkUpdateSelectedTeams(isChecked: boolean, teamIds: string[]): void {
    if (isChecked) {
      this.selectedTeams = _union(this.selectedTeams, teamIds);
    } else {
      this.selectedTeams = _pullAll(this.selectedTeams, teamIds);
    }
    this.noSelectedTeams = this.selectedTeams.length === 0;
    this.emitShowRequiredError();
    this.selectionChange.emit(this.selectedTeams);
    this.updateSelectedTeamNames();
  }

  trackById(i: number, tc: TeamCheckbox): string {
    return tc.id;
  }
}
