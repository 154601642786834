import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { TerraIconModule } from '@ninety/terra';
import { PlanName } from '@ninety/ui/legacy/shared/models/billing-v3/plan-name';

import { NinetyTooltipDirective } from '../../../../_directives';
import { CardModule } from '../../../cards/card.module';

@Component({
  selector: 'ninety-compare-plans',
  standalone: true,
  imports: [CommonModule, CardModule, MatExpansionModule, TerraIconModule, NinetyTooltipDirective],
  templateUrl: './compare-plans.component.html',
  styleUrls: ['./compare-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: 0,
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      transition('collapsed <=> expanded', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class ComparePlansComponent {
  @ViewChild('section0') section0: ElementRef;
  @ViewChild('section1') section1: ElementRef;
  @ViewChild('section2') section2: ElementRef;
  @ViewChild('section3') section3: ElementRef;
  @ViewChild('section4') section4: ElementRef;
  @ViewChild('section5') section5: ElementRef;
  @ViewChild('section6') section6: ElementRef;
  @ViewChild('section7') section7: ElementRef;
  @ViewChild('section8') section8: ElementRef;
  @ViewChild('section9') section9: ElementRef;

  PlanName = PlanName;

  protected openSections = [true, true, true, true, true, true, true, true, true, true];

  constructor(private cdr: ChangeDetectorRef) {}

  headerClick(elementNum: number): void {
    const elementId = 'section' + elementNum;
    this.openSections[elementNum] = !this.openSections[elementNum];

    if (this.openSections[elementNum]) {
      this.cdr.detectChanges();

      const element = this[elementId + 'Element'];
      if (element) {
        element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }
}
