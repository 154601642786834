import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RocksStateEffects } from './_state/rocks-v2-state.effects';
import { RockModuleStateKey } from './_state/rocks-v2-state.model';
import { RockStateReducer } from './_state/rocks-v2-state.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(RockModuleStateKey, RockStateReducer),
    EffectsModule.forFeature([RocksStateEffects]),
  ],
})
export class RockStateModule {}
