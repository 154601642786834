import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, of, switchMap, tap } from 'rxjs';

import { ConfirmLicenseChangeComponent } from '@ninety/ui/legacy/components/billing-dialogs/confirm-license-change/confirm-license-change.component';
import { ConfirmLicenseChangeDialogData } from '@ninety/ui/legacy/components/billing-dialogs/confirm-license-change/models/confirm-license-change-dialog-data';
import { PlanName } from '@ninety/ui/legacy/shared/models/billing-v3/plan-name';
import { SubscriptionProvider } from '@ninety/ui/legacy/shared/models/billing-v3/subscription-provider.enum';
import { CompanyActions } from '@ninety/ui/legacy/state/app-global/company/company-state.actions';

import { PricingTierDialogComponent } from '../../../_components/billing-dialogs/pricing-tier-dialog/pricing-tier-dialog.component';
import { BillingV3Service } from '../../../_core/services/billing-v3.service';
import { BillingV2Service } from '../../../_core/services/billingv2.service';
import { PricePerPlan } from '../../../_shared/models/billing-v3/price-per-plan';
import { PricingPlan } from '../../../_shared/models/billing-v3/pricing-plan';
import { getSubscriptionDetails } from '../../../_shared/models/billing-v3/subscription-details';
import {
  selectCompanyBillingCounts,
  selectCurrentPlan,
  selectIsFreeOrTrialing,
  selectIsTrialing,
  selectStripeQueryParams,
} from '../../../_state/app-global/billing/billing-state.selectors';
import { SubscriptionActions } from '../../../_state/app-global/company/subscription/subscription-state.actions';
import {
  selectIsBillingV2Company,
  selectIsBillingV3Company,
  selectSubscriptionConfigId,
} from '../../../_state/app-global/company/subscription/subscription-state.selectors';
import { NotificationActions } from '../../../_state/app-global/notifications/notification.actions';
import { appActions } from '../../../_state/app.actions';
import { AddTeammatesActions, UsersStateActions } from '../../app-entities/users/users-state.actions';
import { selectCurrentUser, selectCurrentUserIsManagerOrAbove } from '../../app-entities/users/users-state.selectors';
import { selectCommercialModelActive } from '../company/company-state.selectors';
import { SpinnerActions } from '../spinner/spinner-state.actions';

import { BillingStateActions } from './billing-state.actions';

@Injectable()
export class BillingStateEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly billingV2Service: BillingV2Service,
    private readonly dialog: MatDialog,
    private readonly billingV3Service: BillingV3Service,
    private readonly router: Router
  ) {}

  /** on app load, check to see if we were redirected from stripe and if so, get billing counts
   *
   *  used in following cases when a license increase is needed:
   * 1) adding paid teammates,
   * 2) inviting paid users
   * 3) changing to paid role
   */
  checkIfRedirectedFromStripeAndFetchCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.dispatchAppStartActions),
      concatLatestFrom(() => [
        this.store.select(selectStripeQueryParams),
        this.store.select(selectCompanyBillingCounts),
      ]),
      filter(([, queryParams]) => !!queryParams),
      switchMap(() => this.billingV3Service.getBillingCounts()),
      map(companyBillingCounts =>
        BillingStateActions.getBillingCountsAfterStripeRedirectSuccess({ companyBillingCounts })
      )
    )
  );

  getUpdatedSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.init, BillingStateActions.getUpdatedSubscription),
      concatLatestFrom(() => this.store.select(selectIsBillingV3Company)),
      filter(([_, isBillingV3Company]) => isBillingV3Company),
      exhaustMap(() =>
        this.billingV3Service.getUpdatedSubscription().pipe(
          map(subscription => CompanyActions.updateCompany({ changes: { subscription } })),
          catchError((error: unknown) => of(BillingStateActions.getUpdatedSubscriptionFailed({ error })))
        )
      )
    )
  );

  getCommercialModelSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.init, BillingStateActions.getCommercialModelSubscriptions),
      concatLatestFrom(() => [
        this.store.select(selectIsBillingV3Company),
        this.store.select(selectIsTrialing),
        this.store.select(selectSubscriptionConfigId),
      ]),
      filter(([_, isBillingV3Company]) => isBillingV3Company),
      exhaustMap(([_, __, isTrialing, configId]) =>
        this.billingV3Service.getCommercialModelSubscriptions().pipe(
          map(subscriptions => {
            const currentPlan = subscriptions.find(s => s.id === configId);
            const subscriptionDetails = getSubscriptionDetails();
            const recommendedTier =
              currentPlan?.displayName === PlanName.Accelerate ? PlanName.Thrive : PlanName.Accelerate;
            const pricingPlans: PricingPlan[] = subscriptions
              .map(sub => ({
                ...sub,
                details: subscriptionDetails[sub.displayName],
                isCurrentPlan: sub.id === currentPlan?.id,
                isRecommended:
                  recommendedTier === sub.displayName && (isTrialing || currentPlan?.displayName !== PlanName.Thrive),
                isCurrentTrial: isTrialing && sub.displayName === PlanName.Thrive,
                isFreeAndTrialing: isTrialing && sub.displayName === PlanName.Freemium,
                price: PricePerPlan[sub.name],
              }))
              /** sort by price because they are not guaranteed to come back in the correct order from the db */
              .sort((a, b) => a.price - b.price);
            return BillingStateActions.getCommercialModelSubscriptionsSuccess({ pricingPlans, currentPlan });
          })
        )
      )
    )
  );

  getStripePlanCheckoutUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.selectPlan),
      filter(({ plan }) => plan.provider === SubscriptionProvider.Stripe),
      concatLatestFrom(() => this.store.select(selectIsFreeOrTrialing)),
      switchMap(([{ plan }, isFreeOrTrialing]) =>
        this.billingV3Service.getCheckoutUrl(isFreeOrTrialing, {
          subscriptionConfigurationId: plan.id,
          redirectUrl: window.location.href,
        })
      ),
      map(redirectUrl => BillingStateActions.routeToStripePortal(redirectUrl)),
      catchError((error: unknown) =>
        of(NotificationActions.notifyError({ error, message: 'Failed to get checkout url from Stripe' }))
      )
    )
  );

  getCancelUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.selectPlan),
      filter(({ plan }) => plan.provider === SubscriptionProvider.Ninety),
      switchMap(() => this.billingV3Service.getCancelUrl()),
      map(redirectUrl => BillingStateActions.routeToStripePortal(redirectUrl)),
      catchError((error: unknown) =>
        of(NotificationActions.notifyError({ error, message: 'Failed to get cancel url from Stripe' }))
      )
    )
  );

  getCancelUrlForSelectFreemium$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.selectFreemium),
      switchMap(() => this.billingV3Service.getCancelUrl()),
      map(redirectUrl => BillingStateActions.routeToStripePortal(redirectUrl)),
      catchError((error: unknown) =>
        of(NotificationActions.notifyError({ error, message: 'Failed to get cancel url from Stripe' }))
      )
    )
  );

  routeToStripePortal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BillingStateActions.routeToStripePortal),
        tap(({ redirectUrl }) => {
          window.location.href = redirectUrl;
        })
      ),
    { dispatch: false }
  );

  getUpcomingInvoiceOnBillingInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.init),
      switchMap(() => this.store.select(selectCurrentUserIsManagerOrAbove)),
      filter(isManagerOrAbove => isManagerOrAbove),
      map(() => BillingStateActions.getUpcomingInvoice())
    )
  );

  getActiveSubscriptionConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.init, BillingStateActions.getActiveSubscriptionConfigurations),
      exhaustMap(() =>
        this.billingV2Service.getActiveSubscriptionConfigurations().pipe(
          map(response => {
            if (!response) {
              return BillingStateActions.getActiveSubscriptionConfigurationsFailure({
                error: null,
                customMessage: 'Failed to get subscription configurations',
              });
            }
            return BillingStateActions.getActiveSubscriptionConfigurationsSuccess({
              subscriptionConfigurations: response,
            });
          })
        )
      )
    )
  );

  getCompanyBillingCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.init, BillingStateActions.getCompanyBillingCounts),
      exhaustMap(() =>
        this.billingV2Service.getBillingCounts().pipe(
          map(response => {
            if (!response) {
              return BillingStateActions.getCompanyBillingCountsFailure({
                error: null,
                customMessage: 'Failed to get company billing counts',
              });
            }
            return BillingStateActions.getCompanyBillingCountsSuccess({
              companyBillingCounts: response,
            });
          })
        )
      )
    )
  );

  changeLicenseCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.changeLicenseCount),
      concatLatestFrom(() => [
        this.store.select(selectCurrentUserIsManagerOrAbove),
        this.store.select(selectIsBillingV3Company),
      ]),
      filter(([_, isManagerOrAbove, isBillingV3Company]) => isManagerOrAbove && isBillingV3Company),
      switchMap(([{ quantity }]) => this.billingV3Service.getChangeQuantityUrl(quantity, window.location.href)),
      map(redirectUrl => BillingStateActions.routeToStripePortal(redirectUrl)),
      catchError((error: unknown) =>
        of(NotificationActions.notifyError({ error, message: 'Failed to redirect to Stripe' }))
      )
    )
  );

  /** temp - for ability to use olf manage seats dialog with billing v3 and billing v2 */
  handleRequestUpdateSubscriptionQuantityUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.requestUpdateSubscriptionQuantityUrl),
      concatLatestFrom(() => this.store.select(selectIsBillingV3Company)),
      filter(([, isBillingV3]) => isBillingV3),
      map(([{ updateSubscriptionQuantityRequest }]) =>
        BillingStateActions.changeLicenseCount({ quantity: updateSubscriptionQuantityRequest.quantity })
      )
    )
  );

  getUpcomingInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getUpcomingInvoice),
      exhaustMap(() =>
        this.billingV2Service.getUpcomingInvoice().pipe(
          map(response => {
            if (!response) {
              return BillingStateActions.getUpcomingInvoiceFailure({
                error: null,
                customMessage: 'Failed to get upcoming invoice',
              });
            }
            return BillingStateActions.getUpcomingInvoiceSuccess({ invoice: response });
          })
        )
      )
    )
  );

  startSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.selectPlan),
      map(() => SpinnerActions.startPrimary({ source: 'Subscription' }))
    )
  );

  billingStateActionEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BillingStateActions.getCompanyBillingCountsSuccess,
        BillingStateActions.getCompanyBillingCountsFailure,
        NotificationActions.notifyError
      ),
      map(() => SpinnerActions.stopPrimary({ source: 'Subscription' }))
    )
  );

  /** New Commercial Model */
  openPricingTierDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BillingStateActions.openPricingPlansModal),
        concatLatestFrom(() => [this.store.select(selectCommercialModelActive), this.store.select(selectCurrentPlan)]),
        tap(([, commercialModelActive, currentPlan]) => {
          if (commercialModelActive)
            this.dialog.open<PricingTierDialogComponent>(PricingTierDialogComponent, {
              panelClass: 'pricing-tier-modal-container',
              data: { currentPlan },
            });
          else this.router.navigateByUrl('settings/billing/overview');
        })
      ),
    { dispatch: false }
  );

  dismissUpdatedSubscriptionNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.dismissUpdatedSubscriptionNotification),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      map(([_, { _id: userId }]) =>
        UsersStateActions.update({ userId, update: { updatedSubscriptionDismissed: true } })
      )
    )
  );

  // banner on the homepage "Your free trial of Thrive is over..."
  dismissFreePlanNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.dismissFreePlanNotification),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      map(([_, { _id: userId }]) =>
        UsersStateActions.update({ userId, update: { freePlanNotificationDismissed: true } })
      )
    )
  );

  private confirmLicenseChangeDialogRef: MatDialogRef<ConfirmLicenseChangeComponent>;
  openConfirmLicenseChangeDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BillingStateActions.openConfirmLicenseChangeDialog),
        tap(({ data }) => {
          this.confirmLicenseChangeDialogRef = this.dialog.open<
            ConfirmLicenseChangeComponent,
            ConfirmLicenseChangeDialogData,
            number
          >(ConfirmLicenseChangeComponent, {
            width: '400px',
            data,
          });
        })
      ),
    { dispatch: false }
  );

  cancelLicenseChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BillingStateActions.cancelLicenseChange),
        tap(() => this.confirmLicenseChangeDialogRef.close())
      ),
    { dispatch: false }
  );

  clearStripeQueryParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BillingStateActions.clearStripeQueryParams),
        tap(() => {
          this.router.navigate([], {
            queryParams: { external: null, reason: null },
            queryParamsHandling: 'merge',
          });
        })
      ),
    { dispatch: false }
  );

  getBillingPortalUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getBillingPortalUrl),
      switchMap(() => this.billingV3Service.getBillingPortalUrl()),
      map(({ redirectUrl }) => BillingStateActions.routeToStripePortal({ redirectUrl }))
    )
  );

  /** for billing v2 companies */
  getBillingCountsAfterChangingNumberOfPaidUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AddTeammatesActions.createUsersSuccess
        /** todo - find out if we want the following actions to trigger this effect */
        // UsersStateActions.updateRoleSuccess,
        // DirectoryActions.inviteUserSuccess
      ),
      concatLatestFrom(() => this.store.select(selectIsBillingV2Company)),
      filter(([, isBillingV2Company]) => isBillingV2Company),
      switchMap(() => this.billingV3Service.getBillingCounts()),
      map(companyBillingCounts =>
        BillingStateActions.getBillingCountsAfterChangingNumberOfPaidUsersSuccess({ companyBillingCounts })
      )
    )
  );

  getBillingCountsAfterChangingNumberOfPaidUsersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getBillingCountsAfterChangingNumberOfPaidUsersSuccess),
      filter(({ companyBillingCounts }) => companyBillingCounts.activeBillingUsers > companyBillingCounts.seatsPaidFor),
      map(({ companyBillingCounts: counts }) =>
        SubscriptionActions.autoIncrementSubscriptionQuantity({ quantity: counts.activeBillingUsers })
      )
    )
  );
}
