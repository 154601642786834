import { AgSeriesHighlightStyle } from 'ag-charts-enterprise';

export function createStandardSeriesHighlight(overrides?: AgSeriesHighlightStyle): AgSeriesHighlightStyle {
  const _default: AgSeriesHighlightStyle = {
    series: {
      enabled: true,
      dimOpacity: 0.2,
    },
  };

  return { ..._default, ...overrides };
}

export function disableSeriesHighlight(): AgSeriesHighlightStyle {
  return { series: { enabled: false } };
}
