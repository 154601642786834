import { CssColor } from 'ag-charts-enterprise';

import {
  DiscoBorderHeavy,
  DiscoBrandNinety,
  DiscoColor,
  DiscoIssueNoRating,
  DiscoIssueRating1,
  DiscoIssueRating2,
  DiscoIssueRating3,
  DiscoIssueRating4,
  DiscoIssueRating5,
  DiscoLineGreekBlue,
  DiscoLineLavender,
  DiscoLineShadow,
  DiscoLineTeal,
  DiscoLineTurquoise,
  DiscoNeutralLight500,
  DiscoNeutralMid100,
  DiscoNeutralMid400,
  DiscoSapphire,
  DiscoSemanticGreen,
  DiscoSemanticGreenHeavy,
  DiscoSemanticGreenLight,
  DiscoSemanticOrange,
  DiscoSemanticOrangeLight,
  DiscoSemanticRed,
} from './index';

/** An array of colors representing a pallet. Expected to be used sequentially and circularly  */
export type DiscoPallet = CssColor[];

/** Produces a monochrome pallet by shading a color to a given opacity */
export function monochromaticPalletFromNSteps(color: DiscoColor, opacities: number[]): DiscoPallet {
  if (!color.isFullOpacity()) throw new Error('Cannot create monochromatic pallet from non-full opacity color');

  const pallet: DiscoPallet = [];
  for (const opacity of opacities) {
    const shaded = color.withOpacityPercent(opacity);
    pallet.push(shaded.toAgChartsCssColor());
  }

  return pallet;
}

export namespace DiscoPallets {
  /** A four-tone pallet representing Very Good - Good - Bad - Very Bad */
  export function semanticFourTone(): DiscoPallet {
    return [DiscoBrandNinety, DiscoSemanticGreen, DiscoNeutralMid100, DiscoSemanticRed];
  }

  /** A five-tone pallet representing good - neutral - warning */
  export function semanticFiveTone(): DiscoPallet {
    return [
      DiscoSemanticGreenHeavy,
      DiscoSemanticGreen,
      DiscoSemanticGreenLight,
      DiscoSemanticOrangeLight,
      DiscoSemanticOrange,
    ];
  }

  /** A monochromatic, five tone pallet  */
  export function blueFiveTone(): DiscoPallet {
    return monochromaticPalletFromNSteps(DiscoColor.fromHexString(DiscoSapphire), [0.9, 0.65, 0.45, 0.32, 0.15]);
  }

  /**
   *  A pallet used to represent the various statuses of discussions
   *  In order: Overdue, Pending Meeting, In Progress, Created, Completed
   */
  export function conversationRate(): DiscoPallet {
    return [DiscoSemanticRed, DiscoSemanticOrange, DiscoNeutralMid400, DiscoNeutralLight500, DiscoSemanticGreen];
  }

  /**
   * A pallet used in conjunction with the {@link conversationRate} pallet to represent the stroke of the sectors
   */
  export function conversationRateStroke(): DiscoPallet {
    return [DiscoSemanticRed, DiscoSemanticOrange, DiscoNeutralMid400, DiscoBorderHeavy, DiscoSemanticGreen];
  }

  export function burndownMultiQuarter(): DiscoPallet {
    return [DiscoLineTeal, DiscoLineGreekBlue, DiscoLineTurquoise, DiscoLineLavender, DiscoLineShadow];
  }

  export function issueResolutionOverTime(): DiscoPallet {
    return [
      DiscoIssueNoRating,
      DiscoIssueRating1,
      DiscoIssueRating2,
      DiscoIssueRating3,
      DiscoIssueRating4,
      DiscoIssueRating5,
    ];
  }
}
