import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraIconModule } from '../../terra-icon';
import { TerraInputAutoFocusDirective } from '../terra-input-auto-focus/terra-input-autofocus.directive';

import { TerraInputPrefixComponent, TerraInputSuffixComponent } from './../terra-input-base/terra-input-base.slots';
import { TerraTextInputComponent } from './terra-text-input.component';

@NgModule({
  imports: [
    CommonModule,
    TerraTextInputComponent,
    TerraIconModule,
    TerraInputPrefixComponent,
    TerraInputSuffixComponent,
    TerraInputAutoFocusDirective,
  ],
  exports: [
    TerraTextInputComponent,
    TerraIconModule,
    TerraInputPrefixComponent,
    TerraInputSuffixComponent,
    TerraInputAutoFocusDirective,
  ],
})
export class TerraTextInputModule {}
