import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { fromEvent, merge } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoggingService } from '../../_core/vendor/opentelemetry/logging.service';
import { SpanService } from '../../_core/vendor/opentelemetry/span.service';
import { TelemetrySessionService } from '../../_core/vendor/opentelemetry/telemetry-session.service';
import { UserListStateActions } from '../../_state/app-entities/user-list/user-list-state.actions';
import { AuthActions } from '../../_state/app-global/auth/auth.actions';

@Injectable()
export class UserSessionEffects {
  startSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserListStateActions.setLoggedInUser),
        tap(action => {
          this.sessionService.startSession(action.loggedInUser._id);
        })
      ),
    { dispatch: false }
  );

  endSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logOut),
        tap(() => {
          this.sessionService.endSession();
        })
      ),
    { dispatch: false }
  );

  trackNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        tap(() => {
          const sessionSpan = this.sessionService.getSessionSpan();
          if (sessionSpan) {
            const currentUrl = this.router.url;
            const previousUrl = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString() || '';
            const currentRoute = document.querySelector('[data-cy]')?.getAttribute('data-cy') || '';

            const navigationSpan = this.spanService.startSpan('Navigation', {
              'navigation.from': previousUrl,
              'navigation.to': currentUrl,
              'navigation.timestamp': new Date().toISOString(),
              'navigation.queryParams': JSON.stringify(this.router.parseUrl(currentUrl).queryParams),
              'navigation.fragment': this.router.parseUrl(currentUrl).fragment || '',
              'navigation.dataCy': currentRoute,
              parentSpan: sessionSpan,
            });

            this.spanService.closeSpan(navigationSpan);
          }
        })
      ),
    { dispatch: false }
  );

  trackUserInteractions$ = createEffect(
    () => {
      const clicks$ = fromEvent<MouseEvent>(document, 'click').pipe(
        tap(event => {
          const sessionSpan = this.sessionService.getSessionSpan();
          if (sessionSpan && event.target instanceof HTMLElement) {
            const dataCy =
              event.target.getAttribute('data-cy') || event.target.closest('[data-cy]')?.getAttribute('data-cy') || '';

            const clickSpan = this.spanService.startSpan('UserClick', {
              'interaction.type': 'click',
              'interaction.dataCy': dataCy,
              'interaction.elementType': event.target.tagName,
              'interaction.elementClass': event.target.className,
              'interaction.timestamp': new Date().toISOString(),
              'interaction.path': event
                .composedPath()
                .map(el => (el instanceof HTMLElement ? el.tagName : ''))
                .join(' > '),
              parentSpan: sessionSpan,
            });

            this.spanService.closeSpan(clickSpan);
          }
        })
      );

      const formSubmissions$ = fromEvent<SubmitEvent>(document, 'submit').pipe(
        tap(event => {
          const sessionSpan = this.sessionService.getSessionSpan();
          if (sessionSpan && event.target instanceof HTMLFormElement) {
            const dataCy =
              event.target.getAttribute('data-cy') || event.target.closest('[data-cy]')?.getAttribute('data-cy') || '';

            const formSpan = this.spanService.startSpan('FormSubmission', {
              'form.id': event.target.id || '',
              'form.name': event.target.name || '',
              'form.dataCy': dataCy,
              'form.timestamp': new Date().toISOString(),
              parentSpan: sessionSpan,
            });

            this.spanService.closeSpan(formSpan);
          }
        })
      );

      return merge(clicks$, formSubmissions$);
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sessionService: TelemetrySessionService,
    private readonly loggingService: LoggingService,
    private readonly spanService: SpanService,
    private readonly router: Router
  ) {}
}
