import { Injectable } from '@angular/core';

import { RoleSelectOption, User } from '../../_shared';
import { RoleCode } from '../../_shared/models/_shared/role-code';
import { RoleName } from '../../_shared/models/_shared/role-name';

const OWNER_OR_COACH = { roleCode: RoleCode.owner } as User;
const ADMIN = { roleCode: RoleCode.admin } as User;
const MANAGER = { roleCode: RoleCode.manager } as User;
const TEAM_MEMBER = { roleCode: RoleCode.managee } as User;

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  static minRole = {
    owner(roleCode: RoleCode): boolean {
      return roleCode === RoleCode.owner;
    },
    admin(roleCode: RoleCode): boolean {
      return roleCode === RoleCode.owner || roleCode === RoleCode.admin;
    },
    manager(roleCode: RoleCode): boolean {
      return roleCode === RoleCode.owner || roleCode === RoleCode.admin || roleCode === RoleCode.manager;
    },
    managee(roleCode: RoleCode): boolean {
      return (
        roleCode === RoleCode.owner ||
        roleCode === RoleCode.admin ||
        roleCode === RoleCode.manager ||
        roleCode === RoleCode.managee
      );
    },
    lite(roleCode: RoleCode): boolean {
      return (
        roleCode === RoleCode.owner ||
        roleCode === RoleCode.admin ||
        roleCode === RoleCode.manager ||
        roleCode === RoleCode.managee ||
        roleCode === RoleCode.lite
      );
    },
    observer(roleCode: RoleCode): boolean {
      return true;
    },
  };

  static minRoleName = {
    owner(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return r.toLocaleLowerCase() === RoleName.owner;
    },
    admin(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return r === RoleName.owner || r === RoleName.admin;
    },
    manager(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return r === RoleName.owner || r === RoleName.admin || r === RoleName.manager;
    },
    managee(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return r === RoleName.owner || r === RoleName.admin || r === RoleName.manager || r === RoleName.managee;
    },
    lite(role: RoleName): boolean {
      if (!role) return false;

      const r = role.toLocaleLowerCase();
      return (
        r === RoleName.owner ||
        r === RoleName.admin ||
        r === RoleName.manager ||
        r === RoleName.managee ||
        r === RoleName.lite
      );
    },
    observer(_role: RoleName): boolean {
      return true;
    },
  };

  static roleName(roleCode: RoleCode) {
    switch (roleCode) {
      case RoleCode.owner:
        return 'Owner';
      case RoleCode.admin:
        return 'Admin';
      case RoleCode.manager:
        return 'Manager';
      case RoleCode.lite:
        return 'Lite';
      case RoleCode.managee:
        return 'Managee';
      case RoleCode.observer:
        return 'Observer';
      default:
        return '';
    }
  }

  static setRolePermissionsGroup<T>(roleCode: RoleCode, context) {
    context.isOwner = RoleService.minRole.owner(roleCode);
    context.isAdminOrOwner = RoleService.minRole.admin(roleCode);
    context.isManagerOrAbove = RoleService.minRole.manager(roleCode);
    context.isManageeOrAbove = RoleService.minRole.managee(roleCode);
  }

  static canUserAssignRoleCode({
    userRoleCode,
    otherRoleCode,
  }: {
    userRoleCode: RoleCode;
    otherRoleCode: RoleCode;
  }): boolean {
    if (!userRoleCode || !otherRoleCode) return false;
    return RoleService.minRole.admin(userRoleCode)
      ? parseInt(userRoleCode, 10) <= parseInt(otherRoleCode, 10)
      : parseInt(userRoleCode, 10) < parseInt(otherRoleCode, 10);
  }

  static canChangeUserRole(currentUser: User, userToEdit: User, numOwners: number): boolean {
    /** must be at least a manager to edit role */
    if (!RoleService.isManagerOrAbove(currentUser)) return false;

    /** company needs at least one owner */
    if (RoleService.isOwner(userToEdit)) return RoleService.isOwnerOrCoach(currentUser) && numOwners > 1;

    /** can edit yourself as long as not an owner and only one owner */
    if (userToEdit._id === currentUser._id) return true;

    /** admin and coaches can only be edited by others of equal or higher role */
    if (RoleService.isAdminCoachOrOwner(userToEdit)) return RoleService.isEqualOrHigherRole(currentUser, userToEdit);

    /** managers can only edit team members and below */
    return RoleService.isHigherRole(currentUser, userToEdit);
  }

  static canEditUser(currentUser: User, userToEdit: User): boolean {
    /** must be at least a manager to edit */
    if (RoleService.isTeamMemberOrBelow(currentUser)) return false;

    /** owners, coaches, and admins can edit users at same or lower roles */
    if (RoleService.isAdminCoachOrOwner(userToEdit)) return RoleService.isEqualOrHigherRole(currentUser, userToEdit);

    /** managers can only edit team members and below */
    return RoleService.isHigherRole(currentUser, userToEdit);
  }

  /**
   * Used for User Detail View on directory page
   * - must be an admin, owner, or coach to edit other active users details
   * - a manager or above can edit if the user hasn't been invited, invite pending, or is inactive
   * */
  static canEditUserDetails(currentUser: User, userToEdit: User): boolean {
    if (currentUser._id === userToEdit?._id) return true;
    if (!userToEdit?.personId) return RoleService.isManagerOrAbove(currentUser);
    return RoleService.isAdminCoachOrOwner(currentUser) && RoleService.isEqualOrHigherRole(currentUser, userToEdit);
  }

  static isEqualOrHigherRole(currentUser: User, otherUser: User): boolean {
    if (!currentUser || !otherUser) return false;
    return parseInt(currentUser.roleCode, 10) <= parseInt(otherUser.roleCode, 10);
  }

  static isEqualOrLowerRole(currentUser: User, otherUser: User): boolean {
    if (!currentUser || !otherUser) return false;
    return parseInt(currentUser.roleCode, 10) >= parseInt(otherUser.roleCode, 10);
  }

  static isHigherRole(currentUser: User, otherUser: User): boolean {
    if (!currentUser || !otherUser) return false;
    return parseInt(currentUser.roleCode, 10) < parseInt(otherUser.roleCode, 10);
  }

  static isOwner(user: User): boolean {
    return user?.roleCode === RoleCode.owner && !user?.isImplementer;
  }

  static isCoach(user: User): boolean {
    return user?.roleCode === RoleCode.owner && user?.isImplementer;
  }

  static isOwnerOrCoach(user: User): boolean {
    return user?.roleCode === RoleCode.owner;
  }

  static isAdmin(user: User): boolean {
    return user?.roleCode === RoleCode.admin;
  }

  static isAdminCoachOrOwner(user: User): boolean {
    return RoleService.isEqualOrHigherRole(user, ADMIN);
  }

  static isManagerOrAbove(user: User): boolean {
    return RoleService.isEqualOrHigherRole(user, MANAGER);
  }

  /** is team member/managee or observer */
  static isTeamMemberOrBelow(user: User): boolean {
    return RoleService.isEqualOrLowerRole(user, TEAM_MEMBER);
  }

  /** does not check if user is active */
  static isFreeRole({ roleCode, isImplementer, ...user }: User | RoleSelectOption): boolean {
    return isImplementer || roleCode === RoleCode.observer || (user as User).isHelpful;
  }

  static isPaidRole({ roleCode, isImplementer, active, ...user }: User | RoleSelectOption): boolean {
    return active && roleCode !== RoleCode.observer && !isImplementer && !(user as User).isHelpful;
  }
}
