import { createReducer, on } from '@ngrx/store';

import { Todo, TodoNegotiatedFields } from '@ninety/ui/legacy/shared/models/todos/todo';

import { todosStateAdapter } from '../_shared/todo-state.shared.model';
import {
  constructSharedReducersWithDistinctActions,
  SharedReducerFunctions,
} from '../_shared/todo-state.shared.reducers';

import {
  TeamTodoActions,
  TeamTodoInlineActions,
  TeamTodoPubnubActions,
  TodoAgreementActions,
} from './team-todo.actions';
import { initialTeamTodoState, TeamTodoState } from './team-todo.model';

export const teamTodoReducer = createReducer<TeamTodoState>(
  initialTeamTodoState,
  ...constructSharedReducersWithDistinctActions<TeamTodoState>('team'),
  on(TeamTodoActions.getTeam, TeamTodoActions.sortBy, (state): TeamTodoState => ({ ...state, loading: true })),
  on(
    TeamTodoActions.teamSelected,
    (state, { teamId }): TeamTodoState => ({
      ...state,
      loading: true,
      pageIndex: 0,
      selectedTodoId: null,
      teamId,
      userPendingAgreements: null,
    })
  ),
  on(
    TeamTodoActions.showArchived,
    (state, { showArchived }): TeamTodoState => ({
      ...state,
      showArchived,
      loading: true,
      pageIndex: 0,
      selectedTodoId: null,
      userPendingAgreements: null,
    })
  ),
  on(TeamTodoActions.toggleArchivedSuccess, SharedReducerFunctions.removeTodoFromStateById),
  on(TeamTodoActions.removeAcceptedTodoFromState, SharedReducerFunctions.removeTodoFromStateById),
  on(TeamTodoActions.toggleArchivedFailure, SharedReducerFunctions.setLoadingToFalse),
  on(TeamTodoActions.getTeamSuccess, SharedReducerFunctions.updateTodosOnGETManySuccess),
  on(TeamTodoActions.getTeamFailure, SharedReducerFunctions.setErrorOnApiFailure),
  on(
    TeamTodoActions.updateLocal,
    TeamTodoActions.updateInlineSuccess,
    TeamTodoActions.updateDueDateSuccess,
    TodoAgreementActions.acceptSuccess,
    /** A successful pubnub attachment event fetches the updated todo and needs to be merged into the list */
    TeamTodoPubnubActions.attachmentEventSuccess,
    (state, { todo }): TeamTodoState => {
      let todos = todosStateAdapter.updateOne({ id: todo._id, changes: { ...todo } }, state.todos);

      if (state.teamId !== 'all') {
        todos = todosStateAdapter.removeMany(t => t.teamId !== state.teamId, todos);
      }

      const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);
      return {
        ...state,
        todos,
        loading: false,
        completionTooltip,
      };
    }
  ),
  /** Updating reducer optimistically for TeamTodoActions.update which is Partial<Todo>  */
  on(TeamTodoActions.update, TeamTodoActions.updateUserSuccess, (state, { todo }): TeamTodoState => {
    let todos = todosStateAdapter.updateOne({ id: state.selectedTodoId, changes: todo }, state.todos);

    if (state.teamId !== 'all') {
      todos = todosStateAdapter.removeMany(t => t.teamId !== state.teamId, todos);
    }

    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);

    return {
      ...state,
      todos,
      loading: false,
      completionTooltip,
    };
  }),
  on(TeamTodoInlineActions.addOne, (state, { todo }): TeamTodoState => {
    const todos = todosStateAdapter.addOne(todo, state.todos);
    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);
    return {
      ...state,
      todos,
      listControlsDisabled: true,
      focusOnInlineAddTodo: false,
      completionTooltip,
    };
  }),
  on(TeamTodoActions.addManySuccess, (state, { response }): TeamTodoState => {
    let todos = state.todos;

    const viewingTeamCreatedTodosExistIn =
      state.teamId === 'all' || response.todos.some(t => t.teamId === state.teamId);
    if (!state.showArchived && viewingTeamCreatedTodosExistIn) {
      todos = todosStateAdapter.addMany(response.todos, state.todos);
    }

    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);

    return {
      ...state,
      todos,
      todoCount: todos.ids.length,
      completionTooltip,
    };
  }),
  on(TeamTodoPubnubActions.addManyFromBroadcast, (state, { todos }): TeamTodoState => {
    let newTodos = state.todos;

    const viewingTeamCreatedTodosExistIn = state.teamId === 'all' || todos.some(t => t.teamId === state.teamId);
    if (!state.showArchived && viewingTeamCreatedTodosExistIn) {
      newTodos = todosStateAdapter.addMany(todos, state.todos);
    }

    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(newTodos.entities);
    return {
      ...state,
      todos: newTodos,
      todoCount: newTodos.ids.length,
      completionTooltip,
    };
  }),
  on(TeamTodoInlineActions.cancelAddOne, (state): TeamTodoState => {
    const todos = todosStateAdapter.removeOne(undefined, state.todos);
    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);
    return {
      ...state,
      todos,
      listControlsDisabled: false,
      focusOnInlineAddTodo: false,
      completionTooltip,
    };
  }),
  on(TeamTodoInlineActions.createOneSuccess, (state, { response }): TeamTodoState => {
    let todos = todosStateAdapter.removeOne(undefined, state.todos);
    todos = todosStateAdapter.addOne(response.todos[0], todos);
    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);
    return {
      ...state,
      todos,
      todoCount: todos.ids.length,
      listControlsDisabled: false,
      focusOnInlineAddTodo: true,
      completionTooltip,
    };
  }),
  on(TeamTodoInlineActions.createOneFailure, (state): TeamTodoState => {
    const todos = todosStateAdapter.removeOne(undefined, state.todos);
    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);
    return {
      ...state,
      todos,
      listControlsDisabled: false,
      focusOnInlineAddTodo: false,
      completionTooltip,
    };
  }),
  on(
    TeamTodoActions.resetState,
    (_oldState, { overrides }): TeamTodoState => ({ ...initialTeamTodoState, ...overrides })
  ),
  on(
    TeamTodoActions.initMeetingConclude,
    (state, { teamId, pageSize }): TeamTodoState => ({ ...state, teamId, pageSize })
  ),
  on(TeamTodoActions.syncTasks, TodoAgreementActions.accept, (state): TeamTodoState => ({ ...state, loading: true })),
  on(
    TeamTodoActions.createTask,
    (state): TeamTodoState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    TeamTodoActions.hydratePageSizeFromLocalStore,
    (state, { pageSize }): TeamTodoState => ({
      ...state,
      pageSize,
    })
  ),
  on(
    TeamTodoActions.hydratePageSortingFromLocalStore,
    (state, { sortField, sortDirection }): TeamTodoState => ({
      ...state,
      sortField,
      sortDirection,
    })
  ),
  on(TeamTodoActions.archiveAllCompletedUserTeam, (state): TeamTodoState => ({ ...state, loading: true })),
  on(
    TeamTodoActions.archiveAllCompletedUserTeamFailure,
    (state, { error }): TeamTodoState => ({ ...state, loading: false, error })
  ),
  on(TeamTodoActions.archiveAllCompletedSuccess, (state): TeamTodoState => ({ ...state, pageIndex: 0 })),
  /** Pubnub action received via broadcast */
  on(TeamTodoPubnubActions.unarchive, (state, { todo }): TeamTodoState => {
    /** User is on the archived view and todo has been unarchived - remove from state */
    if (state.showArchived) {
      return SharedReducerFunctions.removeTodoFromStateById(state, { id: todo._id });
    } else {
      /** User is not on the archived view and is viewing the team the todo belongs to - add to state */
      if (todo.teamId === state.teamId && !Object.values(state.todos.entities).find(t => t._id === todo._id)) {
        const todos = todosStateAdapter.addOne(todo, state.todos);
        const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);
        return {
          ...state,
          todos,
          todoCount: todos.ids.length,
          completionTooltip,
        };
      } else {
        return state;
      }
    }
  }),
  /** Attachments */
  on(TeamTodoActions.attachmentUploaded, (state, { event }) => {
    const todo = Object.values(state.todos.entities).find(t => t._id === event.attachment.parentId);
    if (todo) {
      const todos = todosStateAdapter.updateOne(
        { id: event.attachment.parentId, changes: { attachments: [...todo.attachments, event.attachment] } },
        state.todos
      );
      return {
        ...state,
        todos,
      };
    } else {
      return state;
    }
  }),
  on(TeamTodoActions.attachmentRemoved, (state, { event }) => {
    const todo = Object.values(state.todos.entities).find(t => t._id === event.attachment.parentId);
    if (todo) {
      const attachments = todo.attachments?.filter(a => a._id !== event.attachment._id) ?? [];
      const todos = todosStateAdapter.updateOne(
        { id: event.attachment.parentId, changes: { attachments } },
        state.todos
      );

      return {
        ...state,
        todos,
      };
    }
    return state;
  }),
  on(TeamTodoActions.attachmentReordered, (state, { event }) => {
    const todo = Object.values(state.todos.entities).find(t => t._id === event._id);
    if (todo) {
      const todos = todosStateAdapter.updateOne(
        { id: event._id, changes: { attachments: event.attachments } },
        state.todos
      );
      return {
        ...state,
        todos,
      };
    }
    return state;
  }),
  /**
   * We don't normally update on success because the implicit save causes the cursor
   * to jump around if the user is still editing the title or description - so we're only updating the
   * negotiated fields on update success.
   */
  on(TeamTodoActions.update, (state, { todo: updateResponse }) => {
    const todo = Object.values(state.todos.entities).find(t => t._id === updateResponse._id);
    let todos = state.todos;
    if (!todo && !updateResponse.isPersonal) {
      todosStateAdapter.addOne(updateResponse, todos);
    } else if (todo && updateResponse.isPersonal) {
      todos = todosStateAdapter.removeOne(updateResponse._id, todos);
    }
    if (todo && todo.hasOwnProperty('status')) {
      const negotiatedFields: TodoNegotiatedFields = {
        ownerAcceptedDate: updateResponse.ownerAcceptedDate,
        creatorAcceptedDate: updateResponse.creatorAcceptedDate,
        status: updateResponse.status,
      };
      todos = todosStateAdapter.updateOne({ id: todo._id, changes: { negotiatedFields } }, state.todos);
    }

    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);

    return {
      ...state,
      todos,
      completionTooltip,
    };
  }),
  on(TeamTodoActions.updateDisplayedTodosInSeries, (state, { todo }) => {
    const todos = Object.values(state.todos.entities).reduce((acc, t) => {
      //update some fields for todos in series &
      //avoid updating opened todo again and/or causing jumping cursor
      if (t.seriesId === todo.seriesId && t._id !== state.selectedTodoId) {
        if (todo.isPersonal || (state.teamId !== 'all' && todo.teamId !== state.teamId)) {
          //remove any series todos that are now not part of the list
          //they are now personal or on a different team
          return acc;
        }

        return [
          ...acc,
          Object.assign({}, t, {
            userId: todo.userId,
            user: todo.user,
            title: todo.title,
            description: todo.description,
            teamId: todo.teamId,
            isPersonal: todo.isPersonal,
          }),
        ];
      }
      return [...acc, t];
    }, [] as Todo[]);

    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos);
    return { ...state, todos: todosStateAdapter.setAll(todos, state.todos), completionTooltip };
  }),
  on(TeamTodoActions.addToTeam, (state, { todo: updateResponse }) => {
    const todo = Object.values(state.todos.entities).find(t => t._id === updateResponse._id);
    let todos = state.todos;
    if (!todo && !updateResponse.isPersonal) {
      todos = todosStateAdapter.addOne(updateResponse, state.todos);
    }

    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);
    return { ...state, todos, completionTooltip };
  }),
  on(TeamTodoActions.removeFromTeam, (state, { todo: updateResponse }) => {
    const todo = Object.values(state.todos.entities).find(t => t._id === updateResponse._id);
    let todos = state.todos;
    if (todo && updateResponse.isPersonal) {
      todos = todosStateAdapter.removeOne(todo._id, state.todos);
    }

    const completionTooltip = SharedReducerFunctions.getCompletionTooltip(todos.entities);
    return {
      ...state,
      todos,
      completionTooltip,
    };
  }),
  on(
    TeamTodoActions.clearSort,
    (state): TeamTodoState => ({
      ...state,
      sortDirection: null,
      sortField: null,
    })
  ),
  on(
    TeamTodoActions.toggleUserPendingAgreements,
    (state): TeamTodoState => ({
      ...state,
      loading: true,
      pageIndex: 0,
      // Toggle the filter value in this case null or true
      // and then QueryParamsService.build(params, true) will remove the value from the query params
      userPendingAgreements: state.userPendingAgreements ? null : true,
    })
  )
);
