import { RoleCode } from '../models/_shared/role-code';
import { User } from '../models/_shared/user';

export type RoleInfo = Pick<User, 'roleCode' | 'isImplementer'>;

/** Checks if user should have guide enabled in target company
 * Criteria: Is not an implementer or observer
 * */
export function isGsgTargetRole({ roleCode, isImplementer }: RoleInfo): boolean {
  return roleCode !== RoleCode.observer && !isImplementer;
}
