import { createActionGroup, emptyProps, props } from '@ngrx/store';

import type { Entitlements } from '../../../_shared/models/entitlements/entitlements-model';

export const EntitlementsStateActions = createActionGroup({
  source: 'Entitlements',
  events: {
    Init: emptyProps(),
    'Parse Entitlements Token': emptyProps(),
    'Parse Entitlements Token Success': props<{ entitlements: Entitlements }>(),
    'Clear Entitlements': emptyProps(),
  },
});
