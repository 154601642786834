import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Span } from '@opentelemetry/api';
import { tap } from 'rxjs';

import { SpanService } from '@ninety/ui/legacy/core/vendor/opentelemetry/span.service';
import {
  DeleteTeamCustomAgenda,
  MeetingAgendasComponent,
  TeamMeetingAgendaUpdateByType,
} from '@ninety/ui/legacy/shared/index';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';
import { AgendaV2Service } from '@ninety/web/pages/meetings/_services/agenda-v2.service';
import { MeetingsAgendasV2Actions } from '@ninety/web/pages/meetings/_state/meetings.actions';
import { MeetingsStateSelectors } from '@ninety/web/pages/meetings/_state/meetings.selectors';

@Component({
  selector: 'ninety-agenda-v2',
  standalone: true,
  imports: [CommonModule, SharedModule, PushPipe],
  templateUrl: './agenda-v2.component.html',
  styleUrls: ['./agenda-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgendaV2Component implements OnInit, OnDestroy {
  @ViewChild(MeetingAgendasComponent) meetingAgendasComponent!: MeetingAgendasComponent;

  private store = inject(Store);
  public agendaV2Service = inject(AgendaV2Service);
  private destroyRef = inject(DestroyRef);
  private readonly spanService = inject(SpanService);
  private componentSpan: Span;

  selectedTeam$ = this.store.select(MeetingsStateSelectors.selectSelectedTeam);
  agendaTypeOrIdOrNew$ = this.store.select(MeetingsStateSelectors.selectAgendaTypeOrIdOrNew);

  ngOnInit(): void {
    this.componentSpan = this.spanService.startSpan('AgendaV2Component', {
      attributes: {
        component: 'AgendaV2Component',
        startTime: Date.now(),
      },
    });

    const subscriptionSpan = this.spanService.startSpan('InitializeAgendaSubscriptions', {
      parentSpan: this.componentSpan,
      attributes: {
        'action.type': 'subscription',
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.agendaV2Service.saveNewCustomAgendaEvent$
        .pipe(
          tap(() => {
            this.saveNewCustomAgendaEvent();
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();

      this.agendaV2Service.updateAgendaEvent$
        .pipe(
          tap(() => {
            this.updateAgendaEvent();
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();

      this.agendaV2Service.deleteCustomAgendaEvent$
        .pipe(
          tap(() => {
            this.deleteCustomAgendaEvent();
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();

      this.agendaV2Service.discardChangesEvent$
        .pipe(
          tap(() => {
            this.discardChanges();
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();

      this.spanService.closeSpan(subscriptionSpan);
    } catch (error) {
      subscriptionSpan.recordException(error);
      this.spanService.closeSpan(subscriptionSpan);
    }
  }

  ngOnDestroy(): void {
    this.spanService.closeSpan(this.componentSpan);
  }

  saveNewCustomAgendaEvent() {
    const actionSpan = this.spanService.startSpan('SaveNewCustomAgenda', {
      parentSpan: this.componentSpan,
      attributes: {
        'action.type': 'save',
        'action.isNew': true,
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.meetingAgendasComponent.save(true);
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  updateAgendaEvent() {
    const actionSpan = this.spanService.startSpan('UpdateAgenda', {
      parentSpan: this.componentSpan,
      attributes: {
        'action.type': 'update',
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.meetingAgendasComponent.save();
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  deleteCustomAgendaEvent() {
    const actionSpan = this.spanService.startSpan('DeleteCustomAgenda', {
      parentSpan: this.componentSpan,
      attributes: {
        'action.type': 'delete',
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.meetingAgendasComponent.deleteAgenda(false);
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  discardChanges() {
    const actionSpan = this.spanService.startSpan('DiscardChanges', {
      parentSpan: this.componentSpan,
      attributes: {
        'action.type': 'discard',
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.meetingAgendasComponent.cancel();
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  updateAgendaByType(update: TeamMeetingAgendaUpdateByType): void {
    const actionSpan = this.spanService.startSpan('UpdateAgendaByType', {
      parentSpan: this.componentSpan,
      attributes: {
        'action.type': 'updateByType',
        'action.isNew': !!update.newAgenda,
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      const { newAgenda } = update;
      if (newAgenda) {
        this.store.dispatch(MeetingsAgendasV2Actions.saveNewCustomAgenda({ update }));
      } else {
        this.store.dispatch(MeetingsAgendasV2Actions.updateAgenda({ update }));
      }
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  deleteCustomAgenda({ teamId, agendaId }: DeleteTeamCustomAgenda): void {
    const actionSpan = this.spanService.startSpan('DeleteCustomAgendaById', {
      parentSpan: this.componentSpan,
      attributes: {
        'action.type': 'delete',
        'agenda.teamId': teamId,
        'agenda.id': agendaId,
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.store.dispatch(MeetingsAgendasV2Actions.deleteCustomAgenda({ teamId, agendaId }));
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  validForm(isValid: boolean) {
    const actionSpan = this.spanService.startSpan('ValidateForm', {
      parentSpan: this.componentSpan,
      attributes: {
        'form.isValid': isValid,
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.agendaV2Service.validForm(isValid);
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  newChanges(newChanges: boolean) {
    const actionSpan = this.spanService.startSpan('TrackChanges', {
      parentSpan: this.componentSpan,
      attributes: {
        'changes.hasNew': newChanges,
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.agendaV2Service.newChanges(newChanges);
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }
}
