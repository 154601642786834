import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep, merge as _merge } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  expand,
  filter,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  share,
  Subject,
  switchMap,
  tap,
} from 'rxjs';

import {
  TimezoneDialogData,
  TimezoneDialogResult,
} from '../../_shared/components/_mdc-migration/timezone-changed-dialog/models';
import { TimezoneChangedDialogComponent } from '../../_shared/components/_mdc-migration/timezone-changed-dialog/timezone-changed-dialog.component';
import { NewUserDto } from '../../_shared/components/add-teammates-dialog/models/new-user.dto';
import { DialogMode } from '../../_shared/models/_shared/dialog-mode-types';
import { Email } from '../../_shared/models/_shared/email';
import { PersonMetadata, PersonMetadataUpdate } from '../../_shared/models/_shared/person-metadata';
import { RoleCode } from '../../_shared/models/_shared/role-code';
import { TimezoneEntry } from '../../_shared/models/_shared/timezone-select';
import { AdminEmail, User } from '../../_shared/models/_shared/user';
import { UserSettings } from '../../_shared/models/_shared/user-settings';
import { UserSms } from '../../_shared/models/_shared/user-sms';
import { AccountStatus } from '../../_shared/models/company/company';
import { CompanyUser } from '../../_shared/models/company/company-user';
import { DirectoryUserStatus } from '../../_shared/models/directory/directory-user-status';
import { InviteUserPayload } from '../../_shared/models/directory/invite-user-payload';
import { UpdateUserTeamPayload } from '../../_shared/models/directory/update-user-teams';
import { SnackbarTemplateType } from '../../_shared/models/enums/snackbar-template-type';
import { SortDirection } from '../../_shared/models/enums/sort-direction';
import { SortByUserNamePipe } from '../../_shared/pipes/sort-by-user-name.pipe';
import { UserListRequest } from '../../_state/app-entities/user-list/api/user-list-request.model';
import { UserListResponse } from '../../_state/app-entities/user-list/api/user-list-response.model';
import { UserListModel } from '../../_state/app-entities/user-list/api/user-list.model';
import {
  InviteUsersActions,
  UserInviteActions,
  UsersStateActions,
} from '../../_state/app-entities/users/users-state.actions';
import { selectAllUsers } from '../../_state/app-entities/users/users-state.selectors';
import { SpinThenNotifyOrError } from '../decorators/spin-then-notify-or-error';
import { SpinnerAndCatchError } from '../decorators/spinner-and-catch-error';

import { AppLoadService } from './app-load.service';
import { ErrorService } from './error.service';
import { NotifyService } from './notify.service';
import { SpinnerService } from './spinner.service';
import { StateService } from './state.service';
import { LocalStorageService } from './storage.service';

export interface CanAddUserWithDetails {
  canAdd: boolean;
  companyUserLimit: number;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private usersApi = '/api/v4/Users';
  private readonly inviteUsersApi = '/api/v4/Invite/Users';

  allUsers: User[];
  allUsersAndImplementers: User[];
  users: User[];
  fetchingDirectoryUsers = new Map<string, Observable<User>>();
  directoryUsers$ = new BehaviorSubject<User[]>([]);
  directoryInvitedTotalUsers$ = new BehaviorSubject<number>(0);
  directoryUsersPaginated$ = new BehaviorSubject<User[]>([]);
  directoryUsersPaginatedCount$ = new BehaviorSubject<number>(0);

  canAddUsers$ = new BehaviorSubject<CanAddUserWithDetails>({ canAdd: false, companyUserLimit: 20 });
  users$ = new BehaviorSubject<User[]>(null);
  allUsers$ = new BehaviorSubject<User[]>(null);
  fetchingUsers = false;
  updateUserSettings$ = new Subject<Partial<UserSettings>>();
  readonly saveSettings$ = new Subject<void>();
  readonly smsChange$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private appLoadService: AppLoadService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
    private sortByUserNamePipe: SortByUserNamePipe,
    private stateService: StateService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private notifyService: NotifyService
  ) {
    this.store
      .select(selectAllUsers)
      .pipe(
        filter(users => !!users?.length),
        tap(users => this.filterUsers(users))
      )
      .subscribe();

    this.appLoadService.directoryUsers$.subscribe({
      next: users => this.directoryUsers$.next(this.sortByUserNamePipe.transform(users)),
    });

    this.updateUserSettings$.pipe(switchMap(update => this.updateUserSettings(update))).subscribe();

    combineLatest({
      invitedTotalUsers: this.directoryInvitedTotalUsers$,
      companyUser: this.stateService.currentCompanyUser$,
    })
      .pipe(
        filter(({ companyUser }) => !!companyUser?.company),
        tap(({ companyUser, invitedTotalUsers }) => {
          const { accountStatus, userLimit, trialingUserLimit } = companyUser.company;
          this.canAddUsers$.next({
            //-1 for owner
            canAdd:
              accountStatus === AccountStatus.TRIALING
                ? invitedTotalUsers < trialingUserLimit - 1
                : invitedTotalUsers < userLimit - 1,
            companyUserLimit: accountStatus === AccountStatus.TRIALING ? trialingUserLimit : userLimit,
          });
        })
      )
      .subscribe();
  }

  selectAllUsers() {
    return this.allUsers$.asObservable();
  }

  // invite and user/personmetadata creation if needed
  inviteUser(
    inviteUserPayload: InviteUserPayload,
    sendInvite = false,
    existingDirectoryUser = false,
    previouslyInvited = false
  ): Observable<User> {
    this.store.dispatch(
      InviteUsersActions.inviteUserToCompany({
        invitedUser: inviteUserPayload,
        sendInvite,
        existingDirectoryUser,
        previouslyInvited,
      })
    );

    this.spinnerService.start();
    return this.http
      .post<User>('/api/v4/Invite', inviteUserPayload, {
        params: new HttpParams().set('sendInvite', sendInvite.toString()),
      })
      .pipe(
        tap((user: User) => {
          this.store.dispatch(UserInviteActions.inviteUser({ user }));
          this.updateDirectoryUsers(user, false);
          const successMessage = existingDirectoryUser
            ? `User has been sent an${previouslyInvited ? ' additional ' : ' '}invite.`
            : `${
                user.fullName ? user.fullName : inviteUserPayload.firstName + ' ' + inviteUserPayload.lastName
              } has been invited to Ninety`;

          this.notifyService.notifyWithTemplate(SnackbarTemplateType.success, { message: successMessage }, 3000);
          this.spinnerService.stop();
        }),
        catchError((e: { error?: { errorMessage: string } }) => {
          this.spinnerService.stop();
          let failMessage = e?.error?.errorMessage;
          if (!failMessage) {
            failMessage = inviteUserPayload.hasBeenInvited
              ? 'Could not re-invite user.  Please try again.'
              : `Could not create ${inviteUserPayload.active ? 'user' : 'person'}.  Please try again.`;
          }
          return this.errorService.notify(e, failMessage);
        })
      );
  }

  deleteUser(userId: string): Observable<void> {
    this.spinnerService.start();
    return this.http.delete<void>(`/api/v4/Users/${userId}`).pipe(
      tap(() => {
        const allDirectoryUsers = this.directoryUsers$.value.filter(u => u._id !== userId);
        this.directoryUsers$.next(this.sortByUserNamePipe.transform(allDirectoryUsers));
        const allUsers = this.allUsers.filter(u => u._id !== userId);
        this.filterUsers(allUsers);
        this.spinnerService.stop();
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not delete user. Please try again.'))
    );
  }

  deactivateUser(userId: string): Observable<any> {
    const deactivateUserUpdate: Partial<User> = {
      personId: null,
      active: false,
      hasBeenInvited: false,
      teams: [],
    };

    return this.update(deactivateUserUpdate, userId).pipe(
      tap(() => {
        this.filterUsers(this.allUsers);
        this.snackBar.open('User has been deactivated.', undefined, { duration: 3000 });
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not deactivate user.'))
    );
  }

  fetchUsersInBackground(): void {
    this.getUsers().subscribe();
  }

  filterUsers(users: User[]): void {
    this.allUsersAndImplementers = users;

    const nonImplementers = users.filter(u => !u.isImplementer);
    this.allUsers = this.sortByUserNamePipe.transform(nonImplementers);
    this.allUsers$.next(nonImplementers);

    const filteredUsers = nonImplementers.filter(u => u.active !== false && u.roleCode !== RoleCode.observer);
    this.users$.next(filteredUsers);
    this.users = filteredUsers;
  }

  getUser(userId: string = this.stateService.currentUser$?.getValue()?._id): Observable<User> {
    if (!userId) return of(null);

    const directoryUser = this.directoryUsers$.getValue().find((u: User) => u._id === userId);
    if (directoryUser) return of(directoryUser);

    const fetchingUser$ = this.fetchingDirectoryUsers.get(userId);
    if (fetchingUser$) return fetchingUser$;

    return this.fetchUser(userId);
  }

  fetchUser(userId: string): Observable<User> {
    const user$ = this.http.get<User>(`${this.usersApi}/${userId}`).pipe(
      tap((user: User) => {
        //user that doesn't exist in db returns as empty object, hard-deleted bad test data?
        user = user?._id ? user : { ...user, _id: userId };
        const directoryUsers = [...this.directoryUsers$.getValue()];
        const existingUserIdx = directoryUsers.findIndex((u: User) => u._id === userId);
        if (existingUserIdx > -1) {
          directoryUsers[existingUserIdx] = user;
        } else {
          directoryUsers.push(user);
        }
        this.directoryUsers$.next(directoryUsers);
        this.fetchingDirectoryUsers.delete(user._id);
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not get user.  Please try again.')),
      share()
    );
    this.fetchingDirectoryUsers.set(userId, user$);
    return user$;
  }

  getDirectoryUsers(): Observable<User[]> {
    this.spinnerService.start();
    return this.http.get<{ users: User[]; invitedTotalUsers: number }>(`${this.usersApi}/Directory`).pipe(
      tap(({ users, invitedTotalUsers }) => {
        this.directoryUsers$.next(this.sortByUserNamePipe.transform(users));
        this.directoryInvitedTotalUsers$.next(invitedTotalUsers);
        this.spinnerService.stop();
      }),
      map(({ users }) => users),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not get directory users.  Please try again.'))
    );
  }

  getDirectoryUsersPaginated({
    page,
    pageSize,
    sortField = 'metadata.fullName',
    sortDirection = SortDirection.ASC,
    searchText = '',
    teamId = null,
    userStatus = DirectoryUserStatus.all,
  }: {
    page: number;
    pageSize: number;
    sortField?: keyof User | 'metadata.fullName';
    sortDirection?: SortDirection;
    searchText?: string;
    teamId?: string;
    userStatus?: DirectoryUserStatus;
  }): Observable<{ users: User[]; count: number }> {
    const params = { page, pageSize, sortField, sortDirection, searchText, teamId, userStatus };
    return this.http.get<{ users: User[]; count: number }>(`${this.usersApi}/Directory/Paginated`, { params }).pipe(
      tap(({ users, count }) => {
        this.directoryUsersPaginated$.next(users);
        this.directoryUsersPaginatedCount$.next(count);
      })
    );
  }

  getAdminEmails(limit = 3): Observable<AdminEmail[]> {
    return this.http.get<AdminEmail[]>(`${this.usersApi}/Admins?limit=${limit}`);
  }

  getUsers(): Observable<User[]> {
    if (this.fetchingUsers) return of(null);
    this.fetchingUsers = true;
    return this.http.get<User[]>(this.usersApi).pipe(
      tap(users => {
        this.filterUsers(users);
        this.fetchingUsers = false;
      }),
      catchError((e: unknown) => {
        this.fetchingUsers = false;
        return this.errorService.oops(e);
      })
    );
  }

  getUserList({ sort }: Pick<UserListRequest, 'sort'>): Observable<UserListModel[]> {
    let page = 0;
    const pageSize = 500;
    return this.getUserListPage({ page, pageSize, sort }).pipe(
      expand(({ hasMore }) => (hasMore ? this.getUserListPage({ page: ++page, pageSize, sort }) : EMPTY)),
      mergeMap(({ users }) => of(users))
    );
  }

  getUserListPage({
    page,
    pageSize,
    sort,
  }: Pick<UserListRequest, 'page' | 'pageSize' | 'sort'>): Observable<UserListResponse> {
    return this.http.post<UserListResponse>('/api/v4/Users/List', {
      body: { page, pageSize, ...(sort ? { sort } : {}) },
    });
  }

  update(update: Partial<User>, userId = this.stateService.currentUser$.value._id): Observable<User> {
    const currentUser = this.stateService.currentUser$.value;
    if (currentUser._id === userId) {
      this.stateService.currentUser$.next({ ...currentUser, ...update });
    }
    // could be moved to the effect
    this.store.dispatch(UsersStateActions.updateOne({ _id: userId, changes: update }));

    return this.http.patch<User>(`${this.usersApi}/${userId}`, update).pipe(
      tap((updatedUser: User) => {
        if (updatedUser || update.roleCode) {
          const users = this.directoryUsers$.value.map(u => {
            if (u._id === userId) {
              u = updatedUser ? { ...u, ...updatedUser } : { ...u, ...update };
            }
            return u;
          });

          this.directoryUsers$.next(users);
        }
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not update user.  Please try again.'))
    );
  }

  @SpinnerAndCatchError
  addHelpfulToCompany(companyId: string): Observable<CompanyUser> {
    return this.http.get<CompanyUser>(`/api/v4/AddHelpful/${companyId}`);
  }

  /**
   * Conditionally update the user's settings if they have saved an item in a different DialogMode than
   * the last time.
   *
   * @param newPreference The DialogMode on submit
   * @returns The current user if preference unsaved, else the result of updating the settings.
   */
  updateDialogPreference(newPreference: DialogMode): Observable<User> {
    const currentPreference = this.stateService.currentUser.settings.dialogModePreference;
    return currentPreference === newPreference
      ? this.stateService.currentUser$
      : this.updateUserSettings({ dialogModePreference: newPreference });
  }

  updateUserSettings(update?: Partial<UserSettings>, spinner = false): Observable<User> {
    if (spinner) this.spinnerService.start();
    //todo make sure we are not passing null and are sending a patch value...new ticket will capture this
    if (!update) update = this.stateService.currentUser.settings;
    this.stateService.currentUser.settings = Object.assign({}, this.stateService.currentUser.settings, update);
    this.stateService.currentUser$.next(this.stateService.currentUser);
    this.store.dispatch(
      UsersStateActions.updateSuccess({
        _id: this.stateService.currentUser._id,
        changes: { settings: { ...this.stateService.currentUser.settings, ...update } } as Partial<User>,
      })
    );
    return this.http.patch<User>(`${this.usersApi}/Settings`, update).pipe(
      tap(() => {
        if (spinner) this.spinnerService.stop();
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not update your settings.  Please try again.'))
    );
  }

  static userName(user: User): string {
    const firstName = user?.metadata?.name?.first?.trim() ?? '';
    const lastName = user?.metadata?.name?.last?.trim() ?? '';
    return `${firstName} ${lastName}`.trim();
  }

  static userInitials(user: User): string {
    const firstName = user?.metadata?.name?.first?.trim() ?? '';
    const lastName = user?.metadata?.name?.last?.trim() ?? '';
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }

  updateTeams(update: UpdateUserTeamPayload): Observable<any> {
    return this.http
      .patch('/api/v4/Users/Teams', update)
      .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not update teams.  Please try again.')));
  }

  createAndSendPhoneVerification(userId: string, phoneNumberUpdate: Partial<UserSms>): Observable<any> {
    if (!phoneNumberUpdate.countryCode.startsWith('+')) {
      phoneNumberUpdate.countryCode = `+${phoneNumberUpdate.countryCode}`;
    }

    if (userId && userId.length && phoneNumberUpdate && Object.keys(phoneNumberUpdate).length) {
      return this.http.post(`/api/v4/sms/${userId}/sendTextVerificationCode`, phoneNumberUpdate).pipe(
        tap((resp: any) => {
          if (resp.status !== 'undelivered' && resp.status !== 'failed') {
            this.spinnerService.stop();
            this.snackBar.open('Henryx verification code sent!', undefined, { duration: 3000 });
          }
        }),
        catchError((e: unknown) =>
          this.errorService.notify(e, 'Could not send text verification code. Please try again.')
        )
      );
    }
  }

  verifyPhoneNumber(
    userId: string,
    verificationCode: string,
    countryCode: string,
    number: string
  ): Observable<boolean> {
    if (userId && userId.length && verificationCode && verificationCode.length) {
      return this.http
        .patch<boolean>(`/api/v4/sms/${userId}/verifyPhoneNumber`, {
          verificationCode,
          countryCode,
          phoneNumber: number,
        })
        .pipe(
          tap(() => {
            this.spinnerService.stop();
            // Hack: Should move this whole method to an effect.  That is out of scope for now.
            this.store.dispatch(UsersStateActions.updateSms({ _id: userId, countryCode, number }));
            this.smsChange$.next();
            this.snackBar.open(
              'Your phone number has been verified with Ninety for the Henryx texting service!',
              undefined,
              { duration: 3000 }
            );
          }),
          catchError((e: unknown) =>
            this.errorService.notify(
              e,
              'Could not verify phone number with the verification code sent. Please try again.'
            )
          )
        );
    }
  }

  updateDirectoryUsers(inviteUser: User, directoryUserOnly = false) {
    if (!directoryUserOnly) {
      const existingInviteUserIdx = this.allUsers.findIndex(u => u._id === inviteUser._id);
      if (existingInviteUserIdx > -1) {
        this.allUsers[existingInviteUserIdx] = _merge(_cloneDeep(this.allUsers[existingInviteUserIdx]), inviteUser);
        this.filterUsers([...this.allUsers]);
      } else {
        this.filterUsers([...this.allUsers, inviteUser]);
      }
    }
    const existingDirectoryUserIdx = this.directoryUsers$.value.findIndex(u => u._id === inviteUser._id);
    if (existingDirectoryUserIdx > -1) {
      const updatedDirectoryUser = _merge(_cloneDeep(this.directoryUsers$.value[existingDirectoryUserIdx]), inviteUser);
      this.directoryUsers$.next(
        this.sortByUserNamePipe.transform([
          ...this.directoryUsers$.value.splice(existingDirectoryUserIdx, 1, updatedDirectoryUser),
        ])
      );
    } else {
      this.directoryUsers$.next(this.sortByUserNamePipe.transform([...this.directoryUsers$.value, inviteUser]));
    }
  }

  // leaving this for the directory page for now
  updatePersonMetadataAndUser(
    metadataUpdate: Partial<PersonMetadata>,
    userUpdate: Partial<User>,
    userId: string,
    personMetadataId: string
  ): Observable<any> {
    this.spinnerService.start();
    const currentUser = this.stateService.currentUser$.getValue();
    if (currentUser._id === userId)
      this.stateService.currentUser$.next(_cloneDeep(_merge(currentUser, { ...userUpdate, metadata: metadataUpdate })));
    return forkJoin({
      personUpdate: this.http.patch(`/api/v4/PersonMetadata/${personMetadataId}`, metadataUpdate),
      userUpdate: this.http.patch(`${this.usersApi}/${userId}`, userUpdate),
    }).pipe(
      tap(() => {
        const metadata = { ...currentUser.metadata, ...metadataUpdate };
        this.store.dispatch(UsersStateActions.updateOne({ _id: userId, changes: { ...userUpdate, metadata } }));
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not update user.  Please try again.'))
    );
  }

  @SpinThenNotifyOrError({ err: 'Could not update user.  Please try again.' })
  updateUserAndPersonMetadata(
    { metadataUpdate, userUpdate }: PersonMetadataUpdate,
    personMetadataId = this.stateService.currentUser.personMetadataId,
    userId = this.stateService.currentUser._id
  ): Observable<any> {
    let user = this.stateService.currentUser;
    const observables = [];

    if (metadataUpdate) {
      observables.push(this.updatePersonMetadata(metadataUpdate, personMetadataId));
      user.metadata = { ...user.metadata, ...metadataUpdate };
      const firstName = user.metadata?.name?.first || '';
      const lastName = user.metadata?.name?.last || '';
      if (userUpdate) {
        userUpdate.fullName = `${firstName} ${lastName}`;
      }
    }
    if (userUpdate) {
      observables.push(this.http.patch<void>(`${this.usersApi}/${userId}`, userUpdate));
      user = { ...user, ...userUpdate };
    }

    return forkJoin(observables).pipe(
      tap(() => {
        if (user._id === userId) {
          const changes = {
            ...userUpdate,
            metadata: { ...user.metadata, ...metadataUpdate },
          };
          this.store.dispatch(UsersStateActions.updateOne({ _id: userId, changes }));

          this.stateService.currentUser$.next(
            _cloneDeep(
              _merge(user, {
                ...userUpdate,
                metadata: metadataUpdate,
              })
            )
          );
        }
      })
    );
  }

  updatePersonMetadata(
    update: Partial<PersonMetadata>,
    id = this.stateService.currentUser.personMetadataId
  ): Observable<void> {
    return this.http.patch<void>(`/api/v4/PersonMetadata/${id}`, update);
  }

  // when user is selected, get all other users' emails
  getAllOtherUserEmails(selectedUser?: User): string[] {
    if (selectedUser) {
      return this.allUsers.reduce((arr, currentUser: User) => {
        if (currentUser._id !== selectedUser._id) {
          arr.push(...(currentUser.emailAddresses || []).map((email: Email) => email.email));
        }
        return arr;
      }, []);
    }

    return this.allUsers.reduce((arr, currentUser: User) => {
      arr.push(...(currentUser.emailAddresses || []).map((email: Email) => email.email));
      return arr;
    }, []);
  }

  showTimezoneUpdateDialog(browserTimezoneEntry: TimezoneEntry): Observable<User> {
    const timeZoneUtcFromBrowser = browserTimezoneEntry.fullUtcOffset;
    const timeZoneUtcFromBrowserWithParentheses = '(UTC' + timeZoneUtcFromBrowser + ')';
    const timeZoneForUserWeDisplay = timeZoneUtcFromBrowserWithParentheses + ' ' + browserTimezoneEntry.nameFormatted;

    return this.dialog
      .open<TimezoneChangedDialogComponent, TimezoneDialogData>(TimezoneChangedDialogComponent, {
        autoFocus: false,
        disableClose: true,
        data: {
          title: 'New Timezone Detected',
          timeZoneForUserWeDisplay: timeZoneForUserWeDisplay,
          confirmButtonText: 'Yes, update',
          cancelButtonText: 'Ignore',
          browserTimezoneEntry: browserTimezoneEntry,
        },
      })
      .afterClosed()
      .pipe(
        tap((result: TimezoneDialogResult) => {
          if (result?.dontAskAgain) {
            this.localStorageService.set('dontAskToUpdateTimezoneAgain', true);
          }
        }),
        filter((result: TimezoneDialogResult) => result?.confirm),
        switchMap((result: TimezoneDialogResult) =>
          this.updateUserSettingsToBrowserTimezone(result.browserTimezoneEntry)
        )
      );
  }

  updateUserSettingsToBrowserTimezone(payload: TimezoneEntry): Observable<User> {
    return this.http
      .patch<User>(`${this.usersApi}/timezone`, payload)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update the timezone for the logged in user. Please refresh the page.`)
        )
      );
  }

  createUsers(newUsers: NewUserDto[]): Observable<void> {
    return this.http.post<void>(this.inviteUsersApi, { newUsers });
  }

  /** used to check if any new users are helpful and therefore are free.
   * Since we need to know how many new paid users we will be adding before routing to stripe */
  @SpinnerAndCatchError
  checkForHelpfuls(emails: string[]): Observable<Record<string, boolean>> {
    if (!emails.length) return of({});
    return this.http.post<Record<string, boolean>>(`/api/v4/Helpful/Check`, { emails });
  }
}
