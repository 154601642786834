import {
  AfterViewInit,
  ComponentRef,
  Directive,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { AR_EXPORTS, PdfExportService, ViewerComponent } from '@grapecity/activereports-angular';
import { delay } from 'rxjs';

import { NinetyActiveReportsModule } from '@ninety/web/pages/report-viewer/active-reports.module';

import { PdfLoadingStateEnum } from '../models/pdf-loading-state.enum';
import { ActiveReportsFacade } from '../services/active-reports.facade';

export interface SimpleReportPdfParams {
  reportName: string;
  reportData: Record<string, string>;
}

function createIdTracker(): () => number {
  let id = 0;
  return () => id++;
}

/** A directive that manages a hidden ActiveReports viewer. The viewer is then used to export a PDF. */
@Directive({
  selector: '[ninetyArPdfGenerator]',
  exportAs: 'ninetyArPdfGenerator',
  providers: [{ provide: AR_EXPORTS, useClass: PdfExportService, multi: true }, ActiveReportsFacade],
})
export class ArPdfGeneratorDirective implements AfterViewInit, OnDestroy, OnChanges {
  private static readonly NEXT_ID = createIdTracker();
  private _id = `ar-pdf-generator-${ArPdfGeneratorDirective.NEXT_ID()}`;
  get id(): string {
    return this._id;
  }

  private componentRef: ComponentRef<ViewerComponent>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private facade: ActiveReportsFacade,
    private renderer: Renderer2
  ) {}

  @Input({ required: true }) reportParams: SimpleReportPdfParams;
  @Input({ required: true }) hasData: boolean;

  // TODO: DEV-14593 remove the need for this delay.
  // Delay added to resolve a display issue/change detection race condition that we didn't want to keep spending time on.
  public readonly state$ = this.facade.buttonState$.pipe(delay(0));

  ngAfterViewInit() {
    this.componentRef = this.viewContainerRef.createComponent(ViewerComponent);

    const element = this.componentRef.location.nativeElement as HTMLElement;
    this.renderer.setStyle(element, 'display', 'none');
    this.renderer.setAttribute(element, 'id', this._id);

    this.facade.init(this.componentRef.instance);
  }

  ngOnChanges(changes: SimpleChanges) {
    // If the report params EVER change, we want to reset the viewer.
    if (changes.reportParams) {
      this.facade.reset();
    }
    // If hasData has changed, we want to update the button state based on the new value.
    // Otherwise, use the current value.
    const hasData = changes.hasData?.currentValue ?? this.hasData;
    this.facade.setButtonState(hasData ? PdfLoadingStateEnum.print : PdfLoadingStateEnum.nodata);
  }

  ngOnDestroy() {
    // Not entirely sure if this is needed? Better safe than sorry.
    this.componentRef?.destroy();
  }

  /** Hits the API and uses the ActiveReports viewer to generate a PDF. */
  createPdf() {
    this.facade.generatePdf(this.reportParams);
  }

  /** Opens the generated PDF in a new tab. Expected to never be called before create. */
  openPdf() {
    this.facade.openPdf();
  }
}

@NgModule({
  imports: [NinetyActiveReportsModule], // Note, directives cannot have imports :/
  declarations: [ArPdfGeneratorDirective],
  exports: [ArPdfGeneratorDirective],
})
export class ArPdfGeneratorDirectiveModule {}
