<div
  class="terra-input terra-password-input"
  (click)="focus()"
  [ngClass]="{
    'terra-input--disabled': disabled,
    'terra-input--invalid': _terraErrorStateMatcher.isErrorState(_ngControl),
    'terra-input--readonly': readonly
  }">
  <input
    [type]="_showPassword ? 'text' : 'password'"
    #input
    class="terra-input__input"
    [attr.placeholder]="placeholder || null"
    [maxlength]="maxlength || null"
    [disabled]="disabled"
    [readonly]="readonly"
    [autocomplete]="autocomplete"
    [(ngModel)]="_inputValue"
    (ngModelChange)="_onModelChange($event)"
    (blur)="_blurred($event)"
    (focus)="_focused($event)"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby" />
  <!-- This should become a Terra Icon Button in the future -->
  <button
    *ngIf="_inputValue && _inputValue.length > 0 && !disabled"
    class="terra-password-input__toggle"
    (click)="_toggleShowHidePassword()"
    [attr.aria-label]="_showPassword ? 'show password as plain text' : 'hide password'">
    <terra-icon class="terra-input__suffix" [icon]="_showPassword ? 'show' : 'hide'" size="24" />
  </button>
</div>
