import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LetDirective } from '@ngrx/component';

import { TerraIconModule, TerraQuickFilterModule } from '@ninety/terra';
import { ActionBarComponent, ButtonComponent, QuickFilterOption } from '@ninety/ui/legacy/components/index';
import { MeetingAgenda, MeetingType, Team } from '@ninety/ui/legacy/shared/index';

import { MeetingsTab } from '../../_models';
import { MeetingsFacade } from '../../meetings.facade';

@Component({
  selector: 'ninety-meetings-action-bar',
  standalone: true,
  imports: [
    CommonModule,
    ActionBarComponent,
    TerraIconModule,
    TerraQuickFilterModule,
    ButtonComponent,
    LetDirective,
    FormsModule,
  ],
  template: `
    <div *ngrxLet="vm$ as vm">
      <ninety-action-bar *ngIf="vm.selectedTeam">
        <div class="action-bar-left">
          <terra-quick-filter
            maxHeight="450"
            key="Team"
            icon="users-three"
            [ngModel]="vm.selectedTeam"
            (selectionChange)="onTeamSelectionChange($event)"
            [disabled]="vm.loading"
            [compareWith]="compareTeams"
            data-cy="team-filter"
            aria-label="Team filter">
            <terra-option
              *ngFor="let team of vm.teams | terraLazyOptions : 'item' : { findObjectByProperty: true }"
              [value]="team.item"
              attr.data-cy="quick-filter_option-{{ team.displayText }}"
              >{{ team.displayText }}</terra-option
            >
          </terra-quick-filter>
          <terra-quick-filter
            key="Type"
            [disabled]="vm.loading"
            [(ngModel)]="selectedMeetingType"
            (selectionChange)="onMeetingTypeSelectionChange($event)"
            data-cy="type-filter"
            aria-label="Type filter"
            [compareWith]="compareType"
            *ngIf="activeTab === MeetingsTab.Past && !vm.weeklyMeetingOnlyFeature">
            <terra-option
              *ngFor="let type of vm.meetingTypes"
              [value]="type"
              attr.data-cy="quick-filter_option-{{ type.displayText }}"
              >{{ type.displayText }}</terra-option
            >
          </terra-quick-filter>
        </div>
        <div class="action-bar-right" [ngClass]="activeTab !== MeetingsTab.Agendas ? 'hide-print-actions-filter' : ''">
          <ninety-button
            *ngIf="activeTab === MeetingsTab.Past"
            primary
            stroked
            (click)="facade.downloadExcel()"
            [disabled]="vm.loading"
            data-cy="export-excel"
            class="export-excel-btn">
            <terra-icon
              matTooltip="Export to Excel"
              matTooltipPosition="below"
              matTooltipClass="ninety-tooltip"
              icon="download"
              aria-hidden="true" />
          </ninety-button>
          <ninety-button
            primary
            stroked
            (click)="facade.printAgenda(vm.weeklyMeetingOnlyFeature)"
            [disabled]="vm.loading"
            data-cy="print-meeting-agenda"
            listFixedLocation="below">
            <terra-icon icon="file-print" aria-hidden="true" /> Print {{ vm.language.meeting.item }} Agenda
          </ninety-button>
        </div>
      </ninety-action-bar>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .export-excel-btn {
        margin-right: 10px;
      }

      .action-bar-right {
        @media (max-width: 599px) {
          display: none;
        }
      }

      .action-bar-left {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .hide-filter-bar,
      .hide-quick-filter,
      .hide-print-actions {
        display: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingsActionBarComponent {
  facade = inject(MeetingsFacade);
  MeetingsTab = MeetingsTab;
  vm$ = {
    language: this.facade.language$,
    selectedTeam: this.facade.selectedTeam$,
    selectedMeetingType: this.facade.selectedMeetingType$,
    teams: this.facade.teamsOptions$,
    loading: this.facade.loading$,
    meetingTypes: this.facade.meetingTypeOptions$,
    weeklyMeetingOnlyFeature: this.facade.weeklyMeetingOnlyFeature$,
  };
  selectedMeetingType: { id: MeetingType | null } = { id: null };

  @Input() activeTab?: string;

  onTeamSelectionChange(team: Team | Team[]) {
    if (this.activeTab === MeetingsTab.Upcoming) {
      this.facade.teamSelected(team);
    }
    if (this.activeTab === MeetingsTab.Past) {
      this.facade.pastMeetingsTeamSelected(team);
    }
    if (this.activeTab === MeetingsTab.Agendas) {
      this.facade.teamAgendaSelected(team);
    }
  }

  onMeetingTypeSelectionChange(type: QuickFilterOption<MeetingAgenda>) {
    if (this.activeTab === MeetingsTab.Past) {
      this.facade.pastMeetingsMeetingTypeSelected(type);
    }
  }

  compareTeams(team1: Team, team2: Team) {
    return team1?._id === team2?._id;
  }

  compareType(type1: { id?: string }, type2: { id?: string }) {
    return type1?.id === type2?.id;
  }
}
