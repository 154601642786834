import { Directive } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SegmentTrackEvent } from '@ninety/ui/legacy/core/analytics/segment/models/segment-track-event.enum';
import { SegmentActions } from '@ninety/ui/legacy/core/analytics/segment/segment.actions';

import { DashboardExpandActions } from './dashboard-expand.actions';

/** Add this to a component that has the DashboardExpandTargetDirective as a parent to track analytics of expand/collapse. */
@Directive({
  selector: '[ninetyDashboardExpandAnalytics]',
  standalone: true,
})
export class DashboardExpandAnalyticsDirective {
  constructor(readonly store: Store, readonly actions$: Actions) {
    actions$.pipe(ofType(DashboardExpandActions.expand), takeUntilDestroyed()).subscribe(action => {
      store.dispatch(
        SegmentActions.track({
          event: SegmentTrackEvent.INS_WIDGET_EXPANDED,
          params: { widgetId: action.widgetId, buttonConfiguration: action.buttonConfiguration },
        })
      );
    });

    actions$.pipe(ofType(DashboardExpandActions.collapse), takeUntilDestroyed()).subscribe(action => {
      store.dispatch(
        SegmentActions.track({
          event: SegmentTrackEvent.INS_WIDGET_COLLAPSED,
          params: { widgetId: action.widgetId, buttonConfiguration: action.buttonConfiguration },
        })
      );
    });
  }
}
