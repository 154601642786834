<h1 mat-dialog-title>Enter the email confirmation code and new password for {{ email }}</h1>
<div mat-dialog-content>
  <form name="forgotPasswordSubmit" [formGroup]="forgotPasswordSubmitGroup">
    <terra-form-field class="form-fields">
      <terra-text-input
        data-cy="forgot-password-submit-dialog_code"
        autocomplete="one-time-code"
        placeholder="Code"
        [formControl]="codeControl"
        required />
    </terra-form-field>
    <div class="form-fields">
      <terra-form-field>
        <terra-password-input
          data-cy="forgot-password-submit-dialog_new-password"
          autocomplete="new-password"
          placeholder="New password"
          [formControl]="newPasswordControl" />
      </terra-form-field>
    </div>
    <terra-form-field>
      <terra-password-input
        data-cy="forgot-password-submit-dialog_confirm-new-password"
        autocomplete="new-password"
        placeholder="Confirm new password"
        [formControl]="confirmNewPasswordControl" />
    </terra-form-field>
    <div class="password-errors">
      <terra-error *ngIf="isCodeExpired">Code expired -- request new code</terra-error>
      <terra-error *ngIf="forgotPasswordSubmitGroup.errors?.passwordMismatch">Password does not match</terra-error>
      <terra-error *ngIf="forgotPasswordSubmitGroup.errors?.passwordLength"
        >Password must be 8 or more characters</terra-error
      >
      <terra-error *ngIf="forgotPasswordSubmitGroup.errors?.missingLowerAlpha"
        >Password needs a lowercase alphabetical character (a-z).</terra-error
      >
      <terra-error *ngIf="forgotPasswordSubmitGroup.errors?.missingNumeric">Password needs a number (0-9)</terra-error>
      <terra-error *ngIf="forgotPasswordSubmitGroup.errors?.missingSpecial"
        >Password needs a special character</terra-error
      >
      <terra-error *ngIf="forgotPasswordSubmitGroup.errors?.invalidChars"
        >Invalid password characters: {{ forgotPasswordSubmitGroup.errors.invalidChars }}</terra-error
      >
    </div>
  </form>
</div>
<div mat-dialog-actions class="submit-button-box" fxLayout="row" fxLayoutAlign="end center">
  <button data-cy="forgot-password-submit-dialog_close" mat-button mat-dialog-close>Close</button>
  <button
    data-cy="forgot-password-submit-dialog_request-new-code"
    mat-button
    [disabled]="(spinnerService.primary$ | async) || !email"
    (click)="sendForgotPasswordCode()">
    Request new code
  </button>
  <button
    data-cy="forgot-password-submit-dialog_submit"
    mat-button
    color="accent"
    [disabled]="
      forgotPasswordSubmitGroup.invalid ||
      (spinnerService.primary$ | async) ||
      !email ||
      isCodeExpired ||
      codeControl.value === expiredCode
    "
    (click)="forgotPasswordSubmit()">
    Submit
  </button>
</div>
