<!-- Readonly Layout -->
<ng-container *ngIf="!item.isEditing; else editing">
  <!-- Meta - Title + description -->
  <div class="flex-1">
    <div class="accountability-name">{{ item.accountability?.name }}</div>
    <div class="accountability-description">{{ item.accountability?.description }}</div>
  </div>

  <ng-container *ngIf="canEdit">
    <!-- Edit Button -->
    <ninety-button
      data-cy="accountability-list-item_edit-button"
      primary
      icon
      flat
      (click)="enterEditMode()"
      class="edit-button">
      <terra-icon icon="edit" size="20" />
    </ninety-button>

    <!-- Remove Button -->
    <ninety-button
      negative
      icon
      flat
      (click)="clickedRemove.emit(item)"
      class="remove-button"
      data-cy="accountability-list-item_remove-button">
      <terra-icon icon="delete" size="20" />
    </ninety-button>
  </ng-container>
</ng-container>

<!-- Edit Layout -->
<ng-template #editing>
  <fieldset class="flex-1">
    <!-- Role Name -->
    <terra-form-field>
      <terra-form-label>Role</terra-form-label>
      <terra-text-input
        [(ngModel)]="nameCopy"
        #nameInput="ngModel"
        aria-label="Accountabilty name"
        class="role-name-input"
        data-cy="seat-details-role-input"
        name="roleName"
        required />
      <terra-error *ngIf="nameInput.touched && !nameInput.valid"> A role name is required </terra-error>
    </terra-form-field>

    <!-- Description -->
    <terra-form-field class="top-space-15 bottom-space-15">
      <terra-form-label>Responsibilities</terra-form-label>
      <terra-multiline-input
        [(ngModel)]="descriptionCopy"
        aria-label="Accountabilty description"
        data-cy="seat-details-description-input"
        name="description" />
    </terra-form-field>

    <!-- Buttons -->
    <div class="action-group flex-justify-start">
      <!--
        When the seatName is focused & empty & the clicks down on the cancel button, it triggers a blur event,
        which triggers the error message for the seatName input and shifts the layout displaying the message.
        In this case, we preventDefault to prevent that event from reaching the input and causing the layout shift.
       -->
      <ninety-button
        negative
        flat
        (click)="exitEditMode()"
        (mousedown)="$event.preventDefault()"
        class="accountability-list-item-cancel-button">
        Cancel
      </ninety-button>
      <ninety-button
        secondary
        stroked
        [disabled]="!canSave"
        (click)="onClickSave()"
        class="accountability-list-item-save-button"
        data-cy="accountability-list-item_save-button">
        Save
      </ninety-button>
    </div>
  </fieldset>
</ng-template>
