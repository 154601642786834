import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { distinctUntilChanged, filter, skip } from 'rxjs';

// eslint-disable-next-line terra/enforce-terra-module-import
import { TerraCheckboxModule, TerraIconModule, TerraSelectComponent, TerraSelectModule } from '@ninety/terra';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { FuseTeamDirective, ManagedOptions } from '@ninety/web/pages/future-libs';

/* eslint-disable @angular-eslint/no-outputs-metadata-property,@angular-eslint/no-output-rename */

@Component({
  selector: 'ninety-inline-teams-select',
  standalone: true,
  imports: [
    CommonModule,
    PushPipe,
    TerraIconModule,
    FormsModule,
    TerraCheckboxModule,
    NinetyTooltipDirective,
    LetDirective,
    TerraSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './inline-teams-select.component.html',
  styleUrls: ['./inline-teams-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: FuseTeamDirective,
      inputs: ['ninetyFuseTeam: teams$', 'ninetyFuseTeamOptions: fuseOptions'],
    },
  ],
})
export class InlineTeamsSelectComponent implements AfterViewInit {
  @Output() saveTeams = new EventEmitter<Team[]>();

  @Input() set selectedTeams(teams: Team[]) {
    this.initTeams(teams);
  }

  @ViewChild(TerraSelectComponent)
  private readonly _selectComponent: TerraSelectComponent;

  /** for tooltip, easier to store and maintain here */
  protected teamNames: string;
  protected originalTeamNames: string;
  protected originalSelected: Team[];

  protected _teamSearchInput = '';

  protected teamsControl: FormControl<Team[]>;

  constructor(protected readonly fuseProvider: FuseTeamDirective, private readonly destroyRef: DestroyRef) {}

  ngAfterViewInit() {
    this.fuseProvider.fuse.managedOptions$
      .pipe(
        distinctUntilChanged(),
        skip(1),
        // The option list is removed from the DOM when there is no data to display. Also, due to the observable nature of the data source,
        // the list might emit while the select is closed.
        filter(() => Boolean(this._selectComponent.isSelectOpen)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this._selectComponent.scrollToTop());
  }

  initTeams(teams: Team[]) {
    this.teamsControl = new FormControl(teams, [Validators.required]);
    if (teams.length) {
      this.teamNames = teams.map(t => t.name).join(', ');
      this.originalTeamNames = this.teamNames;
      this.originalSelected = [...teams];
    } else {
      this.teamsControl.setErrors({ noTeams: true });
      this.teamsControl.markAsTouched();
      this.teamsControl.updateValueAndValidity();
    }
  }

  close() {
    this._selectComponent.close();
  }

  protected search(query: string) {
    this.fuseProvider.fuse.search({ query });
  }

  /** whether closed by clicking outside, or the ok button, it will emit selected teams */
  protected onOpenChange(isOpen: boolean) {
    if (!isOpen) {
      this.fuseProvider.fuse.search({ query: null });

      if (this._selectComponent.value.length) {
        this.originalSelected = [...this._selectComponent.value];
        this.originalTeamNames = this.teamNames = this._selectComponent.value.map(t => t.name).join(', ');
        this.saveTeams.emit(this._selectComponent.value);
      }
    }
  }

  protected _handleSearchTermChanged($event: string) {
    this._teamSearchInput = $event;
    this.search(this._teamSearchInput);
  }

  protected readonly trackBy: TrackByFunction<ManagedOptions<Team>> = (_index, item) => item.value._id;

  protected _compareById(a: Team, b: Team) {
    return a._id === b._id;
  }
}
