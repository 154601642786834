import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, map, of, switchMap, take, tap } from 'rxjs';

import { ConfirmIdentityDialogStatus } from '../../_shared/components/confirm-identity-dialog/confirm-identity-dialog.component';
import type { ICognitoUser } from '../../_shared/models/_shared/cognito-user';
import { RefreshToken, RefreshTokenPayload } from '../../_shared/models/auth/refresh-token';
import { CompanyUserListModel, extractValueFromStore, selectCurrentUserId } from '../../_state';
import { selectActiveInactiveCompanyUsers } from '../../_state/app-entities/company-users/company-users-state.selectors';
import { SpinnerActions } from '../../_state/app-global/spinner/spinner-state.actions';

import { AuthService } from './auth.service';
import { ErrorService } from './error.service';
import { CognitoMfaType, IdentityProviderService } from './identity-provider.service';
import { NotifyService } from './notify.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private readonly authService: AuthService,
    private readonly errorService: ErrorService,
    private readonly identityProviderService: IdentityProviderService,
    private readonly tokenService: TokenService,
    private readonly notifyService: NotifyService,
    private readonly store: Store
  ) {}

  public switchCompany(nextUser?: CompanyUserListModel, redirectUrl?: string): void {
    if (!nextUser) {
      this.store
        .select(selectActiveInactiveCompanyUsers)
        .pipe(take(1))
        .subscribe(orderedUsers => {
          if (orderedUsers.length) nextUser = orderedUsers[0];
        });
    }

    window.sessionStorage.removeItem('lastAccessedTeamId');
    this.store.dispatch(SpinnerActions.startPrimary({ source: 'sessionService.SwitchCompany' }));

    // case when the user initially tries to switch to a different company
    // but then quickly decides to remain on the current company
    const currentUserId = extractValueFromStore(this.store, selectCurrentUserId);
    if (nextUser.userId === currentUserId) {
      this.store.dispatch(SpinnerActions.stopPrimary({ source: 'sessionService.SwitchCompany' }));
      return;
    }

    this.authService
      .refreshAccessToken(nextUser.userId, false)
      .pipe(
        switchMap(resp => this.checkMfaSetup(resp, nextUser, redirectUrl)),
        take(1),
        catchError((e: unknown) => {
          this.store.dispatch(SpinnerActions.stopPrimary({ source: 'sessionService.SwitchCompany' }));
          return this.errorService.oops(e);
        }),
        tap(() => this.tokenService.removeEntitlementsToken())
      )
      .subscribe(() => (window.location.href = redirectUrl || '/home'));
  }

  private checkMfaSetup(resp: RefreshToken, nextUser: CompanyUserListModel, redirectUrl: string): Observable<unknown> {
    if (!resp.mfaSetupRequired) return of(null);

    return this.identityProviderService.cognitoUser.pipe(
      switchMap(cognitoUser => {
        if (!cognitoUser) throw new Error('No cognito user found');

        const { isUnverifiedFederatedUser, personId, isFederatedUserIncompleteMfaSetup, federatedUser } =
          this.checkCognitoUserSetup(resp, cognitoUser);

        if (isUnverifiedFederatedUser) {
          this.notifyService.toast('Please set up MFA and verify number to continue.');
          return this.authService
            .openVerifyDialog({
              email: cognitoUser.attributes.email,
              personId,
              status: ConfirmIdentityDialogStatus.InitNumber,
              showCloseButton: true,
            })
            .pipe(
              tap(verifyStatus => {
                this.toastAndSwitchCompany(verifyStatus, nextUser, redirectUrl);
              })
            );
        }

        if (isFederatedUserIncompleteMfaSetup) {
          // turn on mfa if company requires and user already has tel verified but not turned mfa on
          this.notifyService.toast('Turning on MFA for you. Trying to switch company again.');
          return this.identityProviderService.setMfaType(CognitoMfaType.SMS).pipe(
            map(_ => of(resp)),
            tap(() => this.switchCompany(nextUser, redirectUrl))
          );
        } else if (federatedUser) {
          this.notifyService.toast(`Can't use MFA with federated login. Please use email login.`);
        }
        return of(null);
      })
    );
  }

  private checkCognitoUserSetup(resp: RefreshToken, cognitoUser: ICognitoUser) {
    const token = resp.tokens.access || resp.tokens.refresh;

    const personId = this.tokenService.decodeToken<RefreshTokenPayload>(token)?.personId;

    const federatedUser = !!cognitoUser.attributes.identities; // this key/val is only present on fed'd users
    const verifiedTel = !!cognitoUser.attributes.phone_number_verified; // we can skip if user already verified
    const userMfa = cognitoUser.preferredMFA !== CognitoMfaType.NONE;
    const isUnverifiedFederatedUser = !federatedUser && !verifiedTel;
    const isFederatedUserIncompleteMfaSetup = !federatedUser && verifiedTel && !userMfa;
    return { isUnverifiedFederatedUser, personId, isFederatedUserIncompleteMfaSetup, federatedUser };
  }

  private toastAndSwitchCompany(
    verifyStatus: string | void | { closedFromBtn: true },
    nextUser: CompanyUserListModel,
    redirectUrl: string
  ) {
    if (
      verifyStatus === ConfirmIdentityDialogStatus.ConfirmMFA ||
      verifyStatus === ConfirmIdentityDialogStatus.ConfirmNumber
    ) {
      this.notifyService.toast('MFA set up successfully. Switching company.');
      this.switchCompany(nextUser, redirectUrl);
    } else {
      this.notifyService.toast(
        `MFA set up failed. Status - ${
          (verifyStatus as { closedFromBtn: true })?.closedFromBtn ? 'user closed' : verifyStatus
        }`
      );
    }
  }
}
