import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';

export type SelectLauncherPositioningStrategy = 'over' | 'right' | 'below';

/**
 * Directive used to launch the select menu overlay. Can be applied to buttons or the {@link SelectBoxComponent}
 */
@Directive({
  selector: '[ninetySelectLauncher]',
  standalone: true,
  exportAs: 'ninetySelectLauncher',
})
export class SelectLauncherDirective implements OnDestroy {
  /** The minimum width the select can be launched with. Expected to match the SCSS variable in the module styles */
  public static readonly MIN_WIDTH = 300;

  /** The template used to render the list of options when the overlay is open. */
  @Input('ninetySelectLauncher') menuTemplate: TemplateRef<unknown>;

  /** When true, any attempt to launch the overlay is ignored */
  @Input({ transform: coerceBooleanProperty }) disabled: BooleanInput = false;

  /** The desired position of the overlay */
  @Input() positioningStrategy: SelectLauncherPositioningStrategy = 'over';

  /** Setting this will "opening"/"closing" the select. */
  @Input() set isOpen(isOpen: boolean) {
    if (isOpen) this.showOverlay();
    else this.destroyOverlay();
  }

  /** Whether the overlay is currently rendered */
  get isOpen(): boolean {
    return !!this.overlayRef;
  }

  /** Emits as the overlay open status changes */
  @Output() isOpenChange = new EventEmitter<boolean>();

  @HostListener('click') protected onClick = () => this.showOverlay();

  /** Overlay reference used to render the list of options. */
  private overlayRef: OverlayRef;

  constructor(
    private destroyRef: DestroyRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private host: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    this.overlayRef?.dispose();
  }

  onEscapeOfOverlay() {
    this.destroyOverlay();
  }

  showOverlay() {
    if (this.disabled) return;
    if (this.isOpen) {
      // console.warn('showOverlay called when overlay is already open', this);
      return;
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.host)
      .withPush(true)
      .withViewportMargin(8)
      .withPositions([this.getConnectedPosition()]);

    const width = Math.max(this.host.nativeElement.clientWidth, SelectLauncherDirective.MIN_WIDTH);
    const overlayConfig = new OverlayConfig({
      positionStrategy,
      width,
      backdropClass: 'popover-backdrop',
      panelClass: 'popover-panel',
      hasBackdrop: true,
      // Never required on initial load, but sometimes required on subsequent loads. If there are enough options to
      // cause the list to overflow, then the options in the template are filtered, the overlay will shrink. The next
      // time its open, if the search is cleared, on edge cases, the overlay will be pushed off the screen. This setting
      // tells the overlay to reposition itself if it is pushed off the screen.
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
    this.overlayRef = this.overlay.create(overlayConfig);

    const templatePortal = new TemplatePortal(this.menuTemplate, this.viewContainerRef);
    this.overlayRef.attach(templatePortal);

    this.overlayRef
      .backdropClick()
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.destroyOverlay());

    this.isOpenChange.emit(true);
  }

  destroyOverlay() {
    this.overlayRef?.detach();
    this.overlayRef = null;
    this.isOpenChange.emit(false);
    // I'm not 100% sure why this is required? But without, the view in the multi-select doesnt update after you select
    // some items and close the dialog.
    this.cdr.markForCheck();
  }

  private getConnectedPosition(): ConnectedPosition {
    if (this.positioningStrategy === 'right') {
      return {
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: 'select-overlay',
      };
    } else if (this.positioningStrategy === 'below') {
      return {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: 'select-overlay',
        offsetY: 4,
      };
    } else {
      return {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'top',
        panelClass: 'select-overlay',
      };
    }
  }
}
