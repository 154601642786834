import { createSelector } from '@ngrx/store';

import { selectGlobalAppState } from '..';
import { StripeSubscriptionStatuses, SubscriptionProviders } from '../../../_shared';
import { StripeQueryParams } from '../../../_shared/models/billing-v3/stripe-query-params';
import { CompleteBillingOverviewDetailsModel } from '../../../_shared/models/billingv2/complete-billing-overview-details.model';
import { selectQueryParams } from '../../../_state/route.selectors';
import { selectCurrentUser } from '../../app-entities/users/users-state.selectors';
import { selectCompany } from '../company/company-state.selectors';
import { selectBillingOverviewDetails, selectSubscription } from '../company/subscription/subscription-state.selectors';

import { BillingStateKey } from './billing-state.model';
import { BillingV2Selectors } from './billing-v2/billing-state-v2.selectors';

export const selectIsFreemiumPlan = createSelector(
  selectSubscription,
  BillingV2Selectors.selectIsFree,
  (sub, isV2Free) =>
    (sub?.provider === SubscriptionProviders.Internal && sub?.providerStatus === StripeSubscriptionStatuses.Active) ||
    isV2Free
);

export const selectIsTrialing = createSelector(
  selectSubscription,
  sub => sub?.providerStatus === StripeSubscriptionStatuses.Trialing
);

export const selectIsPaidPlan = createSelector(
  selectSubscription,
  sub => sub?.provider === SubscriptionProviders.Stripe
);

export const selectIsFreeVIP = createSelector(selectCompany, company => company.implementerFree);

export const selectIsFreeOrTrialing = createSelector(
  selectIsFreemiumPlan,
  selectIsTrialing,
  selectIsFreeVIP,
  (isFreemium, isTrialing, isFreeVIP) => isFreemium || isTrialing || isFreeVIP
);

/** =============================================================================
 *  Billing State
 * ============================================================================= */

export const selectBillingState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[BillingStateKey] : null
);

export const selectSubscriptionConfigurations = createSelector(
  selectBillingState,
  state => state.subscriptionConfigurations
);

export const selectCurrentSubscriptionConfiguration = createSelector(
  selectSubscriptionConfigurations,
  selectSubscription,
  (subscriptionConfigurations, subscription) => {
    if (!subscriptionConfigurations?.length || !subscription?.planPriceId) {
      return null;
    }
    return subscriptionConfigurations.find(config => config.priceId === subscription.planPriceId);
  }
);

export const selectCompanyBillingCounts = createSelector(selectBillingState, state => {
  const counts = state?.companyBillingCounts || { activeBillingUsers: 0, assignableSeats: 0, seatsPaidFor: 0 };
  return {
    ...counts,
    activeBillingUsers: counts.activeBillingUsers ?? 0,
    assignableSeats: counts.assignableSeats ?? 0,
  };
});

export const selectLicensesMaxed = createSelector(
  selectBillingState,
  state => state?.companyBillingCounts?.assignableSeats <= 0
);

export const selectUpcomingInvoice = createSelector(selectBillingState, state => state.upcomingInvoice);

export const selectCompleteBillingOverview = createSelector(
  selectBillingOverviewDetails,
  selectCurrentSubscriptionConfiguration,
  selectCompanyBillingCounts,
  selectUpcomingInvoice,
  (billingOverviewDetails, currentSubscriptionConfiguration, companyBillingCounts, upcomingInvoice) =>
    ({
      billingOverviewDetails,
      currentSubscriptionConfiguration,
      companyBillingCounts,
      upcomingInvoice,
    } as CompleteBillingOverviewDetailsModel)
);

export const selectNumberOfAvailableSeats = createSelector(
  selectIsTrialing,
  selectCompanyBillingCounts,
  selectCompany,
  (isTrialing, counts, { trialingUserLimit }) =>
    isTrialing ? trialingUserLimit - counts.activeBillingUsers : counts.assignableSeats
);

export const selectPricingPlans = createSelector(selectBillingState, state => state?.pricingPlans);

export const selectUpdatedSubscriptionDismissed = createSelector(
  selectCurrentUser,
  user => user.updatedSubscriptionDismissed
);

export const selectFreePlanNotificationDismissed = createSelector(
  selectCurrentUser,
  user => user.freePlanNotificationDismissed
);

export const selectCurrentPlan = createSelector(selectBillingState, state => state.currentPlan);

export const selectStripeQueryParams = createSelector(
  selectQueryParams,
  (params): StripeQueryParams =>
    params['reason']
      ? {
          external: params['external'],
          reason: params['reason'],
        }
      : null
);
