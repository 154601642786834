import { createActionGroup, props } from '@ngrx/store';

import { KpiGroup } from '@ninety/ui/legacy/shared/models/scorecard/kpi-group';
import { KpiGroupDetailForm } from '@ninety/ui/legacy/shared/models/scorecard/kpi-group-detail-form.model';
import { KpiGroupTableData } from '@ninety/ui/legacy/shared/models/scorecard/kpi-group-table-data';
import { KpiWithData } from '@ninety/ui/legacy/shared/models/scorecard/kpi-with-data';

import { ScorecardApiErrorProps } from '../../types';

export const KpiGroupApiActions = createActionGroup({
  source: 'Scorecard KpiGroupApi',
  events: {
    'Get By Id': props<{ id: string }>(),
    'Get By Id Success': props<{ kpiGroup: KpiGroup }>(),
    'Get By Id Failure': props<ScorecardApiErrorProps>(),

    Create: props<{ detail: KpiGroupDetailForm }>(),
    'Create Success': props<{ kpiGroup: KpiGroup; parentScorecardId: string }>(),
    'Create Failure': props<ScorecardApiErrorProps>(),

    'Update By Id': props<{ id: string; changes: KpiGroupDetailForm }>(),
    'Update By Id Success': props<{ kpiGroup: KpiGroup }>(),
    'Update By Id Failure': props<ScorecardApiErrorProps>(),

    'Add Kpis To Kpi Group': props<{ kpiGroupId: string; kpiIds: string[] }>(),
    'Add Kpis To Kpi Group Success': props<{ kpiGroup: KpiGroup; kpiIds: string[] }>(),
    'Add Kpis To Kpi Group Failure': props<
      { scorecardId: string; kpiGroupId: string; kpiIds: string[] } & ScorecardApiErrorProps
    >(),

    'Remove Kpis From Kpi Group': props<{ kpiGroupId: string; kpiIds: string[] }>(),
    'Remove Kpis From Kpi Group Success': props<{ kpiGroup: KpiGroup; kpiIds: string[] }>(),
    'Remove Kpis From Kpi Group Failure': props<ScorecardApiErrorProps>(),

    'Move Kpis To Different Kpi Group': props<{ kpiIds: string[]; oldKpiGroupId: string; newKpiGroupId: string }>(),
    'Move Kpis To Different Kpi Group Success': props<{
      kpiIds: string[];
      oldKpiGroupId: string;
      newKpiGroupId: string;
    }>(),
    'Move Kpis To Different Kpi Group Failure': props<ScorecardApiErrorProps>(),

    'Get With Kpi Scores By Id': props<{ id: string }>(),
    'Get With Kpi Scores By Id Success': props<{ apiResponse: KpiGroupTableData }>(),
    'Get With Kpi Scores By Id Failure': props<
      { scorecardId: string; kpiGroupId: string; statusCode: number } & ScorecardApiErrorProps
    >(),

    'Duplicate Kpis': props<{ kpiIds: string[]; kpiGroupId: string }>(),
    'Duplicate Kpis Success': props<{ kpiGroupId: string }>(),
    'Duplicate Kpis Failure': props<ScorecardApiErrorProps>(),

    'Delete Success': props<{ kpiGroupId: string; parentScorecardId: string }>(),
    'Delete Failure': props<ScorecardApiErrorProps>(),

    'Combine Groups Success': props<{ scorecardId: string; teamId: string }>(),
    'Combine Groups Failure': props<ScorecardApiErrorProps>(),

    'Move Kpi': props<{ kpiId: string; kpiGroupId: string; toIndex: number }>(),
    'Move Kpi Success': props<{ kpiGroup: KpiGroup }>(),
    'Move Kpi Failure': props<ScorecardApiErrorProps>(),

    'Get Kpis By Id': props<{ scorecardId: string; groups: { kpiGroupId: string; kpiIds: string[] }[] }>(),
    'Get Kpis By Id Success': props<{ groups: { kpiGroupId: string; kpis: KpiWithData[] }[] }>(),
    'Get Kpis By Id Failure': props<ScorecardApiErrorProps>(),
  },
});
