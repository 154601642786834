import { CssColor, FontFamily, FontSize, FontStyle, FontWeight } from 'ag-charts-enterprise';

import { DiscoText, DiscoTextLight } from './colors';

interface DiscoFont {
  readonly color?: CssColor;
  readonly fontStyle?: FontStyle;
  readonly fontWeight?: FontWeight;
  readonly fontSize?: FontSize;
  readonly fontFamily?: FontFamily;
}

export const DiscoTitle: Readonly<DiscoFont> = {
  fontFamily: 'Poppins',
  fontSize: 24,
  fontWeight: 700,
};

export const DiscoLargeTitle: Readonly<DiscoFont> = {
  fontWeight: 700,
  fontFamily: 'Poppins',
  fontSize: 36,
};

export const DiscoLargeBody: Readonly<DiscoFont> = {
  fontFamily: 'Nunito Sans',
  fontSize: 16,
  fontWeight: 400,
};

export const DiscoLargeBodyLight: Readonly<DiscoFont> = {
  ...DiscoLargeBody,
  color: DiscoTextLight,
};

export const DiscoAxisLabel: Readonly<DiscoFont> = {
  color: DiscoTextLight,
  fontFamily: 'Nunito Sans',
  fontSize: 14,
  fontWeight: 400,
};

export const DiscoLegendLabel: Readonly<DiscoFont> = {
  ...DiscoAxisLabel,
};

export const DiscoLabel: Readonly<DiscoFont> = {
  color: DiscoText,
  fontFamily: 'Nunito Sans',
  fontSize: 12,
  fontWeight: 700,
};
