import { ChangeDetectionStrategy, Component, TrackByFunction } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, switchMap } from 'rxjs';

import {
  selectCurrentUserId,
  selectUserAvatarInfo,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

import { GuideActions } from '../../../guide/_state/guide.actions';
import { mazChatAnimation } from '../../../guide/components/getting-started-guide/getting-started-guide.animation';
import { MazActions } from '../../_state/maz.actions';
import { MazSelectors } from '../../_state/maz.selectors';
import { MazFeedbackFlag } from '../../models/maz-feedback-flag.enum';
import { MazMessage } from '../../models/maz-message';

@Component({
  selector: 'ninety-maz-chat',
  templateUrl: './maz-chat.component.html',
  styleUrls: ['./maz-chat.component.scss'],
  animations: [mazChatAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MazChatComponent {
  protected readonly description$ = this.store.select(MazSelectors.selectDescription);
  protected readonly isMazChatExpanded$ = this.store.select(MazSelectors.selectIsMazChatExpanded);
  protected readonly mazMessages$ = this.store.select(MazSelectors.selectConversationHistory);
  protected readonly waitingForMazResponse$ = this.store.select(MazSelectors.selectWaitingForMazResponse);
  protected readonly showMazAlert$ = new BehaviorSubject<boolean>(false);
  protected readonly mazToasterFlag$ = new BehaviorSubject<MazFeedbackFlag>(null);

  // handing this to all messages to every message doesn't need to import the store and get the user's picture.url
  protected readonly userAvatarInfo$ = this.store
    .select(selectCurrentUserId)
    .pipe(switchMap(userId => this.store.select(selectUserAvatarInfo(userId))));

  mazInput: string;

  constructor(private store: Store) {}

  toggleChatWindow() {
    this.store.dispatch(MazActions.toggleMazChat());
  }

  sendMessage(): void {
    this.store.dispatch(MazActions.sendMessageToMaz({ userInput: this.mazInput }));
    this.mazInput = null;
  }

  toggleMazFeedbackFlag({ feedback, _id: messageId }: MazMessage): void {
    const flag = feedback?.flag === MazFeedbackFlag.incorrect ? MazFeedbackFlag.correct : MazFeedbackFlag.incorrect;
    this.store.dispatch(MazActions.sendMessageFeedback({ feedback: { ...feedback, messageId, flag } }));
    this.mazToasterFlag$.next(flag);
    if (flag === MazFeedbackFlag.correct) this.showMazAlert();
  }

  submitFeedback(text: string, { feedback, _id: messageId }: MazMessage): void {
    this.store.dispatch(MazActions.sendMessageFeedback({ feedback: { ...feedback, messageId, text } }));
    this.showMazAlert();
  }

  showMazAlert(): void {
    this.showMazAlert$.next(true);
  }

  hideMazAlert(): void {
    this.showMazAlert$.next(false);
  }

  closeMazAndOpenIntercom(): void {
    this.store.dispatch(GuideActions.closeMazAndOpenIntercom());
  }

  trackByMessageId: TrackByFunction<MazMessage> = (_, message) => message._id;
}
