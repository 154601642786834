import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { provideDefaultTippyConfig } from '@ninety/ui/legacy/core/vendor/tippy.config';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';

import { ExpandButtonConfiguration } from '../../providers/expand/dashboard-expand-candidate-state-facade';

/** The possible icons for the multi button */
export enum DashboardWidgetMultiButtonAction {
  /** Expand the widget into singleton mode **/
  expand = 'arrows-out-simple',
  /** Exit singleton mode */
  collapse = 'arrows-in-simple',
  /** Remove widget from dashboard **/
  delete = 'delete',
}

export interface DashboardWidgetMultiButtonClickEvent {
  action: DashboardWidgetMultiButtonAction;
  buttonConfiguration: ExpandButtonConfiguration;
}

/**
 * Button useful in dashboards/grids to delete in layout mode or enter/exit singleton mode
 *
 * Note, this component expects the parent to transition between the action variants. It will emit the current action, but will
 * not change the action itself.
 */
@Component({
  selector: 'ninety-dashboard-widget-multi-button',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ButtonComponent, TerraIconModule, NinetyTooltipDirective],
  styles: [],
  providers: [
    provideDefaultTippyConfig({
      theme: 'ninety-tippy-theme',
      delay: 0,
      offset: [0, 26 /* Chosen to push tooltip ~2px away from top of button */],
    }),
  ],
  template: `
    <ninety-button
      *ngIf="action !== DashboardWidgetMultiButtonAction.delete; else deleteBtn"
      [attr.flat]="configuration?.buttonStyle === 'flat' ? '' : null"
      [attr.filled]="configuration?.buttonStyle === 'filled' ? '' : null"
      [attr.stroked]="configuration?.buttonStyle === 'stroked' ? '' : null"
      [attr.primary]="configuration?.brandColor ? '' : null"
      [attr.secondary]="!configuration?.brandColor && configuration?.buttonStyle !== 'flat' ? '' : null"
      [attr.icon]="configuration.expandLabel === undefined && configuration.collapseLabel === undefined ? '' : null"
      dynamic
      (click)="emit()"
      [ninetyTooltip]="action === DashboardWidgetMultiButtonAction.collapse ? 'Collapse details' : 'Expand details'">
      <terra-icon [icon]="action" />
      {{ getlabel() }}
    </ninety-button>
    <ng-template #deleteBtn>
      <ninety-button flat icon negative (click)="emit()">
        <terra-icon icon="delete" />
      </ninety-button>
    </ng-template>
  `,
})
export class DashboardWidgetMultiButtonComponent {
  protected readonly DashboardWidgetMultiButtonAction = DashboardWidgetMultiButtonAction;

  /** The action/variant of the button */
  @Input() action: DashboardWidgetMultiButtonAction = DashboardWidgetMultiButtonAction.expand;
  @Input() configuration: ExpandButtonConfiguration = ExpandButtonConfiguration.getDefault();
  @Output() actionChanged = new EventEmitter<DashboardWidgetMultiButtonClickEvent>();

  getlabel() {
    switch (this.action) {
      case DashboardWidgetMultiButtonAction.expand:
        return this.configuration.expandLabel;
      case DashboardWidgetMultiButtonAction.collapse:
        return this.configuration.collapseLabel;
      default:
        return null;
    }
  }

  emit() {
    this.actionChanged.emit({ action: this.action, buttonConfiguration: this.configuration });
  }
}
