import { DirectoryUserRole } from '../directory-v2/directory-user-role';
import { CustomLanguage } from '../language/custom-language';

import { RoleCode } from './role-code';

export interface RoleMetadata {
  readonly title: string;
  readonly subtitle: string;
  readonly description: string;
  readonly roleCode: RoleCode;
  readonly isImplementer: boolean;
  readonly active: boolean;
  readonly isFree: boolean;
  readonly userRole: DirectoryUserRole; // this is used for the directory role filter
}

/** ========================================================================
 *        Be advised, descriptions are used in cypress tests
 * ========================================================================
 * */
export const OWNER_DESCRIPTION =
  'View and edit company settings and all items within private & public teams. Can delete the company account.';
export const ADMIN_DESCRIPTION = 'View and edit company settings and all items within private & public teams. ';
export const MANAGER_DESCRIPTION =
  'View and edit all items within assigned teams. Can invite users and create new teams.';
export const TEAM_MEMBER_DESCRIPTION = 'View and edit all items within assigned teams.';

/** this is just to make sure the cypress tests don't fail just because we change the description */
export const COACH_DESCRIPTION_PART_ONE = 'View and edit entire company account.';
export const OBSERVER_DESCRIPTION_PART_ONE = 'View-only access to items within assigned teams.';

function getCoachDescription(language: CustomLanguage): string {
  return `${COACH_DESCRIPTION_PART_ONE} Cannot own ${language?.rock?.items}, ${language?.issue?.items}, or ${language?.todo?.items}.`;
}

function geObserverDescription(language: CustomLanguage): string {
  return `${OBSERVER_DESCRIPTION_PART_ONE} Cannot own ${language?.rock?.items}, ${language?.issue?.items}, or ${language?.todo?.items}.`;
}

export function getInactiveDescription({ acChart }: CustomLanguage): string {
  return `Does not have access to Ninety but can be applied to the ${acChart.route}`;
}

export function getRolesMetadata(language?: CustomLanguage): RoleMetadata[] {
  return [
    {
      title: language?.directory?.owner,
      subtitle: '',
      description: OWNER_DESCRIPTION,
      roleCode: RoleCode.owner,
      userRole: DirectoryUserRole.owner,
      isImplementer: false,
      active: true,
      isFree: false,
    },
    {
      title: language?.directory?.admin,
      subtitle: '',
      description: ADMIN_DESCRIPTION,
      roleCode: RoleCode.admin,
      userRole: DirectoryUserRole.admin,
      isImplementer: false,
      active: true,
      isFree: false,
    },
    {
      title: language?.directory?.manager,
      subtitle: '',
      description: MANAGER_DESCRIPTION,
      roleCode: RoleCode.manager,
      userRole: DirectoryUserRole.manager,
      isImplementer: false,
      active: true,
      isFree: false,
    },
    {
      title: language?.directory?.managee,
      subtitle: '',
      description: TEAM_MEMBER_DESCRIPTION,
      roleCode: RoleCode.managee,
      userRole: DirectoryUserRole.teamMember,
      isImplementer: false,
      active: true,
      isFree: false,
    },
    {
      title: 'Inactive',
      subtitle: 'Free',
      description: getInactiveDescription(language),
      roleCode: RoleCode.observer,
      userRole: DirectoryUserRole.inactive,
      isImplementer: false,
      active: false,
      isFree: true,
    },
    {
      title: language?.directory?.observer,
      subtitle: 'Free',
      description: geObserverDescription(language),
      roleCode: RoleCode.observer,
      userRole: DirectoryUserRole.observer,
      isImplementer: false,
      active: true,
      isFree: true,
    },
    {
      title: language?.directory?.implementer,
      subtitle: 'Free',
      description: getCoachDescription(language),
      roleCode: RoleCode.owner,
      userRole: DirectoryUserRole.coach,
      isImplementer: true,
      active: true,
      isFree: true,
    },
  ];
}
