import { TooltipPosition } from '@angular/material/tooltip';
import { createSelector } from '@ngrx/store';

import { RoleService } from '../../../_core/services/role.service';
import { RoleCode } from '../../../_shared/models/_shared/role-code';
import { getRolesMetadata } from '../../../_shared/models/_shared/role-metadata';
import { RoleSelectOption } from '../../../_shared/models/_shared/role-select-option';
import { FeatureFlagKeys } from '../../app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '../../app-entities/feature-flag/feature-flag-state.selectors';
import { selectIsTrialingLegacy } from '../../app-global/company/company-state.selectors';
import { selectLanguage } from '../../app-global/language/language.selectors';
import { selectRawCurrentUser } from '../users/users-state.selectors';

export const selectRolesMetadata = createSelector(
  selectLanguage,
  selectFeatureFlag(FeatureFlagKeys.directoryAddUsersModal),
  (language, useNewAddTeammatesModal) => {
    const rolesMetadata = getRolesMetadata(language);
    if (useNewAddTeammatesModal) return rolesMetadata;
    /** filter out the Inactive role if not using the new add teammates modal */
    return rolesMetadata.filter(role => role.active);
  }
);

export const selectRoleSelectOptions = createSelector(
  selectRolesMetadata,
  selectRawCurrentUser,
  selectIsTrialingLegacy,
  (rolesMetadata, currentUser, isTrialingLegacy): RoleSelectOption[] =>
    rolesMetadata.map(role => {
      const canAssign =
        currentUser.isImplementer ||
        RoleService.canUserAssignRoleCode({
          userRoleCode: currentUser.roleCode,
          otherRoleCode: role.roleCode,
        });
      const isItemDisabled =
        (!isTrialingLegacy &&
          currentUser.roleCode === RoleCode.observer &&
          RoleService.minRole.managee(role.roleCode) &&
          !role.isImplementer) ||
        !canAssign;
      return {
        ...role,
        cannotAssign: !canAssign,
        isItemDisabled,
        itemCssClass: !(role.roleCode == RoleCode.observer || role.isImplementer) ? 'paid-role' : '',
        text: role.title,
        tooltip: isItemDisabled ? `Add more licenses to update your teammate's Role` : '',
        tooltipPosition: 'left' as TooltipPosition,
        value: { title: role.title, roleCode: role.roleCode },
      } as RoleSelectOption;
    })
);

export const selectActiveRolesSelectOptions = createSelector(selectRoleSelectOptions, (roles): RoleSelectOption[] =>
  roles.filter(r => r.active)
);
