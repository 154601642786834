import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraCompletionCheckboxComponent } from './terra-completion-checkbox.component';

@NgModule({
  imports: [CommonModule, TerraCompletionCheckboxComponent],
  exports: [TerraCompletionCheckboxComponent],
})
export class TerraCompletionCheckboxModule {}
