<terra-quick-filter
  [ngModel]="selectedUser?.[0]"
  key="{{ key }}"
  icon="users-three"
  (selectionChange)="onSelectValue($event)"
  (openedChange)="onOpenChange($event)"
  [disabled]="disabled || readonly"
  maxHeight="350"
  maxWidth="300"
  data-cy="user-pill-select">
  <terra-search-input
    [ngModel]="_userSearchInput"
    placeholder="Search {{ key | lowercase }}..."
    (ngModelChange)="_handleSearchTermChanged($event)"></terra-search-input>
  <terra-description *ngIf="fuseProvider.fuse.hasNoResults$ | ngrxPush" class="no-search-results">{{
    _noResultsText
  }}</terra-description>
  <terra-option [style.display]="!(fuseProvider.fuse.hasSearchTerm$ | ngrxPush) ? '' : 'none'" [value]="'all'"
    >All</terra-option
  >
  <ng-container *ngrxLet="fuseProvider.fuse.managedOptions$ as users">
    <terra-option
      *ngFor="let option of users; trackBy: trackBy"
      [style.display]="option.visible ? '' : 'none'"
      [value]="option.value">
      <terra-avatar *terraOptionPrefix [src]="option.value.avatarUrl" [initials]="option.value.initials" />
      {{ option.value.fullName }}
    </terra-option>
  </ng-container>
</terra-quick-filter>
