import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, catchError, EMPTY, map, of, switchMap, tap } from 'rxjs';

import { AuthService } from '../../../_core/services/auth.service';
import { CognitoMfaType, IdentityProviderService } from '../../../_core/services/identity-provider.service';
import { StateService } from '../../../_core/services/state.service';
import {
  ConfirmIdentityDialogComponent,
  ConfirmIdentityDialogData,
  ConfirmIdentityDialogStatus,
} from '../confirm-identity-dialog/confirm-identity-dialog.component';

@Component({
  selector: 'ninety-password-dialog',
  templateUrl: 'password-dialog.component.html',
  styleUrls: ['password-dialog.component.scss'],
})
export class PasswordDialogComponent implements OnInit, OnDestroy {
  passwordControl: UntypedFormControl;
  protected isLoading$ = new BehaviorSubject(false);

  constructor(
    public dialogRef: MatDialogRef<void, void>,
    public dialog: MatDialog,
    public authService: AuthService,
    private idpService: IdentityProviderService,
    public stateService: StateService
  ) {}

  ngOnInit() {
    this.passwordControl = new UntypedFormControl('', [Validators.required]);
    this.dialogRef.disableClose = true;
  }

  ngOnDestroy() {
    this.passwordControl.reset();
  }

  submitPassword() {
    this.isLoading$.next(true);
    const email = this.stateService.currentPerson$.value.primaryEmail;
    const password = this.passwordControl.value;

    this.authService
      .loginToIdp({ email, password })
      .pipe(
        catchError((err: { message: string; code: string }) => {
          // cognito api responds with http error when an email user hasn't verified their email,
          // but with http success for sms mfa verification. we're handling both as an error here
          if (err.message === 'SMS_MFA') {
            // thrown from identity service on login
            return this.dialog
              .open<
                ConfirmIdentityDialogComponent,
                ConfirmIdentityDialogData,
                { closedFromBtn?: boolean; resendSms?: boolean } | string | void
              >(ConfirmIdentityDialogComponent, {
                data: {
                  email,
                  password,
                  status: ConfirmIdentityDialogStatus.ChallengeMFA,
                  showCloseButton: true,
                },
              })
              .afterClosed()
              .pipe(
                switchMap(resp => {
                  if ((resp as { closedFromBtn: boolean })?.closedFromBtn) {
                    return this.idpService.setMfaType(CognitoMfaType.NONE).pipe(
                      tap(() => {
                        this.authService.logout();
                        this.dialogRef.close();
                      }),
                      map(() => EMPTY)
                    );
                  }

                  return of(resp);
                }),
                tap(() => this.dialogRef.close())
              ); //MFA returns the idToken
          } else {
            // console.error(err);
            this.authService.logout();
            this.dialogRef.close();
          }
          return EMPTY;
        }),
        switchMap((idToken: string) => this.authService.loginToNinety({ idToken }, true)),
        tap(() => {
          this.dialogRef.close();
        })
      )
      .subscribe();
  }
}
