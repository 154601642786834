import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@ninety/ui/web/environments';

@Injectable()
export class HostInterceptor implements HttpInterceptor {
  apiRegex = /^\/?api\/v4/i;
  dashboardRegex = /^\/?dashboard/i;
  mazApiRegex = /^\/?api\/maz\/v1/i;
  trailingSlashRegex = /\/$|\/(?=\?)|\/(?=#)/;

  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const path = req.url;
    let newOrigin: string;
    let withCredentials: boolean;
    if (environment.apiOrigin && this.apiRegex.test(path)) {
      newOrigin = environment.apiOrigin;
      withCredentials = true;
    } else if (environment.dashboardOrigin && this.dashboardRegex.test(path)) {
      newOrigin = environment.dashboardOrigin;
      withCredentials = true;
    } else if (environment.mazApiOrigin && this.mazApiRegex.test(path)) {
      newOrigin = environment.mazApiOrigin;
      withCredentials = true;
    }

    if (newOrigin) {
      const newUrl = new URL(path, newOrigin);
      const normalizedUrl = newUrl.toString().replace(this.trailingSlashRegex, '');
      req = req.clone({ url: normalizedUrl, ...(withCredentials != null ? { withCredentials } : {}) });
    }

    return next.handle(req);
  }
}
