import { Inject, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';

import { TerraLazyOptionsSelect, TERRA_LAZY_OPTIONS_SELECT } from './terra-lazy-options-select.token';

export interface LazyOptionsPipeOptions {
  selectionSize?: number;
  findObjectByProperty?: boolean;
}

const DEFAULT_PIPE_OPTIONS: Required<LazyOptionsPipeOptions> = {
  selectionSize: 5,
  findObjectByProperty: false,
};

@Pipe({ name: 'terraLazyOptions', pure: false, standalone: true })
export class TerraLazyOptionsPipe implements PipeTransform {
  private selectedOptionsOnly = true;

  constructor(@Inject(TERRA_LAZY_OPTIONS_SELECT) private readonly select: TerraLazyOptionsSelect) {
    this.select.openedChange
      .pipe(
        filter(() => this.selectedOptionsOnly),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        this.selectedOptionsOnly = false;
      });

    // Future enhancement to add requestIdleCallback
    // Safari support isn't great at the moment so we'd need to figure that out
    // Right now the options are loaded into the DOM when the select is opened
    // Which can cause some UI jank if there are a lot of options
  }

  // Because the pipe can't be pure when we generate results
  // atleast we can cache the results for subsequent calls
  private _cached: unknown[] | undefined = undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _cachedSelectValue: any = undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _cachedValues: any[] | undefined = undefined;
  private _cachedSelectValueLength: number | undefined = undefined;
  private _cachedValuesLength: number | undefined = undefined;

  /** @ignore */
  transform<T>(values: T[], property?: keyof T | undefined, options: LazyOptionsPipeOptions = {}): T[] {
    if (!this.selectedOptionsOnly) {
      // We need to show all the results
      return values;
    }
    if (this.select.value == undefined) {
      // Incase they went from a form value to undefined we need to clear cache
      // If there is no form value set we return an empty array and not preload anything
      this._cached = undefined;
      return [];
    }

    // Select is in multiple mode if we have an array type for the select value
    if (Array.isArray(this.select.value)) {
      const findObjectByProperty = options?.findObjectByProperty || DEFAULT_PIPE_OPTIONS.findObjectByProperty;

      // Get first x items from the form value
      if (
        !this._cached || // If we don't have a cache yet
        this._cachedSelectValue !== this.select.value || // If the form value has changed
        this._cachedValues !== values || // If the values being passed in have changed
        this._cachedValuesLength !== values.length || // edge case where the length of the values array has changed
        this._cachedSelectValueLength !== this.select.value.length // edge case where the length of the form value has changed
      ) {
        this._cachedSelectValue = this.select.value;
        this._cachedSelectValueLength = this.select.value.length;
        this._cachedValuesLength = values.length;
        this._cachedValues = values;
        const formValues = this.select.value.slice(0, options?.selectionSize || DEFAULT_PIPE_OPTIONS.selectionSize);
        // Grab as a set for faster lookup
        const formValuesSet = new Set(formValues);

        // Find first n matches in values and then exit for performance reasons
        const result: T[] = [];

        for (const value of values) {
          // exit early if we have our window size or we have the same length as the select value
          if (
            result.length >= (options?.selectionSize || DEFAULT_PIPE_OPTIONS.selectionSize) ||
            result.length === formValues.length
          ) {
            break;
          }

          // if we don't have a property we need to do a compareWith check which is possibly slower because of the .some
          // if we have a simple property we can do a faster lookup against the Set
          if (
            (property && findObjectByProperty && formValues.some(s => this.select.compareWith(value[property], s))) ||
            (property === undefined && formValues.some(s => this.select.compareWith(value, s))) ||
            (property && !findObjectByProperty && formValuesSet.has(value[property]))
          ) {
            result.push(value);
          }
        }
        this._cached = result;
      }
      return this._cached as T[];
    }

    // In single select mode we only expect there to be a single match
    if (
      !this._cached || // If we don't have a cache yet
      this._cachedSelectValue !== this.select.value || // If the form value has changed
      this._cachedValues !== values || // If the values being passed in have changed
      this._cachedValuesLength !== values.length // edge case where the length of the values array has changed
    ) {
      this._cachedSelectValue = this.select.value;
      this._cachedValues = values;
      this._cachedValuesLength = values.length;
      const result = values.find(v => this.select.compareWith(property ? v[property] : v, this.select.value));
      this._cached = result ? [result] : [];
    }
    return this._cached as T[];
  }
}
