import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

/**
 * A pipe that returns the page description based on the current language and page title.
 * Used in the Top Toolbar component.
 */
@Pipe({
  name: 'pageDescription',
})
export class PageDescriptionPipe implements PipeTransform {
  private readonly _language$ = this.store.select(selectLanguage);

  constructor(public store: Store) {}

  transform(inPageTitle: string): Observable<string> {
    let defaultLang;
    let description;

    this._language$.subscribe(lang => {
      const entries = Object.entries(lang);
      const tool = entries.find(tool => (tool[1].route || tool[1].items) === inPageTitle);
      defaultLang = tool ? tool[0] : undefined;

      // Custom Language Support
      switch (defaultLang) {
        case 'my90':
          description = `A personalized workspace to view tasks, data, goals, and more.`;
          break;
        case 'insights':
          description =
            'Explore, analyze, and act on insights to drive data-driven decisions across your organization.';
          break;
        case 'scorecard':
          description = 'Record and evaluate key metrics, streamlined for strategic success.';
          break;
        case 'rock':
          description = 'Set and track quarterly goals to help your team consistently hit their targets.';
          break;
        case 'todo':
          description = 'Create, assign, and track deadlines for critical tasks.';
          break;
        case 'issue':
          description = `Identify and organize your team's most pressing ${lang.issue.items} to resolve them with ease.`;
          break;
        case 'meeting':
          description = `Improve alignment and transparency across your organization.`;
          break;
        case 'headline':
          description = `Easily share important announcements with your team.`;
          break;
        case 'vto':
          description = `Communicate and share your ${lang.vto.item} at a company, departmental, and individual level.`;
          break;
        case 'acChart':
          description = 'Map out the roles and responsibilities for each seat in your business.';
          break;
        case 'feedback':
          description = 'Conduct team member reviews through a comprehensive, data-centered, two-way conversation.';
          break;
        case 'process':
          description = 'Document your critical processes and ensure consistency and transparency.';
          break;
        case 'directory':
          description =
            'Streamline user management by inviting users, and managing their status and details in one place.';
          break;
        case 'mastery':
          description = `Learn more about Ninety's concepts, tools, and disciplines.`;
          break;
        case 'survey':
          description = 'Evaluate team strength and organizational development.';
          break;
        case 'measurable':
          description = 'This is measurable description';
          break;
        default:
          description = '';
      }

      // Special overrides for internal pages
      if (!defaultLang) {
        if (inPageTitle === 'Insights') {
          description =
            'Explore, analyze, and act on insights to drive data-driven decisions across your organization.';
        }
        // if (inPageTitle === 'Referred Companies') {
        //   description = 'TERRATODO - PARTNER HUB DESCRIPTION TBD';
        // }
      }
    });

    return description;
  }
}
