import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, inject } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Span } from '@opentelemetry/api';
import { map } from 'rxjs';

import { TerraAvatarModule, TerraIconModule } from '@ninety/terra';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { SpanService } from '@ninety/ui/legacy/core/vendor/opentelemetry/span.service';
import { ContactCardHoverDirective, MeetingType } from '@ninety/ui/legacy/shared/index';
import { UserIdToAvatarPipe } from '@ninety/ui/legacy/shared/pipes/user/user-id-to-avatar.pipe';
import {
  selectCurrentUserId,
  selectCurrentUserIsManageeOrAbove,
  selectCurrentUserIsOwner,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { selectWeeklyMeetingsOnlyFeature } from '@ninety/ui/legacy/state/index';

import { MeetingDialogActions, MeetingsPageActions, MeetingSchedulingActions } from '../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../_state/meetings.selectors';

@Component({
  selector: 'ninety-active-meeting',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    LetDirective,
    ButtonComponent,
    TerraAvatarModule,
    UserIdToAvatarPipe,
    TerraIconModule,
    MaterialMdcModule,
    ContactCardHoverDirective,
  ],
  templateUrl: './active-meeting.component.html',
  styleUrls: ['./active-meeting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveMeetingComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly spanService = inject(SpanService);
  private componentSpan: Span;

  team$ = this.store.select(MeetingsStateSelectors.selectSelectedTeam);
  language$ = this.store.select(selectLanguage);
  meetingStatus$ = this.store.select(MeetingsStateSelectors.selectMeetingStatus);
  meetingName$ = this.store.select(MeetingsStateSelectors.selectMeetingName);
  loading$ = this.store.select(MeetingsStateSelectors.selectLoading);
  currentUserIsManageeOrAbove$ = this.store.select(selectCurrentUserIsManageeOrAbove);
  currentUserIsOwner$ = this.store.select(selectCurrentUserIsOwner);

  currentUserIsOwnerOrPresenter$ = this.store.select(selectCurrentUserIsOwner).pipe(
    concatLatestFrom(() => [
      this.store.select(MeetingsStateSelectors.selectMeetingStatusPresenterUserId),
      this.store.select(selectCurrentUserId),
    ]),
    map(([isOwner, presenterId, userId]) => isOwner || presenterId === userId)
  );

  meetingAttendees$ = this.store.select(MeetingsStateSelectors.selectScheduledMeetingsVisibleAndNonVisibleAttendees);
  teamHasNonObserverUsers$ = this.store.select(MeetingsStateSelectors.selectTeamHasNonObserverUsers);
  weeklyMeetingsOnlyFeature$ = this.store.select(selectWeeklyMeetingsOnlyFeature);

  ngOnInit(): void {
    this.componentSpan = this.spanService.startSpan('ActiveMeetingComponent', {
      attributes: {
        component: 'ActiveMeetingComponent',
        startTime: Date.now(),
      },
    });

    const initSpan = this.spanService.startSpan('GetActiveMeetingInfo', {
      parentSpan: this.componentSpan,
      attributes: {
        'action.type': 'initialization',
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.store.dispatch(MeetingsPageActions.getActiveMeetingInfo());
      this.spanService.closeSpan(initSpan);
    } catch (error) {
      initSpan.recordException(error);
      this.spanService.closeSpan(initSpan);
    }
  }

  ngOnDestroy(): void {
    this.spanService.closeSpan(this.componentSpan);
  }

  openStartMeetingDialog() {
    const actionSpan = this.spanService.startSpan('OpenStartMeetingDialog', {
      parentSpan: this.componentSpan,
      attributes: {
        'interaction.type': 'dialog',
        'interaction.action': 'open',
        'interaction.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.store.dispatch(MeetingDialogActions.openStartMeetingDialog());
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  startWeeklyMeeting() {
    const actionSpan = this.spanService.startSpan('StartWeeklyMeeting', {
      parentSpan: this.componentSpan,
      attributes: {
        'meeting.type': 'weekly',
        'interaction.action': 'start',
        'interaction.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.store.dispatch(MeetingDialogActions.createMeeting({ meetingType: MeetingType.weekly }));
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  openScheduleMeetingDialog() {
    this.store.dispatch(MeetingSchedulingActions.openScheduleMeetingDialog());
  }

  joinMeeting() {
    const actionSpan = this.spanService.startSpan('JoinMeeting', {
      parentSpan: this.componentSpan,
      attributes: {
        'interaction.type': 'join',
        'interaction.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.store.dispatch(MeetingsPageActions.getMeeting());
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  resumeActiveMeeting() {
    const actionSpan = this.spanService.startSpan('ResumeMeeting', {
      parentSpan: this.componentSpan,
      attributes: {
        'interaction.type': 'resume',
        'interaction.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.store.dispatch(MeetingsPageActions.resumeMeeting());
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }

  openDeleteMeetingDialog() {
    const actionSpan = this.spanService.startSpan('DeleteMeetingDialog', {
      parentSpan: this.componentSpan,
      attributes: {
        'interaction.type': 'dialog',
        'interaction.action': 'delete',
        'interaction.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.store.dispatch(MeetingsPageActions.openDeleteMeetingDialog());
      this.spanService.closeSpan(actionSpan);
    } catch (error) {
      actionSpan.recordException(error);
      this.spanService.closeSpan(actionSpan);
    }
  }
}
