import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { SegmentTrackEvent } from '../../../_core/analytics/segment/models/segment-track-event.enum';
import { SegmentActions } from '../../../_core/analytics/segment/segment.actions';

import { NavigationActions } from './navigation.actions';

@Injectable()
export class NavigationStateEffects {
  trackABTestNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.trackABTestNavigation),
      map(({ feature, label }) => {
        return SegmentActions.track({ event: SegmentTrackEvent.NAVIGATION_AB_TEST, params: { feature, label } });
      })
    )
  );

  constructor(private actions$: Actions) {}
}
