import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { TerraFormFieldModule, TerraIconModule, TerraTextInputModule } from '@ninety/terra';

import { SharedModule } from '../../../_shared/shared.module';
import { BillingStateActions } from '../../../_state/app-global/billing/billing-state.actions';
import { ButtonComponent } from '../../buttons/button/button.component';
import { ButtonRowComponent } from '../../buttons/button-row/button-row.component';
import { CardModule } from '../../cards/card.module';

import { ConfirmLicenseChangeDialogData } from './models/confirm-license-change-dialog-data';

@Component({
  selector: 'ninety-confirm-license-change',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    ButtonComponent,
    TerraIconModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TerraTextInputModule,
    TerraFormFieldModule,
    ButtonRowComponent,
  ],
  templateUrl: './confirm-license-change.component.html',
  styleUrls: ['./confirm-license-change.component.scss'],
})
export class ConfirmLicenseChangeComponent implements OnInit {
  licensesToAdd: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmLicenseChangeDialogData,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.licensesToAdd = this.data.counts.activeBillingUsers + this.data.paidSeatsToAdd - this.data.counts.seatsPaidFor;
  }

  close(): void {
    this.store.dispatch(BillingStateActions.cancelLicenseChange({ userId: this.data.userId }));
  }

  addLicenses(): void {
    this.store.dispatch(
      BillingStateActions.changeLicenseCount({
        quantity: this.data.counts.seatsPaidFor + this.licensesToAdd,
      })
    );
  }
}
