import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, OnDestroy } from '@angular/core';
import { Span } from '@opentelemetry/api';

import { SpanService } from '@ninety/ui/legacy/core/vendor/opentelemetry/span.service';

import { MeetingsFacade } from '../../meetings.facade';
import { PastMeetingsTableComponent } from '../past-meetings-table/past-meetings.component';

@Component({
  selector: 'ninety-past-meetings-page',
  standalone: true,
  imports: [CommonModule, PastMeetingsTableComponent],
  template: ` <ninety-past-meetings></ninety-past-meetings> `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PastMeetingsPageComponent implements OnInit, OnDestroy {
  facade = inject(MeetingsFacade);
  private readonly spanService = inject(SpanService);
  private componentSpan: Span;

  ngOnInit(): void {
    this.componentSpan = this.spanService.startSpan('PastMeetingsPageComponent', {
      attributes: {
        component: 'PastMeetingsPageComponent',
        startTime: Date.now(),
      },
    });

    const filterSpan = this.spanService.startSpan('InitializeMeetingTypeFilter', {
      parentSpan: this.componentSpan,
      attributes: {
        'action.type': 'filter',
        'filter.type': 'meetingType',
        'filter.value': 'all',
        'action.timestamp': new Date().toISOString(),
      },
    });

    try {
      this.facade.pastMeetingsMeetingTypeSelected({ id: null, displayText: 'all', selected: true });
      this.spanService.closeSpan(filterSpan);
    } catch (error) {
      filterSpan.recordException(error);
      this.spanService.closeSpan(filterSpan);
    }
  }

  ngOnDestroy(): void {
    this.spanService.closeSpan(this.componentSpan);
  }
}
