import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LetDirective } from '@ngrx/component';
import { provideComponentStore } from '@ngrx/component-store';

import { TerraCheckboxModule, TerraModule } from '@ninety/terra';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { NotifyIntersectDirective } from '@ninety/ui/legacy/components/index';
import { LinkedItemTypeEnum, SharedModule } from '@ninety/ui/legacy/shared/index';

import { LinkedExistingItemsStore } from './linked-existing-items.store';

@Component({
  selector: 'ninety-linked-existing-items-dialog',
  templateUrl: './linked-existing-items-dialog.component.html',
  styleUrls: ['./linked-existing-items-dialog.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    MaterialMdcModule,
    FormsModule,
    TerraModule,
    LetDirective,
    NotifyIntersectDirective,
    TerraCheckboxModule,
  ],
  providers: [provideComponentStore(LinkedExistingItemsStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedExistingItemsDialogComponent implements OnInit, OnDestroy {
  protected store = inject(LinkedExistingItemsStore);

  constructor(
    public dialogRef: MatDialogRef<LinkedExistingItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { fromId: string; linkedItemType: LinkedItemTypeEnum; title: string; teamId: string }
  ) {
    this.store.setFromLinkedItem({
      fromLinkedItem: { id: data.fromId, type: data.linkedItemType },
      fromLinkedItemTitle: data.title,
      fromLinkedItemTeamId: data.teamId,
    });
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }

  ngOnInit() {
    this.store.fetchMore();
  }

  trackBy(_, item) {
    return item._id;
  }

  createLinkedItems(): void {
    this.store.createLinkedItems();
    this.dialogRef.close();
  }

  fetchMore(event: boolean) {
    if (event) {
      this.store.fetchMore();
    }
  }
}
