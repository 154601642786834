<button
  class="terra-option"
  role="option"
  tabindex="-1"
  [disabled]="disabled || disabledOverride"
  [attr.aria-selected]="_selected"
  [attr.aria-labelledby]="optionId + '-label'"
  [attr.aria-describedby]="optionId + '-description'"
  [attr.id]="optionId"
  (click)="_onClick($event)"
  [ngClass]="{
    'terra-option--selected': _selected,
    'terra-option--highlighted': _highlighted
  }">
  <div
    *ngIf="_checkbox"
    class="terra-option__checkmark"
    [ngClass]="{
      'terra-option__checkmark--disabled': disabled || disabledOverride,
      'terra-option__checkmark--checked': _selected
    }">
    <svg
      class="terra-option__check-icon"
      width="16px"
      height="16px"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9993 24.4992C12.616 24.4992 12.2326 24.3492 11.9326 24.0659L4.93262 17.0659C4.34928 16.4826 4.34928 15.5326 4.93262 14.9492C5.51595 14.3659 6.46595 14.3659 7.04928 14.9492L12.9993 20.8826L25.9326 7.94922C26.516 7.36589 27.466 7.36589 28.0493 7.94922C28.6326 8.53255 28.6326 9.48255 28.0493 10.0659L14.0493 24.0659C13.766 24.3492 13.3826 24.4992 12.9993 24.4992Z"
        fill="currentColor"></path>
    </svg>
  </div>
  <div class="terra-option__prefix" *ngIf="_prefixTemplate">
    <ng-container [ngTemplateOutlet]="_prefixTemplate" />
  </div>
  <div class="terra-option__content">
    <div class="terra-option__label" [id]="optionId + '-label'" #label (cdkObserveContent)="_projectedContentChanged()">
      <ng-content />
    </div>
    <div [id]="optionId + '-description'" class="terra-option__description">
      <ng-content select="terra-description" />
    </div>
  </div>
  <div class="terra-option__suffix" *ngIf="_suffixTemplate">
    <ng-container [ngTemplateOutlet]="_suffixTemplate" />
  </div>
</button>
