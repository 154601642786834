<div #checkbox class="terra-checkbox">
  <input
    #checkboxInput
    [id]="_checkboxId"
    class="terra-checkbox__input"
    type="checkbox"
    [disabled]="disabled"
    [tabIndex]="disabled ? -1 : _tabIndex"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby || _checkboxId"
    [attr.aria-describedby]="ariaDescribedby || 'terra-checkbox-description-' + _checkboxId"
    (change)="_inputChange($event)"
    (blur)="blur()" />
  <span
    class="terra-checkbox__checkmark"
    [ngClass]="{
      'terra-checkbox__checkmark--invalid': _terraErrorStateMatcher.isErrorState(ngControl)
    }"
    (click)="toggle()">
    <terra-icon
      class="terra-checkbox__checkmark-icon"
      [ngClass]="{ 'terra-checkbox__checkmark--checked': _checked }"
      icon="check"
      size="16"
      variant="bold"></terra-icon>
  </span>
  <div class="terra-checkbox__label-group">
    <label class="terra-checkbox__label" [attr.for]="_checkboxId" (click)="$event.stopPropagation()">
      <ng-content></ng-content>
    </label>
    <div [id]="'terra-checkbox-description-' + _checkboxId" class="terra-checkbox__description">
      <ng-content select="terra-description"></ng-content>
    </div>
  </div>
</div>
