import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraInputAutoFocusDirective } from '../terra-input-auto-focus/terra-input-autofocus.directive';

import { TerraSearchInputComponent } from './terra-search-input.component';

@NgModule({
  imports: [CommonModule, TerraSearchInputComponent, TerraInputAutoFocusDirective],
  exports: [TerraSearchInputComponent, TerraInputAutoFocusDirective],
})
export class TerraSearchInputModule {}
