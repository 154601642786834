import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraModule } from '@ninety/terra';
import { PlanName } from '@ninety/ui/legacy/shared/models/billing-v3/plan-name';
import {
  BillingStateActions,
  selectFreePlanNotificationDismissed,
  selectIsFreeSubscription,
} from '@ninety/ui/legacy/state/index';

@Component({
  selector: 'ninety-billing-notification-banner',
  templateUrl: './billing-notification-banner.component.html',
  styleUrls: ['./billing-notification-banner.component.scss'],
  standalone: true,
  imports: [CommonModule, PushPipe, TerraModule],
})
export class BillingNotificationBannerComponent {
  protected readonly PlanName = PlanName;

  protected readonly isFreeSubscription$ = this.store.select(selectIsFreeSubscription);
  protected readonly freePlanNotificationDismissed$ = this.store.select(selectFreePlanNotificationDismissed);

  constructor(private readonly store: Store) {}

  learnMore() {
    this.store.dispatch(BillingStateActions.openPricingPlansModal());
  }
  close() {
    this.store.dispatch(BillingStateActions.dismissFreePlanNotification());
  }
}
