import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { MazSelectors } from '../../../maz/_state/maz.selectors';
import { GuideActions } from '../../_state/guide.actions';
import { GuideSelectors } from '../../_state/guide.selectors';

import { mazChatAnimation } from './getting-started-guide.animation';

@Component({
  selector: 'ninety-getting-started-guide',
  templateUrl: './getting-started-guide.component.html',
  styleUrls: ['./getting-started-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [mazChatAnimation],
})
export class GettingStartedGuideComponent implements OnInit {
  protected readonly selectedPhase$ = this.store.select(GuideSelectors.selectSelectedPhase);
  protected readonly mazChatEnabled$ = this.store.select(MazSelectors.selectMazChatEnabled);
  protected readonly isMazChatExpanded$ = this.store.select(MazSelectors.selectIsMazChatExpanded);

  constructor(private readonly store: Store) {}

  ngOnInit() {
    /** use this if the definitions of done become more complex and hard to put everywhere */
    this.store.dispatch(GuideActions.checkCompletionStatus());
  }

  close() {
    this.store.dispatch(GuideActions.closeGuide());
  }
}
