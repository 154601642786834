import ObjectId from 'bson-objectid';
import { merge } from 'lodash';
import { PartialDeep } from 'type-fest';

import { ManagerPick } from '../../_state/app-entities/user-list/api/user-list.model';
import { PersonMetadata } from '../models/_shared/person-metadata';
import { User } from '../models/_shared/user';

/** Create a user designed for the UserSelect and UserAvatar components */
export function createUser(partial?: PartialDeep<User>): User {
  const metadata: PersonMetadata = {
    country: undefined,
    preferredPronouns: '',
    name: {
      first: 'John',
      last: 'Doe',
    },
    picture: {
      url: '/stories/assets/images/newman-profile.png',
    },
  };

  const user: User = {
    _id: new ObjectId().toHexString(),
    preImplementer: undefined,
    settings: undefined,
    sms: undefined,
    metadata,
  };

  return merge({}, user, partial);
}

export function createMockManagerPick(partial?: Partial<ManagerPick>): ManagerPick {
  const _default: ManagerPick = {
    avatarUrl: '',
    initials: 'JD',
    _id: new ObjectId().toHexString(),
    fullName: 'John Doe',
  };

  return merge({}, _default, partial);
}
