<div #radioButton class="terra-radio-button">
  <input
    #radioButtonInput
    [id]="_radioButtonId"
    class="terra-radio-button__input"
    type="radio"
    [name]="_name"
    [value]="value"
    [disabled]="disabled"
    [tabIndex]="disabled ? -1 : _tabIndex"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby || _radioButtonId"
    [attr.aria-describedby]="ariaDescribedby || 'terra-radio-button-description-' + _radioButtonId"
    (change)="_radioButtonInputChange($event)"
    (blur)="_blur()" />
  <span
    class="terra-radio-button__radio"
    (click)="_radioClicked()"
    [ngClass]="{
      'terra-radio-button__radio--invalid': _hasError && !disabled,
    }">
    <div class="terra-radio-button__radio-dot"></div>
  </span>
  <div class="terra-radio-button__label-group">
    <label class="terra-radio-button__label" [attr.for]="_radioButtonId" (click)="$event.stopPropagation()">
      <ng-content />
    </label>
    <div [id]="'terra-radio-button-description-' + _radioButtonId" class="terra-radio-button__description">
      <ng-content select="terra-description" />
    </div>
  </div>
</div>
