<div class="compare-plans">
  <ng-template #row let-title="title" let-icons="icons" let-tt="tt">
    <tr>
      <td>
        <span [ngClass]="{ 'has-tooltip': tt }" [ninetyTooltip]="tt" tpPlacement="right">{{ title }}</span>
      </td>
      <td><terra-icon icon="check" variant="bold" size="32" *ngIf="icons > 3" /></td>
      <td><terra-icon icon="check" variant="bold" size="32" *ngIf="icons > 2" /></td>
      <td><terra-icon icon="check" variant="bold" size="32" *ngIf="icons > 1" /></td>
      <td><terra-icon icon="check" variant="bold" size="32" /></td>
    </tr>
  </ng-template>

  <div class="sticky-wrapper">
    <div class="title">Compare Plans</div>
    <div class="column-header-wrapper">
      <span class="free-span">{{ PlanName.Freemium }}</span>
      <span class="essential-span">{{ PlanName.Essentials }}</span>
      <span class="scale-span">{{ PlanName.Accelerate }}</span>
      <span class="thrive-span">{{ PlanName.Thrive }}</span>
    </div>
  </div>

  <div class="expansion-panel-header head-0" (click)="headerClick(0)">
    <div class="title-content" [class.open]="openSections[0]">
      <terra-icon [icon]="openSections[0] ? 'caret-up' : 'caret-down'" variant="bold" size="24" class="header-caret" />
      Get started with 90
    </div>
  </div>
  <div [@expandCollapse]="openSections[0] ? 'expanded' : 'collapsed'">
    <table #section0>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Baseline + Org Fit Assessments',
            icons: 4,
            tt: ''
          }
        "></ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Directory',
            icons: 4,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'KST: Mastery',
            icons: 4,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Maz for Onboarding',
            icons: 4,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Org Chart',
            icons: 4,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Vision + Strategy',
            icons: 4,
            tt: ''
          }
        ">
      </ng-container>
    </table>
  </div>

  <div class="expansion-panel-header head-1" (click)="headerClick(1)">
    <div class="title-content" [class.open]="openSections[1]">
      <terra-icon [icon]="openSections[1] ? 'caret-up' : 'caret-down'" variant="bold" size="24" class="header-caret" />
      Build up with a BOS toolbox
    </div>
  </div>
  <div [@expandCollapse]="openSections[1] ? 'expanded' : 'collapsed'">
    <table #section1>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Headlines',
            icons: 3,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Issues',
            icons: 3,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Milestones',
            icons: 3,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'My 90',
            icons: 3,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Rocks',
            icons: 3,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Scorecard',
            icons: 3,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'To-Dos',
            icons: 3,
            tt: ''
          }
        ">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          row;
          context: {
            title: 'Weekly Meeitngs',
            icons: 3,
            tt: ''
          }
        ">
      </ng-container>
    </table>
  </div>

  <div class="expansion-panel-header head-2" (click)="headerClick(2)">
    <div class="title-content" [class.open]="openSections[2]">
      <terra-icon [icon]="openSections[2] ? 'caret-up' : 'caret-down'" variant="bold" size="24" class="header-caret" />
      Customize and integrate
    </div>
  </div>
  <div [@expandCollapse]="openSections[2] ? 'expanded' : 'collapsed'">
    <table #section2>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Annual Meetings', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Cascading Messages', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Custom Meetings', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Google Integrations', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Implementation Meetings', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Legacy Process Tool', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'MIcrosoft Integrations', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Quarterly Discussions', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Quarterly Meetings', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Reporting', icons: 2 }"> </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Scorecard integrations and API', icons: 2 }">
      </ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Weekly Discussions', icons: 2 }"> </ng-container>
    </table>
  </div>
  <div class="expansion-panel-header head-3" (click)="headerClick(3)">
    <div class="title-content" [class.open]="openSections[3]">
      <terra-icon [icon]="openSections[3] ? 'caret-up' : 'caret-down'" variant="bold" size="24" class="header-caret" />
      Analyze and grow
    </div>
  </div>
  <div [@expandCollapse]="openSections[3] ? 'expanded' : 'collapsed'">
    <table #section3>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Benchmarking', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Board Meetings', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Custom Assessments', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Exit Planning', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'General Purpose APIs', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'iPaaS Integrations', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'KST: Authoring', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Maz for KPIs', icons: 1 }"></ng-container>
      <ng-container
        *ngTemplateOutlet="row; context: { title: 'Maz for Personal Development', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Portfolio Views', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Predictive Analytics', icons: 1 }"></ng-container>
      <ng-container *ngTemplateOutlet="row; context: { title: 'Project Views', icons: 1 }"></ng-container>
      <ng-container
        *ngTemplateOutlet="row; context: { title: 'Teams / Slack / Zoom integrations', icons: 1 }"></ng-container>
    </table>
  </div>
</div>
