<ng-container>
  <ninety-button
    [disabled]="disabled"
    ninetyTooltip="Cannot take actions on this role"
    [tpIsEnabled]="disabled"
    [matMenuTriggerFor]="moreActions"
    compact
    icon>
    <terra-icon icon="more" />
  </ninety-button>

  <mat-menu #moreActions="matMenu" class="template-options-menu-directory-action" xPosition="before">
    <button mat-menu-item *ngIf="user.status === DirectoryUserStatusV2.invitePending" (click)="copyInviteLink()">
      <terra-icon class="text-light" icon="link" size="20" />
      <span class="terra-body">Copy unique invite link</span>
    </button>
    <button mat-menu-item *ngIf="user.active" (click)="openDeactivateUserDialog()">
      <terra-icon class="text-light" icon="minus-circle" size="20" />
      <span class="terra-body">Deactivate </span><span class="text-light">(remove Ninety access)</span>
    </button>
    <button mat-menu-item (click)="openRemovePersonDialog()" class="color-red">
      <terra-icon icon="delete" size="20" />
      <span class="terra-body">Delete user</span>
    </button>
  </mat-menu>
</ng-container>
