import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, retry, switchMap, take } from 'rxjs/operators';

import { RefreshToken, Tokens } from '../../_shared/models/auth/refresh-token';
import { AuthService } from '../services/auth.service';

/**
 * Reference:
 * https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshInProgress = false;
  private readonly accessTokenB$ = new BehaviorSubject<string>(null);

  constructor(private readonly authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((httpErr: HttpErrorResponse) => {
        // We don't want to refresh token for some requests like login or refresh
        if (
          request.url.toLowerCase().includes('signup') ||
          request.url.toLowerCase().includes('login') ||
          request.url.toLowerCase().includes('invite') ||
          request.url.toLowerCase().includes('refresh')
        ) {
          return throwError(() => httpErr);
        }

        // Only send refresh on token expiration
        if (httpErr?.error?.errorMessage !== 'TokenExpiredError') {
          return throwError(() => httpErr);
        }

        // Wait until token is returned, then replay with new access token
        if (this.refreshInProgress) {
          return this.accessTokenB$.pipe(
            filter(val => val !== null),
            take(1),
            switchMap(token => next.handle(this.addAccessToken(request, token)))
          );
        }

        this.refreshInProgress = true;

        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
        this.accessTokenB$.next(null);

        return this.authService.refreshAccessToken().pipe(
          // re-try refresh up to 3 times before forcing logout
          retry(3),
          catchError((pipeErr: unknown) => {
            this.authService.logout();
            return throwError(() => pipeErr);
          }),
          map((resp: RefreshToken) => resp.tokens),
          filter(tokens => !!tokens?.access),
          switchMap((tokens: Tokens) => {
            this.accessTokenB$.next(tokens.access);
            this.refreshInProgress = false;

            return next.handle(this.addAccessToken(request, tokens.access));
          }),
          // cloned request error doesn't matter for logout
          catchError((pipeErr: unknown) => throwError(() => pipeErr))
        );
      })
    );
  }

  private addAccessToken(request: HttpRequest<any>, accessToken: string) {
    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
