import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraDividerModule, TerraIconModule, TerraSelectModule } from '@ninety/terra';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectRoleSelectOptions } from '@ninety/ui/legacy/state/app-entities/roles/roles-state.selectors';

@Component({
  selector: 'ninety-inline-role-select',
  standalone: true,
  imports: [CommonModule, TerraIconModule, TerraDividerModule, PushPipe, TerraSelectModule, FormsModule, LetDirective],
  templateUrl: './inline-role-select.component.html',
  styleUrls: ['./inline-role-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineRoleSelectComponent {
  @Input() selectedRole: RoleSelectOption;
  @Input() disabled: boolean;

  @Output() updateRole = new EventEmitter<RoleSelectOption>();

  protected readonly roles$ = this.store.select(selectRoleSelectOptions);
  protected readonly inactiveAsRole$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.directoryAddUsersModal));

  constructor(private readonly store: Store) {}

  selectRole(role: RoleSelectOption): void {
    this.updateRole.emit(role);
  }
}
