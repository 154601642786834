import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, tap } from 'rxjs';

import { selectAuthShouldHaveAccess } from '../../_state/app-global/auth/auth.selectors';
import { BillingService } from '../services/billing.service';
import { SpinnerService } from '../services/spinner.service';
import { StateService } from '../services/state.service';

export const STRIPE_BILLING_PATH = 'company/billing';
export const BILLING_V2_ROOT_PATH = 'billing';
export const BILLING_V3_ROOT_PATH = 'billing-v3';

@Injectable({
  providedIn: 'root',
})
export class ActiveSubscriptionGuard {
  constructor(
    private billingService: BillingService,
    public stateService: StateService,
    private store: Store,
    public spinnerService: SpinnerService,
    private router: Router
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.store.select(selectAuthShouldHaveAccess).pipe(
      map(authShouldHaveAccess => BillingService.isAllowedLocationForUnpaid() || authShouldHaveAccess),
      tap(shouldHaveAccess => {
        if (!shouldHaveAccess) this.navigateToBilling();
      })
    );
  }

  private navigateToBilling() {
    if (this.stateService.isManagerOrAbove) {
      this.router.navigateByUrl(`/settings/${STRIPE_BILLING_PATH}`);
    } else {
      this.router.navigateByUrl('/home');
    }
  }
}
