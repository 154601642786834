import { initialTodoStateBase, TodoStateBase } from '../_shared/todo-state.shared.model';

import { CompletedSelectValue } from './completedselectvalue';

export interface PersonalTodoState extends TodoStateBase {
  /**
   * What type of personal todos to show - incomplete or completed (includes archived)
   */
  typeOfTodosToShow: CompletedSelectValue;
  listControlsDisabled: boolean;
  focusOnInlineAddTodo: boolean;
}

export const initialPersonalTodoState: PersonalTodoState = {
  ...initialTodoStateBase,
  typeOfTodosToShow: CompletedSelectValue.incomplete,
  listControlsDisabled: false,
  focusOnInlineAddTodo: false,
};
