import { CssColor } from 'ag-charts-enterprise';

/*

Semantic

 */
export const DiscoSemanticGreenHeavy: CssColor = '#428A44';
export const DiscoSemanticGreen = '#48984B';
export const DiscoSemanticGreenLight: CssColor = '#4EA651';

export const DiscoSemanticOrangeLight = '#E87F36';
export const DiscoSemanticOrange: CssColor = '#E57424';

export const DiscoSemanticRed = '#CD4551';
export const DiscoSemanticRedLight: CssColor = '#D25661';

/*

Neutral

 */

export const DiscoNeutralMid100: CssColor = '#E5E5E5FF';
export const DiscoNeutralMid200: CssColor = '#CCCCCCFF';
export const DiscoNeutralMid400: CssColor = '#999999FF';
export const DiscoNeutralMid500: CssColor = '#808080FF';

export const DiscoNeutralLight500: CssColor = '#F2F2F2FF';

/*

Text

 */

export const DiscoText: CssColor = '#000000';
export const DiscoTextLight: CssColor = '#0000008A';

/*

Border

*/

export const DiscoBorderHeavy: CssColor = '#C4C4C4FF';

/*

Brand

*/

export const DiscoBrandNinety: CssColor = '#2F8BAA';

/*

Pallets

*/

export const DiscoSapphire: CssColor = '#006B8A';

/*

Line Colors

*/
export const DiscoLineTeal: CssColor = '#00ACC4';
export const DiscoLineGreekBlue: CssColor = '#304FFE';
export const DiscoLineTurquoise: CssColor = '#008571';
export const DiscoLineLavender: CssColor = '#AF6EE8';
export const DiscoLineShadow: CssColor = '#566573';

/*

Bar colors

*/
export const DiscoBarDarkGreen = DiscoSemanticGreen;
export const DiscoBarLightGreen: CssColor = '#B6D6B7';
export const DiscoBarRed = DiscoSemanticRed;

/*

Issues colors

*/
export const DiscoIssueRating5: CssColor = '#0B2845';
export const DiscoIssueRating4: CssColor = '#3409A9';
export const DiscoIssueRating3: CssColor = '#8409A9';
export const DiscoIssueRating2: CssColor = '#096EA9';
export const DiscoIssueRating1: CssColor = '#0996A9';
export const DiscoIssueNoRating: CssColor = '#949494';

export const IssuesByRating = {
  none: DiscoIssueNoRating,
  '1': DiscoIssueRating1,
  '2': DiscoIssueRating2,
  '3': DiscoIssueRating3,
  '4': DiscoIssueRating4,
  '5': DiscoIssueRating5,
};
