<div
  class="terra-input"
  (click)="focus()"
  [ngClass]="{
    'terra-input--disabled': disabled,
    'terra-input--invalid': _terraErrorStateMatcher.isErrorState(_ngControl),
    'terra-input--readonly': readonly
  }">
  <textarea
    #input
    cdkTextareaAutosize=""
    [cdkAutosizeMaxRows]="maxRows"
    [cdkAutosizeMinRows]="minRows"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
    [autocomplete]="autocomplete"
    [maxlength]="maxlength || null"
    [(ngModel)]="_inputValue"
    (ngModelChange)="_onModelChange($event)"
    (blur)="_blurred($event)"
    (focus)="_focused($event)"
    class="terra-input__input"
    [attr.placeholder]="placeholder || null"
    [disabled]="disabled"
    [readonly]="readonly"></textarea>
</div>
