import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, distinctUntilChanged, map, tap } from 'rxjs';

import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectIsBillingV3Company } from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';
import { appActions } from '@ninety/ui/legacy/state/app.actions';
import { selectCurrentRoute } from '@ninety/ui/legacy/state/route.selectors';
import { MeetingService } from '@ninety/web/pages/meeting/_shared/services/meeting.service';

import { NinetyLayoutType } from '../../_state/page/page-state.models';
import { selectLayoutType } from '../../_state/page/page-state.selectors';

@Component({
  selector: 'ninety-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public state$!: Observable<{
    inMeeting: boolean;
    inFeedback: boolean;
    isMobile: boolean;
    layoutType: NinetyLayoutType;
    showNotes: boolean;
    showQuarterlyQuestions: boolean;
    showSideNav: boolean;
  }>;
  public layouts = NinetyLayoutType;

  private layoutType$ = this.store.select(selectLayoutType);
  private route$ = this.store.select(selectCurrentRoute);

  protected readonly billingV3Company$ = this.store.select(selectIsBillingV3Company);

  public constructor(
    private breakpointObserver: BreakpointObserver,
    public stateService: StateService,
    public meetingService: MeetingService,
    private featureFlagFacade: FeatureFlagFacade,
    public store: Store
  ) {}

  public ngOnInit(): void {
    this.state$ = combineLatest({
      inMeeting: this.layoutType$.pipe(
        map(layout => layout !== this.layouts.normal),
        distinctUntilChanged()
      ),
      inFeedback: combineLatest([
        this.route$,
        this.featureFlagFacade.getFlag(FeatureFlagKeys.webWeeklyConversations),
      ]).pipe(map(([route, flag]) => route?.data.route === 'feedback')),
      isMobile: this.breakpointObserver.observe(['(min-width: 960px)']).pipe(
        map(({ breakpoints }) => !breakpoints['(min-width: 960px)']),
        tap(isMobile => this.stateService.toggleMeetingSideNav(!isMobile))
      ),
      layoutType: this.layoutType$,
      showNotes: this.meetingService.showNotes$,
      showQuarterlyQuestions: this.featureFlagFacade.getFlag(FeatureFlagKeys.quarterlyQuestionSettings),
      showSideNav: this.stateService.toggleMeetingSideNavB$,
    });

    this.store.dispatch(appActions.dispatchAppStartActions());
  }
}
