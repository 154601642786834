<terra-select
  *ngrxLet="fuseProvider.fuse.managedOptions$ as teams"
  (openedChange)="onOpenChange($event)"
  [ninetyTooltip]="teamNames"
  placeholder="Team(s) required"
  multiple="true"
  [formControl]="teamsControl"
  maxHeight="350"
  maxWidth="300"
  [compareWith]="_compareById">
  <terra-select-search *ngIf="teams.length > 5">
    <terra-search-input
      [ngModel]="_teamSearchInput"
      (ngModelChange)="_handleSearchTermChanged($event)"
      placeholder="Search teams..." />
  </terra-select-search>
  <terra-description *ngIf="fuseProvider.fuse.hasNoResults$ | ngrxPush" class="no-search-results">
    No search results found. Please check your spelling or try a different search.
  </terra-description>

  <ng-container
    *ngFor="let option of teams | terraLazyOptions : 'value' : { findObjectByProperty: true }; trackBy: trackBy">
    <terra-divider *ngIf="option.value['divider']" />
    <terra-option
      [attr.data-option-name]="option.value.name"
      [style.display]="option.visible ? '' : 'none'"
      [disabled]="option.value.cannotAssign"
      [value]="option.value"
      ninetyTooltip="You must be a member of this private team to add users to it."
      [tpIsEnabled]="option.value.cannotAssign">
      {{ option.value.name }}
    </terra-option>
  </ng-container>
</terra-select>
