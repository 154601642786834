import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';

import { NinetyChipComponent } from '../../../../../../_components/ninety-chip/ninety-chip.component';
import { PricingPlan } from '../../../../../../_shared/models/billing-v3/pricing-plan';
import { BillingStateActions } from '../../../../../../_state/app-global/billing/billing-state.actions';
import { ButtonComponent } from '../../../../../buttons/button/button.component';
import { CardModule } from '../../../../../cards/card.module';

@Component({
  selector: 'ninety-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss'],
  imports: [CommonModule, CardModule, TerraIconModule, ButtonComponent, NinetyChipComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingCardComponent implements OnInit {
  @Input() plan: PricingPlan;

  protected extendedListOpen = false;

  constructor(
    private readonly hostElement: ElementRef,
    private readonly renderer: Renderer2,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.renderer.addClass(this.hostElement.nativeElement, `${this.plan.displayName.toLowerCase()}-card`);
  }

  selectPlan() {
    this.store.dispatch(BillingStateActions.selectPlan({ plan: this.plan }));
  }
}
