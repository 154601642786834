import { createActionGroup, props } from '@ngrx/store';

import { LegacyGoal } from '@ninety/ui/legacy/shared/models/scorecard/legacy-goal';

export const KpiActions = createActionGroup({
  source: 'Scorecard Kpi',
  events: {
    // Detail
    'Open Edit Detail': props<{ id: string; kpiGroupId: string }>(),

    'Set Future Goal Success': props<{ kpiId: string; defaultGoal: LegacyGoal }>(),
  },
});
