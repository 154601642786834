import { createActionGroup, props } from '@ngrx/store';

import { ExpandButtonConfiguration } from './dashboard-expand-candidate-state-facade';

export const DashboardExpandActions = createActionGroup({
  source: 'Dashboard - Expansion',
  events: {
    Expand: props<{ widgetId: string; buttonConfiguration?: ExpandButtonConfiguration }>(),
    Collapse: props<{ widgetId: string; buttonConfiguration?: ExpandButtonConfiguration }>(),
  },
});
