import { Params } from '@angular/router';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SeatDetailComponentViewType } from '@ninety/ui/legacy/shared/models/accountability-chart/seat-detail.model';

import { ChartPageReferrer } from '../../routes/org-chart-routing.model';

export type NavigateToSeatDetailsParamsCreate = { seatId: string; type: SeatDetailComponentViewType.create };
export type NavigateToSeatDetailsParamsView = { seatId: string; type: SeatDetailComponentViewType.view };
export type NavigateToSeatDetailsParams = NavigateToSeatDetailsParamsCreate | NavigateToSeatDetailsParamsView;

export namespace NavigateToSeatDetailsParams {
  export const isCreate = (params: Params | NavigateToSeatDetailsParams): params is NavigateToSeatDetailsParamsCreate =>
    params.type === SeatDetailComponentViewType.create && 'seatId' in params;
  export const isView = (params: Params | NavigateToSeatDetailsParams): params is NavigateToSeatDetailsParamsView =>
    params.type === SeatDetailComponentViewType.view && 'seatId' in params;

  export function parse(rawParams: Params): NavigateToSeatDetailsParams {
    if (isCreate(rawParams)) return { seatId: rawParams.seatId, type: SeatDetailComponentViewType.create };
    if (isView(rawParams)) return { seatId: rawParams.seatId, type: SeatDetailComponentViewType.view };

    // console.error({ message: 'Bad params', data: { rawParams } });
    throw new Error('Invalid route params');
  }
}

export const ResponsibilitiesNavigationActions = createActionGroup({
  source: 'Responsibilities - Nav',
  events: {
    'Navigate to Primary Chart': emptyProps(),
    'Navigate to Shared': emptyProps(),
    'Navigate to Chart': props<{ chartId: string; referrer: ChartPageReferrer }>(),

    /** Returns the user "back" to the last core page the user edited, with the referrer from the current URL */
    'Pop Chart Breadcrumb': props<{ referrer: ChartPageReferrer }>(),

    'Create Seat In Details': props<{ params: NavigateToSeatDetailsParamsCreate }>(),
    'View Seat In Details': props<{ params: NavigateToSeatDetailsParamsView }>(),
    'Navigate To Seat Details': props<{ params: NavigateToSeatDetailsParams }>(),
  },
});
