<h4 class="section-header {{ headerClass }}">{{ title }}</h4>
<div class="knowledge-config__container">
  <terra-switch
    data-cy="knowledge-config_toggle-button"
    [ngModel]="knowledgeSettings.hideDefaultModules"
    (change)="update('hideDefaultModules', $event)"
    [disabled]="readonly">
    {{ actionDescription }}
    <terra-description> {{ subText }} </terra-description>
  </terra-switch>
</div>
