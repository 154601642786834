import { ElementRef, Injectable } from '@angular/core';

interface CallbackMapEntry {
  element: HTMLElement;

  callback(event: ResizeObserverEntry): void;
}

/**
 * Globally defined service to wrap ResizeObserver.
 *
 * It is far more performant to only use a single observer to watch multiple elements.
 * See https://github.com/WICG/resize-observer/issues/59
 *
 * @see ResizeObserverServiceForTests
 */
@Injectable({
  providedIn: 'root',
})
export class ResizeObserverService {
  private static readonly ATTR_NAME = 'resize-aware-id';

  private readonly resizeObserver: ResizeObserver;
  private readonly watchedElements: Map<number, CallbackMapEntry>;

  constructor() {
    this.watchedElements = new Map();
    this.resizeObserver = new ResizeObserver(entries => this.handleEntries(entries));
  }

  handleEntries(entries: ResizeObserverEntry[]) {
    if (!Array.isArray(entries) || !entries.length) return;

    entries.forEach(entry => this.handleEntry(entry));
  }

  observe(id: number, element: ElementRef, callback: (event: ResizeObserverEntry) => void) {
    if (this.watchedElements.has(id)) {
      throw new Error(`Duplicate call to observe for id '${id}', likely race condition or implementation error`);
    }

    try {
      const domElement = this.observeElement(element);

      // Set attribute so element can be identified from ResizeObserverEntry.target in this.handleEvent
      this.watchedElements.set(id, { callback, element: domElement });
      domElement.setAttribute(ResizeObserverService.ATTR_NAME, String(id));
    } catch (e) {
      // console.error(`Failed to create ResizeObserver subscription for id '${id}'`, e);
    }
  }

  unobserve(id: number) {
    try {
      if (this.watchedElements.has(id)) {
        const { element } = this.watchedElements.get(id);
        this.resizeObserver.unobserve(element);

        this.watchedElements.delete(id);
      }
    } catch (e) {
      // console.error(`Failed to remove ResizeObserver subscription for id '${id}'`, e);
    }
  }

  private observeElement(element: ElementRef): HTMLElement {
    let domElement = element.nativeElement;

    try {
      this.resizeObserver.observe(domElement);
    } catch {
      domElement = domElement.parentElement;
      this.resizeObserver.observe(domElement);
    }

    return domElement;
  }

  protected handleEntry(event: ResizeObserverEntry) {
    let id: number;
    try {
      id = +event.target.attributes[ResizeObserverService.ATTR_NAME].value;
      const entry = this.watchedElements.get(id);
      if (entry) entry.callback(event);
    } catch (e) {
      // console.error(`Failed to handle ResizeObserverEntry for id '${id}'`, e);
    }
  }
}

/**
 * The various test frameworks (cypress and jasmine) have problems around the ResizeObserver browser API. In specific,
 * they throw "ResizeObserver - loop limit exceeded" errors. "This error means that ResizeObserver was not able to
 * deliver all observations within a single animation frame. It is benign (your site will not break)."
 *
 * This solution "pushes the layout change onto the macrotask queue" which ensures the error does not appear. However,
 * this is not desirable in app. Thus, this separate service is provided to tests via
 * {@link ElementResizeAwareModule#forTests}.
 *
 * @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
 */
@Injectable()
export class ResizeObserverServiceForTests extends ResizeObserverService {
  handleEntries(entries: ResizeObserverEntry[]) {
    window.requestAnimationFrame(() => {
      super.handleEntries(entries);
    });
  }
}
