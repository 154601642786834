<ninety-card class="flex flex-1" *ngIf="contactCardInput">
  <div class="contact-card-content">
    <div class="contact-avatar">
      <terra-avatar
        [src]="contactCardInput.pictureURL"
        [initials]="contactCardInput.userInitials"
        [inactive]="contactCardInput.transparent"
        size="xl" />
    </div>
    <div [ngClass]="['contact-user-info', contactCardInput.title ? '' : 'center-contents']">
      <div class="contact-user-name">
        <span class="terra-body-bold">{{ contactCardInput.fullUserName || contactCardInput.email }}</span>
        <span class="terra-body you-span" *ngIf="contactCardInput.isCurrentUser"> (You)</span>
      </div>
      <ng-container *ngIf="contactCardInput.fullUserName">
        <hr *ngIf="contactCardInput.title || contactCardInput.email" />
        <div *ngIf="contactCardInput.title" class="contact-user-title terra-body-small">
          {{ contactCardInput.title }}
        </div>
        <div *ngIf="contactCardInput.email" class="contact-user-email terra-body-small">
          <a href="mailto:{{ contactCardInput.email }}">{{ contactCardInput.email }}</a>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="contact-status"
    [ngClass]="{
      'contact-complete': contactCardInput.statusColor === contactCardStatusColor.completed,
      'contact-in-progress': contactCardInput.statusColor === contactCardStatusColor.inProgress,
      'contact-not-started': contactCardInput.statusColor === contactCardStatusColor.notStarted
    }"
    *ngIf="contactCardInput.showStatus">
    <div *ngIf="!!contactCardInput.statusIcon">
      <terra-icon
        class="contact-status-icon"
        [icon]="contactCardInput.statusIcon"
        [variant]="contactCardInput.statusIconVariant || 'regular'" />
    </div>
    <div class="terra-body contact-status-text">{{ contactCardInput.statusText }}</div>
  </div>
</ninety-card>
