import { InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DashboardWidgetMultiButtonClickEvent } from '../../widgets/dashboard-widget-multi-button/dashboard-widget-multi-button.component';

/**
 * For each widget, this facade exposes the current expansion state, as well as methods to change it. Abstracted to an interface
 * to make it far easier to mock in tests & stories.
 *
 * @see DashboardExpandCandidateDirective
 */
export interface DashboardExpandCandidateStateFacade {
  /** A unique identifier for this widget. */
  readonly id: string;

  /** When true, this widget is being rendered in an expanded state. */
  isExpanded$: Observable<boolean>;

  /** Provides options for the expand button display. */
  buttonConfiguration$: Observable<ExpandButtonConfiguration>;

  /** Requests that a {@link DashboardExpandTargetDirective} expand this widget. */
  requestExpand($event?: DashboardWidgetMultiButtonClickEvent): void;
  /** Requests that a {@link DashboardExpandTargetDirective} collapse this widget. */
  requestCollapse($event?: DashboardWidgetMultiButtonClickEvent): void;
}

export interface ExpandButtonConfiguration {
  /** The label for the expand button. Not shown if not provided */
  expandLabel?: string;
  /** The label for the collapse button. Not shown if not provided */
  collapseLabel?: string;
  /** The terra button style to apply. */
  buttonStyle?: 'filled' | 'stroked' | 'flat';
  /** Whether or not to apply brand color to the button. */
  brandColor?: boolean;
}

export namespace ExpandButtonConfiguration {
  export function getDefault(): ExpandButtonConfiguration {
    return {
      buttonStyle: 'flat',
      brandColor: false,
    };
  }
}

export const DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN = new InjectionToken<DashboardExpandCandidateStateFacade>(
  'DASHBOARD_EXPAND_CANDIDATE_STATE_FACADE_TOKEN'
);

export interface DashboardExpandCandidateStateFacadeMock extends DashboardExpandCandidateStateFacade {
  isExpanded$: BehaviorSubject<boolean>;
}

export function createMockDashboardExpandCandidateStateFacade(
  inputSource?: BehaviorSubject<boolean> | boolean,
  id = 'example',
  buttonConfiguration?: BehaviorSubject<ExpandButtonConfiguration> | ExpandButtonConfiguration
): DashboardExpandCandidateStateFacadeMock {
  const source =
    typeof inputSource === 'boolean'
      ? new BehaviorSubject(inputSource) //
      : inputSource ?? new BehaviorSubject(false);
  const config =
    buttonConfiguration instanceof BehaviorSubject
      ? buttonConfiguration
      : new BehaviorSubject(buttonConfiguration ?? ExpandButtonConfiguration.getDefault());

  return {
    id,
    isExpanded$: source,
    buttonConfiguration$: config,
    requestExpand: (_?: DashboardWidgetMultiButtonClickEvent) => {
      source.next(true);
    },
    requestCollapse: (_?: DashboardWidgetMultiButtonClickEvent) => {
      source.next(false);
    },
  };
}
