import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  Attribute,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';

import { TerraInputBoolean } from '../../../models/terra-input-boolean.models';
import { TerraErrorStateMatcher } from '../../forms/terra-error-state-matcher';

let radioButtonUniqueId = 1;

@Component({
  selector: 'terra-radio-button',
  standalone: true,
  exportAs: 'terraRadioButton',
  imports: [CommonModule],
  templateUrl: './terra-radio-button.component.html',
  styleUrls: ['./terra-radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    // Removes tabindex on the host - applies it instead to the input radio
    '[attr.tabindex]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[attr.aria-describedby]': 'null',
  },
})
export class TerraRadioButtonComponent {
  /**
   * Reference to internal radio button input
   */
  @ViewChild('radioButtonInput', { static: true }) private _radioButtonInput!: ElementRef<HTMLInputElement>;

  /** Emits event when the radio button's `checked` value changes. */
  // eslint-disable-next-line @angular-eslint/no-output-native, @typescript-eslint/no-explicit-any
  @Output() readonly change: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Set the disabled state of the radio button
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /**
   * The value of the radio button
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() get value(): any {
    return this._value;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(newValue: any) {
    this._value = newValue;
    this._changeDetectorRef.markForCheck();
  }
  private _value = '';

  /**
   * @ignore
   * Internal - Set the name for the radio button (from the radio group)
   */
  set name(value: string) {
    this._name = value;
    this._changeDetectorRef.markForCheck();
  }
  protected _name = '';

  /**
   * @ignore
   * Internal - Set the state of the radio input
   * @default false
   */
  set checked(value: boolean) {
    this._checked = value;
    this._radioButtonInput.nativeElement.checked = value;
    this._changeDetectorRef.markForCheck();
  }
  protected _checked = false;

  /**
   * @ignore
   * Internal - Show error state if radio group has error
   * @default false
   */
  set hasError(value: boolean) {
    this._hasError = value;
    this._changeDetectorRef.markForCheck();
  }
  protected _hasError = false;

  /**
   * Optional input to override aria-label
   * @default null
   */
  @Input('aria-label') ariaLabel: string | null = null;
  /**
   * Optional input to override aria-labelledby
   * @default null
   */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;
  /**
   * Optional input to override aria-describedby
   * @default null
   */
  @Input('aria-describedby') ariaDescribedby: string | null = null;

  /** Tabindex of the radio button */
  protected _tabIndex = 0;

  /** Unique ID for the radio button input element for aria referencing purposes. */
  protected _radioButtonId = `terra-radio-button-${radioButtonUniqueId++}`;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    protected readonly _terraErrorStateMatcher: TerraErrorStateMatcher,
    @Attribute('tabindex') tabIndex: string
  ) {
    this._tabIndex = parseInt(tabIndex) || 0;
  }

  /**
   * Updates the internal radio input state and emits change events*/
  protected _radioClicked(): void {
    if (!this.disabled && !this._checked) {
      this._emitChangeEvent();
    }
  }
  /**
   * @ignore
   * Focus the radio button
   */
  public _focus(): void {
    this._radioButtonInput.nativeElement.focus();
  }

  /**
   * @ignore
   * Blur the radio button
   */
  public _blur(): void {
    this._radioButtonInput.nativeElement.blur();
  }

  /**
   * @ignore
   * Sends change related events */
  private _emitChangeEvent(): void {
    this.change.emit(this._value);
  }

  /**
   * Triggered when the label for the input is clicked or when navigating with focus */
  protected _radioButtonInputChange($event: Event): void {
    $event.stopPropagation();
    this._radioClicked();
  }
}
