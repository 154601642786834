import { Injectable, OnDestroy } from '@angular/core';
import { Span, Context, trace, context } from '@opentelemetry/api';

import { LoggingService } from './logging.service';
import { SpanService } from './span.service';

@Injectable({
  providedIn: 'root',
})
export class TelemetrySessionService implements OnDestroy {
  private sessionSpan: Span | null = null;
  private sessionId: string | null = null;

  constructor(private spanService: SpanService, private loggingService: LoggingService) {
    window.addEventListener('beforeunload', () => {
      this.endSession();
    });
  }

  startSession(userId: string): void {
    // End any existing session before starting a new one
    if (this.sessionSpan) {
      this.endSession();
    }

    this.sessionId = crypto.randomUUID();
    const startAttributes = {
      'session.start': new Date().toISOString(),
      'user.id': userId,
      'session.id': this.sessionId,
    };

    // Create the session span
    this.sessionSpan = this.spanService.startSpan('UserSession', startAttributes);
    // Set it as the active context
    if (this.sessionSpan) {
      context.with(trace.setSpan(context.active(), this.sessionSpan), () => {
        this.loggingService.logEvent(this.sessionSpan, 'Session started', startAttributes);
      });
    }
  }

  getSessionId(): string | null {
    return this.sessionId;
  }

  // Method to get the current session context
  getSessionContext(): Context | null {
    if (this.sessionSpan) {
      // Create a new context with the session span
      return trace.setSpan(context.active(), this.sessionSpan);
    }
    return null;
  }

  endSession(): void {
    if (this.sessionSpan) {
      const endAttributes = {
        'session.end': new Date().toISOString(),
      };
      this.loggingService.logEvent(this.sessionSpan, 'Session ended', endAttributes);
      this.spanService.closeSpan(this.sessionSpan);
      this.sessionSpan = null;
    } else {
      this.loggingService.logEvent(null, 'Attempted to end session, but no active session exists', {
        severity: 'warn',
      });
    }
  }

  getSessionSpan(): Span | null {
    if (!this.sessionSpan) {
      this.loggingService.logEvent(null, 'Attempted to get session span, but no active session exists', {
        severity: 'warn',
      });
    }
    return this.sessionSpan;
  }

  ngOnDestroy(): void {
    this.endSession();
  }
}
