import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import _cloneDeep from 'lodash/cloneDeep';

import { KnowledgeSettings } from '@ninety/ui/legacy/shared/models/knowledge/knowledge-settings';

import { KnowledgeSettingsLabels } from '../../models/knowledge/learning-constants';

@Component({
  selector: 'ninety-knowledge-config',
  templateUrl: './knowledge-config.component.html',
  styleUrls: ['./knowledge-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeConfigComponent {
  knowledgeSettings: KnowledgeSettings;

  @Input() set knowledge(knowledge: KnowledgeSettings) {
    this.knowledgeSettings = _cloneDeep(knowledge || new KnowledgeSettings());
  }

  @Input() title: string = KnowledgeSettingsLabels.Title;
  @Input() subText: string = KnowledgeSettingsLabels.Description;
  @Input() actionDescription: string = KnowledgeSettingsLabels.Action;

  @Input() headerClass: string;
  @Input() readonly?: boolean;

  @Output() updateKnowledge = new EventEmitter<KnowledgeSettings>();

  update(key: keyof KnowledgeSettings, value: boolean): void {
    this.knowledgeSettings = { ...this.knowledgeSettings, [key]: value };
    this.updateKnowledge.emit(this.knowledgeSettings);
  }
}
