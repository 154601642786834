import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, retry, switchMap, take, tap } from 'rxjs/operators';

import { environment } from '@ninety/ui/web/environments';

import type { Entitlements, EntitlementsToken } from '../../_shared/models/entitlements/entitlements-model';
import { FeatureFlagFacade } from '../../_state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '../../_state/app-entities/feature-flag/feature-flag-state.model';
import { selectIsBillingV3Company } from '../../_state/app-global/company/subscription/subscription-state.selectors';

import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class EntitlementsService {
  private readonly apiBasePath = environment.entitlementsApiOrigin;
  private readonly isEntitlementsFlagEnabled$: Observable<boolean> = this.flags.getFlag(
    FeatureFlagKeys.commercialModelEntitlements
  );
  private readonly EXPIRATION_BUFFER_MINS = 5;
  private readonly entitlementsToken$ = new Subject<string>();
  private isFetchingToken = false;

  constructor(
    private readonly http: HttpClient,
    private readonly flags: FeatureFlagFacade,
    private readonly store: Store,
    private readonly tokenService: TokenService
  ) {}

  clearEntitlementsToken(): void {
    this.tokenService.removeEntitlementsToken();
  }

  fetchEntitlementsToken(authToken = this.tokenService.getAccessToken()): Observable<string> {
    const url = `${this.apiBasePath}/v1/entitlements-token`;
    const headers = this.getHeaders(authToken);
    if (this.isFetchingToken) {
      return this.entitlementsToken$.asObservable().pipe(
        filter(token => token != null),
        take(1)
      );
    }

    this.isFetchingToken = true;
    return this.http.get<string>(url, { headers }).pipe(
      retry(3),
      tap({
        next: entitlementsToken => {
          this.isFetchingToken = false;
          if (entitlementsToken) {
            this.tokenService.setEntitlementsToken(entitlementsToken);
            this.entitlementsToken$.next(entitlementsToken);
          }
        },
        error: () => {
          this.isFetchingToken = false;
          this.entitlementsToken$.next(null);
        },
      }),
      catchError(() => of(''))
    );
  }

  getEntitlementsToken(): string {
    const entitlementsTokenDecoded = this.tokenService.getEntitlementsTokenDecoded();
    const shallExpire = this.tokenService.isExpiredWithinMins(entitlementsTokenDecoded, this.EXPIRATION_BUFFER_MINS);
    if (!entitlementsTokenDecoded || shallExpire) {
      return '';
    }

    return this.tokenService.getEntitlementsToken();
  }

  /**
   * Retrieve current entitlements token if unexpired within the expiration buffer tolerance
   * Otherwise retrieve a new token
   */
  getOrFetchEntitlementsToken(authToken = this.tokenService.getAccessToken()): Observable<string> {
    return this.isEntitlementsEnabled().pipe(
      take(1),
      switchMap(isEntitlementsEnabled => {
        if (!isEntitlementsEnabled) {
          return of('');
        }
        const entitlementsToken = this.getEntitlementsToken();
        if (entitlementsToken) {
          return of(entitlementsToken);
        }
        return this.fetchEntitlementsToken(authToken);
      })
    );
  }

  initEntitlements(authToken = this.tokenService.getAccessToken()): Observable<string> {
    return this.getOrFetchEntitlementsToken(authToken);
  }

  isEntitlementsEnabled(): Observable<boolean> {
    return combineLatest([this.store.select(selectIsBillingV3Company), this.isEntitlementsFlagEnabled$]).pipe(
      map(([isBillingV3Company, entitlementsEnabled]) => isBillingV3Company && entitlementsEnabled)
    );
  }

  parseEntitlementsToken(
    entitlementsToken: EntitlementsToken = this.tokenService.getEntitlementsTokenDecoded()
  ): Entitlements {
    if (!entitlementsToken?.scope) {
      return {
        scopes: [],
        expiration: null,
        companyId: '',
      };
    }

    const scopes = entitlementsToken.scope.split(',').map((scope: string) => {
      const [name, permission] = scope.split(':');
      return { name, permission };
    });

    return {
      scopes,
      expiration: new Date(entitlementsToken.exp * 1000),
      companyId: entitlementsToken.sub,
    };
  }

  private getHeaders(authToken: string): HttpHeaders {
    return new HttpHeaders({
      'content-type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${authToken}`,
    });
  }
}
