import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import ObjectId from 'bson-objectid';

export class NinetyValidators {
  static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { noWhiteSpace: true };
    }

    return null;
  }
  static preventOnlyWhiteSpace(control: FormControl): ValidationErrors | null {
    return !(control.value || '').trim().length ? { onlyWhiteSpace: true } : null;
  }

  static isObjectId(control: AbstractControl): ValidationErrors | null {
    return ObjectId.isValid(control.value) ? null : { isObjectId: true };
  }

  static onlyAlphaNumeric(control: AbstractControl): ValidationErrors | null {
    return /^\w*$/.test(control.value) ? null : { onlyAlphaNumeric: true };
  }

  /** control name must be 'email' */
  static noDuplicateEmailsInFormArray<T extends { [K in keyof T]: AbstractControl } & { email: FormControl<string> }>(
    formArray: FormArray<FormGroup<T>>
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const emails = formArray?.value?.map(group => group.email?.toLowerCase());
      const email = control.value?.trim()?.toLowerCase();
      if (!emails?.length || !email) return null;
      return emails.includes(email) ? { duplicateEmailInForm: true } : null;
    };
  }
}
