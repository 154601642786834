/* eslint-disable @angular-eslint/no-outputs-metadata-property,@angular-eslint/no-output-rename */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { distinctUntilChanged, skip } from 'rxjs';

import {
  TerraAvatarModule,
  TerraInputBoolean,
  // eslint-disable-next-line terra/enforce-terra-module-import
  TerraQuickFilterComponent,
  TerraQuickFilterModule,
  TerraSearchInputModule,
} from '@ninety/terra';
import { ManagerPick } from '@ninety/ui/legacy/state/app-entities/user-list/api/user-list.model';

import { FuseManagerPickDirective } from '../chip-select/services/fuse-manager-pick.directive';
import { ManagedOptions } from '../chip-select/services/fuse-search.service.model';

export type ManagerSelectCandidate = ManagerPick | 'all';

/**
 * User select styled after the "quick filter" component in the ninety design system. Supports fuzzy search and selection of a single user.
 * Based off a pick of the {@link UserListModel} type.
 *
 * @see {@link FuseManagerPickDirective}
 * @see {@link ManagerPick}
 */
@Component({
  selector: 'ninety-user-quick-filter',
  standalone: true,
  imports: [
    CommonModule,
    PushPipe,
    TerraAvatarModule,
    FormsModule,
    LetDirective,
    TerraQuickFilterModule,
    TerraSearchInputModule,
  ],
  templateUrl: './user-quick-filter.component.html',
  styleUrls: ['./user-quick-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: FuseManagerPickDirective,
      inputs: ['ninetyFuseUser: users$', 'ninetyFuseSeatOptions: fuseOptions'],
    },
  ],
})
export class UserQuickFilterComponent<T> implements AfterViewInit {
  @ViewChild(TerraQuickFilterComponent, { static: true }) private _userQuickFilter!: TerraQuickFilterComponent;

  protected readonly trackBy: TrackByFunction<ManagedOptions<ManagerPick>> = (_index, item) => item.value._id;

  @Input() loading = false;
  @Input() key = 'Manager';
  @Input() readonly: TerraInputBoolean;
  @Input() disabled: TerraInputBoolean;
  @Input() selectedUser: T[];

  @Output() selectedUserChange = new EventEmitter<T[]>();

  protected _userSearchInput = '';
  protected _noResultsText = 'No search results found. Please check your spelling or try a different search.';

  constructor(protected readonly fuseProvider: FuseManagerPickDirective, private readonly destroyRef: DestroyRef) {}

  ngAfterViewInit() {
    this.fuseProvider.fuse.managedOptions$
      .pipe(
        distinctUntilChanged(),
        skip(1),
        // The option list is removed from the DOM when there is no data to display. Also, due to the observable nature of the data source,
        // the list might emit while the select is closed.
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this._userQuickFilter.scrollToTop());
  }

  protected search(query: string) {
    this.fuseProvider.fuse.search({ query });
  }

  protected onSelectValue(selectedUser: T) {
    const user = [selectedUser];
    this.selectedUserChange.emit(user);
  }

  protected onOpenChange(isOpen: boolean) {
    if (!isOpen) {
      this.fuseProvider.fuse.search({ query: null });
      this._userSearchInput = '';
    }
  }

  protected _handleSearchTermChanged($event: string) {
    this._userSearchInput = $event;
    this.search(this._userSearchInput);
  }
}
