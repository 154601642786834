import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { map, tap, catchError, of, switchMap, filter, mergeMap, take } from 'rxjs';

import { MeetingType } from '@ninety/ui/legacy/shared/index';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import * as FeatureFlagSelectors from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectCurrentUserTimezone } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { NotificationActions, selectCompanyId, selectLanguage, TeamUserSelectors } from '@ninety/ui/legacy/state/index';
import { getDefaultActionParams } from '@ninety/web/pages/accountability-chart/_state/chart/effects/seat.effects';

import { Cadence, MeetingSchedule } from '../../_models';
import { MeetingsV2Service } from '../../_services/meetings-v2.service';
import {
  DeleteDialogOptions,
  DeleteMeetingScheduleDialogComponent,
  DeleteMeetingScheduleDialogData,
} from '../../meetings-page/meeting-schedules/delete-meeting-schedule-dialog/delete-meeting-schedule-dialog.component';
// eslint-disable-next-line max-len
import { MeetingConfirmationModel } from '../../meetings-page/meeting-schedules/meeting-info-confirmation-dialog/meeting-confirmation.model';
// eslint-disable-next-line max-len
import { MeetingInfoConfirmationDialogComponent } from '../../meetings-page/meeting-schedules/meeting-info-confirmation-dialog/meeting-info-confirmation-dialog.component';
// eslint-disable-next-line max-len
import { ScheduleMeetingDialogComponent } from '../../meetings-page/meeting-schedules/schedule-meeting-dialog/schedule-meeting-dialog.component';
import {
  UpdateDialogOptions,
  UpdateMeetingScheduleDialogComponent,
  UpdateMeetingScheduleDialogData,
} from '../../meetings-page/meeting-schedules/update-meeting-schedule-dialog/update-meeting-schedule-dialog.component';
import { MeetingSchedulingActions } from '../meetings.actions';
import { MeetingsStateSelectors } from '../meetings.selectors';

@Injectable()
export class MeetingSchedulingEffects {
  constructor(private actions$: Actions) {}
  private dialog = inject(MatDialog);
  private store = inject(Store);
  private meetingsV2Service = inject(MeetingsV2Service);

  openScheduleMeetingDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingSchedulingActions.openScheduleMeetingDialog),
        tap(() => {
          this.dialog.open(ScheduleMeetingDialogComponent);
        })
      ),
    { dispatch: false }
  );
  openMeetingInfoConfirmationDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MeetingSchedulingActions.addMeetingScheduleSuccess,
          MeetingSchedulingActions.openMeetingConfirmationDialog
        ),
        concatLatestFrom(() => this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.calendarUrl))),
        filter(([_, enabled]) => !!enabled),
        concatLatestFrom(() => [
          this.store.select(MeetingsStateSelectors.selectSelectedTeamName),
          this.store.select(MeetingsStateSelectors.selectAgendas),
          this.store.select(selectCurrentUserTimezone),
        ]),
        switchMap(([[{ schedule }], teamName, agendas, timezone]) =>
          this.store.select(TeamUserSelectors.selectTeamUsers(schedule.teamId)).pipe(
            take(1),
            tap(users => {
              let agenda;
              if (schedule.meetingType === MeetingType.custom) {
                agenda = agendas.find(agenda => agenda.id === schedule.agendaId);
              } else {
                agenda = agendas.find(agenda => agenda.id === schedule.meetingType);
              }

              if (!agenda) {
                // console.error('Could not find agenda for schedule', schedule);
                return;
              }

              let startDate;
              if ((schedule as MeetingSchedule).scheduledDate !== undefined) {
                startDate = new Date((schedule as MeetingSchedule).scheduledDate);
              } else {
                startDate = new Date(schedule.cadenceStartDate);
              }

              // Duration in seconds. Round up to the nearest 15 minute interval
              const roundedDuration = Math.ceil(agenda.totalDuration / (60 * 15)) * 15;
              const data: MeetingConfirmationModel = {
                meetingTitle: agenda.title,
                meetingStartDate: startDate,
                meetingEndDate: addMinutes(startDate, roundedDuration),
                displayTimezone: formatInTimeZone(startDate, timezone, '(zzz)'),
                teamName,
                cadence: schedule.cadence,
                invitees: users.map(user => user.primaryEmail),
                duration: roundedDuration,
              };

              this.dialog.open(MeetingInfoConfirmationDialogComponent, { data });
            })
          )
        )
      ),
    { dispatch: false }
  );

  addMeetingSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.addMeetingSchedule),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectTeamId),
        this.store.select(selectCompanyId),
      ]),
      switchMap(([{ schedule }, teamId, companyId]) =>
        this.meetingsV2Service.addMeetingSchedule(companyId, { ...schedule, teamId }).pipe(
          map(scheduleResult => MeetingSchedulingActions.addMeetingScheduleSuccess({ schedule: scheduleResult })),
          catchError((error: unknown) =>
            of(MeetingSchedulingActions.addMeetingScheduleFailure({ error, message: 'Add Schedule failed' }))
          )
        )
      )
    )
  );

  addMeetingScheduleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.addMeetingScheduleSuccess),
      concatLatestFrom(() => this.store.select(selectLanguage)),
      map(([_, lang]) =>
        NotificationActions.notifyV2({
          message: `${lang.meeting.item} scheduled`,
          params: getDefaultActionParams(),
        })
      )
    )
  );

  getMeetingSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeetingSchedulingActions.getMeetingSchedules,
        MeetingSchedulingActions.addMeetingScheduleSuccess,
        MeetingSchedulingActions.deleteMeetingScheduleSuccess,
        MeetingSchedulingActions.updateMeetingScheduleSuccess
      ),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectTeamId),
        this.store.select(selectCompanyId),
      ]),
      switchMap(([, teamId, companyId]) =>
        this.meetingsV2Service.getMeetingSchedules({ teamId, companyId }).pipe(
          map(schedules => MeetingSchedulingActions.getMeetingSchedulesSuccess({ schedules })),
          catchError((error: unknown) =>
            of(MeetingSchedulingActions.getMeetingSchedulesFailure({ error, message: 'Get Schedules failed' }))
          )
        )
      )
    )
  );

  getMeetingSchedulesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingSchedulingActions.getMeetingSchedulesSuccess),
        map(({ schedules }) => this.meetingsV2Service.setMeetingSchedules(schedules))
      ),
    { dispatch: false }
  );

  openDeleteMeetingScheduleDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.openDeleteMeetingScheduleDialog),
      concatLatestFrom(() => [this.store.select(selectLanguage)]),
      switchMap(([{ schedule }, language]) =>
        this.dialog
          .open<DeleteMeetingScheduleDialogComponent, DeleteMeetingScheduleDialogData, DeleteDialogOptions>(
            DeleteMeetingScheduleDialogComponent,
            {
              data: {
                meetingLanguage: language.meeting,
                cadence: schedule.cadence,
                isRepeating: schedule.cadence !== Cadence.none,
              },
            }
          )
          .afterClosed()
          .pipe(
            filter(deleteOptions => !!deleteOptions),
            map(deleteOptions => {
              let canceledForDate: Date = null;
              if (deleteOptions.isRepeating && deleteOptions.deleteAll === false) {
                //a specific date when deleting schedule for a specific period
                canceledForDate = new Date(schedule.scheduledDate);
              }
              return MeetingSchedulingActions.deleteMeetingSchedule({
                scheduleId: schedule._id,
                isRepeating: deleteOptions.isRepeating,
                deleteAll: deleteOptions.deleteAll,
                ...(canceledForDate && { canceledForDate }),
              });
            })
          )
      )
    )
  );

  deleteMeetingSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.deleteMeetingSchedule),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectTeamId),
        this.store.select(selectCompanyId),
      ]),
      mergeMap(([{ scheduleId, deleteAll, isRepeating, canceledForDate }, teamId, companyId]) =>
        this.meetingsV2Service
          .deleteMeetingSchedule(scheduleId, {
            teamId,
            companyId,
            deleteAll,
            isRepeating,
            ...(canceledForDate && {
              canceledForDate: new Date(canceledForDate).toISOString(),
            }),
          })
          .pipe(
            map(() => MeetingSchedulingActions.deleteMeetingScheduleSuccess()),
            catchError((error: unknown) =>
              of(MeetingSchedulingActions.deleteMeetingScheduleFailure({ error, message: 'Delete Schedule failed' }))
            )
          )
      )
    )
  );

  notifyOnDeleteMeetingScheduleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.deleteMeetingScheduleSuccess),
      map(() =>
        NotificationActions.notifyV2({
          message: 'Delete successful',
          params: getDefaultActionParams(),
        })
      )
    )
  );

  openUpdateMeetingScheduleDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.openUpdateMeetingScheduleDialog),
      concatLatestFrom(() => [
        this.store.select(selectLanguage),
        this.store.select(MeetingsStateSelectors.selectNonObserverUsersForTeamAndCoaches),
      ]),
      mergeMap(([{ schedule }, language, users]) =>
        this.dialog
          .open<UpdateMeetingScheduleDialogComponent, UpdateMeetingScheduleDialogData, UpdateDialogOptions>(
            UpdateMeetingScheduleDialogComponent,
            {
              maxWidth: '560px',
              data: {
                meetingLanguage: language.meeting,
                schedule,
                users,
              },
            }
          )
          .afterClosed()
          .pipe(
            filter(updateOptions => !!updateOptions),
            map(updateOptions => MeetingSchedulingActions.updateMeetingSchedule({ updateOptions }))
          )
      )
    )
  );

  updateMeetingSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.updateMeetingSchedule),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectTeamId),
        this.store.select(selectCompanyId),
      ]),
      switchMap(([{ updateOptions }, teamId, companyId]) => {
        const { scheduleId, isRepeating, updateAll, update } = updateOptions;

        return this.meetingsV2Service
          .updateMeetingSchedule(scheduleId, update, {
            teamId,
            companyId,
            isRepeating,
            updateAll,
          })
          .pipe(
            map(() => MeetingSchedulingActions.updateMeetingScheduleSuccess()),
            catchError((error: unknown) =>
              of(
                MeetingSchedulingActions.updateMeetingScheduleFailure({
                  error,
                  message: 'Update Schedule failed',
                })
              )
            )
          );
      })
    )
  );

  notifyOnUpdateMeetingScheduleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingSchedulingActions.updateMeetingScheduleSuccess),
      map(() =>
        NotificationActions.notifyV2({
          message: 'Update successful',
          params: getDefaultActionParams(),
        })
      )
    )
  );

  updateMeetingScheduleFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeetingSchedulingActions.updateMeetingScheduleFailure,
        MeetingSchedulingActions.deleteMeetingScheduleFailure,
        MeetingSchedulingActions.getMeetingSchedulesFailure,
        MeetingSchedulingActions.addMeetingScheduleFailure
      ),
      map(({ error, message }) => NotificationActions.notifyError({ error, message }))
    )
  );
}
