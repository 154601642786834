export enum MeetingMessageAction {
  Pause = 'pause',
  Continue = 'continue',
  Finished = 'finished',
  Update = 'update',
  Start = 'start',
  Delete = 'meeting-deleted',
  Suspended = 'meeting-suspended',
  PresenterChanged = 'meeting-presenter-changed',
}
