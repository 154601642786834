export enum RockConstants {
  additionalTeamIds = 'additionalTeamIds',
  allRocks = 'allRocks',
  ascending = 'ascending',
  companyList = 'companyList',
  companyRocks = 'companyRocks',
  completedDate = 'completedDate',
  departmentRocks = 'departmentRocks',
  departmentList = 'departmentList',
  descending = 'descending',
  dueDate = 'dueDate',
  levelCode = 'levelCode',
  rockDictionary = 'rockDictionary',
  rocksArray = 'rocksArray',
  status = 'status',
  statusCode = 'statusCode',
  teamId = 'teamId',
  userId = 'userId',
  noLongerOnTeamRocks = 'noLongerOnTeamRocks',
  userRocks = 'userRocks',
  sharedRocks = 'sharedRocks',
}

export type RockTypes =
  | RockConstants.userRocks
  | RockConstants.companyRocks
  | RockConstants.departmentRocks
  | RockConstants.noLongerOnTeamRocks
  | RockConstants.sharedRocks;
