import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import ObjectId from 'bson-objectid';
import { addDays, format } from 'date-fns';
import { BehaviorSubject, tap } from 'rxjs';

import { TerraFormFieldModule, TerraIconModule, TerraNumberInputModule, TerraSelectModule } from '@ninety/terra';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { MeetingType } from '@ninety/ui/legacy/shared/index';
import { extractValueFromStore, selectCurrentUserId, selectLanguage } from '@ninety/ui/legacy/state/index';

import { TimePeriod, Cadence, CadenceFormOptions, AddMeetingScheduleModel } from '../../../_models';
import { MeetingSchedulingActions } from '../../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../../_state/meetings.selectors';

@Component({
  selector: 'ninety-schedule-meeting-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TerraIconModule,
    LetDirective,
    ButtonComponent,
    MatDialogModule,
    MaterialMdcModule,
    TerraFormFieldModule,
    TerraSelectModule,
    TerraNumberInputModule,
  ],
  templateUrl: './schedule-meeting-dialog.component.html',
  styleUrls: ['./schedule-meeting-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleMeetingDialogComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly dialogRef = inject(MatDialogRef<ScheduleMeetingDialogComponent>);

  form: FormGroup;
  TimePeriod = TimePeriod;
  protected cadence$ = new BehaviorSubject<{ name: string; value: CadenceFormOptions }[]>([
    ...this.getCadenceOptions(new Date()),
  ]);
  minDate = new Date();

  vm$ = {
    language: this.store.select(selectLanguage),
    agendas: this.store.select(MeetingsStateSelectors.selectCombinedAgendaOptionsTiered).pipe(
      tap(agendas => {
        //Note: we default to the first agenda if there is only one
        //happens when on essential tier (for now), only weekly meetings are available
        if (agendas.length === 1) {
          this.form.get('agenda').setValue(agendas[0]);
          this.form.get('agenda').disable();
        }
      })
    ),
    users: this.store.select(MeetingsStateSelectors.selectNonObserverUsersForTeamAndCoaches),
  };

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      agenda: ['', Validators.required],
      date: [new Date(), Validators.required],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      period: [TimePeriod.AM, Validators.required],
      cadence: [CadenceFormOptions.none, Validators.required],
      presenterId: [extractValueFromStore(this.store, selectCurrentUserId), Validators.required],
      scribeId: [],
    });
  }

  dateChanged(date: Date) {
    this.cadence$.next(this.getCadenceOptions(date));
    this.form.patchValue({ cadence: CadenceFormOptions.none });
  }

  save() {
    this.store.dispatch(MeetingSchedulingActions.addMeetingSchedule(this.formToAddMeetingModel()));
    this.dialogRef.close();
  }

  formToAddMeetingModel(): {
    schedule: Omit<AddMeetingScheduleModel, 'teamId'>;
  } {
    const { agenda, date, cadence, hours, minutes, period, presenterId, scribeId } = this.form.getRawValue();

    const meetingType = ObjectId.isValid(agenda.id) ? MeetingType.custom : agenda.id;
    const agendaId = ObjectId.isValid(agenda.id) ? agenda.id : null;

    const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const parsedHours = Number(hours);
    const adjustedHours =
      period === TimePeriod.PM ? (parsedHours === 12 ? 12 : parsedHours + 12) : parsedHours === 12 ? 0 : parsedHours;
    const dateTime = new Date(dateWithoutTime.setHours(adjustedHours, minutes));

    return {
      schedule: {
        meetingType,
        agendaId,
        presenterId,
        scribeId: scribeId || null,
        cadence: cadence === CadenceFormOptions.monthlyLastWeek ? Cadence.monthly : cadence,
        lastWeekOfMonth: cadence === CadenceFormOptions.monthlyLastWeek,
        cadenceStartDate: dateTime,
        parentId: null,
      },
    };
  }

  getCadenceOptions(date: Date): { name: string; value: CadenceFormOptions }[] {
    const cadenceOptions = [
      { name: 'does not repeat', value: CadenceFormOptions.none },
      { name: `Weekly on ${format(date, 'EEEE')}`, value: CadenceFormOptions.weekly },
      { name: `Every 2 weeks on ${format(date, 'EEEE')}`, value: CadenceFormOptions.biWeekly },
    ];

    const plus7 = addDays(date, 7);
    const isLastNthDayOfMonth = plus7.getMonth() !== date.getMonth();
    const dayNum = date.getDate();
    const nthWeekOfTheMonth = Math.floor((dayNum - 1) / 7) + 1;

    if (nthWeekOfTheMonth === 4 && isLastNthDayOfMonth) {
      return [
        ...cadenceOptions,
        { name: `Monthly on the fourth ${format(date, 'EEEE')}`, value: CadenceFormOptions.monthly },
        { name: `Monthly on the last ${format(date, 'EEEE')}`, value: CadenceFormOptions.monthlyLastWeek },
      ];
    }

    if (nthWeekOfTheMonth === 5) {
      return [
        ...cadenceOptions,
        { name: `Monthly on the last ${format(date, 'EEEE')}`, value: CadenceFormOptions.monthlyLastWeek },
      ];
    }

    const ordinals = ['first', 'second', 'third', 'fourth', 'fifth'];
    return [
      ...cadenceOptions,
      {
        name: `Monthly on the ${ordinals[Math.floor((dayNum - 1) / 7)]} ${format(date, 'EEEE')}`,
        value: CadenceFormOptions.monthly,
      },
    ];
  }
}
