<ng-container
  *ngrxLet="{
    companyUserList: companyUserList$,
    currentUserId: currentUserId$
  } as vm">
  <ninety-card class="ninety-card">
    <ninety-card-header header>
      <div class="terra-title">Add Helpful to New Company</div>
      <terra-form-field>
        <terra-text-input
          [formControl]="companyToFindForm"
          placeholder="Enter company ID"
          (keydown.enter)="findCompanyById()">
          <terra-input-prefix class="search-icon">
            <terra-icon icon="search" />
          </terra-input-prefix>
        </terra-text-input>
        <terra-error *ngIf="companyToFindForm.errors?.isObjectId">Please enter a valid object id</terra-error>
      </terra-form-field>
    </ninety-card-header>

    <div *ngIf="foundCompany" class="card-content">
      <div class="found-company">
        <img
          [src]="foundCompany.logoUrl"
          alt="company logo"
          *ngIf="foundCompany.logoUrl"
          class="company-logo"
          loading="lazy" />

        <div class="flex1">
          {{ foundCompany.companyName }}
        </div>

        <ninety-alert *ngIf="alreadyInCompany" alertClass="warning"> Helpful is already in this company </ninety-alert>

        <ng-container *ngIf="!alreadyInCompany">
          <ninety-button filled primary *ngIf="!newCompanyUser" class="uppercase" (click)="addHelpful()">
            <terra-icon icon="add" />
            Add Helpful to this Company
          </ninety-button>
          <ninety-button
            filled
            primary
            *ngIf="newCompanyUser"
            class="uppercase"
            (click)="switchCompany(newCompanyUser)">
            <terra-icon icon="sign-in" />
            Login
          </ninety-button>
        </ng-container>
      </div>
    </div>
  </ninety-card>

  <ninety-card class="ninety-card">
    <ninety-card-header header>
      <div class="terra-title">Helpful Companies</div>
      <terra-search-input
        [class.hidden]="vm.companyUserList?.length <= 10"
        [formControl]="companyFilterSearch"
        placeholder="Search current companies by name or ID"
        (keyup.enter)="findCompanyById()" />
    </ninety-card-header>

    <div class="card-content">
      <mat-list class="ninety-list">
        <mat-list-item
          *ngFor="let c of vm.companyUserList | searchCompanyFilter : companyFilterSearch.value; trackBy: trackById">
          <div matListItemLine class="list-item">
            <div class="company-name">
              {{ c.companyName }}
            </div>
            <img [src]="c?.logoUrl" alt="company logo" *ngIf="c?.logoUrl" class="company-logo" loading="lazy" />
            <ninety-button
              flat
              primary
              (click)="switchCompany(c)"
              [disabled]="c.userId === vm.currentUserId"
              ninetyTooltip="Login to this company">
              <terra-icon icon="sign-in" />
              Login
            </ninety-button>
            <ninety-button
              flat
              icon
              negative
              class="delete-btn"
              (click)="removeHelpfulFromCompany(c)"
              [disabled]="c.userId === vm.currentUserId"
              ninetyTooltip="Remove Helpful from this company">
              <terra-icon icon="delete" />
            </ninety-button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </ninety-card>
</ng-container>
