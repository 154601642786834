<ninety-card class="add-teammates-dialog">
  <div class="card-header">
    <div class="terra-title-small">Add Teammates</div>
    <ninety-button flat compact (click)="close()"><terra-icon icon="x" size="24" /></ninety-button>
  </div>

  <div class="licenses-info" *ngIf="isPaidPlan$ | ngrxPush">
    <ninety-chip color="brand" *ngrxLet="counts$ as counts">
      {{ counts?.activeBillingUsers }} of {{ counts?.seatsPaidFor ? counts.seatsPaidFor : 0 }} licenses assigned
    </ninety-chip>
    <div>
      Assigning paid roles beyond the number of available licenses will increase your subscription cost, and the
      prorated difference will be charged immediately. To manage licenses,
      <a (click)="goToBilling()">visit the Billing page.</a>
    </div>
  </div>

  <div class="bulk-import-button-container">
    <ninety-button
      class="bulk-import-button"
      flat
      primary
      compact
      (click)="goToDataImport()"
      *ngIf="currentUserIsAdminOrOwner$ | ngrxPush">
      <terra-icon icon="upload" size="20" />
      Bulk import via CSV
    </ninety-button>
  </div>

  <div class="dialog-body" #addTeammatesDialogBody>
    <div class="forms-wrapper">
      <div class="form" *ngFor="let userForm of addTeammatesFormArray.controls; let i = index" [formGroup]="userForm">
        <div class="form-header">
          <ninety-button flat compact (click)="removeForm(i)"><terra-icon icon="x" size="20" /></ninety-button>
        </div>
        <terra-form-field>
          <terra-form-label>Role access</terra-form-label>
          <terra-select placeholder="Select a role" formControlName="role" [compareWith]="_compareRole">
            <ng-container *ngFor="let role of roles$ | ngrxPush">
              <ng-container *ngrxLet="inactiveAsRole$ as inactiveAsRole">
                <terra-divider
                  *ngIf="(inactiveAsRole && !role.active) || (!inactiveAsRole && role.isImplementer)"></terra-divider>
              </ng-container>
              <terra-option [disabled]="role.isItemDisabled || role.cannotAssign" [value]="role">
                {{ role.title }}
                <span *terraOptionSuffix>{{ role.subtitle }}</span>
                <terra-description>{{ role.description }}</terra-description>
              </terra-option>
            </ng-container>
          </terra-select>
        </terra-form-field>

        <div class="input-row">
          <ng-container *ngIf="userForm.controls.role.value?.active === false">
            <terra-form-field>
              <terra-form-label>First name</terra-form-label>
              <terra-text-input formControlName="firstName" placeholder="John" />
            </terra-form-field>
            <terra-form-field>
              <terra-form-label>Last name</terra-form-label>
              <terra-text-input formControlName="lastName" placeholder="Ninety" />
            </terra-form-field>
          </ng-container>

          <terra-form-field [optional]="userForm.controls.role.value?.active === false">
            <terra-form-label>Email</terra-form-label>
            <terra-text-input formControlName="email" placeholder="example@gmail.com" />
            <terra-error *ngIf="userForm.controls.email.errors?.emailTaken">
              This email is already associated with an existing user.
            </terra-error>
            <terra-error *ngIf="userForm.controls.email.errors?.duplicateEmailInForm">
              You cannot create users with the same email.
            </terra-error>
          </terra-form-field>

          <terra-form-field
            *ngIf="
              !userForm.controls.role.value ||
              (userForm.controls.role.value?.active && !userForm.controls.role.value?.isImplementer)
            ">
            <terra-form-label>Team(s)</terra-form-label>
            <terra-select
              formControlName="teamIds"
              *ngrxLet="teams$ as teams"
              placeholder="Team(s)"
              multiple="true"
              maxHeight="350"
              maxWidth="300">
              <terra-select-search *ngIf="teams.length > 5">
                <terra-search-input
                  [(ngModel)]="_teamSearchInput"
                  (ngModelChange)="_search(teams, $event)"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Search teams"
                  name="team-search"
                  aria-label="team search" />
              </terra-select-search>
              <terra-description *ngIf="_teamSearchInput && _teamSearchResults.length === 0">
                No search results found. Please check your spelling or try a different search.
              </terra-description>
              <terra-option
                *ngFor="let option of teams | terraLazyOptions : '_id' : { findObjectByProperty: true }"
                [attr.data-option-name]="option.name"
                [style.display]="option | addTeammatesDialogShouldShowOption : _teamSearchInput : _teamSearchResults"
                [value]="option._id">
                {{ option.name }}
              </terra-option>
            </terra-select>
          </terra-form-field>
        </div>

        <div class="send-invite-row" *ngIf="!userForm.controls.role.value || userForm.controls.role.value?.active">
          <terra-checkbox formControlName="shouldInvite">Send invite email</terra-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="add-another-user-row">
    <ninety-button flat primary (click)="addAnotherTeammate()">
      <terra-icon icon="add" size="20" />
      Add another user
    </ninety-button>
  </div>

  <ninety-card-footer>
    <ninety-button filled primary (click)="save()" [disabled]="addTeammatesFormArray.invalid">
      Add teammates
    </ninety-button>
  </ninety-card-footer>
</ninety-card>
