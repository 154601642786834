import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { Score } from '@ninety/ui/legacy/shared/models/scorecard/score';

import { ScorecardRow } from '../../models/scorecard-row.model';

export const ScorecardPageActions = createActionGroup({
  source: 'Scorecard Page',
  events: {
    Init: emptyProps(),
    Destroy: emptyProps(),

    /**
     * This action triggers an effect to perform a navigation updating the :scorecardId route param.
     */
    'Navigate To Scorecard By Id': props<{ scorecardId: string }>(),

    /**
     * This action is used to update the Ngrx state for the current scorecardId. There is an effect that listens for
     * route param changes to :scorecardId, that effect then dispatches this action.
     */
    'Set Scorecard Id': props<{ scorecardId: string }>(),

    'Fetch By Team Id': props<{ teamId: string }>(),

    'Open Universal Create Dialog': props<{
      kpiGroupId: string;
      kpiId: string;
      itemType: Extract<ItemType, ItemType.issue | ItemType.todo>;
    }>(),

    'Open Universal Create Dialog Multiple Kpis': props<{
      kpiGroupId: string;
      kpiIds: string[];
      itemType: Extract<ItemType, ItemType.issue | ItemType.todo>;
    }>(),

    'Open Universal Create Dialog Custom Goal': props<{
      score: Score;
      kpiId: string;
      columnLabel: string;
      itemType: ItemType.issue;
    }>(),

    'Open Kpi Future Goal Dialog': props<{ kpiGroupId: string; kpiId: string }>(),
    'Open Custom Goal Dialog': props<{
      score: Score;
      row: ScorecardRow;
      columnLabel: string;
      kpiGroupId: string;
      kpiId: string;
    }>(),

    'Open Line Chart Dialog': props<{
      row: ScorecardRow;
      kpiId: string;
      kpiGroupId: string;
    }>(),
  },
});
