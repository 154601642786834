<div #completionCheckbox class="terra-completion-checkbox">
  <input
    #completionCheckboxInput
    [id]="_completionCheckboxId"
    class="terra-completion-checkbox__input"
    type="checkbox"
    [disabled]="disabled"
    [tabIndex]="disabled ? -1 : _tabIndex"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby || _completionCheckboxId"
    (blur)="blur()" />
  <span class="terra-completion-checkbox__checkmark" (click)="toggle()">
    <terra-icon
      class="terra-completion-checkbox__checkmark-icon"
      [ngClass]="{ 'terra-completion-checkbox__checkmark--checked': _checked }"
      icon="check"
      size="16"
      variant="bold" />
  </span>
</div>
