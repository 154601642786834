import type { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { NewUserDto } from '@ninety/ui/legacy/shared/components/add-teammates-dialog/models/new-user.dto';

import { AddTeammateFormData } from '../../../_shared/components/add-teammates-dialog/models/add-teammate-form';
import { User, UserTutorials } from '../../../_shared/models/_shared/user';
import { UserTutorialType } from '../../../_shared/models/_shared/user-tutorial-type';
import type { InviteUserPayload } from '../../../_shared/models/directory/invite-user-payload';
import { Theme } from '../../../_shared/models/enums/theme';
import { UserNotificationSettings } from '../../../_shared/models/notifications/event-notification.models';

export const UsersStateActions = createActionGroup({
  source: 'Users',
  events: {
    'Set Current User': props<{ userId: string }>(),
    'Load All Users': props<{ users: User[] }>(),
    'Add One': props<{ user: User }>(),
    'Add Many': props<{ users: User[] }>(),
    'Remove One': props<{ _id: string }>(),
    'Update One': props<{ _id: string; changes: Partial<User> }>(),
    'Update Many': props<{ updates: Update<User>[] }>(),
    'Set Avatar url': props<{ _id: string; url: string }>(),
    'Update Sms': props<{ _id; countryCode: string; number: string }>(),
    'Check To Show TimeZone Update Dialog': emptyProps(),

    // API Actions
    Update: props<{ userId: string; update: Partial<User> }>(),
    'Update Success': props<{ _id: string; changes: Partial<User> }>(),
    'Update Failed': props<{ error: unknown }>(),
    'Delete User': props<{ _id: string }>(),
    'Delete User Success': props<{ _id: string }>(),
    'Delete User Failed': props<{ error: unknown }>(),
    'Deactivate User': props<{ _id: string }>(),
    'Deactivate User Success': props<{ _id: string }>(),
    'Deactivate User Failed': props<{ error: unknown }>(),
    'Update Role': props<{ userId: string; update: Partial<User> }>(),
    'Update Role Success': props<{ _id: string; changes: Partial<User> }>(),
    'Update Role Failed': props<{ _id: string; error: unknown }>(),
  },
});

export const AddTeammatesActions = createActionGroup({
  source: 'Add Teammates Dialog',
  events: {
    'Open Add Teammates Dialog': emptyProps(),
    'Open Unsaved Changes Dialog': emptyProps(),
    'Close Add Teammates Dialog': emptyProps(),
    'Add Teammates': props<{ userForms: AddTeammateFormData[] }>(),
    'Check For Helpfuls Before Adding Teammates': props<{
      userForms: AddTeammateFormData[];
      helpfulsRecord: Record<string, boolean>;
    }>(),
    'Add Teammates To Paid Company': props<{ userForms: AddTeammateFormData[] }>(),
    'Change License Quantity': props<{ quantity: number }>(),
    'Check For Saved Forms': emptyProps(),
    'Create Users': props<{ userForms: AddTeammateFormData[] }>(),
    'Create Users Success': props<{ newUserDtos: NewUserDto[] }>(),
    'Create Users Failed': props<{ error: unknown }>(),
  },
});

export const UserInviteActions = createActionGroup({
  source: 'Invite User Modal',
  events: {
    'Invite User': props<{ user: User }>(),
  },
});

export const InviteUsersActions = createActionGroup({
  source: 'Invite Users',
  events: {
    'Invite user to company': props<{
      invitedUser: InviteUserPayload;
      sendInvite: boolean;
      existingDirectoryUser: boolean;
      previouslyInvited: boolean;
    }>(),
  },
});

export const UserPreferencesActions = createActionGroup({
  source: 'User Preferences',
  events: {
    'Update Theme': props<{ theme: Theme }>(),
    'Update Event notification': props<{ data: UserNotificationSettings[] }>(),
    'Theme Saved': props<{ theme: Theme }>(),
    'Hide User Tutorial': props<{ userTutorialType: UserTutorialType }>(),
    'User Tutorials Updated': props<{ tutorialsHidden: UserTutorials }>(),
  },
});

export const UserSettingsActions = createActionGroup({
  source: 'User Settings',
  events: {
    'Update Timezone': props<{ user: User }>(),
    'Google Task Integration Toggled': props<{ enabled: boolean }>(),
    'Microsoft Task Integration Toggled': props<{ enabled: boolean }>(),
    'Microsoft Task Integration Task List Changed': emptyProps(),
  },
});

export const DirectoryProfileCardActions = createActionGroup({
  source: 'DirectoryProfileCardComponent',
  events: {
    'User Deleted': props<{ _id: string; changes: Partial<User> }>(),
  },
});
