import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';

export class KnowledgeSettings {
  hideDefaultModules: boolean;

  constructor(bos?: BusinessOperatingSystem) {
    Object.assign(this, DEFAULT_KNOWLEDGE_SETTINGS[bos || BusinessOperatingSystem.ninetyOS]);
  }
}

export const DEFAULT_KNOWLEDGE_SETTINGS = {
  [BusinessOperatingSystem.ninetyOS]: { hideDefaultModules: false },
  [BusinessOperatingSystem.eos]: { hideDefaultModules: false },
  [BusinessOperatingSystem.pinnacle]: { hideDefaultModules: false },
  [BusinessOperatingSystem.fireproof]: { hideDefaultModules: false },
  [BusinessOperatingSystem.empire]: { hideDefaultModules: false },
};
