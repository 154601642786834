import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraCheckboxModule } from './components/checkbox/terra-checkbox/terra-checkbox.module';
import { DummyComponent } from './components/dummy/dummy.component';
import { TerraFormFieldModule } from './components/forms/terra-form-field/terra-form-field.module';
import { TerraMultilineInputModule } from './components/inputs/terra-multiline-input/terra-multiline-input.module';
import { TerraNumberInputModule } from './components/inputs/terra-number-input/terra-number-input.module';
import { TerraPasswordInputModule } from './components/inputs/terra-password-input/terra-password-input.module';
import { TerraSearchInputModule } from './components/inputs/terra-search-input/terra-search-input.module';
import { TerraTextInputModule } from './components/inputs/terra-text-input/terra-text-input.module';
import { TerraRadioButtonModule } from './components/radio/terra-radio-button/terra-radio-button.module';
import { TerraAvatarModule } from './components/terra-avatar/terra-avatar.module';
import { TerraBadgeModule } from './components/terra-badge/terra-badge.module';
import { TerraCompletionCheckboxModule } from './components/terra-completion-checkbox/terra-completion-checkbox.module';
import { TerraCounterModule } from './components/terra-counter/terra-counter.module';
import { TerraDividerModule } from './components/terra-divider/terra-divider.module';
import { TerraIconModule } from './components/terra-icon/terra-icon.module';
import { TerraOptgroupModule } from './components/terra-optgroup/terra-optgroup.module';
import { TerraOptionModule } from './components/terra-option/terra-option.module';
import { TerraQuickFilterModule } from './components/terra-quick-filter/terra-quick-filter.module';
import { TerraSelectModule } from './components/terra-select/terra-select.module';
import { TerraSwitchModule } from './components/terra-switch/terra-switch.module';
import { TerraBooleanAttributeModule } from './pipes/boolean-attribute/boolean-attribute.module';

@NgModule({
  imports: [
    CommonModule,
    TerraDividerModule,
    TerraCounterModule,
    TerraIconModule,
    TerraSwitchModule,
    TerraBooleanAttributeModule,
    TerraSelectModule,
    TerraCheckboxModule,
    TerraOptionModule,
    TerraAvatarModule,
    TerraTextInputModule,
    TerraBadgeModule,
    TerraSearchInputModule,
    TerraFormFieldModule,
    TerraQuickFilterModule,
    TerraOptgroupModule,
    TerraPasswordInputModule,
    TerraCompletionCheckboxModule,
    TerraMultilineInputModule,
    TerraRadioButtonModule,
    TerraNumberInputModule,
  ],
  declarations: [DummyComponent],
  exports: [
    TerraNumberInputModule,
    TerraRadioButtonModule,
    TerraMultilineInputModule,
    TerraCompletionCheckboxModule,
    TerraPasswordInputModule,
    TerraOptgroupModule,
    TerraQuickFilterModule,
    TerraFormFieldModule,
    TerraSearchInputModule,
    TerraBadgeModule,
    TerraTextInputModule,
    TerraAvatarModule,
    TerraOptionModule,
    TerraCheckboxModule,
    TerraSelectModule,
    TerraSwitchModule,
    TerraIconModule,
    TerraCounterModule,
    TerraDividerModule,
    DummyComponent,
    TerraBooleanAttributeModule,
  ],
})
export class TerraModule {}
