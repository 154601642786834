import { CssColor } from 'ag-charts-enterprise';

export class DiscoColor {
  readonly r: number;
  readonly g: number;
  readonly b: number;
  readonly a: number;

  private constructor(r: number, g: number, b: number, a = 255) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a; // TODO it makes no sense to store alpha as a number between 0 and 255. Prefer as a percent.
  }

  static fromHexString(hex: string): DiscoColor {
    if (hex.startsWith('#')) hex = hex.slice(1);

    if (hex.length === 6 || hex.length === 8) {
      const r = parseInt(hex.slice(0, 2), 16);
      const g = parseInt(hex.slice(2, 4), 16);
      const b = parseInt(hex.slice(4, 6), 16);
      const a = hex.length === 8 ? parseInt(hex.slice(6, 8), 16) : 255;
      return new DiscoColor(r, g, b, a);
    } else {
      throw new Error('Invalid hex color');
    }
  }

  static fromRGBA(r: number, g: number, b: number, a = 255): DiscoColor {
    return new DiscoColor(r, g, b, a);
  }

  static fromRGBString(rgb: string): DiscoColor {
    const match = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);
    if (match) {
      const r = parseInt(match[1]);
      const g = parseInt(match[2]);
      const b = parseInt(match[3]);
      const a = match[4] ? parseInt(match[4]) : 255;
      return new DiscoColor(r, g, b, a);
    } else {
      throw new Error('Invalid RGB or RGBA string');
    }
  }

  isFullOpacity(): boolean {
    return this.a === 255;
  }

  /** Creates a new color with the same RGB values but with the given opacity. Expects opacity to be a percent between 0 and 1. */
  withOpacityPercent(opacity: number): DiscoColor {
    if (opacity < 0 || opacity > 1) throw new Error('Opacity must be between 0 and 1');
    opacity = Math.round(opacity * 255);
    return new DiscoColor(this.r, this.g, this.b, opacity);
  }

  toHexString(): string {
    const toHex = (n: number) => n.toString(16).toUpperCase().padStart(2, '0');

    const rHex = toHex(this.r);
    const gHex = toHex(this.g);
    const bHex = toHex(this.b);
    const aHex = this.a < 255 ? toHex(this.a) : '';

    return `#${rHex}${gHex}${bHex}${aHex}`;
  }

  toRGBAString(): string {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a / 255})`;
  }

  toAgChartsCssColor(): CssColor {
    return this.toHexString();
  }

  equals(other: DiscoColor): boolean {
    if (!other) return false;
    if (this.r !== other.r) return false;
    if (this.g !== other.g) return false;
    if (this.b !== other.b) return false;
    if (this.a !== other.a) return false;
    return true;
  }

  toString(): string {
    return this.toRGBAString();
  }
}
