<div #formGroup class="terra-checkbox-group">
  <div class="terra-checkbox-group__label">
    <ng-content select="terra-form-label"></ng-content>
  </div>
  <div class="terra-checkbox-group__content" [ngClass]="{ 'terra-checkbox-group__content--compact': compact }">
    <ng-content select="terra-checkbox"></ng-content>
  </div>
  <div class="terra-checkbox-group__context">
    <ng-content select="terra-description"></ng-content>
    <ng-container *ngIf="_isAnyFormControlInvalid()">
      <ng-content select="terra-error"></ng-content>
    </ng-container>
  </div>
</div>
