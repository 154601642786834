import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, catchError, finalize, map, tap } from 'rxjs';

import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { KpiManagerApiService } from '@ninety/ui/legacy/core/services/kpi-manager-api.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { PeriodMeasurable } from '@ninety/ui/legacy/shared/models/_shared/team-measurables';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { MeasurableChangeMessage } from '@ninety/ui/legacy/shared/models/meetings/measurable-change-message';
import { RefreshMessage } from '@ninety/ui/legacy/shared/models/meetings/refresh-message';
import { ScoreChangeMessage } from '@ninety/ui/legacy/shared/models/meetings/score-change-message';
import { LegacyGoal } from '@ninety/ui/legacy/shared/models/scorecard/legacy-goal';
import { LegacyPeriodInterval } from '@ninety/ui/legacy/shared/models/scorecard/legacy-period-interval.enum';
import { LegacyScorecard } from '@ninety/ui/legacy/shared/models/scorecard/legacy-scorecard';
import { Measurable, MeasurableCollection } from '@ninety/ui/legacy/shared/models/scorecard/measurable';
import { PeriodIntervalScorecard } from '@ninety/ui/legacy/shared/models/scorecard/period-interval-scorecard';
import { TeamSelectors } from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

import { KpiActions } from '../_state/actions/kpi.action';
import { MeasurableActions } from '../_state/actions/measurables.actions';

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class MeasurableService {
  private api = '/api/v4/MeasurablesV3';

  // TODO: Figure out if this can be removed
  defaultImageSrc = 'assets/icons/ninety/90_Logo_Square_Margins_Black.svg';
  refresh$ = new Subject<string>();
  openUniversalCreate$ = new Subject<{ measurable: Measurable; itemType: ItemType }>();
  removeMeasurable$ = new Subject<string>();

  newMeasurable$ = new Subject<MeasurableChangeMessage>();
  updatedMeasurable$ = new Subject<MeasurableChangeMessage>();
  deletedMeasurable$ = new Subject<MeasurableChangeMessage>();
  updatedScore$ = new Subject<ScoreChangeMessage>();

  refreshScorecard$ = new Subject<RefreshMessage>();

  constructor(
    private errorService: ErrorService,
    private http: HttpClient,
    private kpiManagerApiService: KpiManagerApiService,
    private spinnerService: SpinnerService,
    private stateService: StateService,
    private store: Store
  ) {}

  update(id: string, update: Partial<Measurable>): Observable<void> {
    this.refresh$.next(id);
    return this.http
      .patch<void>(`${this.api}/${id}`, update)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update ${this.stateService.language.measurable.item}. Please try again.`
          )
        )
      );
  }

  updateDefaultGoalForFuturePeriods(id: string, defaultGoal: LegacyGoal): Observable<void> {
    return this.http.patch<void>(`${this.api}/UpdateDefaultGoalForFuturePeriods/${id}`, defaultGoal).pipe(
      tap(() => this.store.dispatch(KpiActions.setFutureGoalSuccess({ kpiId: id, defaultGoal }))),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not update default goal for ${this.stateService.language.measurable.item}. Please try again.`
        )
      )
    );
  }

  createMeasurable(measurable: Measurable): Observable<Measurable> {
    return this.http.post<string>(`${this.api}`, measurable).pipe(
      map(mId => ({ ...measurable, _id: mId })),
      tap(m => this.store.dispatch(MeasurableActions.createMeasurable({ measurable: m }))),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not create ${this.stateService.language.measurable.item}. Please try again.`)
      )
    );
  }

  getUserScorecard(numPeriods = 13): Observable<LegacyScorecard> {
    const periodInterval =
      this.stateService.currentUser.settings.myMeasurablesPeriodInterval || this.stateService.periodInterval;
    return this.http
      .get<LegacyScorecard>(`${this.api}/UserScorecard?periodInterval=${periodInterval}&numPeriods=${numPeriods}`)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get your ${this.stateService.language.scorecard.item}. Please try refreshing the page.`
          )
        )
      );
  }

  getFullScorecardForConversation(
    conversationId: string,
    startDate?: Date,
    endDate?: Date
  ): Observable<PeriodIntervalScorecard> {
    const params = QueryParamsService.build({ startDate, endDate }, true);
    return this.http
      .get<PeriodIntervalScorecard>(`${this.api}/FullScorecardForConversation/${conversationId}`, { params })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.scorecard.item} for this ${this.stateService.language.feedback.item}.
            Please try refreshing the page.`
          )
        )
      );
  }

  updateUserMeasurablesOrder(
    measurables: Measurable[],
    periodInterval = this.stateService.currentUser.settings.myMeasurablesPeriodInterval
  ): void {
    const periodMeasurables: PeriodMeasurable[] = measurables.map((m, i) => ({ measurableId: m._id, ordinal: i }));
    this.http
      .patch<void>(`${this.api}/User?periodInterval=${periodInterval}`, { periodMeasurables })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update ${this.stateService.language.my90.route}
            ${this.stateService.language.measurable.items} order. Please try again.`
          )
        )
      )
      .subscribe();
  }

  getMeasurables(
    measurableIds: string[],
    page: number,
    pageSize: number,
    searchTerm: string,
    periodInterval?: LegacyPeriodInterval
  ): Observable<MeasurableCollection> {
    // Prevents ExpressionChangedAfterItHasBeenCheckedError when used from v2 AddKpiDialog
    // TODO this service sholud not own the spinner, it should be handled by where it is used
    setTimeout(() => this.spinnerService.start());

    return this.http
      .post<MeasurableCollection>(`${this.api}/Paged`, {
        measurableIds: measurableIds,
        page,
        pageSize,
        periodInterval,
        searchTerm,
      })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not get ${this.stateService.language.measurable.items}. Please try again.`)
        ),
        finalize(() => setTimeout(() => this.spinnerService.stop()))
      );
  }

  deleteMeasurable(measurableId: string): Observable<void> {
    const request = this.kpiManagerApiService
      .bulkDeleteKpisById({
        companyId: this.stateService.companyId,
        kpiIds: [measurableId],
      })
      .pipe(
        tap(() => {
          this.removeMeasurable$.next(measurableId);
        }),
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not delete ${this.stateService.language.measurable.item}. Please try again.`
          )
        )
      );

    return this.spinnerService.spinWhile(request);
  }
}
