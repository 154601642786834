<div class="linked-existing-items" *ngrxLet="store.vm$ as vm">
  <mat-spinner *ngIf="vm.startSpinner" class="linked-existing-items__spinner"></mat-spinner>
  <mat-toolbar class="linked-existing-items__header">
    <div class="linked-existing-items__header-type">
      <h2 class="linked-existing-items__header-title" data-cy="">Link to Existing Items</h2>
      <p class="linked-existing-items__header-description">
        <span class="bold">Link to {{ vm.fromLinkedItemName }}: </span> {{ vm.fromLinkedItemTitle }}
      </p>
    </div>

    <div class="linked-existing-items__header-controls">
      <button
        name="close"
        data-cy="linked_existing-items-dialog__header-controls__close"
        mat-button
        [disableRipple]="true"
        class="linked-existing-items__header-controls__close"
        (click)="dialogRef.close()"
        title="Close">
        <terra-icon icon="x" aria-activedescendant="" />
      </button>
    </div>
  </mat-toolbar>
  <mat-dialog-content class="linked-existing-items__content">
    <div class="linked-existing-items__list-item-container__header">
      <div class="linked-existing-items__list-item-container__header__title">
        <terra-search-input
          [ngModel]="vm.searchText"
          placeholder="Search for {{ vm.entity.name }} on your team"
          (ngModelChange)="store.handleSearchTextInput($event)"></terra-search-input>
        <div>
          <span class="bold">{{ vm.entity.name }} in </span> {{ vm.teamName }}
        </div>
      </div>
    </div>
    <mat-tab-group
      preserveContent="true"
      animationDuration="0"
      fitInkBarToContent
      [disableRipple]="true"
      (selectedIndexChange)="this.store.onSelectedIndexChanged($event)">
      <ng-container
        *ngFor="let entity of vm.entities"
        [ngTemplateOutlet]="tabContentTemplate"
        [ngTemplateOutletContext]="{ entity: entity | async }">
      </ng-container>

      <ng-template #tabContentTemplate let-entity="entity">
        <mat-tab [label]="entity.tabName">
          <ng-template matTabContent>
            <div class="linked-existing-items__list-item-container">
              <div class="linked-existing-items__list" *ngIf="entity.items.length > 0; else noItems">
                <div class="linked-existing-items__list__item-content" *ngFor="let item of entity.items; let i = index">
                  <div
                    class="linked-existing-items__list__item-content__item"
                    *ngIf="i + 1 === entity.items.length"
                    [ninetyNotifyIntersect]="true"
                    [threshold]="0.5"
                    (isIntersecting)="fetchMore($event)">
                    <div class="linked-existing-items__list__item-content__item__left-column-item">
                      <terra-icon [icon]="entity.iconKey" [variant]="entity.iconVariant" />
                      <div class="linked-existing-items__list__item-content__item__left-column-item__title">
                        {{ entity.type === 'goal' ? item.description : item.title }}
                      </div>
                    </div>
                    <terra-checkbox
                      [ngModel]="item.selected"
                      (change)="store.toggleItemSelection({ checked: $event, type: entity.type, index: i })" />
                  </div>
                  <div class="linked-existing-items__list__item-content__item" *ngIf="i + 1 !== entity.items.length">
                    <div class="linked-existing-items__list__item-content__item__left-column-item">
                      <terra-icon [icon]="entity.iconKey" [variant]="entity.iconVariant" />
                      <div class="linked-existing-items__list__item-content__item__left-column-item__title">
                        {{ entity.type === 'goal' ? item.description : item.title }}
                      </div>
                    </div>
                    <terra-checkbox
                      [ngModel]="item.selected"
                      (change)="store.toggleItemSelection({ checked: $event, type: entity.type, index: i })" />
                  </div>
                </div>
              </div>
              <ng-template #noItems>
                <div class="linked-existing-items__list__no-items">
                  <ninety-alert alertClass="secondary">
                    There are no {{ entity.name }} found with your team {{ vm.teamName }}
                  </ninety-alert>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </mat-tab>
      </ng-template>
    </mat-tab-group>
    <terra-divider height="tiny" />
  </mat-dialog-content>
  <mat-dialog-actions class="linked-existing-items__actions">
    <span class="linked-existing-items__actions__selected-count">
      {{ vm.totalCount }} {{ vm.totalCount > 1 ? 'items' : 'item' }}
    </span>
    <button
      data-cy="linked-existing-items__actions__link-button"
      mat-raised-button
      color="accent"
      (click)="createLinkedItems()"
      ninetyStopPropagation
      [disabled]="!vm.totalCount"
      class="linked-existing-items__actions__create-link-button">
      Link items to {{ vm.fromLinkedItemName }}
    </button>
  </mat-dialog-actions>
</div>
