import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraInputAutoFocusDirective } from '../terra-input-auto-focus/terra-input-autofocus.directive';

import { TerraMultilineInputComponent } from './terra-multiline-input.component';

@NgModule({
  imports: [CommonModule, TerraMultilineInputComponent, TerraInputAutoFocusDirective],
  exports: [TerraMultilineInputComponent, TerraInputAutoFocusDirective],
})
export class TerraMultilineInputModule {}
