import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'log',
})
export class LogPipe implements PipeTransform {
  transform(value: unknown): unknown {
    // eslint-disable-next-line no-console
    console.log(value);
    return value;
  }
}
