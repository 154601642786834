import { Pipe, PipeTransform } from '@angular/core';

import { PersonMetadata } from '../../_shared/models/_shared/person-metadata';

/** will almost always be a User or Person, but just allowing for greater flexibility */
interface MetadataAndEmail {
  metadata?: PersonMetadata;
  primaryEmail?: string;
}

@Pipe({
  name: 'displayNameOrEmail',
  standalone: true,
})
/** Displays the full name of a user if available, otherwise displays the email.
 * This is intended for users who are have not yet been invited or have a pending invite.
 * First and last name fields are now optional on Directory V2 when adding active users. */
export class DisplayNameOrEmailPipe implements PipeTransform {
  transform<T extends MetadataAndEmail>(user: T): string {
    return DisplayNameOrEmailPipe.transform(user);
  }

  static transform<T extends MetadataAndEmail>(u: T): string {
    if (!u) return '';
    if (u.metadata?.name?.first && u.metadata?.name?.last)
      return `${u.metadata.name.first} ${u.metadata.name.last}`.trim();
    return u.primaryEmail || '';
  }
}
