import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToDuration',
  standalone: true,
})
export class SecondsToDurationPipe implements PipeTransform {
  transform(value: number): string {
    return SecondsToDurationPipe.apply(value);
  }

  static apply(value: number): string {
    const hours = Math.floor(value / 3600);
    const hoursDisplay = hours > 0 ? `${hours} hr` : '';

    const minutes = Math.floor((value % 3600) / 60);
    const minutesDisplay = minutes > 0 ? `${minutes} min` : '';

    const seconds = value % 60;
    const secondsDisplay = seconds > 0 ? `${seconds} sec` : '';

    let parts: string[];
    if (hours) {
      parts = [hoursDisplay, minutesDisplay];
    } else if (minutes > 9) {
      parts = [minutesDisplay];
    } else {
      parts = [minutesDisplay, secondsDisplay];
    }

    return parts.filter(Boolean).join(' ') || '0 sec'; // edge case - all parts empty, is 0 sec
  }
}
