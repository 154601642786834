import { SubscriptionName } from './subscription-name';

export const PricePerPlan = {
  [SubscriptionName.Freemium]: 0,
  [SubscriptionName.Essentials_Monthly]: 10,
  [SubscriptionName.Essentials_Yearly]: 120,
  [SubscriptionName.Accelerate_Monthly]: 14,
  [SubscriptionName.Accelerate_Yearly]: 168,
  [SubscriptionName.Thrive_Monthly]: 16,
  [SubscriptionName.Thrive_Yearly]: 192,
};
