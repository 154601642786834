<ng-container
  *ngrxLet="{
    isMazChatExpanded: isMazChatExpanded$,
    messages: mazMessages$,
    description: description$,
    userAvatarInfo: userAvatarInfo$,
    showAlert: showMazAlert$,
    mazToasterFlag: mazToasterFlag$
  } as vm">
  <div class="maz-chat" [class.chat-expanded]="vm.isMazChatExpanded">
    <div id="maz-chat-header">
      <div class="title">Have a question?</div>
      <ninety-button flat icon class="expand-chat-btn" (click)="toggleChatWindow()" data-cy="expand-maz-chat-button">
        <terra-icon icon="caret-up" />
      </ninety-button>
    </div>

    <div id="conversation-window">
      <ng-container *ngIf="vm.isMazChatExpanded">
        <div id="maz-conversation" ninetyChatWindowHeight [class.no-messages]="!vm.messages.length">
          <ng-container *ngIf="!vm.messages.length">
            <div class="maz-intro">
              <terra-icon icon="hand-waving" variant="duotone" size="80" />
              <h2>Hey there.</h2>
              <p>{{ vm.description }}</p>
            </div>
            <ninety-maz-common-questions />
          </ng-container>

          <ninety-maz-message
            *ngFor="let message of vm.messages; trackBy: trackByMessageId"
            [message]="message"
            [avatarInfo]="vm.userAvatarInfo"
            (closeMazAndOpenIntercom)="closeMazAndOpenIntercom()"
            (submitFeedback)="submitFeedback($event, message)"
            (toggleMazFeedbackFlag)="toggleMazFeedbackFlag(message)"
            (feedbackClosed)="showMazAlert()" />

          <ninety-maz-waiting-for-response *ngIf="waitingForMazResponse$ | ngrxPush" />

          <ninety-maz-chat-toaster *ngIf="vm.showAlert" (hide)="hideMazAlert()" [flag]="vm.mazToasterFlag" />
        </div>
      </ng-container>
    </div>

    <div id="maz-chat-footer">
      <div class="chat-input">
        <ninety-input-wrapper>
          <input
            type="text"
            ninetyInput
            placeholder="Ask Maz, your AI Onboarding Companion..."
            [(ngModel)]="mazInput"
            (keyup.enter)="sendMessage()" />
          <terra-icon icon="sparkle" class="prefix-icon" ninetyInputPrefix />
        </ninety-input-wrapper>

        <ninety-button filled primary icon (click)="sendMessage()">
          <terra-icon icon="send" variant="fill" />
        </ninety-button>
      </div>
      <p class="disclosure">Maz is always learning. Flagging incorrect responses helps Maz get smarter!</p>
    </div>
  </div>
</ng-container>
