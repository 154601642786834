import {
  AfterViewInit,
  ContentChildren,
  Directive,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';

import { TemplateIfDirective } from './template-if.directive';

@Directive({
  selector: '[ninetyTemplateChooser]',
})
export class TemplateChooserDirective implements AfterViewInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  private currentTemplate?: EmbeddedViewRef<any>;

  @Input() selectState!: Observable<any>;

  @ContentChildren(TemplateIfDirective) matchers!: QueryList<TemplateIfDirective>;

  constructor(private vRef: ViewContainerRef, private renderer2: Renderer2) {}

  ngAfterViewInit(): void {
    this.selectState
      .pipe(distinctUntilChanged())
      .subscribe(state => setTimeout(() => this.choseTemplateOnStateChange(state)));
  }

  ngOnDestroy() {
    this.destroyEmbeddedTemplate();
    this.subscriptions.unsubscribe();
  }

  private destroyEmbeddedTemplate() {
    if (this.currentTemplate) this.currentTemplate.destroy();
  }

  private choseTemplateOnStateChange(state: Record<string, any>) {
    this.destroyEmbeddedTemplate();

    const template = this.matchers.find(t => t.matches(state));
    if (!template) {
      // console.warn('Encountered new state, but did not have a template for it', state);
      return;
    }

    // Render new state
    this.currentTemplate = this.vRef.createEmbeddedView(template.templateRef, { state });
    this.currentTemplate.rootNodes.forEach(node => this.renderer2.addClass(node, 'active-chooser-template'));
    this.currentTemplate.markForCheck();
  }
}
