import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit, tapResponse } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  EMPTY,
  exhaustMap,
  filter,
  map,
  Subscription,
  switchMap,
  tap,
  Observable,
  withLatestFrom,
  debounceTime,
} from 'rxjs';

import { TerraIconName, TerraIconVariant } from '@ninety/terra';
import {
  FromLinkedItem,
  Goal,
  Headline,
  Issue,
  LinkedItem,
  LinkedItemTypeEnum,
  Milestone,
  Rock,
  Todo,
} from '@ninety/ui/legacy/shared/index';
import { extractValueFromStore, selectLanguage, TeamSelectors } from '@ninety/ui/legacy/state/index';
import { HeadlineService } from '@ninety/web/pages/headlines/_shared/services/headline.service';
import { IssueService } from '@ninety/web/pages/issues/_shared/issue.service';
import { MilestoneService } from '@ninety/web/pages/rocks/_shared/milestone.service';
import { RockService } from '@ninety/web/pages/rocks/_shared/rock.service';
import { TodoService } from '@ninety/web/pages/todos/_shared/todo.service';
import { VtoService } from '@ninety/web/pages/vto/services/vto.service';

import { LinkedItemsActions } from '../linked-items/_state/linked-items.actions';

const linkedExistingItemsEntities = ['rock', 'milestone', 'todo', 'issue', 'headline', 'goal'] as const;

type LinkedExistingItemsEntityType = (typeof linkedExistingItemsEntities)[number];

const linkedItemTypeToNameMap: Record<keyof Pick<typeof LinkedItemTypeEnum, LinkedExistingItemsEntityType>, string> = {
  [LinkedItemTypeEnum.todo]: 'To-do',
  [LinkedItemTypeEnum.issue]: 'Issue',
  [LinkedItemTypeEnum.headline]: 'Headline',
  [LinkedItemTypeEnum.rock]: 'Rock',
  [LinkedItemTypeEnum.milestone]: 'Milestone',
  [LinkedItemTypeEnum.goal]: 'Goal',
} as const;

type ItemType = Rock | Todo | Milestone | Headline | Issue | Goal;

type ExtendedLinkedExistingEntityType<T extends ItemType> = T & { selected: boolean };

interface LinkedExistingItemEntity<T> {
  iconKey: TerraIconName;
  iconVariant: TerraIconVariant;
  isLoading: boolean;
  items: Partial<T>[];
  linkedItemType: LinkedItemTypeEnum;
  more: boolean;
  name: string;
  pageNumber: number;
  pageSize: number;
  selections: Set<number>;
  tabName: string;
  type: LinkedExistingItemsEntityType;
  getItems: () => Subscription;
}

interface LinkedExistingItemsState {
  disable: boolean;
  error: string | null;
  rock: LinkedExistingItemEntity<ExtendedLinkedExistingEntityType<Rock>>;
  todo: LinkedExistingItemEntity<ExtendedLinkedExistingEntityType<Todo>>;
  milestone: LinkedExistingItemEntity<ExtendedLinkedExistingEntityType<Milestone>>;
  headline: LinkedExistingItemEntity<ExtendedLinkedExistingEntityType<Headline>>;
  issue: LinkedExistingItemEntity<ExtendedLinkedExistingEntityType<Issue>>;
  goal: LinkedExistingItemEntity<ExtendedLinkedExistingEntityType<Goal>>;
  fromLinkedItem?: FromLinkedItem;
  fromLinkedItemName?: string;
  fromLinkedItemTitle?: string;
  teamId?: string;
  teamName?: string;
  searchText: string | undefined;
  selectedIndex: number;
  startSpinner: boolean;
  entities: Observable<LinkedExistingItemEntity<ItemType>>[];
  displayedItems: Record<LinkedExistingItemsEntityType, Observable<Partial<ItemType>[]>>;
}

const MAX_PAGE_SIZE = 5;

@Injectable()
export class LinkedExistingItemsStore extends ComponentStore<LinkedExistingItemsState> implements OnStoreInit {
  private readonly store = inject(Store);
  private readonly rockService = inject(RockService);
  private readonly todoService = inject(TodoService);
  private readonly issueService = inject(IssueService);
  private readonly milestoneService = inject(MilestoneService);
  private readonly headlineService = inject(HeadlineService);
  private readonly vtoService = inject(VtoService);
  private readonly subscriptions = new Subscription();

  private readonly isLoading$ = this.select(state =>
    linkedExistingItemsEntities.map(entity => state[entity].isLoading).some(loading => loading)
  );

  private readonly allLinkedItems$ = this.select(state => this.getAllLinkedItems(state));

  private readonly totalCount$ = this.select(state => {
    return linkedExistingItemsEntities.reduce((acc, entity) => state[entity].selections.size + acc, 0);
  });

  public readonly vm$ = this.select({
    startSpinner: this.select(state => state.startSpinner),
    isLoading: this.select(this.isLoading$, isLoading => isLoading),
    error: this.select(state => state.error),
    entities: this.select(state => state.entities),
    fromLinkedItemName: this.select(state => state.fromLinkedItemName),
    fromLinkedItemTitle: this.select(state => state.fromLinkedItemTitle),
    totalCount: this.totalCount$,
    teamName: this.select(state => state.teamName),
    searchText: this.select(state => state.searchText),
    entity: this.select(state => state[linkedExistingItemsEntities[state.selectedIndex]]),
    displayedItems: this.select(state => state.displayedItems),
  });

  private getAllLinkedItems(state: LinkedExistingItemsState): LinkedItem[] {
    const linkedItems: LinkedItem[] = [];
    for (const itemEntity of linkedExistingItemsEntities) {
      const entity = state[itemEntity];
      const items = entity.items;
      const selections = entity.selections;
      for (const index of selections) {
        const item = items[index];
        if ('_id' in item) {
          const linkedItem: LinkedItem = {
            fromId: state.fromLinkedItem.id,
            fromType: state.fromLinkedItem.type,
            toId: item._id as string,
            toType: entity.linkedItemType,
          };
          linkedItems.push(linkedItem);
        }
      }
    }

    return linkedItems;
  }

  private readonly getMilestoneItems = this.effect(trigger$ => {
    return trigger$.pipe(
      concatLatestFrom(() => [
        this.select(state => state.milestone.pageNumber),
        this.select(state => state.milestone.pageSize),
        this.select(state => state.searchText),
        this.select(state => state.milestone.more),
      ]),
      tap(() => {
        this.setItemIsLoading('milestone');
      }),
      exhaustMap(([, pageNumber, pageSize, searchText]) => {
        const { teamId } = this.get();
        return this.milestoneService
          .getMilestonesForLinkedItemId(teamId, this.get().fromLinkedItem.id, {
            page: pageNumber,
            pageSize,
            searchText,
          })
          .pipe(
            tapResponse(
              response => this.addItemsToEntity({ entity: 'milestone', items: response[teamId] }),
              (err: HttpErrorResponse) => this.patchState({ error: err.toString(), startSpinner: false })
            )
          );
      })
    );
  });

  private readonly getRockItems = this.effect(trigger$ => {
    return trigger$.pipe(
      concatLatestFrom(() => [
        this.select(state => state.rock.pageNumber),
        this.select(state => state.rock.pageSize),
        this.select(state => state.searchText),
        this.select(state => state.rock.more),
      ]),
      tap(() => {
        this.setItemIsLoading('rock');
      }),
      exhaustMap(([, page, pageSize, searchText]) => {
        const { teamId } = this.get();
        return this.rockService
          .getRocksForLinkedItemId(teamId, this.get().fromLinkedItem.id, { page, pageSize, searchText })
          .pipe(
            tapResponse(
              response => this.addItemsToEntity({ entity: 'rock', items: response[teamId] }),
              (err: HttpErrorResponse) => this.patchState({ error: err.toString(), startSpinner: false })
            )
          );
      })
    );
  });

  private readonly getTodoItems = this.effect(trigger$ => {
    return trigger$.pipe(
      concatLatestFrom(() => [
        this.select(state => state.todo.pageNumber),
        this.select(state => state.todo.pageSize),
        this.select(state => state.searchText),
        this.select(state => state.todo.more),
      ]),
      filter(([, , , , more]) => more),
      tap(() => {
        this.setItemIsLoading('todo');
      }),
      exhaustMap(([, page, pageSize, searchText]) => {
        const { teamId } = this.get();
        return this.todoService
          .getTodosForLinkedItemId(teamId, this.get().fromLinkedItem.id, { page, pageSize, searchText })
          .pipe(
            tapResponse(
              response => this.addItemsToEntity({ entity: 'todo', items: response[teamId] }),
              (err: HttpErrorResponse) => this.patchState({ error: err.toString(), startSpinner: false })
            )
          );
      })
    );
  });

  private readonly getIssueItems = this.effect(trigger$ => {
    const entity: LinkedExistingItemsEntityType = 'issue';

    return trigger$.pipe(
      concatLatestFrom(() => [
        this.select(state => state.issue.pageNumber),
        this.select(state => state.issue.pageSize),
        this.select(state => state.searchText),
        this.select(state => state.issue.more),
      ]),
      filter(([, , , , more]) => more),
      tap(() => {
        this.setItemIsLoading(entity);
      }),
      exhaustMap(([, page, pageSize, searchText]) => {
        const { teamId } = this.get();
        return this.issueService
          .getIssuesForLinkedItemId(teamId, this.get().fromLinkedItem.id, { page, pageSize, searchText })
          .pipe(
            tapResponse(
              response => this.addItemsToEntity({ entity: entity, items: response[teamId] }),
              (err: HttpErrorResponse) => this.patchState({ error: err.toString(), startSpinner: false })
            )
          );
      })
    );
  });

  private readonly getHeadlineItems = this.effect(trigger$ => {
    const entity: LinkedExistingItemsEntityType = 'headline';

    return trigger$.pipe(
      concatLatestFrom(() => [
        this.select(state => state.headline.pageNumber),
        this.select(state => state.headline.pageSize),
        this.select(state => state.searchText),
        this.select(state => state.headline.more),
      ]),
      filter(([, , , , more]) => more),
      tap(() => {
        this.setItemIsLoading(entity);
      }),
      exhaustMap(([, page, pageSize, searchText]) => {
        const { teamId } = this.get();
        return this.headlineService
          .getHeadlinesForLinkedItemId(teamId, this.get().fromLinkedItem.id, { page, pageSize, searchText })
          .pipe(
            tapResponse(
              response => this.addItemsToEntity({ entity: entity, items: response[teamId] }),
              (err: HttpErrorResponse) => this.patchState({ error: err.toString(), startSpinner: false })
            )
          );
      })
    );
  });

  private readonly getGoalItems = this.effect(trigger$ => {
    return trigger$.pipe(
      concatLatestFrom(() => [
        this.select(state => state.goal.pageNumber),
        this.select(state => state.goal.pageSize),
        this.select(state => state.searchText),
        this.select(state => state.rock.more),
      ]),
      tap(() => {
        this.setItemIsLoading('goal');
      }),
      exhaustMap(([, page, pageSize, searchText]) => {
        const { teamId } = this.get();
        return this.vtoService
          .getGoalsForLinkedExistingItemId(teamId, this.get().fromLinkedItem.id, { page, pageSize, searchText })
          .pipe(
            tapResponse(
              response => this.addItemsToEntity({ entity: 'goal', items: response[teamId] }),
              (err: HttpErrorResponse) => this.patchState({ error: err.toString(), startSpinner: false })
            )
          );
      })
    );
  });

  private readonly addItemsToEntity = this.updater(
    (
      state: LinkedExistingItemsState,
      { entity, items }: { entity: LinkedExistingItemsEntityType; items: ItemType[] }
    ): LinkedExistingItemsState => {
      const newItems = items.map(item => ({ ...item, selected: false }));

      return {
        ...state,
        [entity]: {
          ...state[entity],
          items: [...state[entity].items, ...newItems],
          isLoading: false,
          pageNumber: items.length === 0 ? state[entity].pageNumber : state[entity].pageNumber + 1,
          more: items.length >= state[entity].pageSize,
        },
      };
    }
  );

  private readonly updateSelectedIndexChanged = this.updater(
    (state, selectedIndex: number): LinkedExistingItemsState => {
      return {
        ...state,
        selectedIndex,
      };
    }
  );

  readonly createLinkedItems = this.effect<void>(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.allLinkedItems$),
      filter(([_, linkedItems]) => linkedItems.length > 0),
      map(([_, linkedItems]) => {
        return this.store.dispatch(
          LinkedItemsActions.addLinkedItems({
            linkedItems,
            fromId: this.get().fromLinkedItem.id,
            fromType: this.get().fromLinkedItem.type,
          })
        );
      })
    );
  });

  readonly handleSearchTextInput = this.effect<string>(searchText$ => {
    return searchText$.pipe(
      debounceTime(300),
      tap((searchText: string) => {
        this.updateSearchTextInput(searchText);
        this.fetchMore();
      })
    );
  });

  readonly destroy = this.effect<void>(() => {
    this.subscriptions.unsubscribe();
    return EMPTY;
  });

  readonly fetchMore = this.effect<void>(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        const entity = linkedExistingItemsEntities[this.get().selectedIndex];
        if (this.get()[entity].more) {
          this.patchState({ startSpinner: true });
          this.get()[entity].getItems();
        }
      }),
      switchMap(() => this.select(this.isLoading$, isLoading => isLoading)),
      filter(isLoading => !isLoading),
      tap(() => {
        this.patchState({ startSpinner: false });
      })
    );
  });

  onSelectedIndexChanged(selectedIndex: number): void {
    this.updateSelectedIndexChanged(selectedIndex);
    this.patchState({ searchText: undefined });
    const entity = linkedExistingItemsEntities[this.get().selectedIndex];
    if (this.get()[entity].items.length === 0) {
      this.fetchMore();
    }
  }

  private readonly updateSearchTextInput = this.updater((state, searchText: string): LinkedExistingItemsState => {
    const entity = linkedExistingItemsEntities[state.selectedIndex];
    const items = state[entity].items;
    items.length = 0;
    state[entity].pageNumber = 0;
    state[entity].more = true;

    if (searchText === '') {
      return {
        ...state,
        searchText: undefined,
      };
    }

    return {
      ...state,
      searchText,
    };
  });

  readonly setItemIsLoading = this.updater((state, item: LinkedExistingItemsEntityType): LinkedExistingItemsState => {
    return {
      ...state,
      [item]: { ...state[item], isLoading: true },
    };
  });

  readonly setFromLinkedItem = this.updater(
    (
      state,
      {
        fromLinkedItem,
        fromLinkedItemTitle,
        fromLinkedItemTeamId,
      }: { fromLinkedItem: FromLinkedItem; fromLinkedItemTitle: string; fromLinkedItemTeamId: string }
    ): LinkedExistingItemsState => {
      return {
        ...state,
        fromLinkedItem,
        fromLinkedItemTitle,
        teamId: fromLinkedItemTeamId,
        fromLinkedItemName: linkedItemTypeToNameMap[fromLinkedItem.type],
        teamName: extractValueFromStore(this.store, TeamSelectors.selectById(fromLinkedItemTeamId)).name,
      };
    }
  );

  readonly toggleItemSelection = this.updater(
    (
      state,
      { checked, type, index }: { checked: boolean; type: LinkedExistingItemsEntityType; index: number }
    ): LinkedExistingItemsState => {
      const selections = state[type].selections;
      const item = state[type].items[index];
      if (checked) {
        selections.add(index);
        item.selected = true;
      } else {
        selections.delete(index);
        item.selected = false;
      }

      return {
        ...state,
        [type]: {
          ...state[type],
          selections,
        },
      };
    }
  );

  ngrxOnStoreInit(): void {
    this.setState({
      startSpinner: false,
      error: null,
      disable: false,
      // entities: linkedExistingItemsEntities.map(entity => this.select(state => state[entity])),
      entities: linkedExistingItemsEntities.reduce((acc, entity) => {
        acc.push(this.select(state => state[entity]));
        return acc;
      }, [] as Observable<LinkedExistingItemEntity<ItemType>>[]),
      displayedItems: linkedExistingItemsEntities.reduce((acc, entity) => {
        acc[entity] = this.select(state => state[entity].items) as Observable<Partial<ItemType>[]>;
        return acc;
      }, {} as Record<LinkedExistingItemsEntityType, Observable<Partial<ItemType>[]>>),
      rock: {
        selections: new Set(),
        items: [],
        name: extractValueFromStore(this.store, selectLanguage).rock.items,
        tabName: extractValueFromStore(this.store, selectLanguage).rock.item,
        isLoading: false,
        iconKey: 'rocks',
        iconVariant: 'regular',
        type: 'rock',
        pageNumber: 0,
        pageSize: MAX_PAGE_SIZE,
        more: true,
        linkedItemType: LinkedItemTypeEnum.rock,
        getItems: this.getRockItems,
      },
      todo: {
        selections: new Set(),
        items: [],
        name: extractValueFromStore(this.store, selectLanguage).todo.items,
        tabName: extractValueFromStore(this.store, selectLanguage).todo.item,
        isLoading: false,
        iconKey: 'to-dos',
        iconVariant: 'regular',
        type: 'todo',
        pageNumber: 0,
        pageSize: MAX_PAGE_SIZE,
        more: true,
        linkedItemType: LinkedItemTypeEnum.todo,
        getItems: this.getTodoItems,
      },
      headline: {
        selections: new Set(),
        items: [],
        name: extractValueFromStore(this.store, selectLanguage).headline.items,
        tabName: extractValueFromStore(this.store, selectLanguage).headline.item,
        isLoading: false,
        iconKey: 'headlines',
        iconVariant: 'regular',
        type: 'headline',
        pageNumber: 0,
        pageSize: MAX_PAGE_SIZE,
        more: true,
        linkedItemType: LinkedItemTypeEnum.headline,
        getItems: this.getHeadlineItems,
      },
      issue: {
        selections: new Set(),
        items: [],
        name: extractValueFromStore(this.store, selectLanguage).issue.items,
        tabName: extractValueFromStore(this.store, selectLanguage).issue.item,
        isLoading: false,
        iconKey: 'issues',
        iconVariant: 'regular',
        type: 'issue',
        pageNumber: 0,
        pageSize: MAX_PAGE_SIZE,
        more: true,
        linkedItemType: LinkedItemTypeEnum.issue,
        getItems: this.getIssueItems,
      },
      milestone: {
        selections: new Set(),
        items: [],
        name: extractValueFromStore(this.store, selectLanguage).milestone.items,
        tabName: extractValueFromStore(this.store, selectLanguage).milestone.item,
        isLoading: false,
        iconKey: 'milestones',
        iconVariant: 'regular',
        type: 'milestone',
        pageNumber: 0,
        pageSize: MAX_PAGE_SIZE,
        more: true,
        linkedItemType: LinkedItemTypeEnum.milestone,
        getItems: this.getMilestoneItems,
      },
      goal: {
        selections: new Set(),
        items: [],
        name: extractValueFromStore(this.store, selectLanguage).measurable.goals,
        tabName: extractValueFromStore(this.store, selectLanguage).measurable.goal,
        isLoading: false,
        iconKey: 'future-goal',
        iconVariant: 'regular',
        type: 'goal',
        pageNumber: 0,
        pageSize: MAX_PAGE_SIZE,
        more: true,
        linkedItemType: LinkedItemTypeEnum.goal,
        getItems: this.getGoalItems,
      },
      searchText: undefined,
      selectedIndex: 0,
    });
  }
}
