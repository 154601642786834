import { Pipe, PipeTransform } from '@angular/core';

import { Team } from '../../models/_shared/team';

@Pipe({
  name: 'addTeammatesDialogShouldShowOption',
  standalone: true,
})
export class AddTeammatesDialogShouldShowTeamOptionPipe implements PipeTransform {
  transform(team: Team, searchTerm: string, searchResults: Team[]): '' | 'none' {
    return (searchResults.length === 0 && searchTerm.length === 0) || searchResults.includes(team) ? '' : 'none';
  }
}
