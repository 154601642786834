import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

import { TerraCheckboxModule, TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../../_components/buttons/button/button.component';
import { ButtonGroupComponent } from '../../../../_components/buttons/button-group/button-group.component';
import { ButtonRowComponent } from '../../../../_components/buttons/button-row/button-row.component';

import { TimezoneDialogData, TimezoneDialogResult } from './models';

@Component({
  templateUrl: './timezone-changed-dialog.component.html',
  styleUrls: ['./timezone-changed-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDividerModule,
    MatDialogModule,
    TerraIconModule,
    ButtonComponent,
    ButtonRowComponent,
    ButtonGroupComponent,
    TerraCheckboxModule,
  ],
})
export class TimezoneChangedDialogComponent implements OnInit {
  dontAskAgain = false; // Property bound to the checkbox
  timeZoneForUserWeDisplay: string;

  constructor(
    public dialogRef: MatDialogRef<TimezoneChangedDialogComponent, TimezoneDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: TimezoneDialogData
  ) {
    this.dialogRef.addPanelClass('timezone-changed-dialog-container');
    this.data = new TimezoneDialogData(data);
  }
  ngOnInit(): void {
    this.timeZoneForUserWeDisplay = this.data.timeZoneForUserWeDisplay;
  }

  confirm() {
    this.dialogRef.close({
      dontAskAgain: this.dontAskAgain,
      browserTimezoneEntry: this.data.browserTimezoneEntry,
      confirm: true,
    });
  }

  ignore() {
    this.dialogRef.close({
      dontAskAgain: this.dontAskAgain,
      browserTimezoneEntry: this.data.browserTimezoneEntry,
      confirm: false,
    });
  }
}
