import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { distinctUntilChanged, skip } from 'rxjs';

import {
  TerraInputBoolean,
  // eslint-disable-next-line terra/enforce-terra-module-import
  TerraQuickFilterComponent,
  TerraQuickFilterModule,
  TerraSearchInputModule,
} from '@ninety/terra';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { teamAll } from '@ninety/ui/legacy/state/app-entities/team-list/api/team-list.model';

import { ManagedOptions } from '../chip-select/services/fuse-search.service.model';
import { FuseTeamDirective } from '../chip-select/services/fuse-team.directive';

/* eslint-disable @angular-eslint/no-outputs-metadata-property,@angular-eslint/no-output-rename */

@Component({
  selector: 'ninety-team-quick-filter',
  standalone: true,
  imports: [CommonModule, PushPipe, LetDirective, FormsModule, TerraSearchInputModule, TerraQuickFilterModule],
  templateUrl: './team-quick-filter.component.html',
  styleUrls: ['./team-quick-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: FuseTeamDirective,
      inputs: ['ninetyFuseTeam: teams$', 'ninetyFuseTeamOptions: fuseOptions'],
    },
  ],
})
export class TeamQuickFilterComponent<T> implements AfterViewInit {
  @ViewChild(TerraQuickFilterComponent, { static: true }) private _teamQuickFilter!: TerraQuickFilterComponent;

  /**
   * Whether to include the "TeamAll" option in the team select.
   *
   * Most pages do not have a team all besides My90 & Directory, so the default is false.
   *
   * Pass `[shouldShowTeamAll]=true` to enable the option.
   */
  @Input() shouldShowTeamAll = false;
  @Input() readonly: TerraInputBoolean;
  @Input() disabled: TerraInputBoolean;
  @Input() selectedTeam: T[];

  @Output() selectedTeamChange = new EventEmitter<T[]>();

  protected readonly trackBy: TrackByFunction<ManagedOptions<Team>> = (_index, item) => item.value._id;

  protected optionListElement: Element;

  protected _teamSearchInput = '';
  protected _noResultsText = 'No search results found. Please check your spelling or try a different search.';

  constructor(protected readonly fuseProvider: FuseTeamDirective, private readonly _destroyRef: DestroyRef) {}

  ngAfterViewInit() {
    this.fuseProvider.fuse.managedOptions$
      .pipe(distinctUntilChanged(), skip(1), takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this._teamQuickFilter.scrollToTop();
      });
  }

  protected search(query: string) {
    this.fuseProvider.fuse.search({ query });
  }

  protected onSelectValue(selectedTeam: T) {
    const team = [selectedTeam];
    this.selectedTeamChange.emit(team);
  }

  protected onOpenChange(isOpen: boolean) {
    if (!isOpen) {
      this.fuseProvider.fuse.search({ query: null });
      this._teamSearchInput = '';
    }
  }

  protected _teamsAreSame(option: Team, value: Team): boolean {
    return option?._id === value?._id;
  }

  protected _handleSearchTermChanged($event: string) {
    this._teamSearchInput = $event;
    this.search(this._teamSearchInput);
  }

  protected readonly teamAll = teamAll;
}
