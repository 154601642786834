import { SubscriptionTypes } from '../models/billingv2/subscription-types.enum';
import { BusinessOperatingSystem } from '../models/company/business-operating-system.enum';
import { Company } from '../models/company/company';

/** Getting Started Guide target company criteria:
 * 1) Not coached (not created from partner hub or referral link)
 * 2) Not a free account
 * 3) BOS = EOS or 90OS
 * */
export function isGSGTargetCompany(company: Company): boolean {
  if (!company) return false;
  const { bos, isCoached, subscription, implementerFree } = company;
  return (
    !isCoached &&
    !implementerFree &&
    subscription?.type !== SubscriptionTypes.Free &&
    (bos === BusinessOperatingSystem.eos || bos === BusinessOperatingSystem.ninetyOS)
  );
}
