import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, catchError, map, mergeMap, of, tap } from 'rxjs';

import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import {
  OrdinalOrUserOrdinalUpdate,
  OrdinalUpdateWithSort,
} from '@ninety/ui/legacy/shared/models/_shared/ordinal-or-user-ordinal-update';
import { CompletedIncomplete } from '@ninety/ui/legacy/shared/models/charts/todo-completion-chart-data';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { FromLinkedItem } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { ListDropMessage } from '@ninety/ui/legacy/shared/models/meetings/list-drop-message';
import { ListSortMessage } from '@ninety/ui/legacy/shared/models/meetings/list-sort-message';
import type { ReceivedRealtimeMessage } from '@ninety/ui/legacy/shared/models/meetings/realtime-message';
import { GetTodosQueryParams } from '@ninety/ui/legacy/shared/models/todos/get-todos-query-params';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoMessageType } from '@ninety/ui/legacy/shared/models/todos/todo-message-types';
import { TodoOrdinalType } from '@ninety/ui/legacy/shared/models/todos/todo-ordinal-type';
import { TodoRealTimeMessage } from '@ninety/ui/legacy/shared/models/todos/todo-realtime-message';
import { TodoGETManyApiResponse } from '@ninety/ui/legacy/shared/models/todos/todo-response';
import { TodoSortField } from '@ninety/ui/legacy/shared/models/todos/todo-sort-field';
import { selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage, TeamSelectors } from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

import { TeamTodoActions, TeamTodoPubnubActions } from '../_state/team/team-todo.actions';
import { CreateTodoResponse } from '../services/models/create-todo-response';
import { TodoApiService } from '../services/todo-api.service';

@Injectable({
  providedIn: 'root',
})
export class TodoService {
  private api = '/api/v4/ToDos';
  private newApi = '/api/v4/Todos';

  //fromBroadcast lets subscribers know if these observables are being sent as the result of a
  //broadcasted message. Broadcasted messages update local data but shouldn't request updates to the backend
  newToDos$ = new Subject<{ todos: Todo[]; fromBroadcast: boolean }>();
  todoAddedInline$ = new Subject<{ todos: Todo[]; fromBroadcast: boolean }>();
  deletedTodo$ = new Subject<{ id: string; fromBroadcast: boolean }>();
  deletedSeries$ = new Subject<{ id: string; seriesId: string; fromBroadcast: boolean }>();
  updatedTodo$ = new Subject<{ todo: Todo; fromBroadcast: boolean }>();
  completionChange$ = new Subject<{ id: string; completed: boolean; fromBroadcast: boolean }>();
  newCurrentUserTodo$ = new Subject<Todo>();
  unarchiveTodo$ = new Subject<Todo>();
  dropListTodo$ = new Subject<ListDropMessage>();
  sortListTodo$ = new Subject<ListSortMessage>();
  attachmentEvent$ = new Subject<string>();
  messageSubscription = new Subscription();

  channelId: string;
  shouldBroadcast: boolean;

  constructor(
    private http: HttpClient,
    public stateService: StateService,
    private spinnerService: SpinnerService,
    private filterService: FilterService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private notifyService: NotifyService,
    private channelService: ChannelService,
    public helperService: HelperService,
    private todoApiService: TodoApiService,
    private store: Store
  ) {
    this.updatedTodo$
      .pipe(
        tap(({ todo, fromBroadcast }) => {
          if (todo.hasOwnProperty('completed')) {
            this.completionChange$.next({ id: todo._id, completed: todo.completed, fromBroadcast });
          }
        })
      )
      .subscribe();
  }

  getTodos(opts: GetTodosQueryParams): Observable<TodoGETManyApiResponse> {
    return this.todoApiService.getTodos(opts).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not fetch${opts.archived ? ' archived' : ''} ${this.stateService.language.todo.items}.
         Try refreshing the page.`
        )
      )
    );
  }

  getTodo(id: string, primary = true): Observable<Todo> {
    if (primary) this.spinnerService.start();
    return this.http.get<Todo>(`${this.api}/${id}`).pipe(
      tap(() => {
        if (primary) this.spinnerService.stop();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not fetch this ${this.stateService.language.todo.item}.`)
      )
    );
  }

  create(
    toDo: Item,
    userIds: string[],
    createdFrom?: FromLinkedItem,
    addCreatorToFollowersList = true
  ): Observable<CreateTodoResponse> {
    return this.http
      .post<CreateTodoResponse>(this.newApi, { toDo, userIds, from: createdFrom, addCreatorToFollowersList })
      .pipe(
        concatLatestFrom(() => [this.store.select(selectCurrentUser)]),
        map(([response, currentUser]) => {
          const userTodo = response.todos.find((t: Todo) => t.userId === currentUser._id);
          if (userTodo) this.newCurrentUserTodo$.next(userTodo);
          this.newToDos$.next({ todos: response.todos, fromBroadcast: false });
          return {
            ...response,
            fileAttachments: toDo.fileAttachments ?? [],
          };
        }),
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `There was a problem creating this ${this.stateService.language.todo.item}.  Please try again.`
          )
        )
      );
  }

  createOne(toDo: Item): Observable<CreateTodoResponse> {
    return this.http.post<CreateTodoResponse>(this.newApi, { toDo, userIds: [toDo.userId] }).pipe(
      tap(({ todos }) => {
        if (Array.isArray(todos)) {
          this.newCurrentUserTodo$.next(todos[0]);
          this.todoAddedInline$.next({ todos, fromBroadcast: false });
        }
      }),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `There was a problem creating this ${this.stateService.language.todo.item}.  Please try again.`
        )
      )
    );
  }

  updateTodo(id: string, todo: Partial<Todo>): Observable<Todo> {
    const update = Object.assign({}, todo);

    return this.http
      .patch<Todo>(`${this.newApi}/${id}`, update)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update the ${this.stateService.language.todo.item}.  Please try again.`
          )
        )
      );
  }

  updateTodoFollowerList(todoId: string, followerIds: string[]): Observable<unknown> {
    return this.http
      .post(`${this.newApi}/${todoId}/followers`, { userIds: followerIds })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update the ${this.stateService.language.todo.item} followers.  Please try again.`
          )
        )
      );
  }

  deleteUserFromToDoFollowerList(userId: string, todoId: string): Observable<unknown> {
    return this.http
      .delete(`${this.newApi}/${todoId}/followers/${userId}`)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update the ${this.stateService.language.todo.item} followers.  Please try again.`
          )
        )
      );
  }

  delete(todo: Todo, primary = false): Observable<any> {
    primary ? this.spinnerService.start() : this.spinnerService.startAuxiliary();
    return this.http.delete(`${this.newApi}/${todo._id}`).pipe(
      tap(() => {
        this.spinnerService.stop();
        /** Notifies meetings of the delete. Remove once meetings are in NGRX */
        this.deletedTodo$.next({ id: todo._id, fromBroadcast: false });
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not delete the ${this.stateService.language.todo.item}.  Please try again.`)
      )
    );
  }

  deleteSeries(todo: Todo, primary = false): Observable<any> {
    primary ? this.spinnerService.start() : this.spinnerService.startAuxiliary();
    return this.http.delete<any>(`${this.newApi}/${todo._id}/Series/${todo.seriesId}`).pipe(
      tap(() => {
        this.spinnerService.stop();

        //Updates the meeting for anyone subscribed to the channel
        this.deletedSeries$.next({ id: todo._id, seriesId: todo.seriesId, fromBroadcast: false });
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not delete the ${this.stateService.language.todo.item}.  Please try again.`)
      )
    );
  }

  toggleArchive(todo: Todo, primary = false) {
    primary ? this.spinnerService.start() : this.spinnerService.startAuxiliary();

    todo.archived = !todo.archived;
    todo.archivedDate = todo.archived ? new Date() : null;

    return this.http
      .patch<any>(`${this.newApi}/${todo._id}`, {
        archived: todo.archived,
        archivedDate: todo.archivedDate,
      })
      .pipe(
        tap(() => {
          this.spinnerService.stop();
          this.notifyService.notify(
            `${this.stateService.language.todo.item} ${todo.archived ? 'archived' : 'unarchived'}.`,
            3000,
            undefined
          );
        }),
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not update the ${this.stateService.language.todo.item}.  Please try again.`
          )
        )
      );
  }

  updateOrdinals(
    todos: Todo[],
    ordinalKey: TodoOrdinalType = 'userOrdinal',
    offset = 0,
    teamId?: string,
    sortField?: TodoSortField,
    sortDirection?: SortDirection,
    isPersonal?: boolean,
    pageSize?: number
  ): Observable<any> {
    const models: OrdinalOrUserOrdinalUpdate[] = todos.map(
      (t: Todo) => new OrdinalOrUserOrdinalUpdate(t._id, t[ordinalKey] + offset, ordinalKey)
    );
    return this.http.put<OrdinalUpdateWithSort>(`${this.newApi}/Ordinals`, {
      // teamId is an ObjectId field, passing 'all' will cause the API to error with an empty exception when it fails to transform.
      teamId: teamId === 'all' ? undefined : teamId,
      sort: { field: sortField, direction: sortDirection },
      ordinalKey,
      isPersonal,
      pageSize,
      models,
    });
  }

  archiveAllCompleted(): Observable<number | null> {
    return this.confirmArchiveCompletedDialog().pipe(
      mergeMap((confirmed: boolean) => {
        if (confirmed) {
          const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);
          this.spinnerService.start();
          return this.http
            .patch<{ numOfArchivedTodos: number }>(`${this.api}/Archive/${selectedTeamId}/Completed`, null)
            .pipe(
              map(resp => {
                this.spinnerService.stop();
                return resp.numOfArchivedTodos;
              }),
              catchError((e: unknown) =>
                this.errorService.notify(
                  e,
                  `Could not archive the completed ${this.stateService.language.todo.items}.
                Please try again.`
                )
              )
            );
        }
        return of(null);
      })
    );
  }

  confirmArchiveCompletedDialog(): Observable<boolean> {
    const confirmDeleteDialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
      data: {
        title: 'Archive Completed?',
        message: `All completed ${this.stateService.language.todo.items} will be archived.`,
        confirmButtonText: 'Archive',
      },
    });
    return confirmDeleteDialogRef.afterClosed();
  }

  getTodosStatsForConversation(
    conversationId: string,
    startDate?: Date,
    endDate?: Date
  ): Observable<CompletedIncomplete> {
    let params = {};
    if (startDate && endDate) {
      params = { startDate, endDate };
    }
    return this.http
      .get<CompletedIncomplete>(`${this.api}/TodosStatsForConversation/${conversationId}`, { params })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.todo.items} stats for ${this.stateService.language.feedback.item}.
        Please try again.`
          )
        )
      );
  }

  getCompletedIds(todos: Todo[]): string[] {
    return todos.filter(t => t.completed).map(t => t._id);
  }

  broadcastMessage(message: TodoRealTimeMessage): Observable<any> {
    if (this.shouldBroadcast) {
      return this.channelService
        .sendMessage(this.channelId, message)
        .pipe(
          catchError((err: unknown) =>
            this.errorService.notify(
              err,
              `An error occurred notifying ${this.stateService.language.meeting.item} attendees of this change.`
            )
          )
        );
    } else {
      return of({});
    }
  }

  subscribeToTodoChannel(teamId: string) {
    this.channelId = `todo-${this.stateService.companyId}-${teamId}`;
    //TODO: Manage this completely in state eventually and remove local shouldBroadcast
    this.shouldBroadcast = true;
    this.store.dispatch(TeamTodoActions.setShouldBroadcast({ broadcast: true }));

    this.subscribeToMessages();
  }

  subscribeToMessages() {
    this.messageSubscription = this.channelService.messageReceived$.subscribe({
      next: message => {
        switch (message.messageType) {
          case TodoMessageType.todo:
            const todo = message.document as Todo;
            this.updatedTodo$.next({ todo: todo, fromBroadcast: true });
            break;
          case TodoMessageType.new:
            const todos = message.document as Todo[];
            //TODO: newToDos$ is still used by meetings until they can be converted to NGRX
            this.newToDos$.next({ todos, fromBroadcast: true });
            /** Should only be used to update local state from broadcast */
            this.store.dispatch(TeamTodoPubnubActions.addManyFromBroadcast({ todos }));
            break;
          case TodoMessageType.delete:
            const deletedTodoId = message.document as string;
            this.deletedTodo$.next({ id: deletedTodoId, fromBroadcast: true });
            break;
          case TodoMessageType.deleteSeries:
            const { _id, seriesId } = message.document;
            this.deletedSeries$.next({ id: _id, seriesId, fromBroadcast: true });
            break;
          case TodoMessageType.unarchive:
            this.executeUnarchiveTodoMessage(message);
            break;
          case TodoMessageType.drop:
            this.dropListTodo$.next(message.document as ListDropMessage);
            break;
          case TodoMessageType.sort:
            this.sortListTodo$.next(message.document as ListSortMessage);
            break;
          case TodoMessageType.fetch:
            this.handleMessageWithApiGet(message);
            break;
          case TodoMessageType.attachmentUpload:
          case TodoMessageType.attachmentRemove:
          case TodoMessageType.attachmentReorder:
            this.attachmentEvent$.next(message.document as string);
            break;
        }
      },
      // error: (err: unknown) => console.error(err),
    });
  }

  destroyTodoChannel() {
    this.messageSubscription.unsubscribe();
    this.shouldBroadcast = false;
  }

  executeUnarchiveTodoMessage(message: ReceivedRealtimeMessage) {
    this.getTodoById((message.document as Todo)._id).subscribe({
      next: (todo: Todo) => {
        this.unarchiveTodo$.next(todo);
      },
    });
  }

  getTodoById(id: string): Observable<Todo> {
    return this.http
      .get<Todo>(`${this.newApi}/${id}`)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `${this.stateService.language.todo.item} does not exist`)
        )
      );
  }

  getTodosForLinkedItemId(
    teamId: string,
    linkedItemId: string,
    queryParams: {
      page?: number;
      pageSize?: number;
      searchText?: string;
    }
  ): Observable<{ [teamId: string]: Todo[] }> {
    const params = QueryParamsService.build(queryParams);
    return this.http
      .get<{ [teamId: string]: Todo[] }>(`${this.newApi}/${teamId}/linkedItem/${linkedItemId}`, { params })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get linked items for ${
              extractValueFromStore(this.store, selectLanguage).todo.item
            }.  Please try again.`
          )
        )
      );
  }

  handleMessageWithApiGet(message: ReceivedRealtimeMessage): void {
    // Get the required object from API and treat as a Pubnub message
    switch (message.originalMessageType) {
      case TodoMessageType.todo:
      case TodoMessageType.new:
        this.getTodoById((message.document as Todo)._id).subscribe({
          next: (todo: Todo) => {
            this.channelService.messageReceived$.next({
              messageType: message.originalMessageType,
              document: todo,
            } as ReceivedRealtimeMessage);
          },
        });
        break;
    }
  }

  downloadExcel(params: GetTodosQueryParams): Observable<ArrayBuffer> {
    this.spinnerService.start();
    params.timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const compiledParams = QueryParamsService.build(params, true);
    return this.http
      .get(`${this.newApi}/Excel`, {
        params: compiledParams,
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      })
      .pipe(
        tap(() => this.spinnerService.stop()),
        catchError((e: unknown) =>
          this.errorService.notify(e, `There was a problem creating this Excel file.  Please try again.`)
        )
      );
  }
}
