import { createReducer, on } from '@ngrx/store';

import { todosStateAdapter } from '../_shared/todo-state.shared.model';
import {
  constructSharedReducersWithDistinctActions,
  SharedReducerFunctions,
} from '../_shared/todo-state.shared.reducers';

import { CompletedSelectValue } from './completedselectvalue';
import { PersonalTabTodosActions, PersonalTodoActions, PersonalTodoInlineActions } from './personal-todo.actions';
import { initialPersonalTodoState, PersonalTodoState } from './personal-todo.model';

const updateTypeToShowTo =
  (typeOfTodosToShow: CompletedSelectValue) =>
  (state): PersonalTodoState => ({ ...state, typeOfTodosToShow, loading: true });

export const personalTodoReducer = createReducer<PersonalTodoState>(
  initialPersonalTodoState,
  ...constructSharedReducersWithDistinctActions<PersonalTodoState>('personal'),
  on(PersonalTodoActions.getManySuccess, SharedReducerFunctions.updateTodosOnGETManySuccess),
  on(PersonalTodoActions.getManyFailure, SharedReducerFunctions.setErrorOnApiFailure),
  on(PersonalTodoActions.selectComplete, updateTypeToShowTo(CompletedSelectValue.complete)),
  on(PersonalTodoActions.selectIncomplete, updateTypeToShowTo(CompletedSelectValue.incomplete)),
  on(PersonalTodoActions.toggleCompleted, (state: PersonalTodoState, action) => {
    const addToList =
      // Viewing completed todos and the _todo was just completed
      (state.typeOfTodosToShow === CompletedSelectValue.complete && action.isCompleted) ||
      // Viewing incomplete todos and the _todo was just marked as incomplete
      (state.typeOfTodosToShow === CompletedSelectValue.incomplete && !action.isCompleted);

    /*
       Note, the addToList branch is prefaced on the fact that the detail dispatches the entire _todo with
       toggleCompleted. It does not matter that the inline update only dispatches a partial _todo during toggleCompleted
       as you will never need to add a _todo to a list from an inline toggleCompleted update, you will only ever remove
       (you can only see one type of list at a time).
     */
    const todos = addToList
      ? todosStateAdapter.addOne(action.todo, state.todos)
      : todosStateAdapter.removeOne(action.todo._id, state.todos);
    return {
      ...state,
      todos,
      todoCount: todos.ids.length,
    };
  }),
  on(PersonalTodoActions.updateSuccess, PersonalTodoActions.updateUserSuccess, (state, { todo: updateResponse }) => {
    const todo = Object.values(state.todos.entities).find(t => t._id === updateResponse._id);
    let todos = state.todos;
    if (todo && (!updateResponse.isPersonal || todo.userId !== updateResponse.userId)) {
      todos = todosStateAdapter.removeOne(todo._id, state.todos);
    }

    return {
      ...state,
      todos,
    };
  }),
  on(PersonalTodoActions.addToPersonal, (state, { todo: updateResponse }) => {
    let todos = state.todos;
    if (updateResponse.isPersonal) {
      todos = todosStateAdapter.addOne(updateResponse, state.todos);
    }

    return {
      ...state,
      todos,
    };
  }),
  on(PersonalTodoActions.removeFromPersonal, (state, { todo: updateResponse }) => {
    let todos = state.todos;
    if (!updateResponse.isPersonal) {
      todos = todosStateAdapter.removeOne(updateResponse._id, state.todos);
    }

    return {
      ...state,
      todos,
    };
  }),
  on(
    PersonalTodoActions.resetState,
    (_state, { overrides }): PersonalTodoState => ({ ...initialPersonalTodoState, ...overrides })
  ),
  /** Updating reducer optimistically for TeamTodoActions.update which is Partial<Todo>  */
  on(PersonalTodoActions.update, PersonalTodoActions.updateInline, (state, { id, todo }): PersonalTodoState => {
    const todos = todosStateAdapter.updateOne({ id, changes: todo }, state.todos);
    return {
      ...state,
      todos,
      loading: false,
    };
  }),

  //NEXT: there is a edge case when updating a series todo title inline, the opened todo title is not updated ...
  // if the update is done for another todo in the series
  // We need to add a new action for inline updates in series, do team todos as well
  on(PersonalTodoActions.updateDisplayedTodosInSeries, (state, { todo }) => {
    const todos = Object.values(state.todos.entities).reduce((acc, t) => {
      //update some fields for todos in series &
      //avoid updating opened todo again and/or causing jumping cursor
      //NEXT: also filter by user personal to team switch, pass current user id from effect(backlog ticket)
      if (t.seriesId === todo.seriesId && t._id !== state.selectedTodoId) {
        if (!todo.isPersonal) {
          //remove any series todos that are now not part of the list
          //they are now a team todo
          return acc;
        }

        return [
          ...acc,
          Object.assign({}, t, {
            userId: todo.userId,
            user: todo.user,
            title: todo.title,
            description: todo.description,
            teamId: todo.teamId,
            isPersonal: todo.isPersonal,
          }),
        ];
      }
      return [...acc, t];
    }, []);

    return { ...state, todos: todosStateAdapter.setAll(todos, state.todos) };
  }),

  on(PersonalTodoInlineActions.addOne, (state, { todo }): PersonalTodoState => {
    const todos = todosStateAdapter.addOne(todo, state.todos);
    return {
      ...state,
      todos,
      listControlsDisabled: true,
      focusOnInlineAddTodo: false,
    };
  }),
  on(PersonalTodoInlineActions.cancelAddOne, (state): PersonalTodoState => {
    const todos = todosStateAdapter.removeOne(undefined, state.todos);
    return {
      ...state,
      todos,
      listControlsDisabled: false,
      focusOnInlineAddTodo: false,
    };
  }),
  on(PersonalTodoInlineActions.createOneSuccess, (state, { response }): PersonalTodoState => {
    const newTodo = response.todos[0];
    const todos = todosStateAdapter.addOne(newTodo, state.todos);
    return {
      ...state,
      todos,
      todoCount: todos.ids.length,
      listControlsDisabled: false,
      focusOnInlineAddTodo: true,
    };
  }),
  on(PersonalTodoInlineActions.createOneFailure, (state): PersonalTodoState => {
    const todos = todosStateAdapter.removeOne(undefined, state.todos);
    return {
      ...state,
      todos,
      listControlsDisabled: false,
      focusOnInlineAddTodo: false,
    };
  }),
  on(PersonalTodoActions.addManySuccess, (state, { response }): PersonalTodoState => {
    const todos = todosStateAdapter.addMany(response.todos, state.todos);
    return {
      ...state,
      todos,
      todoCount: todos.ids.length,
    };
  }),
  on(
    PersonalTabTodosActions.init,
    (state): PersonalTodoState => ({
      ...state,
      ordinalKey: 'userOrdinal',
    })
  ),
  on(
    PersonalTabTodosActions.hydrate,
    (state, { pageSize, sort }): PersonalTodoState => ({
      ...state,
      pageSize: pageSize || state.pageSize,
      sortDirection: sort?.direction ?? null,
      sortField: sort?.direction ? sort.field : null,
    })
  ),
  on(
    PersonalTabTodosActions.updateOrdinalsSuccess,
    PersonalTabTodosActions.moveToBottom,
    PersonalTabTodosActions.moveToTop,
    (state): PersonalTodoState => ({
      ...state,
      sortDirection: null,
      sortField: null,
    })
  ),
  /** Attachments */
  on(PersonalTodoActions.attachmentUploadedSuccess, (state, { event }) => {
    let todos = state.todos;
    event.attachments.forEach(attachment => {
      todos = todosStateAdapter.updateOne(
        {
          id: attachment.parentId,
          changes: { attachments: [...todos.entities[attachment.parentId].attachments, attachment] },
        },
        todos
      );
    });
    return {
      ...state,
      todos,
    };
  })
);
