import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { MatSortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';

import { InviteUserFormData } from '../dialogs/invite-user-dialog/models/invite-user-form';
import { DirectoryRoleFilterOption } from '../models/directory-role-filter-option';
import { DirectorySort } from '../models/directory-sort';
import { DirectoryUserStatusOption } from '../models/directory-user-status-options';

import { DirectoryFilters, DirectoryMatSorting } from './directory-state.model';

export const DirectoryActions = createActionGroup({
  source: 'Directory',
  events: {
    Init: emptyProps(),
    Destroy: emptyProps(),
    'Get Directory Users': emptyProps(),
    'Get Directory Users Success': props<{ users: User[]; count: number }>(),

    /** Action bar filters */
    'Init Filter Options': props<{ filters: DirectoryFilters & DirectoryMatSorting }>(),
    'Set Query Param': props<{ key: string; value: string }>(),
    'Select Role Filter Option': props<{ selectedRole: DirectoryRoleFilterOption }>(),
    'Select User Status Filter Option': props<{ selectedUserStatus: DirectoryUserStatusOption }>(),
    'Select Team Filter Option': props<{ teamId: string }>(),
    'Set Search Text': props<{ searchText: string }>(),
    'Search Text Changed': props<{ searchText: string }>(),

    /** Table actions */
    'Sort Changed': props<{ sortField: DirectorySort; sortDirection: MatSortDirection }>(),
    'Pagination Changed': props<{ pageIndex: number; pageSize: number }>(),
    'Select User': props<{ user: User }>(),
    'Set Selected User': props<{ user: User }>(),
    'Update User Success': props<{ userId: string; update: Partial<User> }>(),
    'Update User Teams': props<{ user: User; teamIds: string[] }>(),

    'Update User Role': props<{ user: User; role: RoleSelectOption }>(),
    'Check Billing Counts Before Role Change': props<{ userId: string; update: Partial<User> }>(),

    'Toggle User To Invite': props<{ checked: boolean; user: User }>(),
    'Toggle All Users To Invite': props<{ checked: boolean }>(),
    'Open Deactivate User Dialog': props<{ user: User }>(),
    'Open Delete User Dialog': props<{ user: User }>(),

    // deprecated
    'Invite Paid Users': props<{ users: InviteUserPayload[] }>(),
    'Invite Paid Users Success': props<{ users: User[] }>(),
    'Invite Paid Users Failure': emptyProps(),

    'Open Invite User Dialog': props<{ user: User; role: RoleSelectOption }>(),
    'Cancel Invite User': props<{ userId: string }>(),

    'Check Role Before Invite': props<{ user: User; formData: InviteUserFormData }>(),
    'Check Billing Before Invite': props<{ user: User; formData: InviteUserFormData }>(),
    'Invite User': props<{ user: User; formData: InviteUserFormData }>(),
    'Invite User Success': props<{ user: User; changes: Partial<User> }>(),

    'Toggle Manage License Banner': props<{ visible: boolean }>(),

    'Open Universal Create Modal': emptyProps(),
  },
});

/** Todo - These actions could all be cleaned up and simplified a bit,
 * went from local component to one used in aux route so there were several logic changes  */
export const UserDetailActions = createActionGroup({
  source: 'User Detail',
  events: {
    Init: props<{ user: User }>(),

    /** when detail is opened, will check if there's already a selected user in case of refresh with aux route */
    'Check For Selected User': props<{ userId: string }>(),

    /** if user is not selected, will try grab from the store */
    'Get User From Route': props<{ userId: string }>(),

    /** if user is not selected and not in store, will fetch from the server */
    'Fetch User From Route': props<{ userId: string }>(),
    'Init With User From Route': props<{ user: User }>(),

    Save: emptyProps(),
    Cancel: emptyProps(),

    'Update Success': props<{ _id: string; changes: Partial<User> }>(),

    /** if form is dirty, will prompt user to confirm */
    Close: emptyProps(),
    Destroy: emptyProps(),

    'Open Avatar Upload Dialog': emptyProps(),
    'Copy Invite Link': props<{ user: User }>(),
  },
});
