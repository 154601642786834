import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectHasHelpfulPermission } from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import { FilterBarActions } from '@ninety/web/pages/shared/layouts';

@Component({
  selector: 'ninety-helpful',
  templateUrl: './helpful.component.html',
  styleUrls: ['./helpful.component.scss'],
})
export class HelpfulComponent implements OnInit, OnDestroy {
  protected readonly canHardDeleteCompany$ = this.store.select(selectHasHelpfulPermission('hardDeleteCompany'));

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.store.dispatch(FilterBarActions.hide());
  }

  ngOnDestroy() {
    this.store.dispatch(FilterBarActions.show());
  }
}
