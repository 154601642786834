import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import Fuse, { IFuseOptions } from 'fuse.js';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

import { TeamListModel } from '@ninety/ui/legacy/state/app-entities/team-list/api/team-list.model';
import { selectPrimary } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.selectors';
import {
  EditedTeamSelectors,
  FeatureFlagKeys,
  selectFeatureFlag,
  selectLanguage,
  TeamListStateActions,
  UserTeamsActions,
} from '@ninety/ui/legacy/state/index';

import { TeamComponentActions } from './_state/teams-component.actions';
import { TeamsComponentListModel } from './_state/teams-component.model';
import { selectTeamsListForTeamsComponent } from './_state/teams-component.selectors';

@Component({
  selector: 'ninety-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamsComponent implements OnInit {
  protected searchTerm$ = new BehaviorSubject<string>(''); // Initialize with empty search term
  protected currentStatus$ = new BehaviorSubject<string>('active');
  previousStatus = 'active';

  private _filteredTeams$ = combineLatest([
    this.store.select(selectTeamsListForTeamsComponent),
    this.currentStatus$,
    this.searchTerm$,
  ]).pipe(
    map(([teams, currentStatus, searchTerm]) => {
      switch (currentStatus) {
        case 'my-teams':
          return this.applySearch(
            searchTerm,
            teams.filter(team => team.loggedInUserBelongsToTeam)
          );
        case 'archived':
          return this.applySearch(
            searchTerm,
            teams.filter(team => team.archived)
          );
        case 'all':
          return this.applySearch(searchTerm, teams);
        default:
          return this.applySearch(
            searchTerm,
            teams.filter(team => !team.archived)
          );
      }
    })
  );

  vm$ = {
    language: this.store.select(selectLanguage),
    spinner: this.store.select(selectPrimary),
    editedTeamId: this.store.select(EditedTeamSelectors.selectId),
    enableTeamPageEnhancements: this.store.select(selectFeatureFlag(FeatureFlagKeys.enableTeamPageEnhancements)),
    currentStatus: this.currentStatus$,
    filteredTeams: this._filteredTeams$,
    sltTeamId: this._filteredTeams$.pipe(
      map(teams => {
        const sltTeam = teams.find(team => team.isSlt);
        return sltTeam ? sltTeam._id : null;
      })
    ),
  };

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(TeamListStateActions.getArchivedTeamList());
  }

  protected readonly enableTeamPageEnhancements$ = this.store.select(
    selectFeatureFlag(FeatureFlagKeys.enableTeamPageEnhancements)
  );

  editTeam(teamId: string): void {
    this.store.dispatch(TeamListStateActions.openTeamDetail({ teamId }));
  }

  openNewTeamDialog(): void {
    this.store.dispatch(TeamComponentActions.displayAddTeamDialog());
  }

  setSltTeam(event: any, team: TeamsComponentListModel): void {
    event.preventDefault();
    event.stopPropagation();
    if (!team.isSlt && team.canEdit && !team.archived && !team.project) {
      this.store.dispatch(TeamComponentActions.confirmSetSltTeam({ team }));
    }
  }
  updateTeam(id: string, update: Partial<TeamsComponentListModel>): void {
    this.store.dispatch(TeamListStateActions.updateOne({ id, update }));
  }

  selectStatus(status: string) {
    if (this.previousStatus === status) {
      return;
    }
    this.currentStatus$.next(status);
    this.previousStatus = status;
    this.store.dispatch(UserTeamsActions.closeDetailView());
  }

  deleteTeam(team: TeamsComponentListModel): void {
    this.store.dispatch(TeamComponentActions.confirmDeleteTeam({ team }));
  }

  archiveTeam(team: TeamsComponentListModel): void {
    this.store.dispatch(TeamComponentActions.confirmArchiveTeam({ team }));
  }

  reactivateTeam(team: TeamsComponentListModel): void {
    this.store.dispatch(TeamComponentActions.reactivateTeam({ team }));
  }

  onSearchChanged(term: string): void {
    this.searchTerm$.next(term);
  }

  applySearch(searchTerm: string, teams: TeamsComponentListModel[]): TeamsComponentListModel[] {
    let fuse: Fuse<TeamsComponentListModel> | undefined;
    const fuseOptions: IFuseOptions<TeamsComponentListModel> = {
      keys: ['name'],
      includeScore: true,
      threshold: 0.3, // Adjust this value for fuzziness; lower values mean stricter matching
    };

    if (searchTerm.trim() === '') {
      // Return all teams if search term is empty
      return teams;
    }

    fuse = new Fuse(teams, fuseOptions);
    const results = fuse.search(searchTerm);
    const filteredTeams = results.map(result => result.item);

    return filteredTeams;
  }

  trackByTeamId(i: number, team: TeamListModel): string {
    return team._id;
  }
}
