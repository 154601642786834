import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { selectLanguage } from '@ninety/ui/legacy/state/index';
import { FilterBarActions } from '@ninety/web/pages/shared/layouts';

/**
 * This is just an example tiered page for rocks.
 * Mainly to help demonstrate how the 'hasFeatureAccessGuard' route guard  works.
 * This is meant for the team to replace with their own content or completely remove if not needed.
 * */
@Component({
  selector: 'ninety-rocks-tiered-page',
  standalone: true,
  imports: [CommonModule, TerraIconModule, NinetyCMModule, LetDirective],
  template: `<ninety-cm-tiered-page *ngrxLet="language$ as language">
    <div headline>Achieve Your Goals with {{ language.meeting.quarterly }} {{ language.rock.items }}</div>
    <div description>
      Unlock the {{ language.rock.items }} tool to set and track {{ language.meeting.quarterly | lowercase }} goals.
      Keep your team focused, boost accountability, and consistently hit your targets.
    </div>
    <button ninety-cm-button>
      <terra-icon icon="lightning" variant="fill" />
      Explore Essentials Plan
    </button>
    <a href="https://help.ninety.io/introduction-to-rocks" rel="external" target="_blank"
      >Learn more about {{ language.rock.items }}</a
    >
  </ninety-cm-tiered-page>`,
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RocksTieredPageComponent implements OnInit {
  private readonly store = inject(Store);
  protected readonly language$ = this.store.select(selectLanguage);

  ngOnInit(): void {
    this.store.dispatch(FilterBarActions.hide());
  }
}
