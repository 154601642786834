import { createActionGroup, props } from '@ngrx/store';
import { AgChartClickEvent, AgNodeClickEvent } from 'ag-charts-types/dist/types/src/chart/eventOptions';
import { AgChartLegendClickEvent } from 'ag-charts-types/dist/types/src/chart/legendOptions';

import { WithoutNativeHTMLEvent } from '../config/themes';

/**
 * Actions dispatched from AG Chart [Listeners](https://ag-grid.com/charts/options/#reference-AgChartOptions-listeners).
 * Dispatched on every chart that uses the {@link DISCO_THEME} token to define their theme.
 */
export const DiscoAnalyticsActions = createActionGroup({
  source: 'Disco - Analytics',
  events: {
    'Series Click': props<{ event: WithoutNativeHTMLEvent<AgNodeClickEvent<'seriesNodeClick', unknown>> }>(),
    'Chart Click': props<{ event: WithoutNativeHTMLEvent<AgChartClickEvent> }>(),
    'Legend Item Click': props<{ event: WithoutNativeHTMLEvent<AgChartLegendClickEvent> }>(),
  },
});
