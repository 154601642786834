<ng-container *ngrxLet="vm$ as vm">
  <section class="content">
    <!-- Show Status Color Indicator Toggle -->
    <terra-switch
      data-cy="scorecard-settings_toggle-button-colors"
      id="scorecard-settings_toggle-status-indicator"
      [(ngModel)]="vm.settings.measurableStatusIcon"
      (ngModelChange)="updateSettings('measurableStatusIcon', $event)">
      Show {{ vm.language.measurable.item }} status indicators
      <terra-description>
        <p>Display icon status indicators with colors based on each {{ vm.language.measurable.item }}'s target.</p>
        <p class="kpi-status-description">{{ vm.kpiStatusDescription }}</p>
      </terra-description>
    </terra-switch>

    <div class="switch-row">
      <!-- Show Owner Column Toggle -->
      <terra-switch
        data-cy="scorecard-settings_toggle-button-owner"
        [(ngModel)]="vm.settings.measurableShowOwner"
        (ngModelChange)="updateSettings('measurableShowOwner', $event)">
        Show Owner column
        <terra-description>Display the owner of the {{ vm.language.measurable.item }}.</terra-description>
      </terra-switch>

      <!-- Show Goal Column Toggle -->
      <terra-switch
        data-cy="scorecard-settings_toggle-button-goal"
        [(ngModel)]="vm.settings.measurableShowDefaultGoal"
        (ngModelChange)="updateSettings('measurableShowDefaultGoal', $event)">
        Show {{ vm.language.measurable.goal }} column
        <terra-description>
          Display {{ vm.language.measurable.item }} {{ vm.language.measurable.goals }}.
        </terra-description>
      </terra-switch>
    </div>

    <div class="switch-row">
      <!-- Show Average Column Toggle -->
      <terra-switch
        data-cy="scorecard-settings_toggle-button-average"
        id="scorecard-settings_toggle-average-column"
        [(ngModel)]="vm.settings.measurableAverageScore"
        (ngModelChange)="updateSettings('measurableAverageScore', $event)">
        Show Average column
        <terra-description>Display the average of all the data points in the selected date range.</terra-description>
      </terra-switch>

      <!-- Show Total Column Toggle -->
      <terra-switch
        data-cy="scorecard-settings_toggle-button-total"
        id="scorecard-settings_toggle-total-column"
        [(ngModel)]="vm.settings.measurableTotalScore"
        (ngModelChange)="updateSettings('measurableTotalScore', $event)">
        Show Total Column
        <terra-description>Display the sum total of all the data points in the selected date range.</terra-description>
      </terra-switch>
    </div>

    <div class="switch-row">
      <!-- Show Current Period Toggle -->
      <terra-switch
        data-cy="scorecard-settings_toggle-button-current-period"
        [(ngModel)]="vm.settings.measurableShowCurrentPeriod"
        (ngModelChange)="updateSettings('measurableShowCurrentPeriod', $event)">
        Show current period
        <terra-description> Display the current period on the {{ vm.language.scorecard.item }}. </terra-description>
      </terra-switch>
    </div>

    <!-- Weekly Start Day -->
    <terra-form-field class="form-field__weekly-start-day">
      <terra-form-label>Weekly start day</terra-form-label>
      <terra-select
        id="scorecard-settings_select-weekly-start-date"
        data-cy="scorecard-settings_weekly-start-date"
        [(ngModel)]="vm.settings.measurableWeeklyStartDay"
        [compareWith]="startDayIsSame"
        (ngModelChange)="updateSettings('measurableWeeklyStartDay', $event)">
        <terra-option
          attr.data-cy="scorecard-settings_start-day-option-{{ startDay.day }}"
          *ngFor="let startDay of measurableWeeklyStartDays"
          [value]="startDay">
          {{ startDay.day }}
        </terra-option>
      </terra-select>
    </terra-form-field>
  </section>
</ng-container>
