import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { asapScheduler, delay, merge, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { TerraInputBoolean } from '../../../models/terra-input-boolean.models';
import { TerraErrorStateMatcher } from '../../forms/terra-error-state-matcher';
import { TerraRadioButtonComponent } from '../terra-radio-button';

let radioGroupUniqueId = 0;

@Component({
  selector: 'terra-radio-group',
  standalone: true,
  exportAs: 'terraRadioGroup',
  imports: [CommonModule],
  templateUrl: './terra-radio-group.component.html',
  styleUrls: ['./terra-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'radiogroup',
  },
})
export class TerraRadioGroupComponent implements AfterContentInit, OnDestroy, ControlValueAccessor {
  @ContentChildren(TerraRadioButtonComponent) _radioButtons!: QueryList<TerraRadioButtonComponent>;

  /** Emits event when the radio group's value changes. */
  // eslint-disable-next-line @angular-eslint/no-output-native, @typescript-eslint/no-explicit-any
  @Output() readonly change: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Sets whether the radio group shows in compact mode
   * @default false
   */
  @Input() get compact(): boolean {
    return this._compact;
  }
  set compact(value: TerraInputBoolean) {
    this._compact = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _compact = false;

  /**
   * Sets the name for all radio buttons within the group
   */
  @Input() get name(): string {
    return this._name;
  }
  set name(newName: string) {
    this._name = newName;
    this._radioButtons?.forEach(radioButton => {
      radioButton.name = this._name;
    });
  }
  private _name = `terra-radio-group-${radioGroupUniqueId++}`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _value!: any;

  private _destroyed$ = new Subject<void>();

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onChange!: (_: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onTouched!: (_: unknown) => void;

  constructor(
    private readonly _terraErrorStateMatcher: TerraErrorStateMatcher,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    @Self() protected ngControl: NgControl
  ) {
    this.ngControl.valueAccessor = this;
  }

  protected _isFormControlInvalid(): boolean {
    return this._terraErrorStateMatcher.isErrorState(this.ngControl);
  }

  ngAfterContentInit() {
    // Listen to status changes on the form control to show error state
    const formControlsStatusChanges$ = this.ngControl.statusChanges?.pipe(
      startWith(this.ngControl.status),
      tap(() => this._changeDetectorRef.markForCheck())
    );

    // Listen to changes on Query List (e.g. if radio button is remove/added)
    // SwitchMap - When radio button list updates, we need to re-subscribe to the change events
    const radioButtonsChanges$ = this._radioButtons.changes.pipe(
      startWith(this._radioButtons),
      delay(0, asapScheduler),
      tap((radioButtons: QueryList<TerraRadioButtonComponent>) => this._updateRadioButtons(radioButtons)),
      switchMap((newRadioButtons: QueryList<TerraRadioButtonComponent>) => {
        return merge(...newRadioButtons.map(radioButton => radioButton.change));
      }),
      tap(value => this._onRadioButtonChange(value))
    );

    const streams = [formControlsStatusChanges$, radioButtonsChanges$];
    merge(...streams)
      .pipe(takeUntil(this._destroyed$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _updateRadioButtons(radioButtons: QueryList<TerraRadioButtonComponent>): void {
    radioButtons.forEach(radioButton => {
      radioButton.name = this._name;
      radioButton.disabled = radioButton.disabled || this.ngControl.disabled || false;
      radioButton.checked = radioButton.value === this._value;
      radioButton.hasError = this._isFormControlInvalid();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _onRadioButtonChange(value: any): void {
    this._value = value;
    this.change.emit(this._value);
    this._onChange(this._value);
    this._onTouched(true);

    this._radioButtons?.forEach(radioButton => {
      radioButton.checked = radioButton.value === this._value;
      radioButton.hasError = this._isFormControlInvalid();
    });
  }

  /**
   * Focus the selected radio button if it exists,
   * otherwise focus the first non-disabled radio button
   */
  public focus(): void {
    const selectedRadioButton = this._radioButtons?.find(radioButton => radioButton.value === this._value);
    const firstNonDisabledRadioButton = this._radioButtons?.find(radioButton => !radioButton.disabled);
    if (selectedRadioButton) {
      selectedRadioButton._focus();
    } else {
      firstNonDisabledRadioButton?._focus();
    }
  }

  /**
   * Blur all radio buttons in the radio group
   */
  public blur(): void {
    this._radioButtons?.forEach(radioButton => {
      radioButton._blur();
    });
    this._onTouched(true);
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public writeValue(value: any): void {
    this._value = value;
    this._radioButtons?.forEach(radioButton => {
      radioButton.checked = radioButton.value === this._value;
    });
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  setDisabledState?(isDisabled: boolean): void {
    if (this._radioButtons) {
      this._radioButtons.forEach(radioButton => {
        radioButton.disabled = isDisabled;
      });
    }
  }
}
