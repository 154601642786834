import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraDescriptionModule } from '../../forms/terra-description';
import { TerraErrorModule } from '../../forms/terra-error';
import { TerraFormLabelModule } from '../../forms/terra-form-label';
import { TerraCheckboxGroupComponent } from '../terra-checkbox-group';

import { TerraCheckboxComponent } from './terra-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    TerraCheckboxComponent,
    TerraDescriptionModule,
    TerraFormLabelModule,
    TerraErrorModule,
    TerraCheckboxGroupComponent,
  ],
  exports: [
    TerraCheckboxComponent,
    TerraDescriptionModule,
    TerraFormLabelModule,
    TerraErrorModule,
    TerraCheckboxGroupComponent,
  ],
})
export class TerraCheckboxModule {}
