<div class="terra-radio-group">
  <div class="terra-radio-group__label">
    <ng-content select="terra-form-label" />
  </div>
  <div class="terra-radio-group__content" [ngClass]="{ 'terra-radio-group__content--compact': compact }">
    <ng-content select="terra-radio-button" (change)="onRadioButtonChange($event)" />
  </div>
  <div class="terra-radio-group__context">
    <ng-container *ngIf="_isFormControlInvalid()">
      <ng-content select="terra-error" />
    </ng-container>
  </div>
</div>
