<ninety-card class="ninety-card">
  <ninety-card-header header>
    <div class="terra-title">Suppression List</div>
    <terra-search-input
      [formControl]="suppressionListSearch"
      placeholder="Search email suppression list"
      (keyup.enter)="searchSuppressionList()" />
  </ninety-card-header>

  <div class="card-content" *ngIf="!!suppressionList">
    <mat-list class="ninety-list" *ngIf="suppressionList.length; else emailNotFound">
      <mat-list-item
        *ngFor="
          let record of suppressionList | emailAddressSearch : suppressionListSearch.value;
          trackBy: trackByEmail
        ">
        <div matListItemLine class="list-item">
          <div class="email">{{ record.EmailAddress }}</div>
          <div>{{ record.Reason }}</div>
          <div>{{ record.LastUpdateTime }}</div>
          <ninety-button
            flat
            icon
            negative
            class="delete-btn"
            (click)="removeFromSuppressionList(record.EmailAddress)"
            ninetyTooltip="Remove from suppression list">
            <terra-icon icon="delete" />
          </ninety-button>
        </div>
      </mat-list-item>
    </mat-list>

    <ng-template #emailNotFound>
      <ninety-alert alertClass="warning">Email not found in suppression list</ninety-alert>
    </ng-template>
  </div>
</ninety-card>
