import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

import { TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../../_components/buttons/button/button.component';
import { ButtonRowComponent } from '../../../../_components/buttons/button-row/button-row.component';

import { ConfirmDialogData } from './models';

@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDividerModule,
    MatDialogModule,
    TerraIconModule,
    ButtonComponent,
    ButtonRowComponent,
  ],
})
export class ConfirmDialogComponent implements OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    this.dialogRef.addPanelClass('confirm-dialog-container');
    this.data = new ConfirmDialogData(data);
  }

  ngOnDestroy(): void {
    (document.activeElement as HTMLButtonElement).blur();
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
