import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ConfirmLicenseChangeDialogData } from '@ninety/ui/legacy/components/billing-dialogs/confirm-license-change/models/confirm-license-change-dialog-data';
import { SubscriptionV3 } from '@ninety/ui/legacy/shared/models/billing-v3/subscription';
import { SubscriptionConfigurationV3 } from '@ninety/ui/legacy/shared/models/billing-v3/subscription-configuration-v3';

import { PricingPlan } from '../../../_shared/models/billing-v3/pricing-plan';
// aliasing as ...V3 for now to avoid alias collision and since this is currently unused
import { InvoiceV2 } from '../../../_shared/models/billingv2/InvoiceV2-response';
import { BillingCounts } from '../../../_shared/models/billingv2/billing-counts-response';
import { SubscriptionConfiguration } from '../../../_shared/models/billingv2/subscription-configuration.model';

export const BillingStateActions = createActionGroup({
  source: 'Billing',
  events: {
    Init: emptyProps(),
    'Get Active Subscription Configurations': emptyProps(),
    'Get Active Subscription Configurations Success': props<{
      subscriptionConfigurations: SubscriptionConfiguration[];
    }>(),
    'Get Active Subscription Configurations Failure': props<{ error: unknown; customMessage: string }>(),
    'Get Company Billing Counts': emptyProps(),
    'Get Company Billing Counts Success': props<{ companyBillingCounts: BillingCounts }>(),
    'Get Company Billing Counts Failure': props<{ error: unknown; customMessage: string }>(),
    'Get Upcoming Invoice': emptyProps(),
    'Get Upcoming Invoice Success': props<{ invoice: InvoiceV2 }>(),
    'Get Upcoming Invoice Failure': props<{ error: unknown; customMessage: string }>(),

    /** for non billing v3 companies for anything that affects # of licenses (adding paid users, role change, or invite) */
    'Get Billing Counts After Changing Number of Paid Users': emptyProps(),
    'Get Billing Counts After Changing Number of Paid Users Success': props<{ companyBillingCounts: BillingCounts }>(),

    /** Commercial Model */
    'Open Pricing Plans Modal': emptyProps(),
    'Get Commercial Model Subscriptions': emptyProps(),
    'Open Confirm License Change Dialog': props<{ data: ConfirmLicenseChangeDialogData }>(),
    'Cancel License Change': props<{ userId: string }>(),
    'Change License Count': props<{ quantity: number }>(),

    'Get Billing Counts After Stripe Redirect Success': props<{ companyBillingCounts: BillingCounts }>(),
    'Clear Stripe Query Params': emptyProps(),

    'Dismiss Updated Subscription Notification': emptyProps(),
    'Dismiss Free Plan Notification': emptyProps(),
    'Get Commercial Model Subscriptions Success': props<{
      pricingPlans: PricingPlan[];
      currentPlan: SubscriptionConfigurationV3;
    }>(),

    'Select Plan': props<{ plan: SubscriptionConfigurationV3 }>(),
    'Select Freemium': emptyProps(), // AKA: cancel subscription
    'Route To Stripe Portal': props<{ redirectUrl: string }>(),
    'Get Updated Subscription': emptyProps(),
    'Get Updated Subscription Success': props<{ subscription: SubscriptionV3 }>(),
    'Get Updated Subscription Failed': props<{ error: unknown }>(),

    'Get Billing Portal Url': emptyProps(),
  },
});
