import { coerceNumberProperty } from '@angular/cdk/coercion';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TerraInputBaseClass } from '../terra-input-base/terra-input-base';

@Component({
  selector: 'terra-multiline-input',
  standalone: true,
  exportAs: 'terraMultilineInput',
  imports: [CommonModule, FormsModule, TextFieldModule],
  templateUrl: './terra-multiline-input.component.html',
  styleUrls: ['./../terra-input-base/terra-input-base.scss', './terra-multiline-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TerraInputBaseClass,
      useExisting: TerraMultilineInputComponent,
    },
  ],
})
export class TerraMultilineInputComponent extends TerraInputBaseClass {
  /**
   * Set the maxlength of characters allowed in the input
   */
  @Input() get maxlength(): number | undefined {
    return this._maxlength;
  }
  set maxlength(value: string | number | undefined) {
    this._maxlength = coerceNumberProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _maxlength?: number | undefined;

  /**
   * Set the maximum number of rows the input can grow.  A scrollbar will show if the user exceeds the maxRows.
   */
  @Input() get maxRows(): number | undefined {
    return this._maxRows;
  }
  set maxRows(value: string | number | undefined) {
    this._maxRows = coerceNumberProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _maxRows?: number | undefined;

  /**
   * Set the minimum number of rows the input will display.
   */
  @Input() get minRows(): number | undefined {
    return this._minRows;
  }
  set minRows(value: string | number | undefined) {
    this._minRows = coerceNumberProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _minRows?: number | undefined = 1;
}
