import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

import { FocusedDirective } from '../../../_shared/directives/focused/focused.directive';

export type NinetyButtonSizes = 'standard' | 'large';

/**
 * Add "icon" attribute to any button to signify that the button is an icon button
 *  Example: <ninety-button flat primary icon ....
 *  If you want the icon to be rounded, just add the "rounded" class
 */
@Component({
  selector: `
   ninety-button[flat], ninety-button[flat][primary], ninety-button[flat][negative], ninety-button[flat][positive],
   ninety-button[filled][primary], ninety-button[filled][black], ninety-button[filled][negative], ninety-button[filled][positive],
   ninety-button[stroked], ninety-button[stroked][primary], ninety-button[compact][primary],
   ninety-button[compact][secondary], ninety-button[compact][tertiary], ninety-button[compact][inverted],
   ninety-button[compact][negative], ninety-button[compact][disabled], ninety-button[dynamic]`,
  standalone: true,
  template: `<button [disabled]="disabled" [ninetyFocused]="focused" [type]="type" [attr.form]="form || null">
    <div class="button-content">
      <ng-content></ng-content>
    </div>
  </button> `,
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FocusedDirective],
})
export class ButtonComponent implements OnInit, OnDestroy {
  // Add initial root component class
  @HostBinding('class.ninety-button') class = true;

  @Input() disabled: boolean;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() focused: boolean;
  /**
   * Optional property to link button to a form
   */
  @Input() form = '';
  /**
   * Optional property to specify large size (48px) buttons
   */
  @Input() size: NinetyButtonSizes = 'standard';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this._setSize(this.size);

    this.el.nativeElement.addEventListener('click', this._haltDisabledEvents, true);
  }

  ngOnDestroy(): void {
    this.el.nativeElement.removeEventListener('click', this._haltDisabledEvents);
  }

  _setSize(size: 'standard' | 'large'): void {
    this.renderer.addClass(this.el.nativeElement, `ninety-button--size-${size}`);
  }

  _haltDisabledEvents = (event: Event): void => {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  };
}
