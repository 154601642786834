import { createReducer, on } from '@ngrx/store';

import { LinkedItemTypeEnum } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import {
  LinkedCascadingMessageWithMeta,
  LinkedGoalWithMeta,
  LinkedHeadlineWithMeta,
  LinkedIssueWithMeta,
  LinkedLearningTaskWithMeta,
  LinkedLearningTopicWithMeta,
  LinkedMilestoneWithMeta,
  LinkedRockWithMeta,
  LinkedTodoWithMeta,
} from '@ninety/ui/legacy/shared/models/linked-items/linked-items-response.model';

import { LinkedItemsStateModel, linkedItemsStateModel } from './linked-items-state.model';
import { LinkedItemsActions } from './linked-items.actions';

export const LinkedItemsStateReducer = createReducer(
  linkedItemsStateModel,
  on(
    LinkedItemsActions.resetLinkedItems,
    (state): LinkedItemsStateModel => ({
      ...state,
      linkedItems: null,
      loading: false,
      channelId: '',
      shouldBroadcast: false,
    })
  ),
  on(
    LinkedItemsActions.getLinkedItem,
    (state, { id, linkedItemType }): LinkedItemsStateModel => ({
      ...state,
      loading: true,
      _id: id,
      type: linkedItemType,
    })
  ),
  on(
    LinkedItemsActions.getLinkedItemSuccess,
    (state, { response }): LinkedItemsStateModel => ({ ...state, linkedItems: response, loading: false })
  ),
  on(
    LinkedItemsActions.getLinkedItemFailure,
    (state): LinkedItemsStateModel => ({ ...state, linkedItems: null, error: true, loading: false })
  ),
  on(
    LinkedItemsActions.unlinkItemSuccess,
    LinkedItemsActions.unlinkItemFromRealtime,
    (state, { id, linkedItemType }): LinkedItemsStateModel => {
      switch (linkedItemType) {
        case LinkedItemTypeEnum.todo: {
          const newTodosList = state.linkedItems.todos.filter(todo => todo.linkId !== id);
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              todos: newTodosList,
              totalTodos: newTodosList.length,
            },
          };
        }
        case LinkedItemTypeEnum.rock: {
          const newRocksList = state.linkedItems.rocks.filter(rock => rock.linkId !== id);
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              rocks: newRocksList,
              totalRocks: newRocksList.length,
            },
          };
        }
        case LinkedItemTypeEnum.issue: {
          const newIssuesList = state.linkedItems.issues.filter(issue => issue.linkId !== id);
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              issues: newIssuesList,
              totalIssues: newIssuesList.length,
            },
          };
        }
        case LinkedItemTypeEnum.headline: {
          const newHeadlinesList = state.linkedItems.headlines.filter(headline => headline.linkId !== id);
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              headlines: newHeadlinesList,
              totalHeadlines: newHeadlinesList.length,
            },
          };
        }
        case LinkedItemTypeEnum.cascadingMessage: {
          const newCascadingMessagesList = state.linkedItems.cascadingMessages.filter(
            cascadingMessage => cascadingMessage.linkId !== id
          );
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              cascadingMessages: newCascadingMessagesList,
              totalCascadingMessages: newCascadingMessagesList.length,
            },
          };
        }
        case LinkedItemTypeEnum.learningTask: {
          const newLearningTasksList = state.linkedItems.learningTasks?.filter(
            learningTask => learningTask.linkId !== id
          );
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              learningTasks: newLearningTasksList,
              totalLearningTasks: newLearningTasksList.length,
            },
          };
        }
        case LinkedItemTypeEnum.learningTopic: {
          const newLearningTopicsList = state.linkedItems.learningTopics?.filter(
            learningTopic => learningTopic.linkId !== id
          );
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              learningTopics: newLearningTopicsList,
              totalLearningTopics: newLearningTopicsList.length,
            },
          };
        }
        case LinkedItemTypeEnum.milestone: {
          const newMilestonesList = state.linkedItems.milestones?.filter(milestone => milestone.linkId !== id);
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              milestones: newMilestonesList,
              totalMilestones: newMilestonesList.length,
            },
          };
        }
        case LinkedItemTypeEnum.goal: {
          const newGoalsList = state.linkedItems.goals?.filter(goal => goal.linkId !== id);
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              goals: newGoalsList,
              totalGoals: newGoalsList.length,
            },
          };
        }
      }
    }
  ),
  on(
    LinkedItemsActions.unlinkItemFailure,
    (state): LinkedItemsStateModel => ({ ...state, linkedItems: null, error: true })
  ),
  on(
    LinkedItemsActions.linkItemFromRealtime,
    LinkedItemsActions.displayCreatedLinkedItem,
    (state, { linkedItem }): LinkedItemsStateModel => {
      switch (linkedItem.linkedItemType) {
        case LinkedItemTypeEnum.todo: {
          const newTodosList = [...state.linkedItems.todos, linkedItem.linkedItem as LinkedTodoWithMeta];
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              todos: newTodosList,
              totalTodos: newTodosList.length,
            },
          };
        }
        case LinkedItemTypeEnum.rock: {
          const newRocksList = [...state.linkedItems.rocks, linkedItem.linkedItem as LinkedRockWithMeta];
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              rocks: newRocksList,
              totalRocks: newRocksList.length,
            },
          };
        }
        case LinkedItemTypeEnum.issue: {
          const newIssuesList = [...state.linkedItems.issues, linkedItem.linkedItem as LinkedIssueWithMeta];
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              issues: newIssuesList,
              totalIssues: newIssuesList.length,
            },
          };
        }
        case LinkedItemTypeEnum.headline: {
          const newHeadlinesList = [...state.linkedItems.headlines, linkedItem.linkedItem as LinkedHeadlineWithMeta];
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              headlines: newHeadlinesList,
              totalHeadlines: newHeadlinesList.length,
            },
          };
        }
        case LinkedItemTypeEnum.cascadingMessage: {
          const newCascadingMessagesList = [
            ...state.linkedItems.cascadingMessages,
            linkedItem.linkedItem as LinkedCascadingMessageWithMeta,
          ];
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              cascadingMessages: newCascadingMessagesList,
              totalCascadingMessages: newCascadingMessagesList.length,
            },
          };
        }
        case LinkedItemTypeEnum.learningTask: {
          const newLearningTasksList = [
            ...state.linkedItems.learningTasks,
            linkedItem.linkedItem as LinkedLearningTaskWithMeta,
          ];
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              learningTasks: newLearningTasksList,
              totalLearningTasks: newLearningTasksList.length,
            },
          };
        }
        case LinkedItemTypeEnum.learningTopic: {
          const newLearningTopicsList = [
            ...state.linkedItems.learningTopics,
            linkedItem.linkedItem as LinkedLearningTopicWithMeta,
          ];
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              learningTopics: newLearningTopicsList,
              totalLearningTopics: newLearningTopicsList.length,
            },
          };
        }
        case LinkedItemTypeEnum.milestone: {
          const newMilestonesList = [...state.linkedItems.milestones, linkedItem.linkedItem as LinkedMilestoneWithMeta];
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              milestones: newMilestonesList,
              totalMilestones: newMilestonesList.length,
            },
          };
        }
        case LinkedItemTypeEnum.goal: {
          const newGoalsList = [...state.linkedItems.goals, linkedItem.linkedItem as LinkedGoalWithMeta];
          return {
            ...state,
            linkedItems: {
              ...state.linkedItems,
              goals: newGoalsList,
              totalGoals: newGoalsList.length,
            },
          };
        }
      }
    }
  ),
  on(
    LinkedItemsActions.subscribeToLinkedItemsChannel,
    (state, { channelId }): LinkedItemsStateModel => ({
      ...state,
      channelId,
      shouldBroadcast: true,
    })
  )
);
