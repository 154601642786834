<div class="universal-create" *ngIf="mode$ | async as mode" [ngClass]="[mode]" data-cy="universal-create-dialogue">
  <mat-toolbar class="universal-create__header">
    <div class="create-type universal-create__header-type">
      <h2 class="universal-create__header-title" data-cy="universal-create_title">Create</h2>
      <ninety-item-type-select
        [useTerra]="true"
        [disabled]="data.disableItemTypeSelect || mode === dialogModeEnum.collapsed"
        [itemType]="itemType"
        [showCascadedOption]="showCascadingMessageOption"
        (itemTypeChange)="setItemType($event)"
        attr.data-cy="item-type-select_{{ itemName }}"></ninety-item-type-select>
    </div>

    <div class="universal-create__header-controls">
      <!-- underscore collapse -->
      <button
        data-cy="universal-create_minimize-modal"
        *ngIf="mode !== dialogModeEnum.collapsed"
        mat-button
        class="action collapse"
        (click)="onClickChangeMode(dialogModeEnum.collapsed)">
        <terra-icon icon="minimize" />
      </button>

      <!-- underscore maximize -->
      <button
        data-cy="universal-create_maximize-modal"
        *ngIf="mode === dialogModeEnum.collapsed"
        mat-button
        class="action underscore-maximize"
        (click)="onClickChangeMode(dialogModeEnum.expanded)">
        <terra-icon icon="minimize" />
      </button>

      <!-- minimize -->
      <button
        data-cy="universal-create_minimize-to-mini-modal"
        *ngIf="mode === dialogModeEnum.expanded"
        mat-button
        class="action minimize"
        (click)="onClickChangeMode(dialogModeEnum.mini)">
        <terra-icon icon="arrows-in-simple" />
      </button>

      <!-- maximize -->
      <button
        data-cy="universal-create_maximize-modal"
        *ngIf="mode === dialogModeEnum.mini"
        mat-button
        class="action expand-to-full"
        (click)="onClickChangeMode(dialogModeEnum.expanded)">
        <terra-icon icon="arrows-out-simple" />
      </button>

      <!-- expand to mini -->
      <button
        *ngIf="mode === dialogModeEnum.collapsed"
        mat-button
        class="action expand-to-mini"
        (click)="onClickChangeMode(dialogModeEnum.mini)">
        <terra-icon icon="arrows-out-simple" />
      </button>

      <!-- close -->
      <button data-cy="universal-create_close-modal" mat-button class="action close" (click)="onClickClose()">
        <terra-icon icon="x" />
      </button>
    </div>
  </mat-toolbar>
  <ng-container *ngIf="item && mode !== dialogModeEnum.collapsed">
    <mat-dialog-content *ngIf="stateService.language as lang" class="universal-create__body">
      <div class="universal-create__content universal-create__content--short-top">
        <div class="universal-create__title">
          <ninety-user-select
            [userId]="item?.userId"
            [ninetyContactCardHover]="item?.userId"
            orientation="above"
            [users]="item?.isPersonal ? userService.users : teamUsers"
            [selectedUserIds]="selectedUserIds"
            [disableSaveIfNoSelectedUsers]="true"
            [disabled]="stateService.isLiteUser"
            [doNotFilterSelectedUsers]="true"
            [multiple]="(itemType === ItemType.todo || itemType === ItemType.rock) && !data.fromMilestone"
            [required]="true"
            [submitAllUsersOnEverySelect]="true"
            [updateAlways]="true"
            (selectUsersIds)="addUsers($event)"
            (removeUser)="removeSelectedUser()"
            (selectUser)="addUser($event)"
            [useTerra]="true" />
          <terra-form-field>
            <terra-form-label>Title</terra-form-label>
            <terra-text-input
              cdkFocusInitial
              #itemTitle
              required
              aria-label="Add a title for the {{ itemName }}..."
              [(ngModel)]="item.title"
              placeholder="Add a title for the {{ itemName }}..."
              data-cy="universal-create_name-input"
              attr.data-cy-item="{{ itemName }}" />
          </terra-form-field>
        </div>

        <ninety-text-editor
          [(text)]="item.description"
          placeholder="Add a description (optional)..."
          data-cy="universal-create_description-input">
        </ninety-text-editor>

        <!-- Rock Company/Department Switches for Rocks V3-->
        <ng-container *ngIf="itemType === ItemType.rock && !!this.item.teamId">
          <div class="rock-v3-type-selection universal-create__rock-v3-type-selection">
            <ninety-rock-type-selector
              *ngIf="this.item.teamId === this.seniorLeadershipTeamId"
              data-cy="rock-card_company-toggle"
              [rockType]="lang.rock.company"
              [rockLanguage]="lang.rock.item"
              [isSelected]="item?.levelCode === LevelCode.company || item?.levelCode === LevelCode.companyAndDepartment"
              [disabled]="(isUserOnSLT() | async) === false"
              (selectedRockType)="levelCodeCompanyChange($event)" />
            <ninety-rock-type-selector
              *ngIf="this.item.teamId !== this.seniorLeadershipTeamId"
              data-cy="rock-card_departamental-toggle"
              [rockType]="lang.rock.departmental"
              [rockLanguage]="lang.rock.item"
              [isSelected]="
                item?.levelCode === LevelCode.department || item?.levelCode === LevelCode.companyAndDepartment
              "
              [disabled]="false"
              (selectedRockType)="levelCodeDepartmentChange($event)" />
          </div>
        </ng-container>

        <div class="universal-create__controls">
          <!-- Priority select for issue -->
          <terra-form-field
            *ngIf="itemType === ItemType.issue && stateService.company.settings.issueRating"
            optional="true"
            data-cy="universal-create_priority-select">
            <terra-form-label>Priority</terra-form-label>
            <terra-select
              [disabled]="!stateService.isManageeOrAbove || item?.archived"
              [(ngModel)]="item.rating"
              placeholder="Select a priority...">
              <terra-option data-cy="universal-create_priority-none" [value]="undefined"> None </terra-option>
              <terra-option data-cy="universal-create_priority-1" [value]="1">1</terra-option>
              <terra-option data-cy="universal-create_priority-2" [value]="2">2</terra-option>
              <terra-option data-cy="universal-create_priority-3" [value]="3">3</terra-option>
              <terra-option data-cy="universal-create_priority-4" [value]="4">4</terra-option>
              <terra-option data-cy="universal-create_priority-5" [value]="5">5</terra-option>
            </terra-select>
          </terra-form-field>
          <!-- Who field -->
          <div class="_terra-migration-approved-override--terra-mat-form-field" *ngIf="itemType === ItemType.issue">
            <terra-form-label
              >Who
              <span class="_terra-migration-approved-override--terra-form-optional">(optional)</span></terra-form-label
            >
            <mat-form-field class="_terra-migration-approved-override--mat-form-field" appearance="outline">
              <input
                matInput
                placeholder="Select or enter who the Issue is with..."
                value=""
                [(ngModel)]="item.who"
                [matAutocomplete]="auto"
                (ngModelChange)="filterUserNames()"
                aria-label="who"
                data-cy="universal-create_item-who" />
              <mat-autocomplete #auto="matAutocomplete" panelWidth="275">
                <mat-option *ngFor="let user of filteredUsers; trackBy: trackById" [value]="user.fullName">
                  <div class="autocomplete-user-option">
                    <terra-avatar [src]="user?.metadata?.picture?.url" [initials]="user | userInitials" />
                    <span class="user-name">{{ user?.fullName }}</span>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <!-- Due date -->
          <div *ngIf="hasDueDate" class="_terra-migration-approved-override--terra-mat-form-field">
            <terra-form-label>Due date</terra-form-label>
            <mat-form-field
              class="_terra-migration-approved-override--mat-form-field"
              appearance="outline"
              data-cy="universal-create_due-date">
              <input
                matInput
                placeholder=""
                required
                [matDatepicker]="dueDatePicker"
                [min]="today"
                [(ngModel)]="dueDate"
                aria-label="due date"
                data-cy="universal-create_due-date-input"
                (focus)="dueDatePicker.open()"
                (ngModelChange)="updateDueDate()" />
              <mat-datepicker-toggle matSuffix [for]="dueDatePicker">
                <terra-icon matDatepickerToggleIcon icon="calendar" size="16" />
              </mat-datepicker-toggle>
              <mat-datepicker #dueDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- To-do Repeat -->
          <terra-form-field *ngIf="itemType === ItemType.todo">
            <terra-form-label>Repeat</terra-form-label>
            <terra-select [(ngModel)]="item.repeat" [required]="true">
              <terra-option *ngFor="let enum of TodoRepeatType | keyvalue : returnZero" [value]="enum.value">
                {{ enum.value }}
              </terra-option>
            </terra-select>
          </terra-form-field>

          <!-- Rock Status -->
          <terra-form-field *ngIf="itemType === ItemType.rock" data-cy="universal-create_status-select">
            <terra-form-label>Status</terra-form-label>
            <terra-select [(ngModel)]="item.statusCode" placeholder="{{ lang.rock.item }} Status">
              <terra-option
                attr.data-cy="universal-create_status-{{ status.label }}"
                [value]="status.code"
                *ngFor="let status of rockStatusService.statuses">
                {{ status.label }}
              </terra-option>
            </terra-select>
          </terra-form-field>

          <div class="universal-create__team-row">
            <!-- Team select (multiple), there are 2 used in different situations -->
            <!-- Variation for cascaded message and headline -->
            <terra-form-field
              *ngIf="(itemType === ItemType.cascadedMessage || itemType === ItemType.headline) && item.teamIds"
              class="universal-create__control-full">
              <terra-form-label>Team</terra-form-label>
              <terra-select
                maxHeight="350"
                maxWidth="500"
                data-cy="universal-create_team-select"
                multiple
                [(ngModel)]="item.teamIds"
                (selectionChange)="checkTeamsCount()"
                placeholder="Select at least one team...">
                <terra-option
                  data-cy="universal-create_all-teams-button"
                  value="all"
                  (click)="toggleAllTeams()"
                  *ngIf="(itemType == ItemType.cascadedMessage ? teamsToCascade : teams)?.length > 1">
                  All
                </terra-option>
                <terra-option
                  *ngFor="
                    let team of itemType === ItemType.cascadedMessage
                      ? teamsToCascade
                      : (teams | terraLazyOptions : '_id');
                    trackBy: trackById
                  "
                  [value]="team._id"
                  attr.data-cy="universal-create_team-select-{{ team.name }}">
                  {{ team.name }}
                </terra-option>
              </terra-select>
            </terra-form-field>

            <!-- Variation for everything not cascaded message and headline -->
            <terra-form-field *ngIf="itemType !== ItemType.cascadedMessage && itemType !== ItemType.headline">
              <terra-form-label>Team</terra-form-label>
              <terra-select
                maxHeight="350"
                maxWidth="500"
                data-cy="universal-create_team-select"
                [(ngModel)]="item.teamId"
                (ngModelChange)="onTeamChange()"
                [disabled]="item?.isPersonal">
                <terra-select-search>
                  <terra-search-input
                    [(ngModel)]="teamSearchInput"
                    placeholder="Search teams"
                    name="team-search"
                    aria-label="user search" />
                </terra-select-search>
                <terra-option
                  [value]="team._id"
                  *ngFor="
                    let team of teams | terraLazyOptions : '_id' | nameSearch : teamSearchInput;
                    trackBy: trackById
                  "
                  attr.data-cy="universal-create_team-select-{{ team.name }}"
                  >{{ team.name }}
                  <span *terraOptionSuffix>
                    {{
                      !(selectedUserIds | ownerOnTeam : team._id)
                        ? (this.selectedUserIds.length > 1 ? 'One or more owners' : 'Owner') + ' not on team'
                        : ''
                    }}
                  </span>
                </terra-option>
                <terra-optgroup label="Other Teams:" *ngIf="otherTeams?.length">
                  <terra-option
                    *ngFor="let team of otherTeams | terraLazyOptions : '_id'; trackBy: trackById"
                    [value]="team._id">
                    {{ team.name }}
                    <span *terraOptionSuffix>
                      {{
                        !(selectedUserIds | ownerOnTeam : team._id)
                          ? (this.selectedUserIds.length > 1 ? 'One or more owners' : 'Owner') + ' not on team'
                          : ''
                      }}
                    </span>
                  </terra-option>
                </terra-optgroup>
              </terra-select>
            </terra-form-field>

            <!-- Rock Additional teams -->
            <terra-form-field *ngIf="itemType === ItemType.rock" optional="true">
              <terra-form-label>Other Teams</terra-form-label>
              <terra-select
                maxHeight="350"
                maxWidth="500"
                [(ngModel)]="item.additionalTeamIds"
                (selectionChange)="setAdditionalTeamIds($event)"
                placeholder="Add the Rock to other teams..."
                multiple>
                <terra-select-search>
                  <terra-search-input
                    [(ngModel)]="otherTeamSearchInput"
                    placeholder="Search teams"
                    name="other-teams-search"
                    aria-label="other teams search" />
                </terra-select-search>
                <terra-option
                  *ngFor="
                    let team of teams | terraLazyOptions : '_id' | nameSearch : otherTeamSearchInput;
                    trackBy: trackById
                  "
                  [value]="team._id"
                  [disabled]="
                    !!(rocksV3Flag$ | async) && !!(sharedRocks$ | async)
                      ? team._id === item?.teamId
                      : !(selectedUserIds | ownerOnTeam : team._id) || team._id === item?.teamId
                  ">
                  {{ team.name }}
                  <span *terraOptionSuffix>
                    {{
                      (itemType !== ItemType.rock || selectedUserIds?.length === 1) &&
                      teams &&
                      item?.userId &&
                      !(selectedUserIds | ownerOnTeam : team._id)
                        ? 'Owner not on team'
                        : ''
                    }}
                  </span>
                  <span *terraOptionSuffix> {{ team._id === item?.teamId ? 'Already assigned' : '' }}</span>
                </terra-option>
                <terra-optgroup label="Other Teams:" *ngIf="otherTeams?.length">
                  <terra-option
                    *ngFor="let team of otherTeams | terraLazyOptions : '_id'; trackBy: trackById"
                    [value]="team._id"
                    [disabled]="
                      !!(rocksV3Flag$ | async) && !!(sharedRocks$ | async)
                        ? team._id === item?.teamId
                        : !(selectedUserIds | ownerOnTeam : team._id) || team._id === item?.teamId
                    ">
                    {{ team.name }}
                    <span *terraOptionSuffix>
                      {{
                        (itemType !== ItemType.rock || selectedUserIds?.length === 1) &&
                        teams &&
                        item?.userId &&
                        !(selectedUserIds | ownerOnTeam : team._id)
                          ? 'Owner not on team'
                          : ''
                      }}
                    </span>
                    <span *terraOptionSuffix>
                      {{ team._id === item?.teamId ? 'Already assigned' : '' }}
                    </span>
                  </terra-option>
                </terra-optgroup>
              </terra-select>
            </terra-form-field>

            <!-- To-do public/private switch -->
            <terra-form-field *ngIf="itemType === ItemType.todo && !data.fromMilestone">
              <terra-form-label>Private To-Do</terra-form-label>
              <terra-switch
                attr.data-cy="universal-create_personal-toggle-{{ item?.isPersonal }}"
                [(ngModel)]="item.isPersonal">
                Make this To-Do private.
              </terra-switch>
            </terra-form-field>

            <!-- Interval -->
            <terra-form-field *ngIf="itemType === ItemType.issue" data-cy="universal-create_interval-select">
              <terra-form-label>Interval <span class="red">*</span></terra-form-label>
              <terra-select [(ngModel)]="item.intervalCode" placeholder="{{ lang.issue.item }}">
                <terra-option data-cy="universal-create_interval-short-term" [value]="IntervalCode.shortTerm">{{
                  lang.issue.shortTerm
                }}</terra-option>
                <terra-option data-cy="universal-create_interval-long-term" [value]="IntervalCode.longTerm">{{
                  lang.issue.longTerm
                }}</terra-option>
              </terra-select>
            </terra-form-field>

            <!-- Footnote spans 2 columns -->
            <div class="universal-create__footnote" *ngIf="!item?.isPersonal">
              <ng-container *ngIf="itemType !== ItemType.cascadedMessage; else cascadingMessage">
                Changing the team will affect which users the {{ itemName }} can be assigned to.
              </ng-container>
              <ng-template #cascadingMessage>
                Changing the team will affect which users can own the Cascading Message.
              </ng-template>
            </div>
          </div>
          <!-- End of teams subgrid -->

          <!-- Long-term issue visibility for SLT -->
          <terra-form-field
            class="universal-create__control-full"
            *ngIf="itemType === ItemType.issue && isSltTeam && item?.intervalCode === IntervalCode.longTerm">
            <terra-form-label>Public Issue</terra-form-label>
            <terra-switch [(ngModel)]="item.isPublic"
              >Make this {{ lang.issue.item }} visible to other teams on the Leadership Team's
              {{ lang.vto.item }} page.</terra-switch
            >
          </terra-form-field>

          <!-- Rock switches -->
          <ng-container *ngIf="itemType === ItemType.rock">
            <ng-container *ngIf="(rocksV3Flag$ | async) === false">
              <terra-form-field class="universal-create__control-full">
                <terra-form-label
                  >{{ isSltTeam ? lang.rock.company : lang.rock.departmental }} {{ lang.rock.item }}</terra-form-label
                >
                <terra-switch
                  attr.data-cy="universal-create_company-rock-toggle-{{ itemType === ItemType.rock }}"
                  class="company-departmental-option"
                  [ngModel]="item?.levelCode === LevelCode.companyAndDepartment"
                  (change)="levelCodeChange($event)">
                  This {{ lang.rock.item }} impacts
                  {{ isSltTeam ? 'the entire ' + (lang.rock.company | lowercase) : 'an entire team or department' }}.
                </terra-switch>
              </terra-form-field>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <terra-divider height="tall" *ngIf="item?.comments?.length || item?.attachments?.length || data.createdFrom" />

      <!-- Linking -->
      <div class="universal-create__content">
        <div class="universal-create__linked" data-cy="universal-create_linked-item-container" *ngIf="data.createdFrom">
          <terra-form-label>Linking to</terra-form-label>
          <mat-card class="universal-create__linked-card">
            <mat-card-content
              class="universal-create__linked-card-content"
              *ngIf="{ linkedItemOwner: linkedItemOwner$ | async } as vm">
              <terra-avatar
                [ninetyContactCardHover]="vm.linkedItemOwner?._id"
                [interactive]="true"
                [src]="vm.linkedItemOwner?.metadata?.picture?.url"
                [initials]="vm.linkedItemOwner | userInitials" />
              {{ linkedItemTitle }}
              <ninety-button
                flat
                (click)="removeLinkingItemReference($event)"
                matTooltip="Unlink"
                matTooltipPosition="above"
                matTooltipClass="ninety-tooltip">
                <terra-icon icon="x" size="20" />
              </ninety-button>
            </mat-card-content>
          </mat-card>
        </div>

        <!-- Copy comments/existing attachments -->
        <div *ngIf="item?.comments?.length || item?.attachments?.length" class="universal-create__copy">
          <terra-form-label>Copy</terra-form-label>
          <div class="universal-create__copy-content">
            <terra-checkbox [(ngModel)]="copyComments" *ngIf="item?.comments?.length"
              >Copy existing comments
            </terra-checkbox>
            <terra-checkbox
              data-cy="universal-create_copy-existing-attachments"
              *ngIf="item?.attachments?.length"
              [(ngModel)]="copyExistingAttachments"
              >Copy existing attachments
            </terra-checkbox>
          </div>
        </div>
      </div>

      <terra-divider height="tall" />

      <!-- Attachments -->
      <div class="universal-create__content">
        <ninety-attachments [fileAttachments]="item?.fileAttachments" [template]="'secondary'"></ninety-attachments>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="universal-create__footer">
      <ninety-button
        filled
        primary
        class="full-width center-all"
        [disabled]="loading || !itemIsValid()"
        (click)="checkForTooltipsAndSave()"
        data-cy="universal-create_button-item-create">
        Create {{ data.createdFrom ? 'linked' : '' }} {{ itemName }}
      </ninety-button>

      <ninety-button
        data-cy="universal-cancel-button"
        stroked
        secondary
        class="full-width close-btn"
        [disabled]="loading"
        (click)="onClickClose()">
        Cancel
      </ninety-button>
    </mat-dialog-actions>
  </ng-container>
</div>
