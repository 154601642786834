import { SubscriptionConfigurationV3 } from '@ninety/ui/legacy/shared/models/billing-v3/subscription-configuration-v3';

import { PricingPlan } from '../../../_shared/models/billing-v3/pricing-plan';
import { InvoiceV2 } from '../../../_shared/models/billingv2/InvoiceV2-response';
import { BillingCounts } from '../../../_shared/models/billingv2/billing-counts-response';
import { SubscriptionConfiguration } from '../../../_shared/models/billingv2/subscription-configuration.model';

export const BillingStateKey = 'billing';
export interface BillingState {
  subscriptionConfigurations: SubscriptionConfiguration[];
  companyBillingCounts: BillingCounts;
  upcomingInvoice?: InvoiceV2;

  /** Billing V3 (commercial model) */
  pricingPlans: PricingPlan[];

  /** Do not confuse with Subscription object that lives on company */
  currentPlan: SubscriptionConfigurationV3;
}

export const InitialBillingState: BillingState = {
  subscriptionConfigurations: [],
  companyBillingCounts: {} as BillingCounts,
  upcomingInvoice: {} as InvoiceV2,

  /** Billing V3 (commercial model) */
  pricingPlans: null,
  currentPlan: null,
};
