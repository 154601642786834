export type StripeQueryParams = {
  external: boolean;
  reason: StripeReason;
};

export enum StripeReason {
  /** the one we really care about to trigger completion of adding/inviting users after redirect */
  SubscriptionUpdateConfirmation = 'suc',

  CheckoutSession = 'cs',
  BillingPortal = 'bp',
  UpdatePaymentMethod = 'upm',
  SubscriptionUpdate = 'su',
  ReturnNoAction = 'rna',
  SubscriptionReactivate = 'sr',
  SubscriptionCancel = 'sc',
  SubscriptionQuantityUpdate = 'squ',
}
