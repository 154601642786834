import { Pipe, PipeTransform } from '@angular/core';

import { SubscriptionProvider } from '@ninety/ui/legacy/shared/models/billing-v3/subscription-provider.enum';

import { PricingPlan } from '../../../../../_shared/models/billing-v3/pricing-plan';
import { SubscriptionFrequencies } from '../../../../../_shared/models/billingv2/subscription-frequencies.enum';

@Pipe({
  name: 'filterPlansByFrequency',
  standalone: true,
})
export class FilterPlansByFrequency implements PipeTransform {
  transform(plans: PricingPlan[], frequency: SubscriptionFrequencies): PricingPlan[] {
    if (!plans || !frequency) return plans;
    /** always show Freemium plan */
    return plans.filter(plan => plan.provider === SubscriptionProvider.Ninety || plan.frequency === frequency);
  }
}
