<h2 mat-dialog-title>
  {{ data ? data.conversation.title : title + ' Settings' }}
</h2>

<terra-divider />

<mat-dialog-content>
  <ninety-alert *ngIf="questionsHaveAnswers">
    <terra-icon icon="warning-triangle" style="margin-right: 25px" />
    Changing the questions for this {{ title }} will reset any answers that have been saved.
  </ninety-alert>

  <div class="toolbar">
    <h4>Edit {{ data ? '' : 'Default ' }}Questions:</h4>
    <div class="fill-remaining-space"></div>
    <ninety-button stroked (click)="newQuestion()" class="add-btn">
      <terra-icon icon="add" />
      ADD
    </ninety-button>
  </div>
  <div *ngFor="let question of formalQuestions; let i = index" class="annual-review-questions">
    <div class="question-div">
      <terra-form-field>
        <terra-multiline-input
          [placeholder]="'Question #' + (i + 1)"
          [(ngModel)]="question.question"
          minRows="1"
          maxRows="3"
          (ngModelChange)="onChange(question)"
          aria-label="question" />
      </terra-form-field>
      <terra-form-field>
        <terra-text-input
          [placeholder]="'Question #' + (i + 1) + ' sidebar link'"
          [(ngModel)]="question.title"
          (ngModelChange)="onChange(question)"
          aria-label="question number" />
      </terra-form-field>
    </div>
    <ninety-button icon flat (click)="removeQuestion(i)" ninetyTooltip="Remove Question">
      <terra-icon icon="x" />
    </ninety-button>
  </div>
</mat-dialog-content>
<terra-divider />
<mat-dialog-actions class="flex-layout-row flex-justify-end flex-justify-center">
  <ninety-button flat icon (click)="openImageDialog()" class="blue no-hover">
    <terra-icon icon="info-circle" />
    What is a sidebar link?
  </ninety-button>
  <div class="fill-remaining-space"></div>
  <ninety-button
    flat
    (click)="reset()"
    ninetyTooltip="Reset to {{ data ? 'Company Default' : 'Original' }} Questions"
    [disabled]="!canReset">
    <terra-icon icon="reset" />
    Reset
  </ninety-button>
  <ninety-button flat stroked mat-raised-button (click)="dialogRef.close()">Cancel</ninety-button>
  <ninety-button
    filled
    primary
    mat-raised-button
    color="accent"
    (click)="save()"
    [disabled]="!somethingHasChanged || !canSave()"
    class="uppercase">
    Save
  </ninety-button>
</mat-dialog-actions>
