/* eslint-disable no-console */
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { SpanService } from './span.service';
import { TelemetrySessionService } from './telemetry-session.service';

@Injectable()
export class TelemetryHttpInterceptor implements HttpInterceptor {
  constructor(private telemetrySessionService: TelemetrySessionService, private spanService: SpanService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const sessionContext = this.telemetrySessionService.getSessionContext();
    const sessionId = this.telemetrySessionService.getSessionId();

    if (!sessionContext) {
      return next.handle(request);
    }

    let target: string;
    try {
      const url = new URL(request.url);
      target = url.pathname;
    } catch {
      target = request.url;
    }

    const sanitizeBody = (body: any): string => {
      if (!body) return '';
      const sanitized = JSON.parse(JSON.stringify(body));
      const sensitiveFields = ['password', 'token', 'secret', 'authorization'];
      sensitiveFields.forEach(field => {
        if (sanitized[field]) sanitized[field] = '[REDACTED]';
      });
      return JSON.stringify(sanitized);
    };

    const sessionSpan = this.telemetrySessionService.getSessionSpan();

    const httpSpan = this.spanService.startSpan(`${request.method} ${target}`, {
      'http.method': request.method,
      'http.url': request.url,
      'http.target': target,
      'http.body': request.body ? sanitizeBody(request.body) : '',
      'session.id': sessionId,
      parentSpan: sessionSpan,
    });

    return next.handle(request).pipe(
      tap({
        next: event => {
          if (event instanceof HttpResponse) {
            this.spanService.addSpanAttributes(httpSpan, {
              'http.status_code': event.status,
              'http.status_text': event.statusText,
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          this.spanService.addSpanAttributes(httpSpan, {
            'http.status_code': error.status,
            'http.status_text': error.statusText,
            'error.message': error.message,
          });
        },
      }),
      finalize(() => {
        this.spanService.closeSpan(httpSpan);
      })
    );
  }
}
