import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { map } from 'rxjs';

import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { CompanySettings } from '@ninety/ui/legacy/shared/models/company/company-settings';
import { selectCompanySettings } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { FeatureFlagKeys, selectFeatureFlag } from '@ninety/ui/legacy/state/index';

@Component({
  selector: 'ninety-tools-hub',
  templateUrl: './tools-hub.component.html',
  styleUrls: ['./tools-hub.component.scss'],
})
export class ToolsHubComponent {
  spinner = false;
  settings$ = this.store.select(selectCompanySettings).pipe(map(settings => cloneDeep(settings)));
  hideDash$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.insightsHideLegacyDashboard));

  constructor(public stateService: StateService, public companyService: CompanyService, private store: Store) {}

  updateSettings(key: keyof CompanySettings, value: any): void {
    const update = { [key]: value };
    this.companyService.updateSettings(update).subscribe();
  }
}
