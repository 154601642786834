import { createSelector } from '@ngrx/store';

import { selectGlobalAppState } from '..';
import { selectFreePlanNotificationDismissed } from '../billing/billing-state.selectors';
import { selectIsFreeSubscription } from '../company/subscription/subscription-state.selectors';

import { BannerNotificationStateKey } from './banner-notification.model';

export namespace BannerStateSelectors {
  export const selectBannerNotificationState = createSelector(selectGlobalAppState, globalState =>
    globalState ? globalState[BannerNotificationStateKey] : null
  );
  export const selectMessage = createSelector(selectBannerNotificationState, banner => banner);

  //used by settings page to push the menu downward if there is a banner notification.
  //selectBannerNotificationState checks the old banner. remove that after fully moving to billing V3
  export const selectIsBannerVisible = createSelector(
    selectBannerNotificationState,
    selectIsFreeSubscription,
    selectFreePlanNotificationDismissed,
    (banner, isFreeSubscription, freePlanNotificationDismissed) =>
      banner?.actionMessage?.length > 0 || (isFreeSubscription && !freePlanNotificationDismissed)
  );
}
