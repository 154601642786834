import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { GridFilterBarActions } from '@ninety/web/pages/shared/layouts';

import { GridLayoutSelectors } from '../../../../grid-layout/_state/grid-layout-state.selectors';

@Component({
  selector: 'ninety-layout-actions-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, CommonModule, MatTooltipModule, MatButtonModule, TerraIconModule],
  template: `
    <div class="layout-actions-group">
      <ng-container *ngIf="(isEditing$ | async) === false; else editActions">
        <ninety-button
          filled
          primary
          id="edit-layout-button"
          class="action-btn"
          matTooltipClass="ninety-tooltip"
          matTooltip="Enables drag and drop as well as resizing of individual sections"
          (click)="onEnterEditLayoutMode()"
          [disabled]="(isGridInitialized$ | async) === false || isFetching">
          <terra-icon icon="edit" />
          Edit Layout
        </ninety-button>
      </ng-container>
      <ng-template #editActions>
        <button
          mat-raised-button
          class="action-btn layout-action-cancel"
          (click)="onCancelLayoutChanges()"
          data-cy="layout-actions-group_cancel-button">
          Cancel
        </button>
        <button
          mat-raised-button
          class="action-btn layout-action-save"
          color="accent"
          (click)="onClickSave()"
          data-cy="layout-actions-group_save-button">
          Save
        </button>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .layout-actions-group {
        display: flex;
        gap: 8px;
      }

      #edit-layout-button {
        margin-top: 2px;

        @media screen and (max-width: 1022px) {
          display: none;
        }
      }
    `,
  ],
})
export class LayoutActionsGroupComponent {
  @Input() isFetching = false;

  isEditing$ = this.store.select(GridLayoutSelectors.selectIsLayoutModeEnabled);
  isGridInitialized$ = this.store.select(GridLayoutSelectors.selectIsGridInitialized);

  constructor(private store: Store) {}

  onEnterEditLayoutMode() {
    this.store.dispatch(GridFilterBarActions.enterLayoutMode());
  }

  onCancelLayoutChanges() {
    this.store.dispatch(GridFilterBarActions.cancelChanges());
  }

  onClickSave(): void {
    this.store.dispatch(GridFilterBarActions.requestPersistChanges());
  }
}
