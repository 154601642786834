import { InjectionToken, Optional, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AgChartTheme,
  AgLineSeriesOptions,
  AgChartClickEvent,
  AgChartLegendClickEvent,
  AgNodeClickEvent,
} from 'ag-charts-enterprise';
import { merge } from 'lodash';

import { DiscoAnalyticsActions } from '../_state/disco-analytics.actions';
import { DISCO_EMPTY_STATE_MESSAGE } from '../charts/disco-empty-state/disco-empty-state.component';

import { DiscoBrandNinety, DiscoNeutralMid200, DiscoText } from './colors';
import { DiscoAxisLabel, DiscoLabel, DiscoLegendLabel } from './fonts';
import { createStandardSeriesHighlight } from './highlight';
import { DiscoSmallCircleMarker } from './markers';

/**
 * Injection token providing the core Ninety AG-Charts theme. All components should inject this token and register it as
 * `AgChartOptions.theme`.
 *
 * @example
 * ```ts
 * protected options: AgChartOptions;
 *
 * constructor( @Inject(DISCO_THEME) private readonly theme: AgChartTheme ) {}
 *
 * ngOnInit() {
 *   this.options = {
 *     theme: this.theme,
 *     ...
 *   }
 * }
 * ```
 *
 */
export const DISCO_THEME = new InjectionToken<AgChartTheme>('DISCO_THEME');

/**
 * The various AG-Chart events include the native HTML event that led to the AG-Chart event being fired. We don't want to include
 * this in our analytics events as it can add lots of noise to the event payload - this type strips out the event.
 */
export type WithoutNativeHTMLEvent<T extends { event: Event }> = Omit<T, 'event'>;

/** Remove the native HTML event from the event payload. See {@link WithoutNativeHTMLEvent} */
export function stripNativeHtmlEvent<T extends { event: Event }>(baseEvent: T): WithoutNativeHTMLEvent<T> {
  const { event, ...rest } = baseEvent;
  return rest;
}

/**
 * Core provider of the {@link DISCO_THEME} token. As long as the Store can be injected, maps AG Chart events to NGRX actions,
 * which trigger Segment analytics events.
 *
 * While this is provided in both AppModule and the root Storybook module, you can always provide this token at a lower layer to
 * override it.
 *
 * Example:
 * - In the {@link MeetingInsightsStateModule} we override the token to provide sync to every meetings chart.
 * - This is added to the injection scope of the meeting dashboard **route**.
 * - The app will see the sync override, but to a storybook file, no route was loaded thus it just receives the default theme
 *   provided in preview.ts.
 * - Further, when `ninety-issue-resolution-over-time-chart` is loaded in the meetings route it gets sync - when its loaded
 *   by the issues page, it does not.
 *
 * You can extend this pattern to any part of the Injector Hierarchy. Components, directives, and modules are all valid places to
 * call this method and override the global theme.
 */
export function provideAgChartsTheme(theme: AgChartTheme = DiscoCoreTheme): Provider {
  return {
    provide: DISCO_THEME,
    // Handles the store not being available (such as in tests or SB stories).
    // See https://angular.dev/api/core/FactoryProvider#usage-notes
    deps: [[new Optional(), Store]],
    useFactory: (store?: Store): AgChartTheme => {
      if (!store) return theme;
      const withListeners: AgChartTheme = {
        overrides: {
          common: {
            listeners: {
              seriesNodeClick: <TDatum>(event: AgNodeClickEvent<'seriesNodeClick', TDatum>): void => {
                store.dispatch(DiscoAnalyticsActions.seriesClick({ event: stripNativeHtmlEvent(event) }));
              },
              click: (event: AgChartClickEvent): void => {
                store.dispatch(DiscoAnalyticsActions.chartClick({ event: stripNativeHtmlEvent(event) }));
              },
            },
            legend: {
              listeners: {
                legendItemClick: (event: AgChartLegendClickEvent) => {
                  store.dispatch(DiscoAnalyticsActions.legendItemClick({ event: stripNativeHtmlEvent(event) }));
                },
              },
            },
          },
        },
      };

      return merge({}, theme, withListeners);
    },
  };
}

export function provideAgChartsSyncedTheme(): Provider {
  return provideAgChartsTheme(SyncedTheme);
}

/**
 * The core Ninety theme for AG Charts. Prefer to inject instead: `@Inject(DISCO_THEME)`. See {@link provideAgChartsTheme} and
 * {@link DISCO_THEME}.
 *
 * @deprecated Future iterations will make this module private and only expose the injection token.
 */
export const DiscoCoreTheme: AgChartTheme = {
  baseTheme: 'ag-default',
  overrides: {
    common: {
      legend: {
        position: 'bottom',
        orientation: 'horizontal',
        item: {
          marker: DiscoSmallCircleMarker,
          label: DiscoLegendLabel,
          paddingX: 16,
          paddingY: 16,
        },
        maxHeight: 70, // Chosen to ensure two lines of legend items will display
        // Stop a user from removing the final legend item from the chart - also ensures that single series legends arent
        // interactable.
        preventHidingAll: true,
      },
      padding: {
        top: 24,
        right: 24,
        bottom: 24,
        left: 24,
      },
      tooltip: {
        // Tooltips follow mouse pointer and do not snap to sector
        position: { type: 'pointer' },
      },
      axes: {
        category: {
          position: 'bottom',
          label: { ...DiscoAxisLabel },
          crosshair: { enabled: false },
        },
        number: {
          position: 'left',
          label: { ...DiscoAxisLabel },
          gridLine: { enabled: false },
          crosshair: { enabled: false },
        },
      },
      overlays: {
        noData: {
          // See apps/ng-ninety/src/styles/ag-charts.scss
          renderer: () => `<div class="ag-charts-no-data-override">${DISCO_EMPTY_STATE_MESSAGE}</div>`,
        },
      },
    },
    donut: {
      series: {
        // Define the ratio between the width of a sector and the width of the whole chart
        innerRadiusRatio: 0.65,
        // Chart start its animation from -90 degrees
        rotation: -90,
        // Add border radius to each sector
        cornerRadius: 20,
        // Add spacing between sectors
        sectorSpacing: 12,
      },
    },
    'range-bar': {
      series: {
        // Set the fill of the sector to 55% opacity
        fillOpacity: 0.55,
        // Add border radius to each sector
        cornerRadius: 4,
      },
    },
    bar: {
      series: {
        fillOpacity: 1,
        tooltip: {
          enabled: true,
        },
        cornerRadius: 8,
        // Use a white stroke to create a gap between sectors
        stroke: 'white',
        strokeWidth: 0.5,
        strokeOpacity: 1,
        crisp: true,
        label: {
          placement: 'outside-end',
          color: DiscoText,
          fontWeight: 600,
          fontSize: 14,
          padding: 8,
          fontFamily: 'Nunito Sans',
        },
      },
      axes: {
        number: {
          gridLine: {
            enabled: false,
          },
        },
      },
    },
    line: {
      series: {
        // Plot a dot at each data point
        marker: { enabled: true, fill: DiscoBrandNinety, stroke: DiscoBrandNinety, size: 10 },
        stroke: DiscoBrandNinety,
        highlightStyle: createStandardSeriesHighlight(),
        // No label above markers by default, but when turned on, use style:
        label: { enabled: false, ...DiscoLabel },
        connectMissingData: true,
      },
    },
  },
};

/**
 * @deprecated Future iterations will make this module private and only expose the injection token.
 */
export const SyncedTheme: AgChartTheme = merge({}, DiscoCoreTheme, {
  overrides: { common: { sync: { enabled: true } } },
});

export const DiscoInlineLineOverrides: Partial<AgLineSeriesOptions> = {
  strokeWidth: 0,
  showInMiniChart: false,
  tooltip: { enabled: false },
  marker: {
    fill: DiscoNeutralMid200,
    strokeWidth: 2,
    size: 19,
  },
  label: {
    ...DiscoLabel,
    enabled: true,
  },
};
