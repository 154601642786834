import { createSelector } from '@ngrx/store';
import { differenceInCalendarDays, isFuture, isPast, isValid, parseJSON } from 'date-fns';

import { TerraIconName } from '@ninety/terra';

import { getDayDiff } from '../../../../_shared/functions/date-util-functions';
import { BannerType } from '../../../../_shared/models/_shared/banner-type';
import { AccountStatusV2 } from '../../../../_shared/models/billingv2/account-status-v2.enum';
import { BillingOverviewDetails } from '../../../../_shared/models/billingv2/billing-overview-details.model';
import { StripeSubscriptionStatuses } from '../../../../_shared/models/billingv2/stripe-subscription-statuses.enum';
import { SubscriptionTypes } from '../../../../_shared/models/billingv2/subscription-types.enum';
import { Subscription } from '../../../../_shared/models/billingv2/subscription.model';
import { BillingTypes } from '../../../../_shared/models/company/billing-types.enum';
import { compareToToday } from '../../../../_shared/pipes/dates/_utils';
import { FeatureFlagKeys } from '../../../app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '../../../app-entities/feature-flag/feature-flag-state.selectors';
import { UserModelState } from '../../../app-entities/users/users-state.model';
import { selectCurrentUser } from '../../../app-entities/users/users-state.selectors';
import { BannerNotificationEventName } from '../../banner-notification/banner-notification.event-name.type';
import { BannerNotificationModel } from '../../banner-notification/banner-notification.model';
import { selectCurrentPersonIsImplementer } from '../../current-person/current-person.selectors';
import { selectCompany, selectCompanyId } from '../company-state.selectors';

export const selectSubscription = createSelector(selectCompany, company => company?.subscription);
export const selectSubscriptionConfigId = createSelector(
  selectCompany,
  company => company.subscription?.subscriptionConfigurationId
);

export const selectAccountStatus = createSelector(selectCompany, state => state.accountStatus);
export const selectIsFreeSubscription = createSelector(
  selectSubscription,
  subscription => subscription?.type === SubscriptionTypes.Free
);

export const selectIsTrialSubscription = createSelector(
  selectSubscription,
  subscription => subscription?.providerStatus === StripeSubscriptionStatuses.Trialing
);
export const selectIsExpiredTrialSubscription = createSelector(
  selectSubscription,
  // TODO: && subscription.trialEnd < new Date() should be added after QA testing. It will be very hard for QA to test Expired Trials if we add this check now.
  subscription => StripeSubscriptionStatuses.Paused === subscription?.providerStatus // && subscription.trialEnd < new Date()
);

export const selectIsSuspendedCompany = createSelector(
  selectCompany,
  company => company.accountStatus === AccountStatusV2.SUSPENDED
);
export const selectIsActiveSubscription = createSelector(selectCompany, selectSubscription, (company, subscription) => {
  if (company.accountStatus === AccountStatusV2.ACTIVE) {
    return true;
  }

  if (company.accountStatus === AccountStatusV2.INACTIVE) {
    return subscription?.currentPeriodEnd && isFuture(subscription.currentPeriodEnd);
  }

  return false;
});
export const selectIsScheduledForCancellationSubscription = createSelector(
  selectIsActiveSubscription,
  selectSubscription,
  (isActiveSubscription, subscription) => {
    if (!isActiveSubscription) {
      return false;
    }

    const willCancelAt = isValid(subscription?.willCancelAt)
      ? subscription?.willCancelAt
      : parseJSON(subscription?.willCancelAt);
    return isActiveSubscription && isFuture(willCancelAt);
  }
);

export const selectIsAlreadyCancelledSubscription: (state: Subscription) => boolean = createSelector(
  selectSubscription,
  (subscription): boolean => {
    const cancelledAt = isValid(subscription?.cancelledAt)
      ? subscription?.cancelledAt
      : parseJSON(subscription?.cancelledAt);
    const isAlreadyCancelled = isPast(cancelledAt);
    return isAlreadyCancelled;
  }
);

export const selectIsInactiveSubscriptionAndCanReactivate = createSelector(
  selectIsActiveSubscription,
  selectIsSuspendedCompany,
  (isActiveSubscription, isSuspendedCompany) => !isActiveSubscription && !isSuspendedCompany
);

export const selectIsPastDueSubscription = createSelector(
  selectIsActiveSubscription,
  selectSubscription,
  (isActiveSubscription, subscription) => {
    if (!isActiveSubscription) {
      return false;
    }

    return subscription?.providerStatus === StripeSubscriptionStatuses.PastDue;
  }
);

export const selectShouldHaveAccessToSiteBillingV2 = createSelector(
  selectIsSuspendedCompany,
  selectIsActiveSubscription,
  selectIsExpiredTrialSubscription,
  (isSuspendedCompany, isActiveSubscription, isExpiredTrialSubscription) => {
    if (isSuspendedCompany) {
      return false;
    }

    if (isExpiredTrialSubscription) {
      return false;
    }

    return isActiveSubscription;
  }
);

export const selectIsBillingV2Company = createSelector(
  selectCompany,
  company => company.billingType === BillingTypes.BillingV2 || !!company.subscription?.provider
);

export const selectIsBillingV3Company = createSelector(
  selectCompany,
  company => company?.billingType === BillingTypes.BillingV3
);

export const selectDaysLeftInTrial = createSelector(
  selectIsTrialSubscription,
  selectSubscription,
  (isTrialing, subscription) => {
    if (!isTrialing) {
      return 0;
    }

    if (!subscription.trialEnd) {
      return 0;
    }

    const trialEnd = isValid(subscription.trialEnd) ? subscription.trialEnd : parseJSON(subscription.trialEnd);
    if (isPast(trialEnd)) {
      return 0;
    }

    return differenceInCalendarDays(trialEnd, new Date());
  }
);

export const selectShouldDisplayTrialCountdown = createSelector(
  selectFeatureFlag(FeatureFlagKeys.trialCountdown),
  selectIsTrialSubscription,
  selectIsExpiredTrialSubscription,
  selectSubscription,
  selectIsFreeSubscription,
  selectCompany,
  selectCurrentPersonIsImplementer,
  (
    displayTrialCountdownFlag,
    isTrialSubscription,
    isExpiredTrialSubscription,
    subscription,
    isFreeSubscription,
    company,
    personIsImplementer
  ) => {
    if (company?.implementerFree) return false;
    if (personIsImplementer) return false;
    if (isFreeSubscription) return false;
    if (!displayTrialCountdownFlag) return false;
    if (isExpiredTrialSubscription) return true;
    return isTrialSubscription && !subscription?.hasDefaultPaymentMethod;
  }
);

export const selectTrialDaysCountdownDisplay = createSelector(
  selectIsBillingV2Company,
  selectCompany,
  selectShouldDisplayTrialCountdown,
  selectDaysLeftInTrial,
  (isBillingV2Company, company, shouldDisplayTrialCountdown, trialDaysRemainingV2) => {
    if (!shouldDisplayTrialCountdown) {
      return { trialHeaderText: '', trialHeaderTitle: '', trialDaysRemaining: trialDaysRemainingV2 };
    }
    if (isBillingV2Company) {
      return {
        trialHeaderText:
          trialDaysRemainingV2 <= 0
            ? ''
            : trialDaysRemainingV2 === 1
            ? `${trialDaysRemainingV2} day left`
            : `${trialDaysRemainingV2} days left`,
        trialHeaderTitle: trialDaysRemainingV2 <= 0 ? 'Trial Expired' : `Trial: `,
        trialDaysRemaining: trialDaysRemainingV2,
      };
    }
    const trialDaysRemainingLegacy = company?.trialingUntil
      ? getDayDiff(new Date(Date.now()), new Date(company.trialingUntil))
      : 0;
    return {
      trialHeaderText:
        trialDaysRemainingLegacy <= 0
          ? ''
          : trialDaysRemainingLegacy === 1
          ? `${trialDaysRemainingLegacy} day left`
          : `${trialDaysRemainingLegacy} days left`,
      trialHeaderTitle: trialDaysRemainingLegacy <= 0 ? 'Trial Expired' : `Trial: `,
      trialDaysRemaining: trialDaysRemainingLegacy,
    };
  }
);

export const selectTrialCountdownBanner = createSelector(
  selectShouldDisplayTrialCountdown,
  selectDaysLeftInTrial,
  selectCurrentUser,
  selectCompanyId,
  (shouldDisplayTrialCountdown, daysLeftInTrial, user, companyId) => {
    if (shouldDisplayTrialCountdown && daysLeftInTrial < 8) {
      const bannerEventTypeName = BannerNotificationEventName.trialBanner;
      const lastDismissedDate = localStorage.getItem(`${bannerEventTypeName}-BannerLastDismissedDate-${companyId}`)
        ? new Date(localStorage.getItem(`${bannerEventTypeName}-BannerLastDismissedDate-${companyId}`))
        : null;
      const banner = {
        type: 'negative' as unknown as BannerType,
        message: 'Trial expired.',
        actionMessage: 'Subscribe now',
        actionRoute: 'settings/billing/overview',
        hasIcon: true,
        iconKey: 'hourglass-high' as unknown as TerraIconName,
        dismissible: false,
        callToActionActive: user.isAdminOrOwner,
        bannerNotificationEventName: `${bannerEventTypeName}`,
      } as unknown as BannerNotificationModel;
      if (daysLeftInTrial === 0) {
        banner.message = 'Trial expired.';
        banner.dismissible = false;
        return banner;
      } else if (
        user.isAdminOrOwner &&
        daysLeftInTrial < 8 &&
        (compareToToday(lastDismissedDate) === -1 || !lastDismissedDate)
      ) {
        banner.message = `Trial: ${daysLeftInTrial} ${daysLeftInTrial === 1 ? 'day' : 'days'} left `;
        banner.dismissible = true;
        return banner;
      }
    }
  }
);

export const selectCanceledAccountBanner = createSelector(
  selectCurrentUser,
  selectIsAlreadyCancelledSubscription,
  (user: UserModelState, isAlreadyCancelled): BannerNotificationModel | null => {
    if (!isAlreadyCancelled) {
      return null;
    }

    const banner: BannerNotificationModel = {
      type: 'negative' as BannerType,
      message: 'This account is cancelled.',
      actionMessage: 'Reactivate subscription',
      actionRoute: 'settings/billing/overview',
      hasIcon: false,
      dismissible: false,
      callToActionActive: user.isAdminOrOwner,
      bannerNotificationEventName: BannerNotificationEventName.accountCanceledBanner,
    };

    return banner;
  }
);

export const selectBanner = createSelector(
  selectTrialCountdownBanner,
  selectCanceledAccountBanner,
  (trialCountdownBanner, canceledAccountBanner) => {
    return {
      ...trialCountdownBanner,
      ...canceledAccountBanner,
    };
  }
);

export const selectIsPastDueGraceActive = createSelector(
  selectSubscription,
  selectIsFreeSubscription,
  (subscription, isFreeSubscription) => {
    if (isFreeSubscription) {
      return false;
    }

    return (
      subscription?.providerStatus === StripeSubscriptionStatuses.PastDue &&
      subscription?.pastDueGraceEndDate &&
      isFuture(new Date(subscription?.pastDueGraceEndDate))
    );
  }
);

export const selectBillingOverviewDetails = createSelector(
  selectSubscription,
  selectAccountStatus,
  selectIsFreeSubscription,
  selectIsTrialSubscription,
  selectIsExpiredTrialSubscription,
  selectIsActiveSubscription,
  selectIsScheduledForCancellationSubscription,
  selectIsPastDueSubscription,
  selectDaysLeftInTrial,
  selectIsBillingV2Company,
  selectIsInactiveSubscriptionAndCanReactivate,
  (
    subscription,
    accountStatus,
    isFreeSubscription,
    isTrialingSubscription,
    isExpiredTrialSubscription,
    isActiveSubscription,
    isScheduledForCancellationSubscription,
    isPastDueSubscription,
    daysLeftInTrial,
    isBillingV2Company,
    isInactiveSubscriptionAndCanReactivate
  ) =>
    ({
      ...subscription,
      hasSubscription: !!subscription?.subscriptionId,
      accountStatus,
      isFreeSubscription,
      isTrialingSubscription,
      isExpiredTrialSubscription,
      isActiveSubscription,
      isScheduledForCancellationSubscription,
      isPastDueSubscription,
      daysLeftInTrial,
      isBillingV2Company,
      isInactiveSubscriptionAndCanReactivate,
    } as BillingOverviewDetails)
);
