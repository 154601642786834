import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';

import { CascadingMessagesSort } from '../../_shared/models/cascading-messages/cascading-message-sort';
import { ItemType } from '../../_shared/models/enums/item-type';
import { SortDirection } from '../../_shared/models/enums/sort-direction';
import { HeadlinesSort } from '../../_shared/models/headlines/headline-sort';
import { IssuesSort } from '../../_shared/models/issues/issue-sort';
import { MeetingType } from '../../_shared/models/meetings/meeting-type.enum';
import { PeriodInterval } from '../../_shared/models/scorecard/period-interval.enum';
import { PeriodRange } from '../../_shared/models/scorecard/period-range.enum';
import { DateFilter } from '../../_shared/models/scorecard/scorecard-date';
import { TodoSort } from '../../_shared/models/todos/todo-sort';
import { TodoSortField } from '../../_shared/models/todos/todo-sort-field';

import { FileService } from './file.service';
import { NotifyService } from './notify.service';

export enum PrintApi {
  vto = 'vto',
  rocks = 'rocks',
  item = 'item',
  scorecard = 'scorecard',
  scorecardv2 = 'scorecardv2',
  quarterlyExport = 'quarterly-export',
  accountabilityChart = 'accountability-chart',
  feedback = 'feedback',
  fitCheck = 'fit-check',
  todos = 'todos',
  personalTodos = 'personal-todos',
  issues = 'issues',
  processes = 'processes',
  invoice = 'invoice',
  meetingAgenda = 'meeting-agenda',
  headlinesAndCms = 'headlines-and-cms',
}

export enum PDFSeatType {
  detailed = 'detailed',
  condensed = 'condensed',
}

export enum PDFPrintType {
  single = 'single-page',
  multi = 'multi-page',
}

export class PrintOptions {
  isLandscape = false;
  timeZone? = new Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export class PrintParams {
  printOptions: PrintOptions = new PrintOptions();
}

export interface ScorecardPrintParams {
  numberOfPeriods: number;
  periodInterval: PeriodInterval;
  rangeEndDate?: string;
  rangeStartDate?: string;
  teamId: string;
  weeklyRange: PeriodRange;
}

export interface ScorecardV2PrintParams {
  scorecardId: string;
  teamId: string;
  dateFilter: DateFilter;
}

export class ChartPrintParams {
  topSeatId?: string;
  seatType: PDFSeatType;
  printType: PDFPrintType;
  /** The chart ID to print. If this is not provided, the primary chart of the company will be used. */
  chartId?: string;
  teamId: string;
}

export class FeedbackPrintParams {
  conversationId: string;
}

export class FitCheckPrintParams {
  userId: string;
}

export class ItemPrintParams {
  id: string;
  type: ItemType;
}
export class MeetingAgendaPrintParams {
  teamId: string;
  meetingType: MeetingType;
  sortOptions?: PrintMeetingAgendaSortOptions;
  customAgendaId?: string;
}

export class PrintMeetingAgendaSortOptions {
  headlines?: HeadlinesSort;
  //TODO NEXT: cascading messages can also be sorted by team(not currently enabled), create a new type when adding team sorting
  cascadingMessages?: CascadingMessagesSort;
  todos?: TodoSort;
  issues?: IssuesSort;
}

export type PersonalTodosPrintParams = {
  userId: string;
  completed: boolean;
  sort?: {
    direction: SortDirection;
    field: TodoSortField;
  };
  searchText?: string;
};

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private printUrl = '/api/v4/print';

  constructor(private http: HttpClient, private fileService: FileService, private notifyService: NotifyService) {}

  /**
   * Expects a url response from the server and opens it in a new tab.
   *
   * Note: A use case for opening from a URL is when the response size exceeds APIGateway's limit, we can load directly
   * from another store like S3.
   */
  openInNewTab<T>(api: PrintApi, body: T & PrintParams) {
    if (!body.printOptions.timeZone) {
      body.printOptions = { ...body.printOptions, timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone };
    }

    const request = this.http.post(`${this.printUrl}/${api}`, body, { responseType: 'text' });

    return this.fileService.swapTabWithNewContent(request).pipe(
      catchError((e: unknown) => {
        const msg = (e as Error)?.message ?? 'Could not download PDF.  Please try again.';

        this.notifyService.showError(msg);
        return of('Print-pdf failed to open due to an API error. Please try again');
      })
    );
  }

  /**
   * Expects an ArrayBuffer response from the server and renders it as a PDF in a new tab.
   */
  openPdf<T>(api: PrintApi, body: T & PrintParams) {
    if (!body.printOptions.timeZone) {
      body.printOptions = { ...body.printOptions, timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone };
    }

    const request = this.http.post(`${this.printUrl}/${api}`, body, {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    });

    return this.fileService.renderPdf(request).pipe(
      catchError((e: unknown) => {
        const msg = (e as Error)?.message ?? 'Could not download PDF.  Please try again.';

        this.notifyService.showError(msg);
        return of('Print-pdf failed to open due to an API error. Please try again');
      })
    );
  }
}
