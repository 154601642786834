/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs';

/**
 * This effect forwards all actions, with their data, to the console as a log. It is NOT meant to be used in production,
 * but can be very helpful with complex stories which interact with the store in complex ways. Note, the full redux
 * dev tools do not show in the storybook UI, but can be seen if you view the story as its own window.
 *
 * To use, include it in your story's module:
 *
 * ```typescript
 * const meta: Meta = {
 *   decorators: [
 *     applicationConfig({
 *       providers: [importProvidersFrom(EffectsModule.forRoot([ActionsForwarder]))],
 *     }),
 *   ],
 * };
 * ```
 */
@Injectable()
export class ActionsForwarder {
  constructor(private actions$: Actions) {}

  logAll$ = createEffect(
    () =>
      this.actions$.pipe(
        tap(action => {
          const { type, ...params } = action;

          if (!Object.keys(params).length) console.log(type);
          else console.log(type, params);
        })
      ),
    {
      dispatch: false,
    }
  );
}
