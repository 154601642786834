import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';

import { RoleService } from '@ninety/ui/legacy/core/services/role.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { AddTeammatesDialogComponent } from '@ninety/ui/legacy/shared/components/add-teammates-dialog/add-teammates-dialog.component';
import { AddTeammateFormData } from '@ninety/ui/legacy/shared/components/add-teammates-dialog/models/add-teammate-form';
import { AddTeammatesDialogData } from '@ninety/ui/legacy/shared/components/add-teammates-dialog/models/add-teammates-dialog-data';
import { StripeReason } from '@ninety/ui/legacy/shared/models/billing-v3/stripe-query-params';
import { BillingStateActions } from '@ninety/ui/legacy/state/app-global/billing/billing-state.actions';
import { selectIsBillingV3Company } from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';

import { StateService } from '../../../_core/services/state.service';
import { LocalStorageService, SessionStorageService } from '../../../_core/services/storage.service';
import { TimeService } from '../../../_core/services/time.service';
import { UserService } from '../../../_core/services/user.service';
import { CompanyUser, ConfirmDialogData, SnackbarTemplateType } from '../../../_shared';
import { NewUserDto } from '../../../_shared/components/add-teammates-dialog/models/new-user.dto';
import { isGSGTargetCompany } from '../../../_shared/functions/is-gsg-target-company';
import { User } from '../../../_shared/models/_shared/user';
import {
  selectCompanyBillingCounts,
  selectIsFreeOrTrialing,
  selectStripeQueryParams,
} from '../../app-global/billing/billing-state.selectors';
import { selectCompany } from '../../app-global/company/company-state.selectors';
import { SpinnerActions } from '../../app-global/spinner/spinner-state.actions';
import { appActions } from '../../app.actions';
import { FeatureFlagKeys } from '../feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '../feature-flag/feature-flag-state.selectors';
import { UserTeamsActions } from '../team-list/team-list-state.actions';

import {
  AddTeammatesActions,
  UserPreferencesActions,
  UserSettingsActions,
  UsersStateActions,
} from './users-state.actions';
import { selectCurrentUser, UserSelectors } from './users-state.selectors';

const ADD_TEAMMATES_FORM_DATA = 'addTeammatesFormData';

@Injectable()
export class UsersStateEffects {
  timezonePromptFlag$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.timezonePrompt));

  constructor(
    private readonly actions$: Actions,
    private readonly userService: UserService,
    private readonly store: Store,
    private readonly stateService: StateService,
    private readonly timeService: TimeService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly sessionStorage: SessionStorageService
  ) {}

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.update),
      concatMap(({ userId, update }) =>
        // api hands back entire updated user
        this.userService.update(update, userId).pipe(map(() => ({ userId, update })))
      ),
      map(({ userId: _id, update: changes }) => UsersStateActions.updateSuccess({ _id, changes }))
    )
  );

  /** same actions as update, but can have billing implications so separating from typical update action */
  updateUserRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.updateRole),
      concatMap(({ userId, update }) =>
        // api hands back entire updated user
        this.userService.update(update, userId).pipe(
          map(() => UsersStateActions.updateRoleSuccess({ _id: userId, changes: update })),
          catchError((error: unknown) => of(UsersStateActions.updateRoleFailed({ _id: userId, error })))
        )
      )
    )
  );

  /** since we have other listeners besides the user state reducer for update success, just pass it along instead of adding it to all the places */
  updateUserRoleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.updateRoleSuccess),
      map(({ _id, changes }) => UsersStateActions.updateSuccess({ _id, changes }))
    )
  );

  updateUserRoleFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.updateRoleFailed),
      map(({ error }) =>
        NotificationActions.notifyError({ error: error, message: 'Could not update user role.  Please try again.' })
      )
    )
  );

  /** this will update role permissions, etc. if needed.  Hopefully this won't be needed for much longer */
  updateStateServiceCurrentCompanyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UsersStateActions.updateSuccess),
        concatLatestFrom(() => this.stateService.currentCompanyUser$),
        map(([{ _id, changes }, currentCompanyUser]) => {
          if (_id === currentCompanyUser._id) {
            this.stateService.currentCompanyUser$.next({ ...currentCompanyUser, ...changes } as CompanyUser);
          }
        })
      ),
    { dispatch: false }
  );

  addUsersToTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserTeamsActions.addUsersToTeam),
      concatMap(({ users, teamId }) => {
        const updates = users.map(u => {
          if (u.teams.some(t => t.teamId === teamId)) {
            return;
          }
          return this.userService.update({ teams: [...u.teams, { teamId }] }, u._id);
        });
        return forkJoin(updates);
      }),
      map(users => UserTeamsActions.addUsersToTeamSuccess({ users })),
      catchError((error: unknown) => of(UserTeamsActions.addUsersToTeamFailed({ error })))
    )
  );

  removeUserFromTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserTeamsActions.removeUserFromTeam),
      concatLatestFrom(({ userId, teamId }) => this.store.select(UserSelectors.selectUserById(userId))),
      concatMap(([{ userId, teamId }, user]) =>
        this.userService.update({ teams: [...user.teams.filter(t => t.teamId !== teamId)] }, user._id).pipe(
          map(user => UserTeamsActions.removeUserFromTeamSuccess({ userId, teamId })),
          catchError((error: unknown) => of(UserTeamsActions.removeUserFromTeamFailed({ error })))
        )
      )
    )
  );

  userUpdateStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserTeamsActions.removeUserFromTeam,
        UserTeamsActions.addUsersToTeam,
        BillingStateActions.changeLicenseCount,
        AddTeammatesActions.createUsers,
        BillingStateActions.getUpdatedSubscription
      ),
      map(action => SpinnerActions.startPrimary({ source: action.type }))
    )
  );

  userUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserTeamsActions.removeUserFromTeamSuccess,
        UserTeamsActions.removeUserFromTeamFailed,
        UserTeamsActions.addUsersToTeamFailed,
        UserTeamsActions.addUsersToTeamSuccess,
        AddTeammatesActions.createUsersSuccess,
        AddTeammatesActions.createUsersFailed,
        BillingStateActions.getUpdatedSubscriptionFailed
      ),
      map(action => SpinnerActions.stopPrimary({ source: action.type }))
    )
  );

  updateUserTutorials = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferencesActions.hideUserTutorial),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      concatMap(([{ userTutorialType }, currentUser]) =>
        this.userService.update({
          tutorialsHidden: { ...currentUser.tutorialsHidden, [userTutorialType]: true },
        })
      ),
      map(user => UserPreferencesActions.userTutorialsUpdated({ tutorialsHidden: user.tutorialsHidden }))
    )
  );

  checkTimezonePrompt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.checkToShowTimeZoneUpdateDialog),
      concatLatestFrom(() => [this.store.select(selectCurrentUser), this.timezonePromptFlag$]),
      filter(([_, _user, timezonePromptFlag]) => {
        const flagEnabled = !!timezonePromptFlag;
        const notLoginUrl = !this.router.url.includes('/login/');
        const dontAskToUpdateTimezoneAgain = this.localStorageService.get('dontAskToUpdateTimezoneAgain') !== 'true';

        const shouldProceed = flagEnabled && notLoginUrl && dontAskToUpdateTimezoneAgain;
        return shouldProceed;
      }),
      map(([_, user]) => {
        const browserTimezoneEntry = this.timeService.getBrowserTimezone();
        return { userTimeZoneNotFormatted: user?.settings.timezone, browserTimezoneEntry };
      }),
      switchMap(({ userTimeZoneNotFormatted, browserTimezoneEntry }) => {
        const shouldShowPromptOutsideOfLoginFlow = userTimeZoneNotFormatted !== browserTimezoneEntry.name;

        if (shouldShowPromptOutsideOfLoginFlow) {
          return this.userService
            .showTimezoneUpdateDialog(browserTimezoneEntry)
            .pipe(map((user: User) => UserSettingsActions.updateTimezone({ user })));
        } else {
          return of(appActions.noop());
        }
      })
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.deleteUser),
      switchMap(({ _id }) => {
        //Make the call to the backend to delete the user and update the store
        return this.userService.deleteUser(_id).pipe(
          map(() => {
            return UsersStateActions.deleteUserSuccess({ _id }); //Dispatch success action, will refetch directory users
          }),
          catchError((error: unknown) => {
            return of(UsersStateActions.deleteUserFailed({ error }));
          })
        );
      })
    )
  );

  deactivateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.deactivateUser),
      switchMap(({ _id }) => {
        //Make the call to the backend to deactivate the user and update the store
        return this.userService.deactivateUser(_id).pipe(
          map(() => {
            return UsersStateActions.deactivateUserSuccess({ _id }); //Dispatch success action, will refetch directory users
          }),
          catchError((error: unknown) => {
            return of(UsersStateActions.deactivateUserFailed({ error }));
          })
        );
      })
    )
  );

  /** new add teammates dialog */
  private addTeammatesDialogRef: MatDialogRef<AddTeammatesDialogComponent>;

  /** if create users fails, it will also reopen the dialog with the stored forms from session storage */
  openAddTeammatesDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTeammatesActions.openAddTeammatesDialog, AddTeammatesActions.createUsersFailed),
      filter(() => this.addTeammatesDialogRef?.getState() !== MatDialogState.OPEN),
      concatLatestFrom(() => this.store.select(selectFeatureFlag(FeatureFlagKeys.directoryAddUsersModal))),
      filter(([, useNewAddUserDialog]) => useNewAddUserDialog),
      tap(() => {
        const storedData = this.sessionStorage.get(ADD_TEAMMATES_FORM_DATA);
        this.sessionStorage.delete(ADD_TEAMMATES_FORM_DATA);
        this.addTeammatesDialogRef = this.dialog.open<AddTeammatesDialogComponent, AddTeammatesDialogData>(
          AddTeammatesDialogComponent,
          {
            width: '684px',
            data: { savedFormData: JSON.parse(storedData) ?? [null] },
          }
        );

        this.addTeammatesDialogRef.afterClosed().subscribe(() => {
          this.sessionStorage.delete(ADD_TEAMMATES_FORM_DATA);
        });
      }),
      /** get billing counts when opening the add teammates dialog,
       * mainly in case of opening it from the left side nav as directory will already fetch them */
      map(() => BillingStateActions.getCompanyBillingCounts())
    )
  );

  closeAddTeammatesDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddTeammatesActions.closeAddTeammatesDialog, AddTeammatesActions.createUsersSuccess),
        tap(() => {
          this.addTeammatesDialogRef?.close();
        })
      ),
    { dispatch: false }
  );

  openUnsavedChangesDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTeammatesActions.openUnsavedChangesDialog),
      switchMap(() =>
        this.dialog
          .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
              title: 'Unsaved changes',
              message:
                'You’ve made unsaved changes but haven’t invited your new teammate yet. Do you want to go back and continue?',
              confirmButtonText: 'Go back',
              cancelButtonText: 'Discard changes',
            },
            disableClose: true,
          })
          .afterClosed()
      ),
      filter(keepChanges => !keepChanges),
      map(() => AddTeammatesActions.closeAddTeammatesDialog())
    )
  );

  /** if free or trialing company, create users.  Otherwise, need to check billing */
  addTeammates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTeammatesActions.addTeammates),
      concatLatestFrom(() => [this.store.select(selectIsBillingV3Company), this.store.select(selectIsFreeOrTrialing)]),
      map(([{ userForms }, isBillingV3Company, isFreeOrTrialing]) =>
        !isBillingV3Company || isFreeOrTrialing
          ? AddTeammatesActions.createUsers({ userForms })
          : AddTeammatesActions.addTeammatesToPaidCompany({ userForms })
      )
    )
  );

  addTeammatesToPayingCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTeammatesActions.addTeammatesToPaidCompany),
      switchMap(({ userForms }) => {
        const emails = userForms.filter(u => u.email).map(u => u?.email);
        return this.userService.checkForHelpfuls(emails).pipe(map(helpfulsRecord => ({ userForms, helpfulsRecord })));
      }),
      concatLatestFrom(() => this.store.select(selectCompanyBillingCounts)),
      map(([{ userForms, helpfulsRecord }, counts]) => {
        const numPaidUsersToCreate = userForms.filter(
          ({ role, email }) => RoleService.isPaidRole(role) && !helpfulsRecord[email.toLowerCase()]
        ).length;
        if (counts.assignableSeats < numPaidUsersToCreate) {
          this.sessionStorage.set(ADD_TEAMMATES_FORM_DATA, JSON.stringify(userForms));
          return BillingStateActions.openConfirmLicenseChangeDialog({
            data: {
              counts,
              paidSeatsToAdd: numPaidUsersToCreate,
            },
          });
        }

        return AddTeammatesActions.createUsers({ userForms });
      })
    )
  );

  /** if canceling license change, clear the session storage */
  cancelLicenseChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BillingStateActions.cancelLicenseChange),
        tap(() => this.sessionStorage.delete(ADD_TEAMMATES_FORM_DATA))
      ),
    { dispatch: false }
  );

  /** after getting updated subscription, if there are saved forms, recall add teammates.
   * This will recalculate and compare the number of new paid users,
   * but we don't know if the user successfully added new licenses in stripe or just canceled/came back so we can't call create users yet */
  checkForSavedFormsAfterUpdatingSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getBillingCountsAfterStripeRedirectSuccess),
      map((): AddTeammateFormData[] => JSON.parse(this.sessionStorage.get(ADD_TEAMMATES_FORM_DATA))),
      filter(userForms => !!userForms),
      concatLatestFrom(() => this.store.select(selectStripeQueryParams)),
      map(([userForms, stripeParams]) => {
        /** if there are stored user forms and updating license count was successful, try adding teammates.
         * otherwise reopen the dialog with the stored forms */
        return stripeParams?.reason == StripeReason.SubscriptionUpdateConfirmation
          ? AddTeammatesActions.addTeammatesToPaidCompany({ userForms })
          : AddTeammatesActions.openAddTeammatesDialog();
      })
    )
  );

  createUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTeammatesActions.createUsers),
      concatLatestFrom(() => this.store.select(selectCompany)),
      map(([{ userForms }, company]) => userForms.map(f => NewUserDto.fromFormData(f, isGSGTargetCompany(company)))),
      switchMap((newUserDtos: NewUserDto[]) =>
        this.userService.createUsers(newUserDtos).pipe(
          map(() => {
            this.sessionStorage.delete(ADD_TEAMMATES_FORM_DATA);
            return AddTeammatesActions.createUsersSuccess({ newUserDtos });
          }),
          catchError((error: unknown) => of(AddTeammatesActions.createUsersFailed({ error })))
        )
      )
    )
  );

  notifyCreateUsersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTeammatesActions.createUsersSuccess),
      /** filter out v1 invite for now */
      filter(({ newUserDtos }) => !!newUserDtos.length), // only notify if there are new users created
      map(({ newUserDtos }) => {
        let title: string;
        if (newUserDtos.length === 1) {
          const newUser = newUserDtos[0];
          title = newUser.active ? newUser.email : `${newUser.firstName} ${newUser.lastName}`;
        } else {
          title = `${newUserDtos.length} users`;
        }
        const message = `${newUserDtos.length === 1 ? 'has' : 'have'} been added to Ninety.`;
        return NotificationActions.notifyWithTemplate({
          templateType: SnackbarTemplateType.newUsersAdded,
          data: { title, message },
        });
      })
    )
  );

  createUsersFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTeammatesActions.createUsersFailed),
      map(({ error }) =>
        NotificationActions.notifyError({ error: error, message: 'Could not create users.  Please try again.' })
      )
    )
  );

  clearStripeQueryParamsAfterOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTeammatesActions.addTeammatesToPaidCompany, AddTeammatesActions.openAddTeammatesDialog),
      map(() => BillingStateActions.clearStripeQueryParams())
    )
  );
  /************************************************************************************
   * Legacy code block to keep stateService up to date. To be deleted once off stateService
   *************************************************************************************/
  addUsersUpdateStateService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserTeamsActions.addUsersToTeamSuccess),
        concatLatestFrom(() => this.store.select(selectCurrentUser)),
        map(([{ users }, currentUser]) => users.find(u => u._id === currentUser._id)),
        filter(user => !!user),
        tap(user => {
          const currentUser = this.stateService.currentCompanyUser$.value;
          currentUser.teams = user.teams;
          this.stateService.currentCompanyUser$.next(currentUser);
        })
      ),
    { dispatch: false }
  );

  updateCurrentUserThemeStateService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserPreferencesActions.updateTheme),
        tap(({ theme }) => {
          this.stateService.currentUser.settings.theme = theme;
        })
      ),
    { dispatch: false }
  );
  /************************************************************************************
   * End Legacy Code
   *************************************************************************************/
}
