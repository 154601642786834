import { Component, TrackByFunction } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import ObjectID from 'bson-objectid';

import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { SessionService } from '@ninety/ui/legacy/core/services/session.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { CompanyUsersStateActions } from '@ninety/ui/legacy/state/app-entities/company-users/company-users-state.actions';
import { CompanyUserListModel } from '@ninety/ui/legacy/state/app-entities/company-users/company-users-state.model';
import {
  selectAllCompanyUsers,
  selectCompanyUserByCompanyId,
} from '@ninety/ui/legacy/state/app-entities/company-users/company-users-state.selectors';
import { selectCurrentUserId } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

@Component({
  selector: 'ninety-add-helpful',
  templateUrl: './add-helpful.component.html',
  styleUrls: ['../_shared/tab-styles.scss', './add-helpful.component.scss'],
})
export class AddHelpfulComponent {
  protected readonly companyUserList$ = this.store.select(selectAllCompanyUsers);
  protected readonly currentUserId$ = this.store.select(selectCurrentUserId);

  companyToFindForm = new FormControl('', {
    validators: [
      (c: AbstractControl): ValidationErrors =>
        !c.value || ObjectID.isValid(c.value) ? null : { isObjectId: { value: c.value } },
    ],
  });
  companyFilterSearch = new FormControl('');

  foundCompany: CompanyUserListModel;
  alreadyInCompany = false;
  newCompanyUser: CompanyUserListModel;
  displayedColumns = ['logo', 'name', 'login', 'remove'];

  constructor(
    private readonly store: Store,
    private readonly companyService: CompanyService,
    private readonly userService: UserService,
    private readonly sessionService: SessionService
  ) {}

  findCompanyById() {
    const companyId = this.companyToFindForm.value.trim();

    if (!ObjectID.isValid(companyId)) {
      this.companyToFindForm.markAsTouched();
      this.companyToFindForm.updateValueAndValidity();
      return;
    }

    const companyUser = extractValueFromStore(
      this.store,
      selectCompanyUserByCompanyId(companyId)
    ) as CompanyUserListModel;
    if (companyUser) {
      this.alreadyInCompany = true;
      this.foundCompany = companyUser;
      return;
    }

    this.companyService.getCompanyById(companyId).subscribe({
      next: company => {
        this.foundCompany = {
          userId: null,
          companyId: company._id,
          accountStatus: company.accountStatus,
          companyName: company.name,
          logoUrl: company.logo.url,
          bos: company.bos,
        };
        this.alreadyInCompany = false;
        this.newCompanyUser = null;
        this.store.dispatch(SpinnerActions.stopPrimary({ source: 'add-helpful' }));
      },
    });
  }

  addHelpful() {
    this.userService.addHelpfulToCompany(this.foundCompany.companyId).subscribe({
      next: cu => {
        const entity: CompanyUserListModel = {
          companyId: cu.company._id,
          userId: cu._id,
          accountStatus: cu.company.accountStatus,
          companyName: cu.company.name,
          logoUrl: cu.company.logo.url,
          bos: cu.company.bos,
        };
        this.store.dispatch(CompanyUsersStateActions.addOne({ entity: entity }));
        this.newCompanyUser = entity;
      },
    });
  }

  removeHelpfulFromCompany(companyUser: CompanyUserListModel) {
    this.store.dispatch(SpinnerActions.startPrimary({ source: 'remove-helpful' }));

    this.userService.update({ deleted: true }, companyUser.userId).subscribe({
      next: () => {
        this.store.dispatch(CompanyUsersStateActions.removeOne({ userId: companyUser.userId }));
        this.store.dispatch(SpinnerActions.stopPrimary({ source: 'remove-helpful' }));
      },
    });
  }

  clearCompanyFilterSearch(): void {
    this.companyFilterSearch.setValue('');
  }

  switchCompany(nextUser: CompanyUserListModel): void {
    this.sessionService.switchCompany(nextUser);
  }

  protected readonly trackById: TrackByFunction<CompanyUserListModel> = (
    _i: number,
    user: CompanyUserListModel
  ): string => user.userId;
}
