import { Entitlements } from '../../../_shared/models/entitlements/entitlements-model';

export const EntitlementsStateKey = 'entitlements';
export interface EntitlementsState {
  entitlements: Entitlements;
}

export const InitialEntitlementsState: EntitlementsState = {
  entitlements: {
    scopes: [],
  } as Entitlements,
};
