import { PlanFeature } from './plan-feature';
import { PlanName } from './plan-name';

export interface SubscriptionDetails {
  description: string;
  everythingText: string;
  features: PlanFeature[];
  featuresExtended?: PlanFeature[];
}

export function getSubscriptionDetails(): Record<PlanName, SubscriptionDetails> {
  return {
    [PlanName.Freemium]: {
      description: `Good for teams just starting their journey, the ${PlanName.Freemium} plan gives you the tools to learn,
      strategize, and build the foundation of your organization.`,
      everythingText: 'Everything you need to get started!',
      features: [
        { title: 'Vision', description: 'Vision' },
        { title: 'Org Chart', description: 'Org Chart' },
        { title: 'Baseline + Org Fit Assessments', description: 'Baseline + Org Fit Assessments' },
        { title: 'OS Toolbox', description: 'OS Toolbox' },
        { title: 'Knowledge Portal (Read Only)', description: 'Knowledge Portal (Read Only)' },
        { title: 'Maz for Onboarding', description: 'Maz for Onboarding' },
      ],
    },
    [PlanName.Essentials]: {
      description: `Ideal for teams focused on tactical execution, the ${PlanName.Essentials} plan equips you to run
      effective Weekly Team Meetings and stay aligned on your goals.`,
      everythingText: `Everything in ${PlanName.Freemium} plus:`,
      features: [
        { title: 'Weekly Meetings', description: 'Weekly Meetings' },
        { title: 'Scorecard', description: '' },
        { title: 'Rocks', description: '' },
        { title: 'To-Dos', description: '' },
        { title: 'Issues', description: '' },
        { title: 'Task/Calendar Integrations', description: '' },
      ],
    },
    [PlanName.Accelerate]: {
      description: `Designed for teams ready to grow, the ${PlanName.Accelerate} plan offers tools to plan each quarter,
       think strategically, and scale your operations seamlessly.`,
      everythingText: `Everything in ${PlanName.Essentials} plus:`,
      features: [
        { title: 'Reporting', description: '' },
        { title: 'Custom Meetings', description: '' },
        { title: 'Quarterly & Annual Meetings', description: '' },
        { title: 'Quarterly & Annual Discussions', description: '' },
        { title: 'Scorecard Integrations (coming soon)', description: '' },
        { title: 'Project Management Integrations', description: '' },
      ],
    },
    [PlanName.Thrive]: {
      description: `For ambitious organizations, the ${PlanName.Thrive} plan leverages advanced tools and AI-powered
      capabilities to align teams, accelerate growth, and drive unparalleled performance.`,
      everythingText: `Everything in ${PlanName.Accelerate} plus:`,
      features: [
        { title: 'Knowledge Portal', description: '' },
        { title: 'Process Documentation', description: '' },
        { title: 'Maz, Draft Rocks', description: '' },
        { title: 'Maz, Meeting Prep', description: '' },
        { title: 'Advanced Integrations (coming soon)', description: '' },
        { title: 'Public APIs (coming soon)', description: '' },
      ],
    },
  };
}
