import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { TerraInputBoolean } from '../../models/terra-input-boolean.models';
import { TerraBooleanAttributeModule } from '../../pipes/boolean-attribute/boolean-attribute.module';
import { TerraIconModule } from '../terra-icon';

let completionCheckboxUniqueId = 1;

@Component({
  selector: 'terra-completion-checkbox',
  standalone: true,
  exportAs: 'terraCompletionCheckbox',
  imports: [CommonModule, TerraBooleanAttributeModule, TerraIconModule],
  templateUrl: './terra-completion-checkbox.component.html',
  styleUrls: ['./terra-completion-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    // Removes tabindex on the host - applies it instead to the input checkbox
    '[attr.tabindex]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
  },
})
export class TerraCompletionCheckboxComponent {
  /**
   * Reference to internal completion checkbox input
   */
  @ViewChild('completionCheckboxInput', { static: true })
  private _completionCheckboxInput!: ElementRef<HTMLInputElement>;

  /** Emits event when the completion checkbox's `checked` value changes. */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly change: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Set the disabled state of the checkbox
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /**
   * Optional input to override aria-label
   * @default null
   */
  @Input('aria-label') ariaLabel: string | null = null;
  /**
   * Optional input to override aria-labelledby
   * @default null
   */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  /**
   * State of the completion checkbox input
   * @default false
   */
  protected _checked = false;

  /** Tabindex of the completion checkbox */
  protected _tabIndex = 0;

  /** Unique ID for the completion checkbox input element for aria referencing purposes. */
  protected _completionCheckboxId = `terra-completion-checkbox-${completionCheckboxUniqueId++}`;

  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private _onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private _onTouched = (_: any) => {};

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    @Attribute('tabindex') tabIndex: string,
    @Self() protected ngControl: NgControl
  ) {
    this._tabIndex = parseInt(tabIndex) || 0;
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Updates the internal completion checkbox state and emits change events*/
  public toggle(): void {
    if (!this.disabled) {
      this._checked = !this._checked;
      this._completionCheckboxInput.nativeElement.checked = this._checked;
      this._emitChangeEvent();
    }
  }

  /**
   * Focus the completion checkbox
   */
  public focus(): void {
    this._completionCheckboxInput.nativeElement.focus();
  }

  /**
   * Blur the completion checkbox
   */
  public blur(): void {
    this._completionCheckboxInput.nativeElement.blur();
    this._onTouched(true);
  }

  /**
   * @ignore
   * Sends change related events */
  private _emitChangeEvent(): void {
    this._onChange(this._checked);
    this.change.emit(this._checked);
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public writeValue(value: any): void {
    this._checked = !!value;
    this._completionCheckboxInput.nativeElement.checked = this._checked;
    this._changeDetectorRef.markForCheck();
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * @ignore
   * Implemented as part of ControlValueAccessor. */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._changeDetectorRef.markForCheck();
  }
}
