import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TerraBooleanAttributeModule } from '../../../pipes';
import { TerraIconModule } from '../../terra-icon';
import { TerraInputBaseClass } from '../terra-input-base/terra-input-base';

@Component({
  selector: 'terra-number-input',
  standalone: true,
  exportAs: 'terraNumberInput',
  imports: [CommonModule, FormsModule, TerraIconModule, TerraBooleanAttributeModule],
  templateUrl: './terra-number-input.component.html',
  styleUrls: ['./../terra-input-base/terra-input-base.scss', './terra-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TerraInputBaseClass,
      useExisting: TerraNumberInputComponent,
    },
  ],
})
export class TerraNumberInputComponent extends TerraInputBaseClass<number> {
  /**
   * The highest number that can be entered in the input.
   * Entered numbers higher then this will be corrected on blur.
   */
  @Input() get max(): number | undefined {
    return this._max;
  }
  set max(value: string | number | undefined) {
    this._max = coerceNumberProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  protected _max?: number | undefined;

  /**
   * The lowest number that can be entered in the input.
   * Entered numbers lower then this will be corrected on blur.
   */
  @Input() get min(): number | undefined {
    return this._min;
  }
  set min(value: string | number | undefined) {
    this._min = coerceNumberProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  protected _min?: number | undefined;

  /**
   *  The stepping interval when clicking up and down spinner buttons (or up/down arrow keys).
   */
  @Input() get step(): number {
    return this._step;
  }
  set step(value: string | number) {
    this._step = coerceNumberProperty(value);

    this._changeDetectorRef.markForCheck();
  }
  protected _step = 1;

  protected _changeNumber(event: Event, direction: number) {
    event.preventDefault();
    event.stopPropagation();

    this._onTouched(true);

    // Calling the native stepUp/stepDown avoids float errors
    this._input.nativeElement.stepUp(direction);

    // But that method will not dispatch an input event for Angular to pick up, we do it manually
    this._input.nativeElement.dispatchEvent(new Event('input'));
  }

  /**
   * Because of our custom stepper, we need to prevent the input from losing focus
   * This is called on mousedown and accomplishes that
   */
  protected _keepFocused(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private _checkForBounds(value: number) {
    if (this._max !== undefined && value > this._max) {
      return this._max;
    } else if (this._min !== undefined && value < this._min) {
      return this._min;
    }
    return value;
  }

  protected _blurredNumber(blurEvent: FocusEvent) {
    if (this._inputValue !== null && this._inputValue !== undefined) {
      const boundedVal = this._checkForBounds(this._inputValue);
      if (boundedVal !== this._inputValue) {
        this._onModelChange(boundedVal);
      }
    }
    super._blurred(blurEvent);
  }
}
