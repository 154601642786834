import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'terra-input-prefix',
  template: '<ng-content select="terra-icon, terra-input-unit" />',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TerraInputPrefixComponent {}

@Component({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'terra-input-suffix',
  template: '<ng-content select="terra-icon, terra-input-unit" />',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TerraInputSuffixComponent {}

@Component({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'terra-input-unit',
  template: '<div class="terra-input-unit">{{ unit }}</div>',
  standalone: true,
  styleUrls: ['./terra-input-base.slots.scss'],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TerraInputUnitComponent {
  @Input() unit: string | undefined;
}
