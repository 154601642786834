<h1 mat-dialog-title>Update Scheduled {{ data.schedule.agendaName }}</h1>
<h2 mat-dialog-subtitle *ngIf="subtitle">{{ subtitle }}</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <div class="date">
      <div class="_terra-migration-approved-override--terra-mat-form-field">
        <terra-form-label>Date</terra-form-label>

        <mat-form-field
          appearance="outline"
          id="date-field"
          class="date__field _terra-migration-approved-override--mat-form-field">
          <input
            matInput
            [min]="data.schedule.scheduledDate"
            [matDatepicker]="datePicker"
            required
            aria-label="date picker"
            formControlName="date" />
          <mat-datepicker-toggle matSuffix [for]="datePicker">
            <terra-icon matDatepickerToggleIcon icon="calendar" />
          </mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="time">
      <div class="_terra-migration-approved-override--terra-mat-form-field">
        <terra-form-label>Time</terra-form-label>

        <div class="time-fields-wrp" id="time-fields">
          <terra-number-input
            class="time-hh"
            min="1"
            max="12"
            aria-label="hours"
            formControlName="hours"
            placeholder="hh"
            data-cy="update-schedule-time_hours" />
          <terra-number-input
            class="time-mm"
            min="0"
            max="59"
            aria-label="minutes"
            formControlName="minutes"
            placeholder="mm" />
          <terra-select formControlName="period">
            <terra-option [value]="TimePeriod.AM">{{ TimePeriod.AM }}</terra-option>
            <terra-option [value]="TimePeriod.PM">{{ TimePeriod.PM }}</terra-option>
          </terra-select>
        </div>
      </div>
    </div>

    <div class="presenter">
      <terra-form-field>
        <terra-form-label>Facilitator</terra-form-label>
        <terra-select formControlName="presenterId" maxHeight="350">
          <terra-option *ngFor="let user of data.users" [value]="user.value">{{ user.name }}</terra-option>
        </terra-select>
      </terra-form-field>
    </div>

    <div class="scribe">
      <terra-form-field>
        <terra-form-label>Scribe</terra-form-label>
        <terra-select formControlName="scribeId" maxHeight="350" placeholder="Select scribe">
          <terra-option [value]="undefined">None</terra-option>
          <terra-option *ngFor="let user of data.users" [value]="user.value">{{ user.name }}</terra-option>
        </terra-select>
      </terra-form-field>
    </div>

    <div class="update-options" *ngIf="data.schedule.cadence !== Cadence.none">
      <terra-radio-group aria-labelledby="What-to-update" formControlName="updateAll">
        <terra-form-label>
          Do you want to update one or all {{ data.meetingLanguage.item }} schedules?
        </terra-form-label>
        <terra-radio-button [value]="false" data-cy="update-schedule_radio-btn-one">
          Update only this {{ data.meetingLanguage.item }}
        </terra-radio-button>
        <terra-radio-button [value]="true" data-cy="update-schedule_radio-btn-all"> Update all </terra-radio-button>
      </terra-radio-group>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ninety-button stroked mat-dialog-close class="right-space-10">Cancel</ninety-button>
  <ninety-button
    filled
    primary
    (click)="onUpdate()"
    [disabled]="!form.valid || !form.dirty"
    data-cy="update-schedule-actions_save-btn">
    <terra-icon icon="save" />
    Save
  </ninety-button>
</mat-dialog-actions>
