import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, exhaustMap, filter, forkJoin, iif, map, of, switchMap, tap } from 'rxjs';

import { ExcelExportType } from '@ninety/ui/legacy/core/services/_state/filter-service/excel-export-types.enum';
import { FilterServiceActions } from '@ninety/ui/legacy/core/services/_state/filter-service/filter.service.actions';
import { FileService } from '@ninety/ui/legacy/core/services/file.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { LibraryService } from '@ninety/ui/legacy/core/services/library.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { PersonalTodosPrintParams, PrintApi, PrintService } from '@ninety/ui/legacy/core/services/print.service';
import { LocalStorageService } from '@ninety/ui/legacy/core/services/storage.service';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import {
  LinkedItemTypeEnum,
  linkedItemTypeToDetailTypesMap,
} from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { TodoDeleOptions } from '@ninety/ui/legacy/shared/models/todos/todo-delete-options';
import { TodoRepeatType } from '@ninety/ui/legacy/shared/models/todos/todo-repeat-types';
import { TodoSort } from '@ninety/ui/legacy/shared/models/todos/todo-sort';
import {
  selectCurrentUser,
  selectCurrentUserId,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { appActions } from '@ninety/ui/legacy/state/app.actions';
import { selectIsMyNinetyUrl, selectRouteIsAnyOfDetailType } from '@ninety/ui/legacy/state/route.selectors';
import { LinkedItemsActions } from '@ninety/web/pages/detail-view/_shared/linked-items/_state/linked-items.actions';
import { DetailViewActions } from '@ninety/web/pages/detail-view/_state/detail-view.actions';
import { CreateDialogService } from '@ninety/web/pages/layouts/services/create-dialog.service';
import { getDueDateFromRockOrTodo } from '@ninety/web/pages/layouts/services/universal-create.service';
import { IntegrationService } from '@ninety/web/pages/settings/user/integration/_api/integration.service';

import { TodoService } from '../../_shared/todo.service';
import { PersonalTodosChildStateKey, TodoRootStateKey, selectPersonalTodoState } from '../../_state';
import { RepeatTodoDeleteDialogComponent } from '../../repeat-todo-delete/repeat-todo-delete-dialog.component';
import { CreateTodoResponse } from '../../services/models/create-todo-response';
import { createOrdinalUpdate } from '../_shared/todo-state.shared.effects';
import { TodoDetailActions } from '../detail/todo-detail.actions';
import { TeamTodoActions } from '../team/team-todo.actions';

import { CompletedSelectValue } from './completedselectvalue';
import { PersonalTabTodosActions, PersonalTodoActions, PersonalTodoInlineActions } from './personal-todo.actions';
import { PersonalTodoSelectors } from './personal-todo.selectors';

@Injectable()
export class PersonalTodoEffects {
  constructor(
    private actions$: Actions,
    private todoService: TodoService,
    private store: Store,
    private createDialogService: CreateDialogService,
    private localStorageService: LocalStorageService,
    private libraryService: LibraryService,
    private printService: PrintService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private integrationService: IntegrationService,
    private notifyService: NotifyService,
    private fileService: FileService
  ) {}

  getPersonalTodos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PersonalTodoActions.sortBy,
        PersonalTabTodosActions.sortBy,
        PersonalTodoActions.paginationChange,
        PersonalTabTodosActions.paginationChange,
        PersonalTodoActions.selectComplete,
        PersonalTodoActions.selectIncomplete,
        PersonalTodoActions.search,
        PersonalTodoActions.deleteSeriesSuccess
      ),
      concatLatestFrom(() => this.store.select(PersonalTodoSelectors.selectGETRequestParams)),
      switchMap(([_, params]) =>
        // I'd like to call the todoApiService directly here, but don't want to inject the filter service for search
        this.todoService.getTodos(params).pipe(
          map(response => PersonalTodoActions.getManySuccess({ response })),
          catchError((error: unknown) => of(PersonalTodoActions.getManyFailure({ error })))
        )
      )
    )
  );

  //Calls the todo service to update ordinals when a user moves a todo item in the list
  //Ordinals are used as the default sort order when no sort has been set
  updateOrdinals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.updateOrdinals),
      concatLatestFrom(() => [
        this.store.select(selectPersonalTodoState),
        this.store.select(PersonalTodoSelectors.selectPageSize),
      ]),
      switchMap(([action, state, pageSize]) => {
        const todosWithChanges = createOrdinalUpdate(action, state);

        return this.todoService
          .updateOrdinals(
            todosWithChanges,
            state.ordinalKey,
            state.pageIndex * state.pageSize,
            null,
            null,
            null,
            true,
            pageSize
          )
          .pipe(
            map(_ => PersonalTodoActions.updateOrdinalsSuccess()),
            catchError(() => of(TeamTodoActions.updateOrdinalsFailure()))
          );
      }),
      catchError(() => of(PersonalTodoActions.updateOrdinalsFailure()))
    )
  );

  //This duplicates the above but add some some additional fields to the ordinal update
  //For Tab Personal Todos we need to send the current sort order and direction saved in state
  //For My90 Personal Todos that information is taken from the database(user my90 settings looks like)
  updateOrdinalsPersonalTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTabTodosActions.updateOrdinals),
      concatLatestFrom(() => [this.store.select(selectPersonalTodoState)]),
      exhaustMap(([action, state]) => {
        const todosWithChanges = createOrdinalUpdate(action, state);

        return this.todoService
          .updateOrdinals(
            todosWithChanges,
            state.ordinalKey,
            state.pageIndex * state.pageSize,
            null,
            state.sortField,
            state.sortDirection
          )
          .pipe(
            map(_ => PersonalTabTodosActions.updateOrdinalsSuccess()),
            catchError(() => of(PersonalTabTodosActions.updateOrdinalsFailure()))
          );
      }),
      catchError(() => of(PersonalTabTodosActions.updateOrdinalsFailure()))
    )
  );

  /**
   * Handles inline-add for Todo.
   */
  createOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoInlineActions.createOne),
      exhaustMap(action => this.todoService.createOne({ ...action.todo, isPersonal: true })),
      map(response => PersonalTodoInlineActions.createOneSuccess({ response })),
      catchError(() => of(PersonalTodoInlineActions.createOneFailure()))
    )
  );

  addMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.addMany),
      concatLatestFrom(() => [
        this.store.select(selectCurrentUserId),
        this.store.select(PersonalTodoSelectors.selectTypeOfTodosToShow),
      ]),
      exhaustMap(([{ todo, userIds, createdFrom }, currentUserId, typeOfTodosToShow]) =>
        this.todoService.create(todo, userIds, createdFrom).pipe(
          map(response => {
            //when creating a todo for multiple users, the response is unfiltered
            //completed list: do not add any todos as they are not completed on creation
            //not completed: only add for current user id
            const filteredResponse: CreateTodoResponse = {
              todos:
                typeOfTodosToShow === CompletedSelectValue.complete
                  ? []
                  : response.todos.filter(todo => todo.userId === currentUserId),
              integrationErrors: response.integrationErrors,
              fileAttachments: response.fileAttachments,
            };
            return PersonalTodoActions.addManySuccess({ response: filteredResponse, createdFrom });
          }),
          catchError(() => of(PersonalTodoActions.addManyFailure()))
        )
      )
    )
  );

  syncLinkedItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.addManySuccess),
      filter(({ createdFrom }) => !!createdFrom),
      concatLatestFrom(({ createdFrom }) => [
        this.store.select(selectRouteIsAnyOfDetailType(linkedItemTypeToDetailTypesMap[createdFrom.type])),
        this.store.select(selectIsMyNinetyUrl),
      ]),
      filter(([_, isType, isMy90]) => isType || isMy90),
      map(([{ createdFrom }]) =>
        LinkedItemsActions.getLinkedItem({ id: createdFrom.id, linkedItemType: createdFrom.type })
      )
    )
  );

  uploadAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.addManySuccess),
      filter(({ response }) => !!response.fileAttachments?.length),
      switchMap(data =>
        iif(
          () => !!data.response?.fileAttachments?.length,
          forkJoin([
            ...this.libraryService.handleTodoFileUploads(data.response.todos, data.response.fileAttachments),
          ]).pipe(map(attachments => ({ attachments }))),
          of({ attachments: [] })
        )
      ),
      map(({ attachments }) => PersonalTodoActions.attachmentUploadedSuccess({ event: { attachments } }))
    )
  );

  /**
   * Handles opening the create dialog, but does not respond to it being closed.
   *
   * New todos created by the create dialog are handed by {@link MyNinetyPageEffects#addPersonalTodosToStateAfterPersist$}
   * in My90 and the {@link TodosComponent} on the main Todos page.
   */
  openCreateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PersonalTodoActions.openCreateDialog),
        switchMap(() => this.createDialogService.open({ itemType: ItemType.todo, item: { isPersonal: true } }))
      ),
    { dispatch: false }
  );

  updateTodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.update),
      switchMap(({ id, todo }) =>
        this.todoService.updateTodo(id, todo).pipe(
          map(response =>
            PersonalTodoActions.updateSuccess({
              todo: response,
              completeStatusChange: todo.hasOwnProperty('completed'),
            })
          )
        )
      ),
      catchError(() => of(PersonalTodoActions.updateFailure()))
    )
  );

  updateTodoInline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.updateInline),
      switchMap(({ id, todo }) =>
        this.todoService.updateTodo(id, todo).pipe(
          map(response =>
            PersonalTodoActions.updateInlineSuccess({
              todo: response,
              completeStatusChange: todo.hasOwnProperty('completed'),
            })
          )
        )
      ),
      catchError(() => of(PersonalTodoActions.updateFailure()))
    )
  );

  updateTodoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.updateSuccess),
      filter(({ completeStatusChange }) => completeStatusChange),
      map(({ todo }) => PersonalTodoActions.toggleCompleted({ todo, isCompleted: todo.completed, source: 'detail' }))
    )
  );

  updateTeamTodosOnMyNinety$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.updateSuccess),
      concatLatestFrom(() => [this.store.select(selectIsMyNinetyUrl)]),
      filter(([_, isMyNinetyUrl]) => isMyNinetyUrl),
      map(([{ todo }]) => {
        if (!todo.isPersonal) return TeamTodoActions.addToTeam({ todo });
        else return TeamTodoActions.removeFromTeam({ todo });
      })
    )
  );

  refreshPersonalTodosInSeriesInStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.updateSuccess, PersonalTodoActions.updateInlineSuccess),
      map(({ todo }) => {
        if (todo.seriesId && todo.repeat !== TodoRepeatType.DontRepeat) {
          return PersonalTodoActions.updateDisplayedTodosInSeries({ todo });
        }
        return appActions.noop();
      })
    )
  );

  updateTodoInlineSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.updateInlineSuccess),
      filter(({ completeStatusChange }) => completeStatusChange),
      map(({ todo }) => PersonalTodoActions.toggleCompleted({ todo, isCompleted: todo.completed, source: 'inline' }))
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.delete),
      concatMap(({ todo }) =>
        this.todoService.delete(todo).pipe(
          map(() => PersonalTodoActions.deleteSuccess({ id: todo._id })),
          catchError(() => of(PersonalTodoActions.deleteFailure()))
        )
      )
    )
  );

  deleteConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.deleteConfirm),
      map(action =>
        action.todo.repeat !== TodoRepeatType.DontRepeat
          ? PersonalTodoActions.openDeleteTodoDialog({ todo: action.todo })
          : PersonalTodoActions.delete({ todo: action.todo })
      )
    )
  );

  openDeleteTodoDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.openDeleteTodoDialog),
      exhaustMap(({ todo }) =>
        this.dialog
          .open(RepeatTodoDeleteDialogComponent, {
            data: { todo },
          })
          .afterClosed()
      ),
      filter(option => !!option),
      map(({ option, todo }) => {
        if (option === TodoDeleOptions.item) return PersonalTodoActions.delete({ todo: todo });
        else return PersonalTodoActions.deleteSeries({ todo: todo });
      })
    )
  );

  deleteSeries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.deleteSeries),
      concatMap(action =>
        this.todoService.deleteSeries(action.todo).pipe(
          map(_ => PersonalTodoActions.deleteSeriesSuccess({ seriesId: action.todo.seriesId, todo: action.todo })),
          catchError(() => of(PersonalTodoActions.deleteSeriesFailure()))
        )
      )
    )
  );

  moveToBottom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.moveToBottom, PersonalTabTodosActions.moveToBottom),
      concatLatestFrom(() => this.store.select(PersonalTodoSelectors.selectCount)),
      map(([{ index: previousIndex }, todosCount]) =>
        PersonalTodoActions.updateOrdinals({ previousIndex, currentIndex: todosCount - 1 })
      )
    )
  );

  moveToTop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.moveToTop, PersonalTabTodosActions.moveToTop),
      map(({ index: previousIndex }) => PersonalTodoActions.updateOrdinals({ previousIndex, currentIndex: 0 }))
    )
  );

  makeItAnIssue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PersonalTodoActions.makeItAnIssue),
        switchMap(action =>
          this.createDialogService.open({
            item: action.todo,
            itemType: ItemType.issue,
            createdFrom: {
              id: action.todo._id,
              type: LinkedItemTypeEnum.todo,
            },
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  createTodo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PersonalTodoActions.createTodo),
        switchMap(action =>
          this.createDialogService.open({
            item: action.todo,
            itemType: ItemType.todo,
            createdFrom: {
              id: action.todo._id,
              type: LinkedItemTypeEnum.todo,
            },
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  createRock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PersonalTodoActions.createRock),
        switchMap(action =>
          this.createDialogService.open({
            item: action.todo,
            itemType: ItemType.rock,
            createdFrom: {
              id: action.todo._id,
              type: LinkedItemTypeEnum.todo,
            },
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  createHeadline$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PersonalTodoActions.createHeadline),
        switchMap(action =>
          this.createDialogService.open({
            item: action.todo,
            itemType: ItemType.headline,
            createdFrom: {
              id: action.todo._id,
              type: LinkedItemTypeEnum.todo,
            },
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  personalTabSetFilterToolbarSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTabTodosActions.setFilterToolbarSuccess),
      map(() => PersonalTodoActions.selectIncomplete())
    )
  );

  loadTodoIntoTodoDetailOnSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTabTodosActions.selectTodo),
      map(({ todo }) => TodoDetailActions.openInDetail({ todo }))
    )
  );

  setPersonalSelectedTodoId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTabTodosActions.selectTodo),
      map(({ todo }) => PersonalTodoActions.setSelectedId({ todoId: todo._id }))
    )
  );

  addOneInlineFromPersonalTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTabTodosActions.addOneInline),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      map(([, currentUser]) =>
        PersonalTodoInlineActions.addOne({
          todo: {
            userId: currentUser._id,
            title: '',
            description: '',
            dueDate: getDueDateFromRockOrTodo(ItemType.todo),
            itemType: ItemType.todo,
            companyId: currentUser.company.companyId,
            ordinal: -1,
            userOrdinal: -1,
            who: '',
            intervalCode: null,
          },
        })
      )
    )
  );

  clearSelectedOnDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.deselect),
      map(() => DetailViewActions.closed({ itemType: DetailType.todo }))
    )
  );

  sortBy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PersonalTabTodosActions.sortBy),
        tap(({ sort }) => {
          if (sort.direction) {
            //direction is null when sort cycle is complete
            this.localStorageService.set(`${TodoRootStateKey}.${PersonalTodosChildStateKey}.sort`, sort);
          } else {
            this.localStorageService.delete(`${TodoRootStateKey}.${PersonalTodosChildStateKey}.sort`);
          }
        })
      ),
    { dispatch: false }
  );

  paginationChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PersonalTabTodosActions.paginationChange),
        tap(({ size }) =>
          this.localStorageService.set(`${TodoRootStateKey}.${PersonalTodosChildStateKey}.pagination.size`, size)
        )
      ),
    { dispatch: false }
  );

  clearSort$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PersonalTabTodosActions.updateOrdinals,
          PersonalTabTodosActions.moveToBottom,
          PersonalTabTodosActions.moveToTop
        ),
        tap(_ => this.localStorageService.delete(`${TodoRootStateKey}.${PersonalTodosChildStateKey}.sort`))
      ),
    { dispatch: false }
  );

  hydratePagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTabTodosActions.init),
      map(() => {
        const pageSize = parseInt(
          this.localStorageService.get(`${TodoRootStateKey}.${PersonalTodosChildStateKey}.pagination.size`)
        );

        const sort = this.localStorageService.getAndParse<TodoSort>(
          `${TodoRootStateKey}.${PersonalTodosChildStateKey}.sort`
        );

        return PersonalTabTodosActions.hydrate({ pageSize, sort });
      })
    )
  );

  print$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTabTodosActions.print),
      concatLatestFrom(() => [
        this.store.select(PersonalTodoSelectors.selectSort),
        this.store.select(selectCurrentUserId),
        this.filterService.showCompleted$,
        this.filterService.searchText$,
      ]),
      switchMap(([{ printOptions }, sort, userId, completed, searchText]) =>
        this.printService
          .openPdf<PersonalTodosPrintParams>(PrintApi.personalTodos, {
            userId,
            completed,
            ...(!completed && sort.sortDirection //only sort NON completed personal todos for now, feature coming in DEV-8873
              ? { sort: { direction: sort.sortDirection, field: sort.sortField } }
              : null),
            ...(searchText ? { searchText } : null),
            printOptions,
          })
          .pipe(
            map(() => PersonalTabTodosActions.printSuccess()),
            catchError(() => of(PersonalTabTodosActions.printFailure()))
          )
      )
    )
  );

  syncTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.syncTasks),
      concatLatestFrom(() => this.store.select(selectLanguage)),
      exhaustMap(([_, language]) =>
        this.integrationService.syncPersonalTasks().pipe(
          tap(() =>
            this.notifyService.notifyV2(`${language.todo.items} successfully resynced with Google Tasks`, {
              config: { duration: 2000 },
            })
          ),
          map(() => PersonalTabTodosActions.init()),
          catchError(() => of(PersonalTodoActions.syncTasksFailure()))
        )
      )
    )
  );

  /** Creates a google task from the TD */
  createTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.createTask),
      concatMap(action =>
        this.integrationService.createTask(action.id).pipe(
          map(task => PersonalTodoActions.createTaskSuccess({ todoId: action.id, googleTaskId: task.id })),
          catchError(() => of(PersonalTodoActions.createTaskFailure()))
        )
      )
    )
  );

  setSelectedIdOnReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.getManySuccess),
      concatLatestFrom(() => [this.store.select(PersonalTodoSelectors.selectTodoIdFromRoute)]),
      filter(([_, todoId]) => !!todoId),
      map(([_, todoId]) => PersonalTodoActions.setSelectedId({ todoId }))
    )
  );

  downloadExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterServiceActions.exportToExcel),
      filter(({ exportType }) => exportType === ExcelExportType.personalTodo),
      concatLatestFrom(() => [
        this.store.select(PersonalTodoSelectors.selectGETRequestParams),
        this.store.select(selectLanguage),
      ]),
      switchMap(([, params, language]) =>
        this.todoService
          .downloadExcel(params)
          .pipe(
            tap(response => {
              const currentDate = new Date();
              const fileName = `personal_${language.todo.item}_${formatDate(currentDate, 'MMddyyyy', 'en-US')}.xlsx`;
              this.fileService.downloadExcelFile(response, fileName);
            })
          )
          .pipe(
            map(() => PersonalTodoActions.downloadExcelSuccess()),
            catchError(() => of(PersonalTodoActions.downloadExcelFailure()))
          )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.updateUser),
      concatMap(({ id, todo }) =>
        this.todoService.updateTodo(id, todo).pipe(
          map(todo => PersonalTodoActions.updateUserSuccess({ todo })),
          catchError(() => of(PersonalTodoActions.updateUserFailure()))
        )
      )
    )
  );

  updateUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonalTodoActions.updateUserSuccess),
      map(({ todo }) => DetailViewActions.close())
    )
  );
}
