import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import Fuse, { IFuseOptions } from 'fuse.js';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  exhaustMap,
  filter,
  map,
  mergeMap,
  Subscription,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

import { QuickFilterOption } from '@ninety/ui/legacy/components/index';
import { ExcelExportType } from '@ninety/ui/legacy/core/services/_state/filter-service/excel-export-types.enum';
import { AuxiliaryRouterOutletService } from '@ninety/ui/legacy/core/services/auxiliary-router-outlet.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { RockStatusService } from '@ninety/ui/legacy/core/services/rock-status.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { RockStatus, User } from '@ninety/ui/legacy/shared/index';
import { ConversationDetailInput } from '@ninety/ui/legacy/shared/models/_shared/detail-view-input';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { DirectoryUserStatus } from '@ninety/ui/legacy/shared/models/directory/directory-user-status';
import { Conversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { MasteryFilterStatus } from '@ninety/ui/legacy/shared/models/mastery/mastery-status';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { teamAll, TeamListModel, teamNone } from '@ninety/ui/legacy/state/app-entities/team-list/api/team-list.model';
import { TeamListStateActions } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.actions';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';
import { CurrentUserSelectors } from '@ninety/ui/legacy/state/app-entities/user-list/user-list-state.selectors';
import { selectCurrentUserIsAdminOrOwner } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import {
  selectHasAnyHelpfulPermission,
  selectHasHelpfulPermission,
} from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { selectFeatureEnabled } from '@ninety/ui/legacy/state/index';
import { AccountabilityChartService } from '@ninety/web/pages/accountability-chart/services/accountability-chart.service';
import { DetailService } from '@ninety/web/pages/detail-view/_services/detail.service';
import { FeedbackService } from '@ninety/web/pages/feedback/_shared/feedback.service';
import { selectShowCompletedConversations } from '@ninety/web/pages/feedback/_state/conversation-list/conversation-list.selectors';
import { FormalConversationSettingsComponent } from '@ninety/web/pages/feedback/formal-conversation-settings/formal-conversation-settings.component';
import { TeamSearchComponent } from '@ninety/web/pages/settings/company/teams/search/team-search/team-search.component';
import { LegacyVTOGridLayoutActions } from '@ninety/web/pages/vto/services/legacy-vto-grid-layout-actions.service';
import { VtoService } from '@ninety/web/pages/vto/services/vto.service';

import { GridLayoutSelectors } from '../../grid-layout/_state/grid-layout-state.selectors';

interface searchResultType extends TeamListModel {
  canEdit?: boolean;
  dontShowInResults?: boolean;
}

@Component({
  selector: 'ninety-filters-toolbar',
  templateUrl: './filters-toolbar.component.html',
  styleUrls: ['./filters-toolbar.component.scss', './filters-toolbar.terrafy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersToolbarComponent implements OnInit, OnDestroy {
  @Input() isMeeting?: boolean;
  @Input() isFeedback?: boolean;

  // selectedTeam = teamAll;
  subscriptions = new Subscription();
  RoleCode = RoleCode;
  MasteryFilterStatus = MasteryFilterStatus;
  DirectoryUserStatus = DirectoryUserStatus;

  private searchTerm$ = new BehaviorSubject<string>('');

  @ViewChild(TeamSearchComponent) teamSearchComponent: TeamSearchComponent;

  isEditingMy90Layout$ = this.store.select(GridLayoutSelectors.selectIsLayoutModeEnabled);
  printOrDownloadFeature$ = this.store.select(selectFeatureEnabled(NinetyFeatures.printOrDownload));
  showCompletedConversations$ = this.store.select(selectShowCompletedConversations);

  quarterlyQuestionsEnabled = false;

  data$ = {
    options: this.filterService.options$,
    isFetchingData: this.filterService.isFetchingData$,
    showArchived: this.filterService.showArchived$,
    showCompleted: this.filterService.showCompleted$,
    selectedUserId: this.filterService.selectedUserId$,
    selectedUserStatusId: this.filterService.selectedUserStatusId$,
    selectedRockFilter: this.filterService.selectedRockStatusCode$,
    selectedMasteryStatus: this.filterService.selectedMasteryStatus$,
    selectedOwnerId: this.filterService.selectedOwnerId$,
    showResponsiveFilters: this.filterService.filtersToolbarExpanded$,
    archivedVto: this.vtoService.archivedVto$,
    language: this.store.select(selectLanguage),
    excelDownloadFeatureFlag: this.store.select(selectFeatureFlag(FeatureFlagKeys.webExcelDownload)),
    excelDownloadBatch2FeatureFlag: this.store.select(selectFeatureFlag(FeatureFlagKeys.webExcelDownloadBatch2)),
    weeklyFeatureFlag: this.store.select(selectFeatureFlag(FeatureFlagKeys.webWeeklyConversations)),
    isSlt: this.store.select(TeamSelectors.selectFilterBarTeamIsSlt),
    selectedTeam: this.store.select(TeamSelectors.selectFilterBarTeamLite),
    userTeams: this.store.select(CurrentUserSelectors.selectTeams),
    filteredTeams: combineLatest([
      this.store.select(CurrentUserSelectors.selectTeams),
      this.searchTerm$,
      this.filterService.options$,
      this.store.select(TeamSelectors.selectFilterBarTeamLite),
    ]).pipe(
      map(([teams, searchTerm, options, selectedTeam]): searchResultType[] => {
        return this.filterTeams(searchTerm, teams, options, selectedTeam);
      })
    ),
    rocksQuickFilteredTeams: this.store.select(CurrentUserSelectors.selectTeams).pipe(
      withLatestFrom(this.store.select(TeamSelectors.selectFilterBarTeamLite)),
      map(([teams, selectedTeam]): QuickFilterOption<Team>[] => {
        return teams.map(team => ({
          displayText: team.name,
          id: team._id,
          item: team,
          selected: selectedTeam && selectedTeam._id ? team._id === selectedTeam._id : false,
        }));
      })
    ),
    rocksQuickFilteredStatuses: this.filterService.options$.pipe(
      map(options => {
        if (options.rocksQuickFilters) {
          const statuses: QuickFilterOption<RockStatus | 'all'>[] = this.rockStatusService.statuses.map(status => {
            return { displayText: status.label, id: status.code, item: status, selected: false };
          });
          statuses.unshift({
            displayText: 'All',
            id: 'all',
            item: 'all',
            selected: true,
          } as QuickFilterOption<'all'>);
          return statuses;
        }
      })
    ),
    teams: this.store.select(CurrentUserSelectors.selectTeams),
    searchTerm: this.searchTerm$,
  };

  readonly hasDataUploadPermission$ = this.store.select(selectHasHelpfulPermission('dataUpload')).pipe(
    combineLatestWith(
      this.store.select(selectCurrentUserIsAdminOrOwner),
      this.featureFlagFacade.getFlag(FeatureFlagKeys.clientFacingDataUpload)
    ),
    map(([hasDataUploadPermissions, isAdminOrOwner, flag]) => (flag && isAdminOrOwner) || hasDataUploadPermissions)
  );

  readonly isHelpful$ = this.store.select(selectHasAnyHelpfulPermission);

  ExcelExportType = ExcelExportType;

  constructor(
    public stateService: StateService,
    public filterService: FilterService,
    public rockStatusService: RockStatusService,
    public spinnerService: SpinnerService,
    public userService: UserService,
    public accountabilityChartService: AccountabilityChartService,
    public vtoService: VtoService,
    public helperService: HelperService,
    public gridLayoutActionsService: LegacyVTOGridLayoutActions,
    public feedbackService: FeedbackService,
    public legacyDialog: MatLegacyDialog,
    public detailService: DetailService<ConversationDetailInput>,
    public auxRouterService: AuxiliaryRouterOutletService,
    public activatedRoute: ActivatedRoute,
    private store: Store,
    private featureFlagFacade: FeatureFlagFacade
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.featureFlagFacade
        .getFlag(FeatureFlagKeys.quarterlyQuestionSettings)
        .pipe(take(1))
        .subscribe(flag => {
          this.quarterlyQuestionsEnabled = flag;
        })
    );

    let conversation: Conversation;

    this.subscriptions.add(
      this.detailService.conversationOpenFormalSettingsDialog$
        .pipe(
          tap(c => (conversation = c)),
          exhaustMap(conversation =>
            this.legacyDialog
              .open(FormalConversationSettingsComponent, {
                autoFocus: false,
                data: conversation ? { conversation, quarterlyQuestionsEnabled: this.quarterlyQuestionsEnabled } : null,
              })
              .afterClosed()
          ),
          filter(questions => !!questions),
          tap(questions => {
            if (conversation.type === 'quarterly') {
              conversation.quarterlyQuestions = questions;
            } else {
              conversation.meetingQuestions = questions;
            }
          }),
          mergeMap(questions => this.feedbackService.updateConversationQuestions(conversation._id, questions))
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  parsePercent(number: number): string {
    return `${Math.round(number * 100)}%`;
  }

  selectTeam(team: Team): void {
    this.store.dispatch(TeamListStateActions.changeFilterBarTeam({ id: team._id }));
  }

  selectTeamByQuickFilter(filters: QuickFilterOption<Team>[]): void {
    const selectedTeam = filters.find(filter => filter.selected);

    if (selectedTeam) {
      this.store.dispatch(TeamListStateActions.changeFilterBarTeam({ id: selectedTeam.id }));
    }
  }
  trackById = <T>(_i: number, item): string => item._id;

  teamsAreSame(option, value): boolean {
    return option?._id === value?._id;
  }

  onSearchChanged(term: string): void {
    this.searchTerm$.next(term);
  }

  teamSearchVal = '';

  filterTeams(
    searchTerm: string,
    teams: TeamListModel[],
    filterOptions,
    selectedTeam: TeamListModel
  ): searchResultType[] {
    const fuseOptions: IFuseOptions<searchResultType> = {
      keys: ['name'],
      includeScore: true,
      threshold: 0.3, // Adjust this value for fuzziness; lower values mean stricter matching
    };
    const fuse = new Fuse<searchResultType>(teams, fuseOptions);

    if (filterOptions?.allTeamsOption) {
      const allTeams = { ...teamAll, canEdit: false };
      teams = [allTeams, ...teams];
    } else if (teams.length === 0) {
      // This may be completely unneeded.  Everyone should be on at least one team.
      const noneTeam = { ...teamNone, canEdit: false };
      teams = [noneTeam];
    }

    if (searchTerm.trim() === '') {
      return teams;
    }

    if (searchTerm.trim() === '') {
      // Return all teams if search term is empty
      return teams;
    }

    const filteredTeams = fuse.search(searchTerm).map(result => result.item);
    const selectedTeamIsNotInResults = !filteredTeams.find(x => selectedTeam._id === x._id);
    if (selectedTeamIsNotInResults) {
      // Add selected team back to filtered results so that the mat select
      // does not blank out the currently selected item
      filteredTeams.push({ ...selectedTeam, dontShowInResults: true });
    }

    return filteredTeams;
  }

  onTeamDropdownOpened(event: boolean) {
    if (event) {
      this.teamSearchComponent.inputWrapper.focusInput();
    }
  }

  selectStatus(statuses: QuickFilterOption<RockStatus | string>[]): void {
    const status = statuses.find(status => status.selected);
    this.filterService.setRockStatusFilter(status.item === 'all' ? 'all' : (<RockStatus>status.item).code);
  }

  getUserById(users: User[], userId: string): User {
    return users.find(user => user._id === userId);
  }
}
