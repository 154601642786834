import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AccessToken } from '../../_shared/models/auth/access-token';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root',
})
export class ChartsAuthGuard {
  private readonly NinetyCompanyId = '589348d38ebcc2000ea17f07';

  constructor(private authService: AuthService, private router: Router, private tokenService: TokenService) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const person = this.tokenService.decodeToken<AccessToken>();

    if (!this.authService.isLoggedIn) {
      return this.router.parseUrl(`/login?redirectUrl=${state.url}`);
    }

    const authorized = person.companyId === this.NinetyCompanyId;
    if (!authorized) {
      // This will reset all state.
      this.router.navigateByUrl('');
      return false;
    } else {
      return true;
    }
  }
}
