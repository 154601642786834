import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TerraIconModule } from '../../terra-icon';
import { TerraInputBaseClass } from '../terra-input-base/terra-input-base';
import { TerraTextInputModule } from '../terra-text-input';

@Component({
  selector: 'terra-password-input',
  standalone: true,
  exportAs: 'terraPasswordInput',
  imports: [CommonModule, TerraTextInputModule, TerraIconModule, FormsModule],
  templateUrl: './terra-password-input.component.html',
  styleUrls: ['./terra-password-input.component.scss', '../terra-input-base/terra-input-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TerraInputBaseClass,
      useExisting: TerraPasswordInputComponent,
    },
  ],
})
export class TerraPasswordInputComponent extends TerraInputBaseClass {
  /**
   * Set the maxlength of characters allowed in the input
   */
  @Input() get maxlength(): string | number | undefined {
    return this._maxlength;
  }
  set maxlength(value: string | number | undefined) {
    this._maxlength = coerceNumberProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _maxlength?: number | undefined;

  protected _showPassword = false;

  protected _toggleShowHidePassword(): void {
    this._showPassword = !this._showPassword;
  }
}
