<div class="helpful-container">
  <mat-tab-group mat-stretch-tabs="false" class="helpful-tabs" dynamicHeight>
    <mat-tab label="Add Helpful">
      <ninety-add-helpful />
    </mat-tab>
    <mat-tab label="Email Suppression List">
      <ninety-email-suppression-list />
    </mat-tab>
    <mat-tab label="Hard Delete Company" *ngIf="canHardDeleteCompany$ | ngrxPush">
      <ninety-hard-delete-company />
    </mat-tab>
  </mat-tab-group>
</div>
