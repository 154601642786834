import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraDescriptionModule } from '../../forms/terra-description';
import { TerraErrorModule } from '../../forms/terra-error';
import { TerraFormLabelModule } from '../../forms/terra-form-label';
import { TerraRadioGroupComponent } from '../terra-radio-group';

import { TerraRadioButtonComponent } from './terra-radio-button.component';

@NgModule({
  imports: [
    CommonModule,
    TerraRadioButtonComponent,
    TerraRadioGroupComponent,
    TerraFormLabelModule,
    TerraErrorModule,
    TerraDescriptionModule,
  ],
  exports: [
    TerraRadioButtonComponent,
    TerraRadioGroupComponent,
    TerraFormLabelModule,
    TerraErrorModule,
    TerraDescriptionModule,
  ],
})
export class TerraRadioButtonModule {}
