import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { asapScheduler, delay, filter, merge, startWith, Subject, takeUntil, tap } from 'rxjs';

import { TerraInputBoolean } from '../../models';
import { TerraDescriptionComponent } from '../forms/terra-description';
import { TerraAvatarComponent } from '../terra-avatar/terra-avatar.component';
import { TerraIconComponent } from '../terra-icon';

import {
  TerraOptionPrefixTemplateRefDirective,
  TerraOptionSuffixTemplateRefDirective,
} from './terra-option-slots.directive';
import { TerraOptionBase, TERRA_OPTION_BASE } from './terra-option.interface';

let optionUniqueId = 1;
@Component({
  selector: 'terra-option',
  standalone: true,
  exportAs: 'terraOption',
  imports: [CommonModule, ObserversModule],
  templateUrl: './terra-option.component.html',
  styleUrls: ['./terra-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TERRA_OPTION_BASE,
      useExisting: TerraOptionComponent,
    },
  ],
})
export class TerraOptionComponent implements AfterContentInit, OnDestroy, TerraOptionBase {
  /** @ignore */
  @ContentChild(TerraOptionPrefixTemplateRefDirective, { read: TemplateRef, static: true })
  _prefixTemplate: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TemplateRef<any> | undefined;

  /** @ignore */
  @ContentChild(TerraOptionSuffixTemplateRefDirective, { read: TemplateRef, static: true })
  _suffixTemplate: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TemplateRef<any> | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChildren(TerraIconComponent) private _icons!: QueryList<TerraIconComponent<any>>;
  @ContentChildren(TerraAvatarComponent) private _avatars!: QueryList<TerraAvatarComponent>;

  @ViewChild('label', { static: true }) private _optionLabel!: ElementRef;

  @ContentChild(TerraDescriptionComponent, { static: true })
  private _optionDescription!: ElementRef<TerraDescriptionComponent>;

  private _destroyed$ = new Subject<void>();

  protected _highlighted = false;

  private _optionId = `terra-option-${optionUniqueId++}`;
  get optionId() {
    return this._optionId;
  }

  /** Ouput when an option has been selected */
  // eslint-disable-next-line @angular-eslint/no-output-native, @typescript-eslint/no-explicit-any
  @Output() select = new EventEmitter<any>();

  /** Output when the content of the option changes */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() content = new EventEmitter<any>();

  /**
   * @ignore
   * Used by tightly coupled parents to show/hide the checkbox
   */
  set checkbox(value: boolean) {
    this._checkbox = value;
    this._changeDetectorRef.markForCheck();
  }
  protected _checkbox = false;

  /**
   * Selected state of the option
   * @default false
   */
  get selected(): boolean {
    return this._selected;
  }
  set selected(value: boolean) {
    this._selected = value;
    this._changeDetectorRef.markForCheck();
  }
  protected _selected = false;

  /**
   * Disabled state of the option
   * @default false
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._setDisabledStates(this._disabled);
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /*
   * Set the disabled state without losing value set by disabled input
   *
   * Used by components that compose this component into larger components.
   * */
  get disabledOverride(): boolean {
    return this._disabledOverride;
  }
  set disabledOverride(value: boolean) {
    this._disabledOverride = value;
    if (this._disabledOverride) {
      this._setDisabledStates(this._disabledOverride);
    } else {
      // If we are unsetting the disabled state then we should
      // set it back to the value of the disabled input
      this._setDisabledStates(this._disabled);
    }
    this._changeDetectorRef.markForCheck();
  }
  private _disabledOverride = false;

  /**
   * Value of the option, required
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({ required: true }) get value(): any {
    return this._value;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(value: any) {
    this._value = value;
    this._changeDetectorRef.markForCheck();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _value: any;

  constructor(
    /** @ignore */
    public readonly elementRef: ElementRef,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _ngZone: NgZone
  ) {}

  /** @ignore */
  public _onClick(_$event: Event): void {
    // This needs to be turned off so consumers can add a (click) to the option
    // I can't remember why it was needed, and thought it was related nested elements when clicked
    // still bubbling up the event, but in testing that doesn't seem to be the case
    // If we do find out what this is for we will need to consider situations
    // where consumers have a (click) on the terra-option
    // $event.stopImmediatePropagation(); // Stops native click from continuing to propagate
    !this.disabled && this.select.emit(this._value);
  }

  // Callback for when the MutationObserver detects changes to the label content
  protected _projectedContentChanged(): void {
    // We only care about label changes if the option is selected
    // as the option in the list will update but the trigger won't update automatically
    // when/if the label changes
    if (this.selected) {
      // contentObserve runs outside of 'NgZone' so we need to bring it back in
      this._ngZone.run(() => {
        this.content.emit(this._value);
      });
    }
  }

  ngAfterContentInit(): void {
    const iconEnforceSize$ = this._icons.changes.pipe(
      startWith(this._icons),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((icons: QueryList<TerraIconComponent<any>>) => {
        // Options that have a description are auto-changed to the large size (including icon size)
        // This will enforce the icon size accordingly
        const hasDescription = !!this._optionDescription;
        icons.map(icon => {
          if (hasDescription) {
            icon.size = 36;
          } else {
            icon.size = 20;
          }
        });
        this._changeDetectorRef.markForCheck();
      })
    );

    const avatarEnforceSize$ = this._avatars.changes.pipe(
      startWith(this._avatars),
      filter(data => data.length > 0),
      delay(0, asapScheduler),
      tap((avatars: QueryList<TerraAvatarComponent>) => {
        avatars.map(avatar => (avatar.inactive = this.disabled || this.disabledOverride));
      }),
      tap((avatars: QueryList<TerraAvatarComponent>) => {
        const hasDescription = !!this._optionDescription;
        avatars.map(avatar => {
          if (hasDescription) {
            avatar.size = 'large';
          } else {
            avatar.size = 'small';
          }
          this._changeDetectorRef.markForCheck();
        });
      })
    );

    merge(iconEnforceSize$, avatarEnforceSize$).pipe(takeUntil(this._destroyed$)).subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _scrollIntoView(): void {
    this.elementRef.nativeElement.scrollIntoView({ block: 'center' });
  }

  // Below needed as part of KeyManager interface

  private _getLabel(): string {
    return (this._optionLabel.nativeElement.textContent || '').trim();
  }

  /** @ignore */
  getLabel(): string {
    return this._getLabel();
  }

  /** @ignore */
  setActiveStyles() {
    this._highlighted = true;
    this._scrollIntoView();
    this._changeDetectorRef.markForCheck();
  }

  /** @ignore */
  setInactiveStyles(): void {
    this._highlighted = false;
    this._changeDetectorRef.markForCheck();
  }

  // Util function to set various states depending on the disabled value
  private _setDisabledStates(isDisabled: boolean): void {
    this._avatars?.map(avatar => (avatar.inactive = isDisabled));
  }
}
