import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';

import { LibraryService } from '../../../_core/services/library.service';
import { Attachment } from '../../models/_shared/attachment';
import { AttachmentEvent } from '../../models/_shared/attachment-event';
import { AttachmentInfo } from '../../models/_shared/attachment-info';
import { AttachmentParentType } from '../../models/_shared/attachment-parent-type';

import { selectCurrentUser } from './../../../_state/app-entities/users/users-state.selectors';

// TODO: Find the places these models are used and update them so `_id` & `attachments` are not optional
// Typically in the detail view components or dialogs
// Once that's done, you delete the commented out import
// import { Seat } from '../../models/accountability-chart/seat';
// import { Conversation } from '../../models/feedback/conversation';
// import { Issue } from '../../models/issues/issue';
// import { Process } from '../../models/process/process';
// import { SubProcess } from '../../models/process/sub-process';
// import { Rock } from '../../models/rocks/rock';
// import { Todo } from '../../models/todos/todo';

@Component({
  selector: 'ninety-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent {
  // These are optional because most legacy models define all properties as optional, so this is for
  // backwards compatibility.
  @Input() item: { _id?: string; attachments?: Attachment[] };
  @Input() attachmentParentType: AttachmentParentType;
  @Input() muted = true;
  @Input() cascaded = false;
  @Input() hideForManagees = false;
  @Input() orangeHeader: boolean;
  @Input() fileAttachments: File[];

  /**
   * There are two template types, one for create and one for updates.
   *
   * Create:
   *  - projection slots: createAttachmentTitle
   *  - Operates off mutating a File[]
   *  - No drag'n drop
   *  - No network requests
   *  - No download button
   *
   * Update:
   *  - projection slots: updateAttachmentTitle
   *  - Requires an item & attachmentParentType
   *  - Performs network request on add/remove
   *  - Supports drag'n drop
   *
   * 'primary' | 'secondary' are the legacy templates.
   * 'create' | 'update' are variants of the legacy template with tweaks with minor additional enhancements.
   *  - Allows content projecting the section header for styling header text/styles
   *  - Uses the new ninety-button & ninety-icon components
   *  - Disables drag'n drop when there is only 1 item or less
   *
   */
  @Input() template: 'primary' | 'secondary' | 'create' | 'update' = 'primary';
  /** Only applicable to create/update/primary template */
  @Input() canEdit = true;

  @Output() afterUploadOrRemove = new EventEmitter<AttachmentEvent>();

  spinner = false;

  isMinRole$ = this.store.select(selectCurrentUser).pipe(
    map(user => {
      if (!user) {
        return false;
      }

      if (user.isLite) {
        return (
          !this.attachmentParentType ||
          ['To-Do', 'Rock', 'Measurable', 'Conversation'].includes(this.attachmentParentType)
        );
      }

      if (this.hideForManagees) {
        return user.isManagerOrAbove;
      }

      return user.isManageeOrAbove;
    })
  );

  constructor(private libraryService: LibraryService, private store: Store, private cdr: ChangeDetectorRef) {}

  downloadAttachment(attachment: Attachment) {
    this.libraryService.downloadAttachment(attachment);
  }

  removeAttachment(attachment: Attachment, index: number) {
    this.spinner = true;
    this.libraryService.deleteAttachment(attachment, this.item).subscribe({
      next: () => {
        this.item.attachments.splice(index, 1);

        this.afterUploadOrRemove.emit({ type: 'remove', attachment });
        this.spinner = false;
        this.cdr.markForCheck();
      },
      error: () => {
        this.spinner = false;
        this.cdr.markForCheck();
      },
    });
  }

  addFileAttachment(input: HTMLInputElement) {
    if (input.files.length) {
      for (let i = 0; i < input.files.length; i++) {
        this.fileAttachments.push(input.files[i]);
      }

      input.form.reset();
    }
  }

  removeFileAttachment(index: number) {
    if (index > -1) this.fileAttachments.splice(index, 1);
  }

  uploadFile(input: HTMLInputElement) {
    if (input.files.length) {
      const file = this.libraryService.getFileWithMimeType(input.files[0]);
      if (!file) return;
      this.spinner = true;

      this.libraryService
        .getAttachmentUrl(file, this.item._id, this.attachmentParentType)
        .pipe(
          mergeMap((attachmentInfo: AttachmentInfo) =>
            this.libraryService.uploadAttachment({ attachmentInfo, data: file, parent: this.item }).pipe(
              map(() => attachmentInfo.attachment),
              mergeMap((attachment: Attachment) =>
                this.libraryService.setUploadedAttachment(attachment).pipe(map(attachmentUp => attachmentUp))
              )
            )
          )
        )
        .subscribe({
          next: (attachment: Attachment) => {
            if (!this.item.attachments) this.item.attachments = [];

            this.item.attachments.push(attachment);

            this.afterUploadOrRemove.emit({ type: 'upload', attachment, parent: this.item });
            this.spinner = false;

            input.value = '';

            this.cdr.markForCheck();
          },
          error: () => {
            this.spinner = false;

            this.cdr.markForCheck();
          },
        });
    }
  }

  drop(event: CdkDragDrop<Attachment[]>) {
    this.spinner = true;
    const previousId = event.previousContainer.data[event.previousIndex]._id;
    const currentId = event.container.data[event.currentIndex]._id;
    const previousIndex = this.item.attachments.findIndex(i => i._id === previousId);
    const currentIndex =
      this.item.attachments.findIndex(i => i._id === currentId) -
      (event.container.id > event.previousContainer.id ? 1 : 0);
    moveItemInArray(this.item.attachments, previousIndex, currentIndex);
    this.item.attachments = this.item.attachments.map((a: Attachment, index: number) => ({ ...a, ordinal: index }));
    this.libraryService.updateAttachmentOrdinals(this.item).subscribe({
      next: () => {
        this.spinner = false;
        this.cdr.markForCheck();
      },
      error: () => {
        this.spinner = false;
        this.cdr.markForCheck();
      },
    });
  }
}
