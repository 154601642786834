<div
  class="terra-input terra-number-input"
  (click)="focus()"
  [ngClass]="{
    'terra-input--disabled': disabled,
    'terra-input--invalid': _terraErrorStateMatcher.isErrorState(_ngControl),
    'terra-input--readonly': readonly
  }">
  <span class="terra-input__slot">
    <ng-content select="terra-input-prefix" />
  </span>
  <input
    type="number"
    #input
    class="terra-input__input terra-number-input__input"
    [max]="_max ?? null"
    [min]="_min ?? null"
    [step]="_step"
    [attr.placeholder]="placeholder || null"
    [disabled]="disabled"
    [readonly]="readonly"
    [autocomplete]="autocomplete"
    [(ngModel)]="_inputValue"
    (ngModelChange)="_onModelChange($event)"
    (blur)="_blurredNumber($event)"
    (focus)="_focused($event)"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby" />
  <span class="terra-input__slot">
    <ng-content select="terra-input-suffix" />
  </span>
  <div class="terra-number-input__steppers">
    <button
      tabindex="-1"
      (mousedown)="_keepFocused($event)"
      (click)="_changeNumber($event, 1)"
      class="terra-number-input__stepper"
      [disabled]="disabled || readonly">
      <terra-icon icon="caret-up" size="16" />
    </button>
    <button
      tabindex="-1"
      (mousedown)="_keepFocused($event)"
      (click)="_changeNumber($event, -1)"
      class="terra-number-input__stepper"
      [disabled]="disabled || readonly">
      <terra-icon icon="caret-down" size="16" />
    </button>
  </div>
</div>
