import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable, ReplaySubject, Subject, catchError, map, of, take, tap } from 'rxjs';

import {
  AuxiliaryRouterOutletService,
  OutletRouteParams,
} from '@ninety/ui/legacy/core/services/auxiliary-router-outlet.service';
import { DetailData } from '@ninety/ui/legacy/shared/models/_shared/detail-data';
import { DetailItem } from '@ninety/ui/legacy/shared/models/_shared/detail-item.type';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { CommentDeleteEvent } from '@ninety/ui/legacy/shared/models/_shared/detail-view-input';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { Conversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { IntervalCode } from '@ninety/ui/legacy/shared/models/issues/interval-code';
import { Issue } from '@ninety/ui/legacy/shared/models/issues/issue';
import { SendIssueBackEvent } from '@ninety/ui/legacy/shared/models/issues/send-issue-back-event';
import { SendIssueEvent } from '@ninety/ui/legacy/shared/models/issues/send-issue-event';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { MeetingChangeEvent } from '@ninety/ui/legacy/shared/models/meetings/meeting-events';
import { Process } from '@ninety/ui/legacy/shared/models/process/process';
import { Milestone } from '@ninety/ui/legacy/shared/models/rocks/milestone';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { RockStatusChangeEvent } from '@ninety/ui/legacy/shared/models/rocks/update-rock-event';
import { Measurable } from '@ninety/ui/legacy/shared/models/scorecard/measurable';
import { FutureGoal } from '@ninety/ui/legacy/shared/models/vto/future-goal';
import { MeetingsPageActions } from '@ninety/web/pages/meetings/_state/meetings.actions';

import {
  DetailServiceMilestoneActions,
  DetailServiceRockActions,
  DetailViewActions,
} from '../_state/detail-view.actions';
import { DetailServiceSelectors } from '../_state/detail-view.selectors';

export interface TeamChangeDetailEvent {
  teamId?: string;
  additionalTeamIds?: string[];
  isPersonal?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DetailService<T> {
  private readonly data$ = new ReplaySubject<DetailData<T> | null>(1);

  /**
   * Whether to stream detail actions to the store. Sourced from store, but maintained in component to avoid cost of
   * checking the store on each dispatch. Normal flow is the page enables streaming onInit. The detail view events can't
   * be dispatched before that point, so there is little concern about race conditions and sequencing.
   */
  private _shouldStreamToStore: boolean;

  readonly detailViewClosed$ = new Subject<void>();

  // Common events
  readonly attachmentChange$ = new Subject<DetailItem>();
  readonly completed$ = new Subject<DetailItem>();
  readonly delete$ = new Subject<DetailItem>();
  readonly teamChange$ = new Subject<TeamChangeDetailEvent>();
  readonly toggleArchive$ = new Subject<DetailItem>();
  readonly update$ = new Subject<Partial<DetailItem>>();
  readonly refresh$ = new Subject<void>();

  // Issue events
  readonly issueIntervalChange$ = new Subject<IntervalCode>();
  readonly issueSentToAnotherTeam$ = new Subject<SendIssueEvent>();
  readonly issueReturnedToOriginalTeam$ = new Subject<SendIssueBackEvent>();
  readonly mergeIssue$ = new Subject<Issue>();
  readonly issueUpdate$ = new Subject<Partial<DetailItem>>();
  readonly issueTeamChange$ = new Subject<string>();
  readonly issueDeleteComment$ = new Subject<CommentDeleteEvent>();

  // Rock Events
  readonly statusChange$ = new Subject<RockStatusChangeEvent>();
  readonly changeLevel$ = new Subject<Rock>();
  readonly selectMilestoneRock$ = new Subject<Milestone>();
  readonly rockUpdate$ = new Subject<Partial<DetailItem>>();
  readonly rockDeleteComment$ = new Subject<CommentDeleteEvent>();

  // My-90 rock and milestone coupling
  readonly milestoneUpdate$ = new Subject<Partial<Milestone>>();
  readonly milestoneDelete$ = new Subject<Milestone>();
  readonly milestoneAdd$ = new Subject<Milestone>();
  readonly rockMilestoneUpdate$ = new Subject<Partial<Milestone>>();
  readonly milestoneAddFromRock$ = new Subject<Milestone>();
  readonly rockMilestoneDelete$ = new Subject<string>();

  // Measurable detail
  readonly measurableSaved$ = new Subject<Partial<Measurable>>();

  // Measurable Create Detail
  readonly measurableCreateSave$ = new Subject<Measurable>();

  // Future Goal detail
  readonly futureGoalDelete$ = new Subject<FutureGoal>();
  readonly futureGoalSave$ = new Subject<FutureGoal>();
  readonly futureGoalOpenCreate$ = new Subject<ItemType>();

  // Process detail
  readonly processCloned$ = new Subject<Process>();
  readonly processDeleted$ = new Subject<Process>();

  // Meeting detail
  readonly meetingDelete$ = new Subject<Meeting>();
  readonly meetingSave$ = new Subject<MeetingChangeEvent>();

  // Conversation
  readonly conversationOpenFormalSettingsDialog$ = new Subject<Conversation>();

  constructor(private auxiliaryRouterOutletService: AuxiliaryRouterOutletService, private store: Store) {
    this.store
      .select(DetailServiceSelectors.isStreamingEnabled)
      .subscribe(enabled => (this._shouldStreamToStore = enabled));
  }

  setData(data: DetailData<T> | null) {
    this.data$.next(data);
  }

  getData() {
    return this.data$.asObservable();
  }

  updateInputs(changes: Partial<T>) {
    this.data$
      .pipe(
        take(1),
        tap(data =>
          this.data$.next({
            ...data,
            input: {
              ...data?.input,
              ...changes,
            },
          })
        )
      )
      .subscribe();
  }

  /**
   * a more direct way to update the item inside detail view
   * sometimes needed for the ngrx store transition aka add cloneDeep everywhere
   */
  updateInputsItem(changes: Partial<Item>) {
    this.data$
      .pipe(
        take(1),
        tap((data: any) => {
          data.input.item = { ...data.input.item, ...cloneDeep(changes) };
          return this.data$.next(data);
        })
      )
      .subscribe();
  }

  updateInputsItemArray(changes: Partial<Item>, arrayName: string) {
    this.data$
      .pipe(
        take(1),
        tap((data: any) => {
          if (data.input.item[arrayName]) {
            const index = data.input.item[arrayName].findIndex(i => i._id === changes._id);
            if (index > -1) {
              data.input.item[arrayName][index] = { ...cloneDeep(data.input.item[arrayName][index]), ...changes };
            }
          }
          return this.data$.next(data);
        })
      )
      .subscribe();
  }

  getInputs(): Observable<T | null> {
    return this.data$.asObservable().pipe(map(d => d?.input));
  }

  /**
   * Consumers already dispatch this to the store, no need to stream.
   */
  open<Q>(params: OutletRouteParams<Q>) {
    return this.auxiliaryRouterOutletService.open(params);
  }

  /**
   * Close the detail view and reset data.
   *
   * Note, DetailViewActions.closed is dispatched by the detail-view-wrapper itself. This ensures that the closed action
   * is always dispatched when the detail view is closed, regardless of if this method is called or not. This handles
   * the edge cases around navigation, such as the detail closed by hitting the back button or by clicking on one of the
   * main navigation links. See DEV-5578 and DEV-4931 for more details.
   *
   * @param dispatchClose Whether to dispatch the close action to the store. Passed as false when the effects pipeline
   *                      runs in response to a close action.
   */
  close(dispatchClose = true): Observable<boolean> {
    if (dispatchClose) this.store.dispatch(DetailViewActions.close());

    return this.auxiliaryRouterOutletService.close().pipe(
      catchError((err: unknown) => {
        // console.error('Error while closing detail view: ', err);
        return of(false);
      }),
      tap(() => this.setData(null))
    );
  }

  // Common emitters

  onComplete(item: DetailItem, itemType: DetailType): void {
    this.completed$.next(item);
    this.streamActionToStore(() => DetailViewActions.completed({ item, itemType }));
  }

  onUpdate(changes: Partial<DetailItem>, type: DetailType): void {
    // We need to emit based on item type...
    // because meeting conclude has both todos and headlines lists active at the same time and events would get triggered in both
    // In the future any "pages" that have multiple list components active will need individual emitters
    switch (type) {
      case DetailType.rock:
      case DetailType.rockStore:
        this.rockUpdate$.next(changes);
        if (changes.hasOwnProperty('userId')) {
          //TODO NEXT: Handle this differently, treat user update similar to a teamUpdate,
          //or refactor item cards to connect to the store directly
          this.streamActionToStore(() => DetailServiceRockActions.updatedUser({ userId: changes.userId }));
        } else {
          this.streamActionToStore(() => DetailServiceRockActions.updated({ update: changes as Partial<Rock> }));
        }
        break;
      case DetailType.issue:
        this.issueUpdate$.next(changes);
        break;
      default:
        this.update$.next(changes);
        break;
    }
  }

  onToggleArchive(item: DetailItem, itemType: DetailType): void {
    this.toggleArchive$.next(item);
    this.streamActionToStore(() => DetailViewActions.toggledArchived({ item, itemType }));
  }

  onDelete(item: Item, itemType: DetailType): void {
    this.delete$.next(item);
    this.streamActionToStore(() => DetailViewActions.deleted({ item, itemType }));
  }

  onDeleteComment(event: CommentDeleteEvent, itemType: DetailType): void {
    switch (itemType) {
      case DetailType.rock:
      case DetailType.rockStore:
        this.rockDeleteComment$.next(event);
        this.streamActionToStore(() => DetailServiceRockActions.deleteComment({ event }));
        break;
      case DetailType.issue:
        this.issueDeleteComment$.next(event);
        break;
    }
  }

  onTeamChange(changes: TeamChangeDetailEvent, itemType: DetailType): void {
    // We need to emit based on item type...
    // because meeting conclude has both todos and headlines lists active at the same time and events would get triggered in both
    // In the future any "pages" that have multiple list components active will need individual emitters
    switch (itemType) {
      case DetailType.issue:
        if (changes.teamId) this.issueTeamChange$.next(changes.teamId);
        break;
      default:
        this.teamChange$.next(changes);
        this.streamActionToStore(() => DetailViewActions.teamChanged({ changes, itemType }));
        break;
    }
  }

  onRatingChange(item: Issue): void {
    this.issueUpdate$.next({ rating: item.rating });
  }

  onAttachmentChange(item: DetailItem): void {
    this.attachmentChange$.next(item);
  }

  onRefresh(): void {
    this.refresh$.next();
  }

  // Issue emitters

  onIntervalChange(code: IntervalCode): void {
    this.issueIntervalChange$.next(code);
  }

  onMergeIssue(issue: Issue): void {
    this.mergeIssue$.next(issue);
  }

  onReturnIssue(event: SendIssueBackEvent): void {
    this.issueReturnedToOriginalTeam$.next(event);
  }

  onSendIssue(event: SendIssueEvent): void {
    this.issueSentToAnotherTeam$.next(event);
  }

  // Rock emitters
  onStatusChange(event: RockStatusChangeEvent): void {
    this.statusChange$.next(event);
    this.streamActionToStore(() => DetailServiceRockActions.updatedStatusCode({ event }));
  }

  onLevelChange(changes: Partial<Rock>): void {
    this.changeLevel$.next(changes);
  }

  onSelectMilestoneRock(milestone: Milestone) {
    this.selectMilestoneRock$.next(milestone);
  }

  onMilestoneAdd(milestone: Milestone) {
    this.milestoneAdd$.next(milestone);
    //A request is sent before we get here so only update the store from here on
    this.streamActionToStore(() => DetailServiceMilestoneActions.added({ milestone }));
  }

  onMilestoneDelete(milestone: Milestone) {
    this.milestoneDelete$.next(milestone);
    //A request is sent before we get here so only update the store from here on
    this.streamActionToStore(() => DetailServiceMilestoneActions.deleted({ milestoneId: milestone._id }));
  }

  onMilestoneUpdate(changes: Partial<Milestone>) {
    this.milestoneUpdate$.next(changes);
    //A request is sent before we get here so only update the store from here on,
    //no other post requests needed
    //TODO NEXT: refactor milestone list in rock detail view
    this.streamActionToStore(() => DetailServiceMilestoneActions.updated({ update: changes as Partial<Milestone> }));
  }

  onRockMilestoneUpdate(changes: Partial<Milestone>) {
    this.rockMilestoneUpdate$.next(changes);
  }

  // Measurable

  onMeasurableUpdate(changes: Partial<Measurable>): void {
    this.measurableSaved$.next(changes);
  }

  // Measurable Create

  onMeasurableCreateSave(measurable: Measurable) {
    this.measurableCreateSave$.next(measurable);
  }

  // Future Goal

  onFutureGoalDelete(goal: FutureGoal): void {
    this.futureGoalDelete$.next(goal);
  }

  onFutureGoalSave(goal: FutureGoal): void {
    this.futureGoalSave$.next(goal);
  }

  onFutureGoalOpenCreate(itemType: ItemType): void {
    this.futureGoalOpenCreate$.next(itemType);
  }

  // Process

  onProcessClone(process: Process): void {
    this.processCloned$.next(process);
  }

  onProcessDelete(process: Process): void {
    this.processDeleted$.next(process);
  }

  // Meeting

  onMeetingDelete(meeting: Meeting) {
    this.store.dispatch(MeetingsPageActions.deleteMeeting({ meeting }));
    this.meetingDelete$.next(meeting);
  }

  onMeetingSave(event: MeetingChangeEvent) {
    this.meetingSave$.next(event);
    const { item, changes } = event;
    this.store.dispatch(MeetingsPageActions.updateMeetingInStore({ _id: item._id, update: cloneDeep(changes) }));
  }

  // Conversation
  onConversationOpenFormalSettings(c: Conversation): void {
    this.conversationOpenFormalSettingsDialog$.next(c);
  }

  // Private

  /**
   * Only stream actions to the store when requested. Feature modules should turn this on when they load and turn it
   * off when they are destroyed. See MyNinetyPageEffects for an example.
   *
   * Prefer supplier of action VS literal action to not pay any costs when disabled. (For example, if you need to
   * cloneDeep before dispatching an action, do that in the supplier method and it will only be invoked when streaming
   * is enabled.
   */
  private streamActionToStore(actionSupplier: () => Action) {
    if (this._shouldStreamToStore) this.store.dispatch(actionSupplier());
  }
}
