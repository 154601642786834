<ng-container *ngIf="state$ | async as state">
  <ninety-main-layout
    [inMeeting]="state.inMeeting"
    [inFeedback]="state.inFeedback"
    [isMobile]="state.isMobile"
    [isConversation]="state.layoutType === layouts.conversation">
    <ng-container page-banner>
      <ninety-notification-banner *ngIf="!(billingV3Company$ | ngrxPush)"></ninety-notification-banner>
    </ng-container>
    <ng-container billing-page-banner>
      <ninety-billing-notification-banner *ngIf="billingV3Company$ | ngrxPush"></ninety-billing-notification-banner>
    </ng-container>
    <ng-container page-sidebar [ngSwitch]="state.layoutType">
      <ninety-meeting-sidenav
        data-cy="home_ninety-meeting-sidenav"
        *ngSwitchCase="layouts.meeting"
        [showNav]="state.showSideNav"
        [isMobile]="state.isMobile"
        [showNotes]="state.showNotes"></ninety-meeting-sidenav>
      <ninety-conversation-sidenav
        *ngSwitchCase="layouts.conversation"
        [showNav]="state.showSideNav"
        [isMobile]="state.isMobile"
        [showQuarterlyQuestions]="state.showQuarterlyQuestions"></ninety-conversation-sidenav>
      <ng-container *ngSwitchCase="'normal'">
        <ninety-main-sidebar [isMobile]="state.isMobile"></ninety-main-sidebar>
      </ng-container>
    </ng-container>
    <ng-container page-content>
      <router-outlet></router-outlet>
      <ninety-meeting-notes *ngIf="state.showNotes" [class.nav-expanded]="state.showSideNav"></ninety-meeting-notes>
    </ng-container>
  </ninety-main-layout>
</ng-container>
