import { AgChartLegendMarkerOptions } from 'ag-charts-enterprise';

/** A larger marker with a border */
export const DiscoCircleMarker: Readonly<AgChartLegendMarkerOptions> = {
  size: 20,
  strokeWidth: 3,
  shape: 'circle',
};

/** A smaller marker with no border */
export const DiscoSmallCircleMarker: Readonly<AgChartLegendMarkerOptions> = {
  size: 12,
  strokeWidth: 0,
  shape: 'circle',
};
