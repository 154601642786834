import { PaginationConstants } from '../../../_components/pagination/pagination.component.model';
import { ChartType } from '../charts/chart-type.enum';
import { SortDirection } from '../enums/sort-direction';
import { Theme } from '../enums/theme';
import { IssuesListView } from '../issues/list-view';
import { MilestoneStatus } from '../milestone/milestone-status.enum';
import { MyNinetyWidgetTypeKeys } from '../my-90/my-90-widget-type-keys';
import { UserNotificationSettings } from '../notifications/event-notification.models';
import { LegacyPeriodInterval } from '../scorecard/legacy-period-interval.enum';
import { PeriodInterval } from '../scorecard/period-interval.enum';
import { TodoSortField } from '../todos/todo-sort-field';

import { DialogMode } from './dialog-mode-types';

export interface MyNinetyWidgetSettings {
  pageSize?: number;
  sortField?: TodoSortField;
  sortDirection?: SortDirection;
}

export interface MyNinetyUserSettings {
  widgets: {
    [MyNinetyWidgetTypeKeys.PERSONAL_TODOS]?: MyNinetyWidgetSettings;
    [MyNinetyWidgetTypeKeys.TEAM_TODOS]?: MyNinetyWidgetSettings;
    [MyNinetyWidgetTypeKeys.ROCKS_AND_MILESTONES]?: MyNinetyWidgetSettings;
  };
}

export class UserSettings {
  sidenavPinned: 'expanded' | 'collapsed' | null = null;
  defaultTeamId: string;
  myMilestonesStatus: MilestoneStatus = MilestoneStatus.all;
  myMeasurablesPeriodInterval: LegacyPeriodInterval;
  preferredLocale: Locale = Locale.default;
  notificationEmails: NotificationEmails;
  eventNotifications: UserNotificationSettings[];
  timezone: string;
  timezoneOffsetInMinutes: number;
  setTimezoneAutomatically = false;
  issuesListView: IssuesListView = IssuesListView.list;
  dialogModePreference: DialogMode;
  integrations: Integrations;
  settingsAlerts: UserSettingsAlerts;
  theme: Theme = Theme.LIGHT;

  todoChart: My90ChartDefaults = {
    chartType: ChartType.advancedPie,
    selectedTeamId: 'company',
    allTeamsSelected: true,
    selectedTeamIds: ['all'],
    selectedOptions: ['completedOnTime'],
  };
  rockChart: My90ChartDefaults = {
    chartType: ChartType.pieGrid,
    selectedTeamId: 'company',
    selectedTeamIds: ['all'],
    allTeamsSelected: true,
  };
  measurableChart: My90ChartDefaults = {
    chartType: ChartType.groupedBarVertical,
    selectedTeamId: 'company',
    selectedTeamIds: ['all'],
    allTeamsSelected: true,
    periodInterval: PeriodInterval.weekly,
  };

  myNinety: MyNinetyUserSettings = {
    widgets: {
      [MyNinetyWidgetTypeKeys.PERSONAL_TODOS]: {
        pageSize: PaginationConstants.INITIAL_PAGE_SIZE,
      },
      [MyNinetyWidgetTypeKeys.TEAM_TODOS]: {
        pageSize: PaginationConstants.INITIAL_PAGE_SIZE,
      },
      [MyNinetyWidgetTypeKeys.ROCKS_AND_MILESTONES]: {
        pageSize: PaginationConstants.INITIAL_PAGE_SIZE,
      },
    },
  };

  // for implementers only
  meetingRecaps: 'all' | 'slt' | 'multiple' | 'none' = 'slt';
  selectedTeamIdsMeetingRecaps: string[] = [];

  constructor() {}
}

export class Integrations {
  google?: GoogleSettings;
}

export class GoogleSettings {
  createTasks = false;
  taskListId: string;
  requiresAuthentication = false;
  /**
   * These properties should never make it to the FE but still exist here to
   * set to null if the user is disabling the integration, which helps avoid trouble down
   * the road if they re-enable.
   */
  accessToken?: string;
  refreshToken?: string;
  expireDate?: Date;
}

export class My90ChartDefaults {
  chartType: ChartType;
  selectedTeamIds: string[];
  selectedTeamId?: string | 'company' | 'user';
  allTeamsSelected: boolean;

  // todo chart only
  selectedOptions?: ['completedOnTime' | 'pastDue'];

  // measurables chart only
  periodInterval?: PeriodInterval;
}

export class NotificationEmails {
  monday: DailyEmailOptions;
  tuesday: DailyEmailOptions;
  wednesday: DailyEmailOptions;
  thursday: DailyEmailOptions;
  friday: DailyEmailOptions;
  saturday: DailyEmailOptions;
  sunday: DailyEmailOptions;
  useTwentyFourHourTime: boolean;
}

export class DailyEmailOptions {
  enabled: boolean;
  adjacentUtcDay: 'prev' | 'next' | null;
  adjustedUtcTime: string;
  time: string;
  minutesOffsetPastMidnight: number;
}

export enum Locale {
  default = 'en-US',
  greatBritain = 'en-GB',
  canada = 'en-CA',
}

export class UserSettingsAlerts {
  profile: string;
  account: string;
  preferences: string;
  integration: string;
}

export const twelveHourTimes = [
  '12:00',
  '12:30',
  '1:00',
  '1:30',
  '2:00',
  '2:30',
  '3:00',
  '3:30',
  '4:00',
  '4:30',
  '5:00',
  '5:30',
  '6:00',
  '6:30',
  '7:00',
  '7:30',
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
];

export const twentyFourHourTimes = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];
