import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameSearch',
})
export class NameSearchPipe implements PipeTransform {
  transform<T extends { name?: string }>(items: T[], searchText: string): T[] {
    if (!items || items.length === 0 || !searchText) return items;
    const input = searchText.toLocaleLowerCase();
    return items.filter(item => item.name?.toLocaleLowerCase().includes(input));
  }
}
