import { Component, TrackByFunction } from '@angular/core';
import { FormControl } from '@angular/forms';

import { HelpfulService } from '@ninety/ui/legacy/core/services/helpful.service';
import type { SuppressionList } from '@ninety/ui/legacy/shared/models/helpful/suppression-list.interface';

@Component({
  selector: 'ninety-email-suppression-list',
  templateUrl: './email-suppression-list.component.html',
  styleUrls: ['../_shared/tab-styles.scss', './email-suppression-list.component.scss'],
})
export class EmailSuppressionListComponent {
  suppressionList: SuppressionList[];
  suppressionListSearch = new FormControl('');

  constructor(private readonly helpfulService: HelpfulService) {}

  searchSuppressionList() {
    this.suppressionList = null;
    this.helpfulService
      .getSuppressionList(this.suppressionListSearch.value)
      .subscribe(list => (this.suppressionList = list));
  }

  removeFromSuppressionList(email: string) {
    this.helpfulService.removeFromSuppressionList(email).subscribe(() => {
      this.suppressionList = this.suppressionList.filter(({ EmailAddress }) => EmailAddress !== email);
    });
  }

  trackByEmail: TrackByFunction<SuppressionList> = (_i: number, item: SuppressionList): string => item.EmailAddress;
}
