import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap, take } from 'rxjs';

import { RoleCode } from '../../_shared/models/_shared/role-code';
import { RoleName } from '../../_shared/models/_shared/role-name';
import { routeNames } from '../../_shared/models/constants/route-names';
import { selectCurrentUser } from '../../_state/app-entities/users/users-state.selectors';
import { AuthService } from '../services/auth.service';
import { RoleService } from '../services/role.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private readonly permittedRoutesForManagerOrManagee = [
    routeNames.directory,
    routeNames.feedback,
    routeNames.ac,
    routeNames.process,
    routeNames.myfocus,
  ];

  private readonly permittedRoutesForObserver = [
    routeNames.directory,
    routeNames.ac,
    routeNames.myfocus,
    routeNames.process,
  ];

  private readonly permittedRoutesForLite = [
    routeNames.feedback,
    routeNames.ac,
    routeNames.process,
    routeNames.myfocus,
    routeNames.myaccount,
    routeNames.conversation,
    routeNames.oneOnOne,
    routeNames.discussions,
    routeNames.discussion,
  ];

  constructor(private authService: AuthService, private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> {
    if (!this.authService.isLoggedIn) {
      return this.redirectToLogin(state);
    }

    return this.store.select(selectCurrentUser).pipe(
      take(1),
      switchMap(user => {
        const userRole = this.getUserRoleName(user.roleCode);
        const routes = route.url.map(r => r.path);
        if (routes.includes('partner-hub')) return of(true);

        if (userRole === RoleName.lite && routes?.length !== 0) {
          const permittedRoutesForLite = routes.some(r => this.permittedRoutesForLite.includes(r));
          if (!permittedRoutesForLite) this.router.navigateByUrl('/home');
          return of(true);
        }

        const userHasTeams = RoleService.minRoleName.admin(userRole) || user.teams?.length > 0;
        if (!userHasTeams && routes?.length !== 0) {
          if (state.url.startsWith('/settings/user')) return of(true);

          const isFeedBackProcessAc = routes.some(r => this.permittedRoutesForManagerOrManagee.includes(r));
          const isProcessOrAc = routes.some(r => this.permittedRoutesForObserver.includes(r));

          return of(
            (userRole === RoleName.observer && isProcessOrAc) || isFeedBackProcessAc || this.router.parseUrl('/home')
          );
        }

        if (route.data && route.data['checkMinRole']) {
          const authorized = RoleService.minRoleName[route.data['checkMinRole']](userRole);
          if (!authorized) {
            // This will reset all state.
            this.router.navigateByUrl('');
            return of(false);
          }
        }
        return of(true);
      })
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): UrlTree | boolean | Observable<UrlTree | boolean> {
    if (!this.authService.isLoggedIn) {
      return this.redirectToLogin(state);
    }

    return this.store.select(selectCurrentUser).pipe(
      take(1),
      switchMap(user => {
        const userRole = this.getUserRoleName(user.roleCode);
        if (route.data && route.data['checkMinRole']) {
          const authorized = RoleService.minRoleName[route.data['checkMinRole']](userRole);
          if (!authorized) {
            this.router.navigateByUrl('');
            return of(false);
          }
        }
        return of(true);
      })
    );
  }

  private redirectToLogin(state: RouterStateSnapshot): UrlTree {
    return this.router.parseUrl(`/login?redirectUrl=${state.url}`);
  }

  private getUserRoleName(roleCode: RoleCode): RoleName {
    return RoleService.roleName(roleCode).toLowerCase() as RoleName;
  }
}
