import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { EntitlementsService } from '../../../_core/services/entitlements.service';

import { EntitlementsStateActions } from './entitlements-state.actions';

@Injectable()
export class EntitlementsStateEffects {
  constructor(private readonly actions$: Actions, private readonly entitlementsService: EntitlementsService) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntitlementsStateActions.init),
      switchMap(() => this.entitlementsService.initEntitlements()),
      map(() => EntitlementsStateActions.parseEntitlementsToken())
    )
  );

  clearEntitlements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntitlementsStateActions.clearEntitlements),
      map(() => {
        this.entitlementsService.clearEntitlementsToken();
        return EntitlementsStateActions.parseEntitlementsToken();
      })
    )
  );

  parseEntitlementsToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntitlementsStateActions.parseEntitlementsToken),
      map(() => {
        const entitlements = this.entitlementsService.parseEntitlementsToken();
        return EntitlementsStateActions.parseEntitlementsTokenSuccess({
          entitlements,
        });
      })
    )
  );
}
