import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(words: string[] | string, ...args: unknown[]): string {
    return InitialsPipe.buildInitials(words);
  }

  static buildInitials(words: string[] | string): string {
    if (!words) {
      // console.warn('Passed a falsey value to InitialsPipe');
      return '';
    }

    if (!Array.isArray(words)) words = words.split(/\s+/);

    if (words.length == 0) {
      // console.warn('Passed an empty array/delimited-string to InitialsPipe');
      return '';
    }

    try {
      return words
        .filter(word => !!word)
        .slice(0, 2)
        .map(word => InitialsPipe.getFirstChar(word))
        .reduce((acc, cur) => acc + cur, '');
    } catch (e) {
      // console.error('Failed to extract initials from input - ', e);
      return '';
    }
  }

  private static getFirstChar(word: string): string {
    return word[0].charAt(0).toLocaleUpperCase();
  }
}
