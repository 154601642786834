import { Directive, OnInit, Self } from '@angular/core';

import { TerraInputBaseClass } from '../terra-input-base/terra-input-base';

@Directive({
  selector: '[terraAutoFocus]',
  standalone: true,
})
export class TerraInputAutoFocusDirective implements OnInit {
  constructor(@Self() private readonly _terraInputBase: TerraInputBaseClass) {}

  ngOnInit() {
    setTimeout(() => this._terraInputBase.focus());
  }
}
