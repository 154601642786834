import { Component } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import ObjectId from 'bson-objectid';
import { BehaviorSubject, filter, switchMap, tap } from 'rxjs';

import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { HelpfulService } from '@ninety/ui/legacy/core/services/helpful.service';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { Company } from '@ninety/ui/legacy/shared/models/company/company';

@Component({
  selector: 'ninety-hard-delete-company',
  templateUrl: './hard-delete-company.component.html',
  styleUrls: ['../_shared/tab-styles.scss', './hard-delete-company.component.scss'],
})
export class HardDeleteCompanyComponent {
  private readonly _companyDeletionResult$ = new BehaviorSubject<string>('');
  protected readonly companyDeletionResult$ = this._companyDeletionResult$.asObservable();

  companyIdToDeleteSearch = new FormControl('', {
    validators: [
      (c: AbstractControl): ValidationErrors =>
        !c.value || ObjectId.isValid(c.value) ? null : { isObjectId: { value: c.value } },
    ],
  });

  companyToDelete: Company;

  constructor(
    private readonly helpfulService: HelpfulService,
    private readonly dialog: MatDialog,
    private readonly companyService: CompanyService
  ) {}

  findCompanyById() {
    const companyId = this.companyIdToDeleteSearch.value.trim();

    if (!ObjectId.isValid(companyId)) {
      this.companyIdToDeleteSearch.markAsTouched();
      this.companyIdToDeleteSearch.updateValueAndValidity();
      return;
    }

    this.companyService.getCompanyById(companyId).subscribe({
      next: company => (this.companyToDelete = company),
    });
  }

  openDeleteCompanyDialog(): void {
    this.dialog
      .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
        data: {
          title: `Hard Delete Company ${this.companyToDelete.name}?`,
          message: '<strong>Caution:</strong> This action cannot be undone.',
          confirmButtonText: 'I understand... Delete Anyway',
        },
      })
      .afterClosed()
      .pipe(
        filter(shouldDelete => shouldDelete),
        switchMap(() => this.helpfulService.hardDeleteCompany(this.companyToDelete._id)),
        tap(deleteResult => this._companyDeletionResult$.next(deleteResult))
      )
      .subscribe();
  }
}
