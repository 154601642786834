import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, of, startWith, switchMap, tap } from 'rxjs';

import { StateService } from '@ninety/ui/legacy/core/index';
import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/index';
import { FeatureFlagFacade, FeatureFlagKeys, selectFeatureFlag } from '@ninety/ui/legacy/state/index';

interface TabGroupModel {
  tool: string;
  tabs: TabRouteModel[];
}

interface TabRouteModel {
  route: string;
  label: string;
  show$?: Observable<boolean>;
  dataCy?: string;
}

@Component({
  selector: 'ninety-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss', './main-header.terrafy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.main-header--shadow]': 'showHeaderShadow',
  },
})
export class MainHeaderComponent {
  @Input() inMeeting: boolean;
  @Input() inFeedback: boolean;
  @Input() isMobile: boolean;
  @Input() isConversation: boolean;

  // Master list of all terrafy tab data
  private _allTabs: TabGroupModel[] = [
    {
      tool: 'data',
      tabs: [
        { route: 'weekly', label: 'Weekly' },
        {
          route: 'trailing',
          label: 'T4W & T13W',
          show$: this._stateService.scorecardTrailingTabToggle$,
        },
        { route: 'monthly', label: 'Monthly' },
        { route: 'quarterly', label: 'Quarterly' },
        { route: 'annual', label: 'Annual' },
      ],
    },
    {
      tool: 'issues',
      tabs: [
        { route: 'short-term', label: this._stateService.language.issue.shortTerm, dataCy: 'issues_term-tab' },
        { route: 'long-term', label: this._stateService.language.issue.longTerm, dataCy: 'issues_term-tab' },
      ],
    },
    {
      tool: 'todos',
      tabs: [
        { route: 'team', label: this._stateService.language.todo.team, dataCy: 'todos-app_tab-link-Team' },
        { route: 'personal', label: this._stateService.language.todo.personal, dataCy: 'todos-app_tab-link-Private' },
      ],
    },
    {
      tool: 'vision',
      tabs: [
        {
          route: 'vision',
          label: this._stateService.language.vto.vision,
          dataCy: 'terrafy-vision-tab-label',
        },
        {
          route: 'goals',
          label: this._stateService.language.vto.traction,
          dataCy: 'terrafy-traction-tab-label',
        },
        {
          route: 'long-term',
          label: this._stateService.language.issue.longTerm + ' ' + this._stateService.language.issue.items,
          show$: this.store
            .select(selectFeatureFlag(FeatureFlagKeys.coreNinetyDefaultVTO))
            .pipe(map(flagIsTrue => flagIsTrue && this._stateService.company.bos === BusinessOperatingSystem.ninetyOS)),
          dataCy: 'terrafy-lti-tab-label',
        },
        { route: 'swot', label: this._stateService.language.vto.swot, dataCy: 'terrafy-swot-tab-label' },
      ],
    },
    {
      tool: '1-on-1',
      tabs: [
        {
          route: 'discussions/quarterly',
          label: this._stateService.language.feedback.quarterlyItems,
          dataCy: 'conversations_link-quarterly',
        },
        {
          route: 'discussions/annual',
          label: this._stateService.language.feedback.annualItems,
          dataCy: 'conversations_link-annual',
        },
        {
          route: 'discussions/fit-check',
          label: this._stateService.language.feedback.peopleAnalyzer,
          dataCy: 'conversations_link-fit-check',
        },
      ],
    },
  ];
  // Because we need this for the host binding and can't use async pipe there we need to imperatively store the value
  protected showHeaderShadow = false;

  protected urlAfterRedirects$ = this._router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map((event: NavigationEnd) => {
      return event.urlAfterRedirects;
    }),
    startWith(this._router.url)
  );

  protected activeTool$ = this.urlAfterRedirects$.pipe(
    switchMap(url => {
      return of(this._getActiveTool(url));
    })
  );

  protected activeRoute$ = combineLatest([this.activeTool$, this.urlAfterRedirects$]).pipe(
    switchMap(([activeTool, url]) => {
      return of(this._getActiveRoute(activeTool, url));
    })
  );

  // Stream of tabs for the active tool
  protected toolTabGroup$ = this.activeTool$.pipe(
    startWith(this._getActiveTool(this._router.url)),
    switchMap(activeTool => {
      return of(this._getTabGroupForTool(this._allTabs, activeTool));
    })
  );

  protected data$ = {
    activeRoute: this.activeRoute$,
    activeTool: this.activeTool$,
    toolTabGroup: this.toolTabGroup$,
    weeklyConversationsFeatureFlag: this._featureFlags.getFlag(FeatureFlagKeys.webWeeklyConversations),
    rocksv3FeatureFlag: this._featureFlags.getFlag(FeatureFlagKeys.webRocksV3),
    activeToolHeaderShadow: this.activeTool$.pipe(
      tap(activeTool => {
        /**
         * There are some tools that are using the main-header for the title but not the
         * tabs or filters toolbar.  So we need to filter for those tools and not show the shadow
         *
         * All other ones that are using the main-header and in terrafy mode should show the shadow
         */
        switch (activeTool) {
          case 'insights':
          case 'chart':
          case 'assessments':
          case 'meetings':
          case 'data-v2':
            this.showHeaderShadow = false;
            break;
          default:
            this.showHeaderShadow = true;
            break;
        }
      })
    ),
  };

  constructor(
    private _stateService: StateService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _featureFlags: FeatureFlagFacade,
    private store: Store
  ) {}

  private _getActiveRoute(activeTool: string, url: string): string {
    // Replace cleans urls that include other named router outlets like todos details pane
    const splitUrl = url.replace(/\([^]*/g, '').split('/');
    const rootTool = splitUrl[1];

    if (activeTool === '1-on-1') {
      return `${splitUrl[2]}/${splitUrl[3]}`;
    }

    if (activeTool === 'issues') {
      if (rootTool === 'meeting') {
        return splitUrl[splitUrl.length - 1];
      }
    }
    return splitUrl[2];
  }

  private _getActiveTool(url: string): string {
    const splitUrl = url.split('/');
    const rootTool = splitUrl[1];

    // Meeting has nested tools like vision and issues
    // if (rootTool === 'meeting') {
    //   const meetingTool = splitUrl[splitUrl.length - 1];
    //   switch (meetingTool) {
    //     case 'short-term':
    //     case 'long-term':
    //       // For issues nested under rock review we don't want to show new tabs as it wasn't working
    //       if (url.includes('/rocks')) {
    //         return rootTool;
    //       }
    //       return 'issues';
    //   }
    // }

    // Disables the tabs for the 1-on-1 tool when the form is open
    if (rootTool === '1-on-1' && splitUrl.at(-1) === 'form') {
      return '';
    }

    return rootTool;
  }

  private _getTabGroupForTool(tabsData: TabGroupModel[], tool: string): TabGroupModel {
    return tabsData.find(tab => tab.tool === tool);
  }

  navigateTo(activeTool: string, route: string): void {
    const inMeeting = this._activatedRoute.firstChild?.snapshot.url.some(segment => segment.path.includes('meeting'));

    if (inMeeting) {
      this._router.navigate([activeTool, route], { relativeTo: this._activatedRoute.firstChild });
    } else {
      this._router.navigate([`/${activeTool}/${route}`]);
    }
  }
}
