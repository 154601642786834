import { TerraIconName } from '@ninety/terra';
import { PlanName } from '@ninety/ui/legacy/shared/models/billing-v3/plan-name';

import {
  InitialLoggingFeatureFlagState,
  type LoggingFeatureFlagState,
} from '../../app-logging/logging-feature-flag-state';

export interface LabelAbTest {
  label: string;
  icon: TerraIconName;
}

export enum FeatureFlagKeys {
  appLogging = 'web-app-logging',
  appRefresh = 'web-app-refresh',
  commercialModelActive = 'ninety-commercial-model',
  coreNinetyDefaultVTO = 'web-coreNinety-vto-defaultVto',
  directoryAddUsersModal = 'directory-add-users-modal',
  directoryV2 = 'web-app-user-management-enhancements',
  discussionFormV2 = 'web-discussion-form-v-2',
  dragDropFileAttachments = 'web-item-detail-dnd-attachments',
  enableAssessments = 'web-enable-assessments',
  enableAssessmentsSidenavPill = 'web-enable-assessments-new-pill',
  enableContactCard = 'web-app-contact-card',
  enableLearningModule = 'web-learning-module',
  enableLearningModuleCategories = 'web-learning-module-categories',
  enableLearnMode90UGAOnly = 'web-enable-learn-mode-90u-ga-only',
  enableTeamPageEnhancements = 'web-app-team-page-enhancements',
  enableRepeatConversations = 'web-enable-repeat-conversations',
  enableFrequentDiscussions = 'web-enable-frequent-discussions',
  gettingStartedGuide = 'getting-started-guide',
  headlinesV2 = 'web-headlines-v2',
  hideReferFriendMenuItem = 'web-app-hide-refer-a-friend-menu-item',
  kpiManager = 'web-kpi-manager',
  linkedItems = 'web-linked-items',
  mazChat = 'maz-chat',
  mazChat90os = 'maz-chat-90-os',
  modifyBillingCoupons = 'web-settings-billing-coupons',
  partnerHubInsights = 'partner-hub-insights',
  pinnacleDefaultVTO = 'web-pinnacle-vto-defaultVto',
  pinnacleMissingData = 'web-pinnacle-missing-data',
  responsibilitiesBeta = 'web-responsibilities-v2',
  responsibilitiesBetaSetAsPrimary = 'web-responsibilities-v2-set-as-primary',
  responsibilitiesHideTopSeatSelect = 'web-org-chart-hide-top-seat-select',
  responsibilitiesSearch = 'web-org-chart-show-seat-search',
  responsibilitiesSeatDetailsV3 = 'web-org-chart-seat-details-v3',
  sessionLogout = 'web-session-logout',
  showUserEventNotificationsTab = 'web-settings-user-notifications',
  webUserSettingsIntegrationZapier = 'web-user-settings-integrations-zapier',
  webIntegrationsMicrosoft = 'web-integrations-microsoft',
  trialBanner = 'web-billing-trial-banner',
  trialCountdown = 'web-billing-trial-countdown',
  todosTabs = 'web-todos-tabs',
  useImageUploaderForCompany = 'web-settings-use-image-loader-for-company',
  quarterlyQuestionSettings = 'web-quarterly-questions-settings',
  webConversationSettingsRatings = 'web-conversation-settings-ratings',
  webWeeklyConversations = 'web-weekly-conversations',
  webExcelDownload = 'web-excel-download',
  webExcelDownloadBatch2 = 'web-excel-download-batch-2',
  clientFacingDataUpload = 'client-facing-data-upload',
  /** insights A/B test flags */
  insightsTabNameAbTest = 'web-insights-tab-name-ab-test',
  insightsExpandButtonConfiguration = 'web-insights-expand-button-ab-test',
  /** Insights dashboard flags */
  insightsTopLevel = 'web-insights-top-level-access',
  insightsHiddenWidgets = 'web-insights-hidden-widgets',
  insightsRightPersonRightSeat = 'web-right-person-right-seat-banner',
  insightsRocksDashboard = 'web-insights-rocks-dashboard',
  insightsFeedbackDashboard = 'web-insights-feedback-dashboard',
  insightsIssuesDashboard = 'web-insights-issues-dashboard',
  insightsMeetingDashboard = 'web-insights-meeting-dashboard',
  insightsTodosDashboard = 'web-insights-todo-dashboard',
  insightsHideLegacyDashboard = 'web-insights-hide-legacy-dashboard',
  mfaDenyList = 'web-account-mfa-denylist',
  inlineDateEdit = 'web-inline-date-edit',
  vidyardLearningVideos = 'vidyard-learning-videos',
  linkedItemsRealTime = 'web-linked-items-real-time',
  timezonePrompt = 'web-app-timezone-prompt',
  microLearningVideos = 'web-app-micro-learning-videos',
  webDiscussionsPubnub = 'web-discussions-pubnub',
  rocksRedesign = 'web-rocks-redesign',
  rockCardRedesign = 'web-rocks-card-redesign',
  followersEnabled = 'web-followers',
  webRocksV3 = 'web-rocks-v3',
  referralProgram = 'web-referral-program',
  enableKnowledgePartnerHub = 'web-enable-knowledge-partner-hub',
  webTempPricingTier = 'web-temp-pricing-tier',
  linkedVision = 'web-linked-vision',
  webPricingGoLiveDate = 'web-pricing-go-live-date',
  calendarUrl = 'web-calendar-url',
  weeklyOneOnOneMeetingEnabled = 'weekly-one-on-one-meeting-enabled',
  webSharedRocks = 'web-shared-rocks',
  webLinkExistingItems = 'web-link-existing-items',
  enableKnowledgeSearch = 'web-enable-knowledge-search',
  commercialModelEntitlements = 'cm-entitlements',
  webIntegrationsAlbato = 'web-integrations-albato',
}

export interface FeatureFlagStateModel {
  userFlagsAreLoaded: boolean;
  [FeatureFlagKeys.appLogging]: LoggingFeatureFlagState;
  [FeatureFlagKeys.appRefresh]: boolean;
  [FeatureFlagKeys.commercialModelActive]: boolean;
  [FeatureFlagKeys.coreNinetyDefaultVTO]: boolean;
  [FeatureFlagKeys.directoryAddUsersModal]: boolean;
  [FeatureFlagKeys.directoryV2]: boolean;
  [FeatureFlagKeys.discussionFormV2]: boolean;
  [FeatureFlagKeys.dragDropFileAttachments]: boolean;
  [FeatureFlagKeys.enableAssessments]: boolean;
  [FeatureFlagKeys.enableAssessments]: boolean;
  [FeatureFlagKeys.enableAssessmentsSidenavPill]: boolean;
  [FeatureFlagKeys.enableContactCard]: boolean;
  [FeatureFlagKeys.enableLearningModule]: boolean;
  [FeatureFlagKeys.enableLearningModuleCategories]: boolean;
  [FeatureFlagKeys.enableLearnMode90UGAOnly]: boolean;
  [FeatureFlagKeys.enableTeamPageEnhancements]: boolean;
  [FeatureFlagKeys.enableRepeatConversations]: boolean;
  [FeatureFlagKeys.enableFrequentDiscussions]: boolean;
  [FeatureFlagKeys.gettingStartedGuide]: boolean;
  [FeatureFlagKeys.headlinesV2]: boolean;
  [FeatureFlagKeys.hideReferFriendMenuItem]: boolean;
  [FeatureFlagKeys.kpiManager]: boolean;
  [FeatureFlagKeys.mazChat]: boolean;
  [FeatureFlagKeys.mazChat90os]: boolean;
  [FeatureFlagKeys.modifyBillingCoupons]: boolean;
  [FeatureFlagKeys.partnerHubInsights]: boolean;
  [FeatureFlagKeys.pinnacleDefaultVTO]: boolean;
  [FeatureFlagKeys.pinnacleMissingData]: boolean;
  [FeatureFlagKeys.responsibilitiesBeta]: boolean;
  [FeatureFlagKeys.responsibilitiesBetaSetAsPrimary]: boolean;
  [FeatureFlagKeys.responsibilitiesHideTopSeatSelect]: boolean;
  [FeatureFlagKeys.responsibilitiesSearch]: boolean;
  [FeatureFlagKeys.responsibilitiesSeatDetailsV3]: boolean;
  [FeatureFlagKeys.sessionLogout]: boolean;
  [FeatureFlagKeys.showUserEventNotificationsTab]: boolean;
  [FeatureFlagKeys.webUserSettingsIntegrationZapier]: boolean;
  [FeatureFlagKeys.webIntegrationsMicrosoft]: boolean;
  [FeatureFlagKeys.trialBanner]: boolean;
  [FeatureFlagKeys.trialCountdown]: boolean;
  [FeatureFlagKeys.todosTabs]: boolean;
  [FeatureFlagKeys.useImageUploaderForCompany]: boolean;
  [FeatureFlagKeys.quarterlyQuestionSettings]: boolean;
  [FeatureFlagKeys.webWeeklyConversations]: boolean;
  [FeatureFlagKeys.webConversationSettingsRatings]: boolean;
  [FeatureFlagKeys.webExcelDownload]: boolean;
  [FeatureFlagKeys.webExcelDownloadBatch2]: boolean;
  [FeatureFlagKeys.clientFacingDataUpload]: boolean;
  [FeatureFlagKeys.insightsTabNameAbTest]: LabelAbTest;
  [FeatureFlagKeys.insightsExpandButtonConfiguration]: object;
  [FeatureFlagKeys.insightsTopLevel]: boolean;
  [FeatureFlagKeys.insightsHiddenWidgets]: { HiddenWidgetSelectors: string[] };
  [FeatureFlagKeys.insightsRightPersonRightSeat]: boolean;
  [FeatureFlagKeys.insightsRocksDashboard]: boolean;
  [FeatureFlagKeys.insightsFeedbackDashboard]: boolean;
  [FeatureFlagKeys.insightsIssuesDashboard]: boolean;
  [FeatureFlagKeys.insightsMeetingDashboard]: boolean;
  [FeatureFlagKeys.insightsTodosDashboard]: boolean;
  [FeatureFlagKeys.insightsHideLegacyDashboard]: boolean;
  [FeatureFlagKeys.mfaDenyList]: Array<string>;
  [FeatureFlagKeys.inlineDateEdit]: boolean;
  [FeatureFlagKeys.vidyardLearningVideos]: boolean;
  [FeatureFlagKeys.linkedItemsRealTime]: boolean;
  [FeatureFlagKeys.timezonePrompt]: boolean;
  [FeatureFlagKeys.microLearningVideos]: boolean;
  [FeatureFlagKeys.webDiscussionsPubnub]: boolean;
  [FeatureFlagKeys.rocksRedesign]: boolean;
  [FeatureFlagKeys.rockCardRedesign]: boolean;
  [FeatureFlagKeys.followersEnabled]: boolean;
  [FeatureFlagKeys.webRocksV3]: boolean;
  [FeatureFlagKeys.referralProgram]: boolean;
  [FeatureFlagKeys.enableKnowledgePartnerHub]: boolean;
  [FeatureFlagKeys.webTempPricingTier]: PlanName;
  [FeatureFlagKeys.linkedVision]: boolean;
  [FeatureFlagKeys.webPricingGoLiveDate]: string;
  [FeatureFlagKeys.calendarUrl]: boolean;
  [FeatureFlagKeys.weeklyOneOnOneMeetingEnabled]: boolean;
  [FeatureFlagKeys.webSharedRocks]: boolean;
  [FeatureFlagKeys.webLinkExistingItems]: boolean;
  [FeatureFlagKeys.enableKnowledgeSearch]: boolean;
  [FeatureFlagKeys.commercialModelEntitlements]: boolean;
  [FeatureFlagKeys.webIntegrationsAlbato]: boolean;
}

export const initialFeatureFlagState: FeatureFlagStateModel = {
  userFlagsAreLoaded: false,
  [FeatureFlagKeys.appLogging]: InitialLoggingFeatureFlagState,
  [FeatureFlagKeys.appRefresh]: false,
  [FeatureFlagKeys.commercialModelActive]: false,
  [FeatureFlagKeys.coreNinetyDefaultVTO]: false,
  [FeatureFlagKeys.directoryAddUsersModal]: false,
  [FeatureFlagKeys.directoryV2]: false,
  [FeatureFlagKeys.discussionFormV2]: false,
  [FeatureFlagKeys.dragDropFileAttachments]: false,
  [FeatureFlagKeys.enableAssessments]: false,
  [FeatureFlagKeys.enableAssessmentsSidenavPill]: false,
  [FeatureFlagKeys.enableContactCard]: false,
  [FeatureFlagKeys.enableLearningModule]: false,
  [FeatureFlagKeys.enableLearningModuleCategories]: false,
  [FeatureFlagKeys.enableLearnMode90UGAOnly]: false,
  [FeatureFlagKeys.enableKnowledgePartnerHub]: false,
  [FeatureFlagKeys.enableTeamPageEnhancements]: false,
  [FeatureFlagKeys.enableRepeatConversations]: false,
  [FeatureFlagKeys.enableFrequentDiscussions]: false,
  [FeatureFlagKeys.gettingStartedGuide]: false,
  [FeatureFlagKeys.headlinesV2]: false,
  [FeatureFlagKeys.hideReferFriendMenuItem]: true,
  [FeatureFlagKeys.kpiManager]: false,
  [FeatureFlagKeys.mazChat]: false,
  [FeatureFlagKeys.mazChat90os]: false,
  [FeatureFlagKeys.modifyBillingCoupons]: false,
  [FeatureFlagKeys.partnerHubInsights]: false,
  [FeatureFlagKeys.pinnacleDefaultVTO]: false,
  [FeatureFlagKeys.pinnacleMissingData]: false,
  [FeatureFlagKeys.responsibilitiesBeta]: true,
  [FeatureFlagKeys.responsibilitiesBetaSetAsPrimary]: true,
  [FeatureFlagKeys.responsibilitiesHideTopSeatSelect]: false,
  [FeatureFlagKeys.responsibilitiesSearch]: false,
  [FeatureFlagKeys.responsibilitiesSeatDetailsV3]: false,
  [FeatureFlagKeys.sessionLogout]: false,
  [FeatureFlagKeys.showUserEventNotificationsTab]: false,
  [FeatureFlagKeys.webUserSettingsIntegrationZapier]: false,
  [FeatureFlagKeys.webIntegrationsMicrosoft]: false,
  [FeatureFlagKeys.trialBanner]: false,
  [FeatureFlagKeys.trialCountdown]: false,
  [FeatureFlagKeys.todosTabs]: false,
  [FeatureFlagKeys.useImageUploaderForCompany]: false,
  [FeatureFlagKeys.quarterlyQuestionSettings]: false,
  [FeatureFlagKeys.webConversationSettingsRatings]: false,
  [FeatureFlagKeys.webWeeklyConversations]: false,
  [FeatureFlagKeys.webExcelDownload]: false,
  [FeatureFlagKeys.webExcelDownloadBatch2]: false,
  [FeatureFlagKeys.clientFacingDataUpload]: false,
  [FeatureFlagKeys.insightsTabNameAbTest]: { label: 'Insights', icon: 'lightbulb' },
  [FeatureFlagKeys.insightsExpandButtonConfiguration]: {
    buttonStyle: 'flat',
    brandColor: false,
  },
  [FeatureFlagKeys.insightsTopLevel]: false,
  [FeatureFlagKeys.insightsHiddenWidgets]: { HiddenWidgetSelectors: [] },
  [FeatureFlagKeys.insightsRightPersonRightSeat]: false,
  [FeatureFlagKeys.insightsRocksDashboard]: false,
  [FeatureFlagKeys.insightsFeedbackDashboard]: false,
  [FeatureFlagKeys.insightsIssuesDashboard]: false,
  [FeatureFlagKeys.insightsMeetingDashboard]: false,
  [FeatureFlagKeys.insightsTodosDashboard]: false,
  [FeatureFlagKeys.insightsHideLegacyDashboard]: false,
  [FeatureFlagKeys.mfaDenyList]: [],
  [FeatureFlagKeys.inlineDateEdit]: false,
  [FeatureFlagKeys.vidyardLearningVideos]: false,
  [FeatureFlagKeys.linkedItemsRealTime]: false,
  [FeatureFlagKeys.timezonePrompt]: false,
  [FeatureFlagKeys.microLearningVideos]: false,
  [FeatureFlagKeys.webDiscussionsPubnub]: false,
  [FeatureFlagKeys.rocksRedesign]: false,
  [FeatureFlagKeys.rockCardRedesign]: false,
  [FeatureFlagKeys.followersEnabled]: false,
  [FeatureFlagKeys.webRocksV3]: false,
  [FeatureFlagKeys.referralProgram]: false,
  [FeatureFlagKeys.webTempPricingTier]: PlanName.Thrive,
  [FeatureFlagKeys.linkedVision]: false,
  [FeatureFlagKeys.webPricingGoLiveDate]: '2025-12-31',
  [FeatureFlagKeys.calendarUrl]: false,
  [FeatureFlagKeys.weeklyOneOnOneMeetingEnabled]: false,
  [FeatureFlagKeys.webSharedRocks]: false,
  [FeatureFlagKeys.webLinkExistingItems]: false,
  [FeatureFlagKeys.enableKnowledgeSearch]: false,
  [FeatureFlagKeys.commercialModelEntitlements]: false,
  [FeatureFlagKeys.webIntegrationsAlbato]: false,
};
