import { Injectable } from '@angular/core';

import { SessionStorageService } from '../../../services/storage.service';

// Global function provided by Google Analytics
// Reference: https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference
// eslint-disable-next-line @typescript-eslint/ban-types
declare let ga: Function & { loaded: boolean; getAll(): any };

@Injectable()
export class GoogleAnalyticsService {
  constructor(private storage: SessionStorageService) {}

  private writeCache(key: string, value: string): void {
    if (value === undefined) return;

    this.storage.set(key, value);
  }

  private readCache(key: string): string | undefined {
    return this.storage.get(key);
  }

  /**
   * We wrap the ga() function so we can cache results and not need to re-fetch the tracker.
   * I believe there was an issue with just saving a reference to the tracker.
   *
   * More on ga(tracker): https://stackoverflow.com/a/40269849/7903952
   */
  async get(field: string): Promise<string | undefined> {
    const v = this.readCache(field);

    if (v) {
      return v;
    }

    /**
     * Need to use a promise wrapper so we can correctly propograte the results in the
     * ga() callback.
     */
    return new Promise((res: Function) => {
      try {
        if (!('ga' in window)) {
          res(undefined);
          return;
        }

        ga(tracker => {
          const value = tracker?.get(field) || undefined;

          // Don't cache undefined
          if (value?.length) {
            this.writeCache(field, value);
          }

          res(value);
        });
      } catch (e) {
        // https://traxion.atlassian.net/browse/DEV-3368
        // console.warn(`Error in GoogleAnalyticsService.get(): ${e.message}`);
        res(undefined);
      }
    });
  }

  async getClientId(): Promise<string | undefined> {
    const id = await this.get('clientId');

    return id ? String(id) : id;
  }
}
