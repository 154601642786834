import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraInputAutoFocusDirective } from '../terra-input-auto-focus/terra-input-autofocus.directive';
import { TerraInputPrefixComponent, TerraInputSuffixComponent, TerraInputUnitComponent } from '../terra-input-base';

import { TerraNumberInputComponent } from './terra-number-input.component';

@NgModule({
  imports: [
    CommonModule,
    TerraNumberInputComponent,
    TerraInputAutoFocusDirective,
    TerraInputPrefixComponent,
    TerraInputSuffixComponent,
    TerraInputUnitComponent,
  ],
  exports: [
    TerraNumberInputComponent,
    TerraInputAutoFocusDirective,
    TerraInputPrefixComponent,
    TerraInputSuffixComponent,
    TerraInputUnitComponent,
  ],
})
export class TerraNumberInputModule {}
