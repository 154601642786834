import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraCheckboxModule, TerraDividerModule, TerraIconModule } from '@ninety/terra';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { MeetingAgendaType } from '@ninety/ui/legacy/shared/models/meetings/meeting-agenda-type.enum';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

import { MeetingDialogActions } from '../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../_state/meetings.selectors';
import { MeetingsFacade } from '../../meetings.facade';

@Component({
  selector: 'ninety-print-agendas-dialog',
  templateUrl: './print-meeting-agenda.component.html',
  styleUrls: ['./print-meeting-agenda.component.scss'],
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px 16px 8px 16px;
        min-width: 600px;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    MaterialMdcModule,
    FormsModule,
    ButtonComponent,
    TerraDividerModule,
    TerraIconModule,
    LetDirective,
    TerraCheckboxModule,
  ],
})
export class PrintAgendasDialogComponent {
  MeetingAgendaType = MeetingAgendaType;

  private store = inject(Store);
  protected dialogRef = inject(MatDialogRef<PrintAgendasDialogComponent>);
  private meetingFacade = inject(MeetingsFacade);

  language$ = this.store.select(selectLanguage);
  readonly customMeetingSettings$ = this.store.select(MeetingsStateSelectors.selectCustomMeetingSettings);

  selectedMeetingType: MeetingAgendaType;

  selectedCustomAgendaId: string | null;

  printAgenda(meetingType: MeetingAgendaType, customAgendaId?: string) {
    this.store.dispatch(
      MeetingDialogActions.printMeetingAgenda({
        meetingType: this.getMeetingTypeFromAgendaType(meetingType),
        sortOptions: this.meetingFacade.getPrintMeetingAgendaSortOptions(),
        ...(customAgendaId ? { customAgendaId } : {}),
      })
    );
    this.dialogRef.close();
  }

  private getMeetingTypeFromAgendaType(meetingType: MeetingAgendaType) {
    switch (meetingType) {
      case MeetingAgendaType.weekly:
        return MeetingType.weekly;
      case MeetingAgendaType.quarterly:
        return MeetingType.quarterly;
      case MeetingAgendaType.annualDayOne:
        return MeetingType.annualDayOne;
      case MeetingAgendaType.weeklyOneOnOne:
        return MeetingType.weeklyOneOnOne;
      case MeetingAgendaType.custom:
        return MeetingType.custom;
      default:
        return null;
    }
  }
}
