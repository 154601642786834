<ninety-card *ngrxLet="companyDeletionResult$ as companyDeletionResult" class="ninety-card">
  <ninety-card-header header>
    <div class="terra-title">Delete Company Data</div>
    <terra-form-field>
      <terra-text-input
        [formControl]="companyIdToDeleteSearch"
        placeholder="Enter company ID"
        (keydown.enter)="findCompanyById()">
        <terra-input-prefix class="search-icon">
          <terra-icon icon="search" />
        </terra-input-prefix>
      </terra-text-input>
      <terra-error *ngIf="companyIdToDeleteSearch.errors?.isObjectId">Please enter a valid object id</terra-error>
    </terra-form-field>
  </ninety-card-header>

  <div class="card-content" *ngIf="companyToDelete">
    <div class="found-company">
      <img
        *ngIf="companyToDelete.logo?.url"
        [src]="companyToDelete.logo.url"
        alt="company logo"
        class="company-logo"
        loading="lazy" />

      <div class="flex1">
        {{ companyToDelete.name }}
      </div>

      <ninety-button filled negative (click)="openDeleteCompanyDialog()">
        <terra-icon icon="delete" />
        Hard Delete
      </ninety-button>
    </div>
  </div>

  <div class="flex-1">
    <div class="left-space-16" *ngFor="let line of companyDeletionResult.split('\n')">
      {{ line }}
    </div>
  </div>
</ninety-card>
