<div
  class="getting-started-container"
  *ngrxLet="mazChatEnabled$ as mazEnabled"
  [class.maz-disabled]="!mazEnabled"
  ninetyStopPropagation
  (ninetyClickOutside)="close()"
  data-cy="getting-started-guide-modal">
  <div class="phases-container" *ngrxLet="selectedPhase$ as phase" [class.phase-selected]="phase">
    <ninety-phase-list *ngIf="!phase; else phaseDetails" />

    <ng-template #phaseDetails>
      <ninety-phase-details [phase]="phase" [mazChatEnabled]="mazEnabled" />
    </ng-template>
  </div>
  <div class="maz-footer" *ngIf="mazEnabled">
    <div
      class="maz-chat-panel"
      [@toggleMazChat]="(isMazChatExpanded$ | ngrxPush) ? 'expanded' : 'collapsed'"
      data-cy="maz-chat-panel">
      <ninety-maz-chat />
    </div>
  </div>
</div>
