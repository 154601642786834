import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { LinkedItem, LinkedItemTypeEnum } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { LinkedItems } from '@ninety/ui/legacy/shared/models/linked-items/linked-items-response.model';
import { LinkedItemFromRealtime } from '@ninety/ui/legacy/shared/models/linked-items/linked-items.model';
import { RealtimeMessage } from '@ninety/ui/legacy/shared/models/meetings/realtime-message';

export const LinkedItemsActions = createActionGroup({
  source: 'Linked Items Detail',
  events: {
    'Reset Linked Items': emptyProps(),
    'Get Linked Item': props<{ id: string; linkedItemType: LinkedItemTypeEnum }>(),
    'Get Linked Item Success': props<{ response: LinkedItems }>(),
    'Get Linked Item Failure': props<{ error: unknown }>(),
    'Unlink Item': props<{ id: string; linkedItemType: LinkedItemTypeEnum }>(),
    'Unlink Item Success': props<{ id: string; linkedItemType: LinkedItemTypeEnum }>(),
    'Unlink Item Failure': props<{ error: unknown }>(),
    'Link Item From Realtime': props<{
      linkedItem: LinkedItemFromRealtime;
    }>(),
    'Unlink Item From Realtime': props<{ id: string; linkedItemType: LinkedItemTypeEnum }>(),
    'Open Linked Item': props<{ linkedItemType: LinkedItemTypeEnum; linkedItemId: string }>(),
    'Created Linked Item': props<{ linkedItem: LinkedItemFromRealtime; createdFrom: LinkedItemTypeEnum }>(),
    'Display Created Linked Item': props<{ linkedItem: LinkedItemFromRealtime }>(),
    'Broadcast Message': props<{ message: RealtimeMessage }>(),
    'Broadcast Message Success': emptyProps(),
    'Broadcast Message Failure': props<{ error: unknown }>(),

    'Subscribe To Linked Items Channel': props<{ channelId: string }>(),
    'Open Linked Existing Items Dialog': props<{
      fromId: string;
      linkedItemType: LinkedItemTypeEnum;
      title: string;
      teamId: string;
    }>(),
    'Add Linked Items': props<{ linkedItems: LinkedItem[]; fromId: string; fromType: LinkedItemTypeEnum }>(),
    'Add Linked Items Failure': props<{ error: unknown }>(),
  },
});
