import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { startWith, Subject, takeUntil } from 'rxjs';

import { TerraIconComponent } from '../../terra-icon/terra-icon.component';
import { TerraInputBaseClass } from '../terra-input-base/terra-input-base';

@Component({
  selector: 'terra-text-input',
  standalone: true,
  exportAs: 'terraTextInput',
  imports: [CommonModule, FormsModule],
  templateUrl: './terra-text-input.component.html',
  styleUrls: ['./../terra-input-base/terra-input-base.scss', './terra-text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TerraInputBaseClass,
      useExisting: TerraTextInputComponent,
    },
  ],
})
export class TerraTextInputComponent extends TerraInputBaseClass implements AfterContentInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChildren(TerraIconComponent, { descendants: true }) private _icons!: QueryList<TerraIconComponent<any>>;

  private _destroyed$ = new Subject<void>();

  /**
   * Set the maxlength of characters allowed in the input
   */
  @Input() get maxlength(): string | number | undefined {
    return this._maxlength;
  }
  set maxlength(value: string | number | undefined) {
    this._maxlength = coerceNumberProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _maxlength?: number | undefined;

  /**
   * Set the inputmode of the input. The default undefined is what is needed in most cases.
   * Numeric will tell devices to show a numeric keyboard, and is useful for like a SMS code input
   * where a number input doesn't make complete sense.
   * @default undefined
   */
  @Input() get inputmode(): 'numeric' | undefined {
    return this._inputmode;
  }
  set inputmode(value: 'numeric' | undefined) {
    this._inputmode = value;
    this._changeDetectorRef.markForCheck();
  }
  private _inputmode?: 'numeric' | undefined;

  ngAfterContentInit(): void {
    this._icons.changes.pipe(startWith(this._icons), takeUntil(this._destroyed$)).subscribe(() => {
      this._icons.forEach(icon => {
        icon.size = 20;
      });
      this._changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
